import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import SingleButtonModal from '../../../shared/components/SingleButtonModal';
import { CountrySelectRow, TextInputRow } from '../../../shared/components/form_inputs/common';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { Buyer } from '../../../shared/types';

interface Props {
  open: boolean;
  onClose: () => void;
  buyer: Buyer;
}

export default function BuyerDetailsModal({ open, onClose, buyer }: Props) {
  const { id, companyName, legalForm, commercialRegisterNumber, website, address1, address2, zipCode, city, country } =
    buyer;

  const [editMode, setEditMode] = useState(false);

  const api = useAdminApi();

  const initialValues = useMemo(() => {
    return {
      id,
      companyName,
      legalForm,
      commercialRegisterNumber,
      address1,
      address2,
      zipCode,
      city,
      country,
      website,
    };
  }, [id, companyName, legalForm, commercialRegisterNumber, website, address1, address2, zipCode, city, country]);

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(requiredMsg),
    legalForm: Yup.string().required(requiredMsg),
    commercialRegisterNumber: Yup.string().required(requiredMsg),
    address1: Yup.string().required(requiredMsg),
    address2: Yup.string(),
    zipCode: Yup.string().required(requiredMsg),
    city: Yup.string().required(requiredMsg),
    country: Yup.string().required(requiredMsg),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Please enter a valid URL',
      )
      .required(requiredMsg),
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values) => {
        return api.users.buyers
          .updateBuyer(id, values)
          .then(async () => {
            await queryClient.invalidateQueries(['adminFetchBuyers']);
            await queryClient.invalidateQueries(['adminFetchAllBuyers']);
            enqueueSnackbar('Buyer details updated', { variant: 'success' });
            handleClose();
          })
          .catch((e) => {
            console.error(e);
            enqueueSnackbar('Failed to update buyer details', { variant: 'error' });
          });
      }}
    >
      {({ isSubmitting, isValid, submitForm, resetForm }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={() => {
              handleClose();
              resetForm();
            }}
            title="Buyer details"
            content={
              <>
                <TextInputRow fieldName="companyName" label="Company name" editMode={editMode} />
                <TextInputRow fieldName="legalForm" label="Legal form" editMode={editMode} />
                <TextInputRow
                  fieldName="commercialRegisterNumber"
                  label="Commercial register number"
                  editMode={editMode}
                />
                <TextInputRow fieldName="address1" label="Address line 1" editMode={editMode} />
                <TextInputRow fieldName="address2" label="Address line 2" editMode={editMode} />
                <TextInputRow fieldName="zipCode" label="ZIP code" editMode={editMode} />
                <TextInputRow fieldName="city" label="City" editMode={editMode} />
                <CountrySelectRow fieldName="country" label="Country" editMode={editMode} />
                <TextInputRow fieldName="website" label="Website" editMode={editMode} />
              </>
            }
            buttonLabel={!editMode ? 'Edit details' : 'Save details'}
            onClick={!editMode ? () => setEditMode(true) : submitForm}
            isSubmitting={isSubmitting}
            disabled={!editMode ? isSubmitting : isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
