import * as React from 'react';

const AttachmentIcon = () => (
  <svg width={18.015} height={19.512}>
    <path
      data-name="Path 5581"
      d="M4.733 19.513a4.733 4.733 0 0 1-3.348-8.082l8.279-8.2a3.618 3.618 0 0 1 5.114 5.118L7.335 15.88a.5.5 0 0 1-.711-.7l7.446-7.529a2.618 2.618 0 1 0-3.7-3.7l-8.279 8.2a3.735 3.735 0 0 0 5.284 5.281l8.236-8.236A4.8 4.8 0 1 0 8.83 2.4L2.84 8.39a.5.5 0 0 1-.707-.707L8.123 1.7a5.794 5.794 0 0 1 8.195 8.192l-8.236 8.236a4.721 4.721 0 0 1-3.349 1.385Z"
      fill="#c4c4c4"
    />
  </svg>
);

export default AttachmentIcon;
