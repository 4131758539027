import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import useWorkspaceConfiguration from '../shared/hooks/useWorkspaceConfiguration';
import { generateTheme } from './theme';
import LoadingBackdrop from '../shared/components/LoadingBackdrop';

interface Props {
  children: JSX.Element;
}

export default function BrandingProvider({ children }: Props) {
  const { data: workspaceConfig } = useWorkspaceConfiguration();

  const theme = useMemo(() => {
    if (workspaceConfig) {
      return generateTheme(workspaceConfig);
    }
  }, [workspaceConfig]);

  if (!theme) {
    return <LoadingBackdrop text="Loading, please wait." open />;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
