import React from 'react';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ButtonProps } from '@mui/material/Button/Button';
import styles from './styles.module.scss';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';

interface Props extends ButtonProps {
  children: JSX.Element | string;
  loading?: boolean;
}

export default function ConfirmButton({ children, loading = false, ...btnProps }: Props) {
  const theme = useTheme();

  return loading ? (
    <LoadingButton loading loadingPosition="start" startIcon={<CheckIcon />} variant="outlined">
      {children}
    </LoadingButton>
  ) : (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<CheckIcon />}
      {...btnProps}
      sx={{ color: theme.palette.accent1.main }}
      className={styles.confirmBtn}
    >
      {children}
    </Button>
  );
}
