import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import styles from './loanDetails.module.scss';
import { countryCodeToName, formatConvertedAmount, formatDate } from '../../../../../shared/utils/helpers';
import useApi from '../../../../../shared/hooks/useApi';

interface Props {
  loanId: number;
}

export default function CollateralTable({ loanId }: Props) {
  const api = useApi();

  const { data: propertyCollaterals } = useQuery(['fetchLoanPropertyCollaterals', loanId], () =>
    api.loan.propertyCollaterals(loanId),
  );

  const { data: nonPropertyCollaterals } = useQuery(['fetchLoanNonPropertyCollaterals', loanId], () =>
    api.loan.nonPropertyCollaterals(loanId),
  );

  const renderPropertyTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Protection ID</TableCell>
            <TableCell>Sector Of Property</TableCell>
            <TableCell>Type Of Property</TableCell>
            <TableCell>Type Of Occupancy</TableCell>
            <TableCell>Property Region</TableCell>
            <TableCell>Property Country</TableCell>
            <TableCell>Tenure</TableCell>
            <TableCell>Currency Of Collateral</TableCell>
            <TableCell>Latest Valuation Amount</TableCell>
            <TableCell>Date Of Latest Valuation</TableCell>
            <TableCell>Current Net Operating Income</TableCell>
            <TableCell>Percentage Complete</TableCell>
            <TableCell>Enforcement Status</TableCell>
            <TableCell>Enforcement Status Third Parties</TableCell>
            <TableCell>Mortgage Amount</TableCell>
            <TableCell>Lien Position</TableCell>
            <TableCell>Higher Ranking Loan </TableCell>
            <TableCell>Enforcement</TableCell>
            <TableCell align="right">Enforcement Amount</TableCell>
            <TableCell align="right">Enforcement Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {propertyCollaterals?.map((collateral) => (
            <TableRow key={collateral.id}>
              <TableCell>{collateral.protectionIdentifier}</TableCell>
              <TableCell>{collateral.sectorOfProperty}</TableCell>
              <TableCell>{collateral.typeOfProperty}</TableCell>
              <TableCell>{collateral.typeOfOccupancy}</TableCell>
              <TableCell>{collateral.geographicRegionOfProperty}</TableCell>
              <TableCell>{countryCodeToName(collateral.propertyCountry)}</TableCell>
              <TableCell>{collateral.tenure}</TableCell>

              <TableCell>{collateral.currencyOfProperty}</TableCell>
              <TableCell>
                {collateral.latestValuationAmount
                  ? formatConvertedAmount(
                      collateral.latestValuationAmount,
                      collateral.currencyOfProperty,
                      collateral.latestValuationAmountConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell>
                {collateral.dateOfLatestValuation ? formatDate(collateral.dateOfLatestValuation) : 'N/A'}
              </TableCell>
              <TableCell>
                {collateral.currentNetOperatingIncome
                  ? formatConvertedAmount(
                      collateral.currentNetOperatingIncome,
                      collateral.currencyOfProperty,
                      collateral.currentNetOperatingIncomeConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell>{collateral.percentageComplete && `${collateral.percentageComplete}%`}</TableCell>
              <TableCell>{collateral.enforcementStatus}</TableCell>
              <TableCell>{collateral.enforcementStatusThirdParties}</TableCell>
              <TableCell>
                {collateral.mortgageAmount
                  ? formatConvertedAmount(
                      collateral.mortgageAmount,
                      collateral.currencyOfProperty,
                      collateral.mortgageAmountConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell>{collateral.lienPosition}</TableCell>
              <TableCell>{collateral.higherRankingLoan}</TableCell>
              <TableCell>{collateral.hasEnforcement ? 'Yes' : 'No'}</TableCell>
              <TableCell align="right">
                {collateral.enforcementAmount
                  ? formatConvertedAmount(
                      collateral.enforcementAmount,
                      collateral.currencyOfProperty,
                      collateral.enforcementAmountConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell align="right">
                {collateral.enforcementCost
                  ? formatConvertedAmount(
                      collateral.enforcementCost,
                      collateral.currencyOfProperty,
                      collateral.enforcementCostConverted,
                    )
                  : 'N/A'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderNonPropertyTable = () => (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Protection ID</TableCell>
            <TableCell>Collateral Type</TableCell>
            <TableCell>Currency Of Collateral</TableCell>
            <TableCell align="right">Latest Valuation Amount</TableCell>
            <TableCell>Date Of Latest Valuation</TableCell>
            <TableCell>Enforcement Status</TableCell>
            <TableCell>Enforcement Status Third Parties</TableCell>
            <TableCell>Lien Position</TableCell>
            <TableCell>Higher Ranking Loan </TableCell>
            <TableCell align="right">Guarantee Amount</TableCell>
            <TableCell>Enforcement</TableCell>
            <TableCell align="right">Enforcement Amount</TableCell>
            <TableCell align="right">Enforcement Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nonPropertyCollaterals?.map((collateral) => (
            <TableRow key={collateral.id}>
              <TableCell>{collateral.protectionIdentifier}</TableCell>
              <TableCell>{collateral.collateralType}</TableCell>
              <TableCell>{collateral.currencyOfCollateral}</TableCell>
              <TableCell align="right">
                {collateral.latestValuationAmount
                  ? formatConvertedAmount(
                      collateral.latestValuationAmount,
                      collateral.currencyOfCollateral,
                      collateral.latestValuationAmountConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell>{collateral.dateOfLatestValuation && formatDate(collateral.dateOfLatestValuation)}</TableCell>
              <TableCell>{collateral.enforcementStatus}</TableCell>
              <TableCell>{collateral.enforcementStatusThirdParties}</TableCell>
              <TableCell>{collateral.lienPosition}</TableCell>
              <TableCell>{collateral.higherRankingLoan}</TableCell>
              <TableCell align="right">
                {collateral.guaranteeAmount
                  ? formatConvertedAmount(
                      collateral.guaranteeAmount,
                      collateral.currencyOfCollateral,
                      collateral.guaranteeAmountConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell>{collateral.hasEnforcement ? 'Yes' : 'No'}</TableCell>
              <TableCell align="right">
                {collateral.enforcementAmount
                  ? formatConvertedAmount(
                      collateral.enforcementAmount,
                      collateral.currencyOfCollateral,
                      collateral.enforcementAmountConverted,
                    )
                  : 'N/A'}
              </TableCell>
              <TableCell align="right">
                {collateral.enforcementCost
                  ? formatConvertedAmount(
                      collateral.enforcementCost,
                      collateral.currencyOfCollateral,
                      collateral.enforcementCostConverted,
                    )
                  : 'N/A'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {!_.isEmpty(propertyCollaterals) && (
        <div className={styles.tableSection}>
          <Typography className={styles.section__title} variant="h2">
            Property Collateral
          </Typography>
          {renderPropertyTable()}
        </div>
      )}
      {!_.isEmpty(nonPropertyCollaterals) && (
        <div className={styles.tableSection}>
          <Typography className={styles.section__title} variant="h2">
            Non-Property Collateral
          </Typography>
          {renderNonPropertyTable()}
        </div>
      )}
    </>
  );
}
