import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Menu, MenuItem, Toolbar } from '@mui/material';
import NavLogo from './NavLogo';
import styles from './nav.module.scss';
import NavButton from './NavButton';
import CurrencySelect from './CurrencySelect';
import { useAuth } from '../../shared/hooks/useAuth';
import useUser from '../../shared/hooks/useUser';
import useWorkspaceConfiguration from '../../shared/hooks/useWorkspaceConfiguration';
import MessageCenterNavBarAdminButton from '../../Admin/MessageCenterPage/MessageCenterNavBarAdminButton';
import MessageCenterNavBarButton from '../../MessageCenterPage/MessageCenterNavBarButton';

export default function Index() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const { user } = useUser();
  const { logout } = useAuth();

  const { data } = useWorkspaceConfiguration();
  const logoImage = data?.navbarLogoImage;

  return (
    <AppBar position="sticky" className={styles.navBar}>
      <Toolbar>
        <div className={styles.navMenu}>
          <Link to="/">
            {logoImage ? (
              <Box
                component="img"
                sx={{
                  height: 35,
                }}
                src={logoImage}
              />
            ) : (
              <NavLogo />
            )}
          </Link>
          <NavButton to="/marketplace">Marketplace</NavButton>
          {user?.isAdmin ? (
            [
              <NavButton key="/portfolio_manager" to="/portfolio_manager">
                Portfolio Manager
              </NavButton>,
              <NavButton key="/buyers" to="/buyers">
                Buyers
              </NavButton>,
            ]
          ) : (
            <NavButton to="/orderbook">Orderbook</NavButton>
          )}
        </div>
        {user && (
          <Box display="flex" alignItems="center">
            <CurrencySelect />
            {user.isAdmin ? <MessageCenterNavBarAdminButton /> : <MessageCenterNavBarButton />}
            <Button
              variant="contained"
              color="primary"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
            >
              Account
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate('/user_profile');
                  handleClose();
                }}
              >
                User Profile
              </MenuItem>
              {user.company &&
                !user.isAdmin && [
                  <MenuItem
                    key="company-kyc"
                    onClick={() => {
                      navigate('/company_kyc');
                      handleClose();
                    }}
                  >
                    Company KYC Data
                  </MenuItem>,
                  <MenuItem
                    key="company-users"
                    onClick={() => {
                      navigate('/company_users');
                      handleClose();
                    }}
                  >
                    Company Users
                  </MenuItem>,
                ]}
              {user.isAdmin && (
                <MenuItem
                  key="admin-users"
                  onClick={() => {
                    navigate('/users_admins');
                    handleClose();
                  }}
                >
                  Admins
                </MenuItem>
              )}
              <MenuItem onClick={logout}>Log Out</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
