import { Grid, Paper, Typography } from '@mui/material';
import styles from './qna.module.scss';
import React, { useState } from 'react';
import ChatConversationList from '../../../Admin/PortfolioManagerPage/PortfolioDetailsPage/QuestionsAndAnswersTab/ChatConversationList';
import MessagesAdmin from '../../../Admin/PortfolioManagerPage/PortfolioDetailsPage/QuestionsAndAnswersTab/MessagesAdmin';
import MessagesDataRoom from '../../../Portfolios/PortfolioDetails/PortfolioData/QuestionsAndAnswersTab/MessagesDataRoom';
import Announcements from './Announcements';
import useApi from '../../hooks/useApi';
import { useQuery } from '@tanstack/react-query';
import { PortfolioAnnouncement } from '../../types';
import useAdminApi from '../../hooks/useAdminApi';

interface Props {
  portfolioId: number;
  adminView?: boolean;
  readOnly?: boolean;
}

export default function QuestionsAndAnswersTab({ portfolioId, adminView = false, readOnly = false }: Props) {
  const [selectedConversationId, selectConversationId] = useState<number | null>(null);

  const api = useApi();
  const adminApi = useAdminApi();

  const { data } = useQuery(['fetchPortfolioAnnouncements', portfolioId], () =>
    adminView
      ? adminApi.portfolios.fetchAnnouncements(portfolioId)
      : api.portfolio_snapshot.fetchPortfolioAnnouncements(portfolioId),
  );
  const announcements: PortfolioAnnouncement[] = data || [];

  return (
    <Grid container spacing={4}>
      {(adminView || Boolean(announcements.length)) && (
        <Grid item xs={12}>
          <Paper variant="outlined">
            <Grid container sx={{ paddingBottom: '20px' }}>
              <Grid item xs={12}>
                <Typography variant="h2" className={styles.section__header}>
                  Announcements
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Announcements portfolioId={portfolioId} announcements={announcements} adminView={adminView} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h2" className={styles.section__header}>
                Chat
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                {adminView && (
                  <Grid
                    item
                    xs={3}
                    style={{
                      borderRight: 1,
                      borderColor: '#EBEBEB',
                      borderStyle: 'solid',
                      maxHeight: 626,
                      overflowY: 'scroll',
                    }}
                  >
                    <ChatConversationList
                      portfolioId={portfolioId}
                      selectedConversationId={selectedConversationId}
                      selectConversationId={selectConversationId}
                    />
                  </Grid>
                )}
                <Grid item xs={adminView ? 9 : 12} style={{ display: 'flex' }}>
                  {adminView ? (
                    <MessagesAdmin
                      portfolioId={portfolioId}
                      selectedConversationId={selectedConversationId}
                      readOnly={readOnly}
                    />
                  ) : (
                    <MessagesDataRoom portfolioId={portfolioId} readOnly={readOnly} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
