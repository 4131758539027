import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { RepaymentScreeningRow } from '../shared/types';

interface Props {
  data?: RepaymentScreeningRow[];
  currency?: string;
}

export default function RepaymentScreeningTable({ data = [], currency = 'EUR' }: Props) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Key figures</TableCell>
            <TableCell align="right">{`Balance (${currency} m)`}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row" align="left">
                {row.key}
              </TableCell>
              <TableCell align="right">{row.balance.toFixed(1)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
