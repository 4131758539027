import React from 'react';
import Header from './Header';
import Preview from './Preview';
import { PortfolioSnapshot } from '../../../shared/types';

interface Props {
  portfolio: PortfolioSnapshot;
  isPortfolioManager?: boolean;
}

export default function PortfolioSummary({ portfolio, isPortfolioManager = false }: Props) {
  return (
    <>
      <Header portfolio={portfolio} />
      <Preview portfolio={portfolio} isPortfolioManager={isPortfolioManager} />
    </>
  );
}
