import React, { useState } from 'react';
import AuthLayout from '../AuthLayout';
import ResetConfirmForm from './ResetConfirmForm';

export default function ResetPasswordConfirm() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  return (
    <AuthLayout
      title="Set New Password"
      errorMsg={error ? 'Failed to reset password.' : ''}
      successMsg={success ? 'Successfully reset password.' : ''}
    >
      <ResetConfirmForm setError={setError} setSuccess={setSuccess} />
    </AuthLayout>
  );
}
