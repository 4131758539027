import React from 'react';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonProps } from '@mui/material/Button/Button';
import styles from './styles.module.scss';
import { useTheme } from '@mui/material/styles';

interface Props extends ButtonProps {
  children: JSX.Element | string;
}

export default function CancelButton({ children, ...btnProps }: Props) {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<CloseIcon />}
      {...btnProps}
      sx={{ color: theme.palette.accent2.main }}
      className={styles.cancelBtn}
    >
      {children}
    </Button>
  );
}
