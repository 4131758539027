import { getStoredAccessToken } from '../utils/authToken';
import { useQuery } from '@tanstack/react-query';
import { KycStatusEnum, User } from '../types';
import useClient from './useClient';

export default function useUser() {
  const client = useClient();

  const accessToken = getStoredAccessToken();
  const fetchUser = async () => client.get('/auth/users/me/').then((res) => res.data as User);
  const { data: user } = useQuery(['fetchMe'], () => fetchUser(), {
    enabled: Boolean(accessToken),
  });

  const isUserFetched = user !== undefined;

  const isKycDone = user?.kycStatus === KycStatusEnum.CONFIRMED;

  const acceptedTos = Boolean(user?.acceptedTos);

  return {
    user,
    isUserFetched,
    isKycDone,
    acceptedTos,
  };
}
