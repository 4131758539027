import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import Messages from '../shared/components/chat/Messages';
import useApi from '../shared/hooks/useApi';
import { GeneralChatConversationList, GeneralChatMessageTypeEnum } from '../shared/types';

interface Props {
  selectedConversation: GeneralChatConversationList;
  deselectConversation: () => void;
}

const pageSize = 20;

export default function GeneralChatMessages({ selectedConversation, deselectConversation }: Props) {
  const api = useApi();
  const queryClient = useQueryClient();

  const [calledCount, setCalledCount] = useState(0);
  const [scrollToBottomOnInitialLoad, setScrollToBottomOnInitialLoad] = useState(false);

  const postMessage = async (form: FormData) => {
    if (!selectedConversation.conversationId) return;
    await api.messageCenter.postGeneralChatMessage(selectedConversation.conversationId, form);
    await queryClient.invalidateQueries(['fetchGeneralChatMessages', selectedConversation.conversationId]);
  };

  const infiniteQuery = useInfiniteQuery(
    ['fetchGeneralChatMessages', selectedConversation.conversationId],
    ({ pageParam = 1 }) => {
      return api.messageCenter.fetchGeneralChatMessages(selectedConversation.conversationId, pageParam, pageSize);
    },
    {
      keepPreviousData: true,
      refetchInterval: 10000,
      getNextPageParam: (lastPage, pages) => {
        const currentPage = pages.length;
        const nextPageAvailavble = Boolean(lastPage?.next);
        if (nextPageAvailavble) {
          return currentPage + 1;
        }
      },
      onSuccess: () => {
        if (calledCount === 0) {
          setScrollToBottomOnInitialLoad(true);
          setCalledCount(calledCount + 1);
          queryClient.invalidateQueries(['fetchGeneralChatConversationList']);
        }
      },
    },
  );

  return (
    <Messages
      adminView
      showFormButtons
      infiniteQuery={infiniteQuery}
      shouldDisplayForm={true}
      scrollToBottomOnInitialLoad={scrollToBottomOnInitialLoad}
      setScrollToBottomOnInitialLoad={setScrollToBottomOnInitialLoad}
      readOnly={false}
      postMessage={postMessage}
      showSelectThreadMessage={false}
      setInitialHeight={infiniteQuery.isInitialLoading}
      highlightedMessageType={GeneralChatMessageTypeEnum.ADMIN}
      onCancel={deselectConversation}
      downloadAttachment={api.messageCenter.downloadGeneralChatAttachment}
    />
  );
}
