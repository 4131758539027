import { Button } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import DeleteAttachmentIcon from '../icons/DeleteAttachmentIcon';
import MessageAttachmentButton from './MessageAttachmentButton';
import PostMessageInput from './PostMessageInput';
import styles from './qna.module.scss';
import { AnnouncementAttachment } from '../../types';
const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: 64, height: 25, marginTop: 3 }}>
      <CircularProgress size={24} />
    </div>
  );
};

interface FormValues {
  text: string;
  attachments: Array<AnnouncementAttachment | File>;
}

export default function AnnouncementUpdateForm() {
  const { values, isSubmitting, setFieldValue } = useFormikContext<FormValues>();

  return (
    <Form className={styles.qna__form} autoComplete="off">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: isSubmitting ? 'rgba(239, 239, 239, 0.3' : 'white',
        }}
        className={styles.qna__form__input}
      >
        {isSubmitting ? <Loader /> : <MessageAttachmentButton />}
        <div className={styles.qna__message_attachment}>
          <Field component={PostMessageInput} variant="standard" name="text" type="text" disabled={isSubmitting} />
          {Boolean(values.attachments.length) && (
            <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 5, marginLeft: 5 }}>
              {values.attachments.map((attachment, index) => {
                return (
                  <div key={('id' in attachment && attachment.id) || URL.createObjectURL(attachment as File)}>
                    <Button
                      // @ts-ignore
                      key={attachment.name}
                      size="small"
                      style={{ paddingLeft: 0 }}
                      endIcon={<DeleteAttachmentIcon />}
                      onClick={() => {
                        // Clear input files so that the removed file can be added again
                        const attachmentUploadInput = document?.getElementById(
                          'qaAddAttachmentInput',
                        ) as HTMLInputElement | null;
                        if (attachmentUploadInput) {
                          attachmentUploadInput.value = '';
                        }
                        setFieldValue(
                          'attachments',
                          values.attachments.filter((_item, i) => i !== index),
                        );
                      }}
                    >
                      {/* @ts-ignore */}
                      <span className={styles.qna__message_attachment_name}>{attachment.name}</span>
                    </Button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Form>
  );
}
