import React from 'react';
import { Box, Typography } from '@mui/material';
import { PortfolioSnapshot } from '../../../shared/types';
import { getPortfolioTitle } from '../../utils';

interface Props {
  portfolio: PortfolioSnapshot;
  modal?: boolean;
}

export default function CardTitle({ portfolio, modal = false }: Props) {
  const cardTitle = getPortfolioTitle(portfolio);

  return (
    <Box>
      <Typography variant="h2">{cardTitle}</Typography>
    </Box>
  );
}
