import React from 'react';

export default function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="454.64" height="80.85" viewBox="0 0 454.64 80.85">
      <g transform="translate(-278 -48)">
        <path
          className="a"
          style={{ fill: '#fff' }}
          d="M136.506,22.221v-.369h-.467L109.669.952V23.333h-7.613V.952H75.219V45.228H91.992v28.6H85.283V51.072h-18.3V.952H56.919V61.778l-6.709-5.317V.952H40.146V48.485l-6.709-5.317V.952H23.372V46.471L40.146,59.765v.369h.466l26.371,20.9V58.6h8.237V81.8h26.836V37.252H85.283V8.928h6.709v21.9h17.677V81.8h10.064V20.207l6.709,5.317V81.8h10.064V33.5l6.709,5.317V81.8h10.064V35.514Z"
          transform="translate(254.628 47.048)"
        />
        <g transform="translate(437.397 64.086)">
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M.83,32.668H7.064l6.572,21.856h.125l7.008-21.856H26.7L34.11,54.524h.121l6.234-21.856h5.93L36.948,61.936H31.33L23.578,40.079h-.125L16.386,61.936H10.46Z"
            transform="translate(-0.83 -15.256)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M14.332,32.769a4.107,4.107,0,0,1,4.074-4.074,4.074,4.074,0,1,1-2.871,6.946A3.919,3.919,0,0,1,14.332,32.769Zm1.3,10.5h5.556V72.533H15.63Z"
            transform="translate(35.182 -25.853)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M48.967,74.6H43.411V70.4H43.29a10.623,10.623,0,0,1-4.415,3.641,13.691,13.691,0,0,1-5.776,1.3,15.456,15.456,0,0,1-6.143-1.177,13.517,13.517,0,0,1-4.69-3.271,14.716,14.716,0,0,1-3-4.877,16.963,16.963,0,0,1-1.049-6.047,17.239,17.239,0,0,1,1.049-6.084,14.354,14.354,0,0,1,3-4.877,13.793,13.793,0,0,1,4.69-3.242A15.52,15.52,0,0,1,33.1,44.591a13.6,13.6,0,0,1,5.93,1.328,10.175,10.175,0,0,1,4.261,3.612h.121V27.92h5.556ZM33.781,70.151a10.061,10.061,0,0,0,4.012-.77,8.853,8.853,0,0,0,3.025-2.1,9.219,9.219,0,0,0,1.914-3.209,12.772,12.772,0,0,0,0-8.215,9.286,9.286,0,0,0-1.914-3.209,8.9,8.9,0,0,0-3.025-2.1,10.8,10.8,0,0,0-8.027,0,8.9,8.9,0,0,0-3.025,2.1,9.343,9.343,0,0,0-1.914,3.209,12.772,12.772,0,0,0,0,8.215A9.275,9.275,0,0,0,26.74,67.28a8.853,8.853,0,0,0,3.025,2.1A10.079,10.079,0,0,0,33.781,70.151Z"
            transform="translate(45.555 -27.92)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M34.349,49.878a7.314,7.314,0,0,0,.836,3.487,8.658,8.658,0,0,0,2.193,2.688,10.093,10.093,0,0,0,3.146,1.727,11.31,11.31,0,0,0,3.7.616,8.277,8.277,0,0,0,4.507-1.2,14.546,14.546,0,0,0,3.52-3.179l4.2,3.212q-4.632,5.985-12.967,5.989a16.08,16.08,0,0,1-6.267-1.177A13.567,13.567,0,0,1,32.468,58.8a14.329,14.329,0,0,1-2.992-4.874,17.152,17.152,0,0,1-1.052-6.08,15.983,15.983,0,0,1,1.144-6.084,15.068,15.068,0,0,1,3.146-4.877A14.372,14.372,0,0,1,37.5,33.64a15.412,15.412,0,0,1,6.051-1.174,14.5,14.5,0,0,1,6.575,1.357,13.029,13.029,0,0,1,4.415,3.554,14.055,14.055,0,0,1,2.5,4.936,20.634,20.634,0,0,1,.77,5.589v1.977Zm17.536-4.445a11.722,11.722,0,0,0-.587-3.4,7.583,7.583,0,0,0-1.573-2.688A7.307,7.307,0,0,0,47.1,37.56a9.58,9.58,0,0,0-3.671-.649,8.874,8.874,0,0,0-3.737.774,9.026,9.026,0,0,0-2.871,2.006A9.29,9.29,0,0,0,35,42.441a7.519,7.519,0,0,0-.649,2.992Z"
            transform="translate(72.766 -15.795)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M41.894,65.16A10.475,10.475,0,0,0,46.06,69.3a12.262,12.262,0,0,0,5.835,1.357,11.671,11.671,0,0,0,3.829-.616A9.7,9.7,0,0,0,58.84,68.31a8.2,8.2,0,0,0,2.1-2.688,7.973,7.973,0,0,0,.77-3.55,6.826,6.826,0,0,0-.957-3.766,7.678,7.678,0,0,0-2.563-2.439,18.887,18.887,0,0,0-3.609-1.636q-2.008-.677-4.137-1.357a44.078,44.078,0,0,1-4.137-1.548A13.64,13.64,0,0,1,42.7,49.076a10.009,10.009,0,0,1-2.563-3.488,12.472,12.472,0,0,1-.957-5.185,11.244,11.244,0,0,1,1.144-5.156A11.022,11.022,0,0,1,43.4,31.481,13.711,13.711,0,0,1,47.882,29.2a18.144,18.144,0,0,1,5.31-.77,17.189,17.189,0,0,1,6.638,1.265,12.606,12.606,0,0,1,5.277,4.228L61.59,36.45a9.717,9.717,0,0,0-8.523-4.32,13.389,13.389,0,0,0-3.766.524A9.4,9.4,0,0,0,46.185,34.2a7.584,7.584,0,0,0-2.131,2.56,7.812,7.812,0,0,0-.8,3.645,7.037,7.037,0,0,0,1.669,5.031,11.767,11.767,0,0,0,4.166,2.78A44.127,44.127,0,0,0,54.52,50a26.125,26.125,0,0,1,5.431,2.068,11.722,11.722,0,0,1,4.17,3.612,10.756,10.756,0,0,1,1.665,6.388,11.847,11.847,0,0,1-1.111,5.248,11.566,11.566,0,0,1-2.992,3.862,12.918,12.918,0,0,1-4.353,2.373,16.471,16.471,0,0,1-5.123.807,20.593,20.593,0,0,1-7.969-1.515A14.59,14.59,0,0,1,38,67.753Z"
            transform="translate(98.318 -26.57)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M64.739,42.175H56.586V60.761a3.991,3.991,0,0,0,1.052,2.963,3.91,3.91,0,0,0,2.9,1.049A8.626,8.626,0,0,0,62.67,64.5a13.751,13.751,0,0,0,2.068-.711l.308,3.333a22.01,22.01,0,0,1-2.409.682,11.045,11.045,0,0,1-2.406.308,8.908,8.908,0,0,1-3.4-.587,5.857,5.857,0,0,1-3.55-3.891,9.88,9.88,0,0,1-.4-2.871V42.175H46.707V38.842h6.176V30.631h3.7v8.211h8.152Z"
            transform="translate(121.533 -20.689)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M53.649,41.436c0-.535-.022-1.181-.062-1.947s-.073-1.529-.092-2.31-.051-1.515-.092-2.193-.062-1.207-.062-1.577h3.7c.04,1.071.073,2.1.092,3.088a9.993,9.993,0,0,0,.154,1.914,12.674,12.674,0,0,1,3.645-4.137,9.026,9.026,0,0,1,5.431-1.606,6.182,6.182,0,0,1,1.082.1c.348.059.708.132,1.078.216L68.1,36.621a4.5,4.5,0,0,0-1.423-.249,10.277,10.277,0,0,0-4.166.774,7.823,7.823,0,0,0-2.9,2.127,8.967,8.967,0,0,0-1.7,3.179,13.423,13.423,0,0,0-.557,3.924v15.56h-3.7Z"
            transform="translate(139.227 -15.256)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M85.087,56.563a12.509,12.509,0,0,1-5.343,4.69,17,17,0,0,1-7.008,1.423,15.132,15.132,0,0,1-5.926-1.115,13.383,13.383,0,0,1-4.569-3.113,13.74,13.74,0,0,1-2.934-4.756,17.38,17.38,0,0,1-1.016-6.08,16.321,16.321,0,0,1,1.049-5.93,13.849,13.849,0,0,1,13.213-9.014,13.8,13.8,0,0,1,5.772,1.14,12.682,12.682,0,0,1,4.2,3.029,12.854,12.854,0,0,1,2.563,4.291,14.426,14.426,0,0,1,.862,4.877V48.6H61.995a12.635,12.635,0,0,0,.462,2.992,10.56,10.56,0,0,0,1.7,3.554,10.772,10.772,0,0,0,3.3,2.963A10.321,10.321,0,0,0,72.8,59.34a11.932,11.932,0,0,0,5.5-1.357,9.921,9.921,0,0,0,4.074-3.7Zm-2.842-11.3a8.442,8.442,0,0,0-.77-3.583,9.691,9.691,0,0,0-2.1-2.934,9.953,9.953,0,0,0-3.15-2.006A10.162,10.162,0,0,0,72.366,36a9.464,9.464,0,0,0-5.031,1.236,11.013,11.013,0,0,0-3.179,2.842,11.215,11.215,0,0,0-1.665,3.113,8.3,8.3,0,0,0-.5,2.072Z"
            transform="translate(152.43 -15.256)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M94.447,56.563a12.479,12.479,0,0,1-5.339,4.69A17,17,0,0,1,82.1,62.677a15.133,15.133,0,0,1-5.926-1.115A13.348,13.348,0,0,1,71.6,58.448a13.684,13.684,0,0,1-2.934-4.756,17.379,17.379,0,0,1-1.02-6.08,16.4,16.4,0,0,1,1.049-5.93,14.164,14.164,0,0,1,2.963-4.72,13.812,13.812,0,0,1,10.25-4.294,13.817,13.817,0,0,1,5.776,1.14,12.556,12.556,0,0,1,6.759,7.32,14.342,14.342,0,0,1,.865,4.877V48.6H71.359a12.447,12.447,0,0,0,.462,2.992,10.56,10.56,0,0,0,1.7,3.554,10.689,10.689,0,0,0,3.3,2.963,10.312,10.312,0,0,0,5.339,1.232,11.918,11.918,0,0,0,5.493-1.357,9.9,9.9,0,0,0,4.074-3.7Zm-2.838-11.3a8.445,8.445,0,0,0-.774-3.583,9.618,9.618,0,0,0-2.1-2.934,9.994,9.994,0,0,0-3.15-2.006A10.19,10.19,0,0,0,81.729,36,9.455,9.455,0,0,0,76.7,37.237a11.012,11.012,0,0,0-3.179,2.842,11.235,11.235,0,0,0-1.669,3.113,8.239,8.239,0,0,0-.491,2.072Z"
            transform="translate(177.395 -15.256)"
          />
          <path
            className="a"
            style={{ fill: '#fff' }}
            d="M94.367,42.175H86.218V60.761a4,4,0,0,0,1.049,2.963,3.915,3.915,0,0,0,2.9,1.049A8.6,8.6,0,0,0,92.3,64.5a13.752,13.752,0,0,0,2.068-.711l.308,3.333a22.177,22.177,0,0,1-2.406.682,11.048,11.048,0,0,1-2.409.308,8.921,8.921,0,0,1-3.4-.587,6.036,6.036,0,0,1-2.285-1.577,6.153,6.153,0,0,1-1.265-2.314,9.879,9.879,0,0,1-.4-2.871V42.175H76.339V38.842h6.176V30.631h3.7v8.211h8.149Z"
            transform="translate(200.567 -20.689)"
          />
        </g>
      </g>
    </svg>
  );
}
