const DeleteGroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16.707" height="16.756" viewBox="0 0 16.707 16.756">
    <g id="cancel" transform="translate(-0.117 22.024)">
      <rect
        id="Rectangle_2835"
        data-name="Rectangle 2835"
        width="16"
        height="15.9"
        transform="translate(0.469 -21.168)"
        fill="none"
      />
      <line
        id="Line_338"
        data-name="Line 338"
        x1="15.719"
        y2="15.9"
        transform="translate(0.75 -21.668)"
        fill="none"
        stroke="#000"
        stroke-width="1"
      />
      <line
        id="Line_370"
        data-name="Line 370"
        x2="16"
        y2="15.818"
        transform="translate(0.469 -21.668)"
        fill="none"
        stroke="#000"
        stroke-width="1"
      />
    </g>
  </svg>
);

export default DeleteGroupIcon;
