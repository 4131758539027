import React from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import styles from './summary.module.scss';
import { PortfolioSnapshot } from '../../../shared/types';
import BalanceChart from '../../BalanceChart';
import BaseDetails from '../../BaseDetails';
import SplitStat from './SplitStat';
import { getFileNameFromContentDispositionHeader } from '../../../shared/utils/helpers';
import useClient from '../../../shared/hooks/useClient';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';

interface Props {
  portfolio: PortfolioSnapshot;
  // flag to use different endpoints when used on portfolio manager page
  isPortfolioManager?: boolean;
}

export default function Preview({ portfolio, isPortfolioManager = false }: Props) {
  const { id, assetClassSplit, productTypeSplit, regionalSplit, hasTermSheet, documents } = portfolio;

  const { enqueueSnackbar } = useSnackbar();
  const client = useClient();
  const handleTermSheetDownload = () => {
    client
      .get(`${isPortfolioManager ? 'admin' : 'eba'}/portfolio_snapshots/${id}/download_term_sheet/`, {
        responseType: 'blob',
      })
      .then((res) => {
        saveAs(res.data, getFileNameFromContentDispositionHeader(res.headers['content-disposition']));
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download term sheet', { variant: 'error' });
      });
  };

  const handleSourceFileDownload = () => {
    client
      .get(`${isPortfolioManager ? 'admin' : 'eba'}/portfolio_snapshots/${id}/download_source_file/`, {
        responseType: 'blob',
      })
      .then((res) => {
        saveAs(res.data, getFileNameFromContentDispositionHeader(res.headers['content-disposition']));
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download source data', { variant: 'error' });
      });
  };

  const handlePortfolioDocumentDownload = (id: number) => {
    client
      .get(`eba/portfolio_snapshot_documents/${id}/download/`, { responseType: 'blob' })
      .then((res) => {
        saveAs(res.data, getFileNameFromContentDispositionHeader(res.headers['content-disposition']));
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download portfolio document', { variant: 'error' });
      });
  };

  return (
    <>
      <Grid container className={styles.section}>
        <Grid item xs={12}>
          <Typography className={styles.section__title} variant="h2">
            Files
          </Typography>
        </Grid>
        {hasTermSheet && (
          <Grid item xs={6}>
            <Button onClick={handleTermSheetDownload}>
              <DescriptionOutlinedIcon fontSize="large" />
              <Typography className={styles.fileName}>Term Sheet</Typography>
            </Button>
          </Grid>
        )}
        <Grid item xs={6}>
          <Button onClick={handleSourceFileDownload}>
            <DescriptionOutlinedIcon fontSize="large" />
            <Typography className={styles.fileName}>Source Data</Typography>
          </Button>
        </Grid>
        {documents.map(({ id, filename }) => (
          <Grid key={id} item xs={6}>
            <Button className={styles.fileButton} onClick={() => handlePortfolioDocumentDownload(id)}>
              <DescriptionOutlinedIcon fontSize="large" />
              <Tooltip title={filename}>
                <Typography className={styles.fileName}>{filename}</Typography>
              </Tooltip>
            </Button>
          </Grid>
        ))}
      </Grid>
      <div className={styles.section}>
        <Typography className={styles.section__title} variant="h2">
          Portfolio Preview
        </Typography>
        <div className={styles.subsection}>
          <BalanceChart portfolio={portfolio} labelSize="15px" valueSize="28px" wrap />
        </div>
        <div className={styles.subsection}>
          <BaseDetails portfolio={portfolio} labelSize="15px" />
        </div>
        <div className={styles.subsection}>
          <SplitStat title="Asset Class Split" splitValues={assetClassSplit} />
        </div>
        <div className={styles.subsection}>
          <SplitStat title="Product Type Split" splitValues={productTypeSplit} />
        </div>
        <div className={styles.subsection}>
          <SplitStat title="Regional Split" splitValues={regionalSplit} />
        </div>
      </div>
    </>
  );
}
