import React, { ChangeEvent, useState } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import styles from './card.module.scss';
import { PortfolioSnapshot } from '../../../shared/types';
import LoanScreeningTable from '../../LoanScreeningTable';
import useApi from '../../../shared/hooks/useApi';
import RepaymentScreeningTable from '../../RepaymentScreeningTable';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className={styles.tabs__panel}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function PortfolioStatistics({ portfolio }: Props) {
  const api = useApi();

  const { data: loanScreening } = useQuery(['fetchPortfolioLoanScreening', portfolio.id], () =>
    api.portfolio_snapshot.loanScreening(portfolio.id),
  );

  const { baseCurrency, description } = portfolio;
  const displayCurrency = loanScreening?.currency || baseCurrency;

  const hasDescription = Boolean(description);

  const [tab, setTab] = useState(0);

  const handleChange = (event: ChangeEvent<unknown>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <div className={styles.tabs__container}>
      <Tabs
        className={styles.tabs}
        orientation="vertical"
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        variant="fullWidth"
      >
        {hasDescription && <Tab label="Portfolio Description" classes={{ root: styles.tabs__tab__wrapper }} />}
        <Tab label="Origination Year" classes={{ root: styles.tabs__tab__wrapper }} />
        <Tab label="Interest Rate" classes={{ root: styles.tabs__tab__wrapper }} />
        <Tab label="Maturity Year" classes={{ root: styles.tabs__tab__wrapper }} />
        <Tab label="Legal Vintage" classes={{ root: styles.tabs__tab__wrapper }} />
        <Tab label="Loan Size Distribution" classes={{ root: styles.tabs__tab__wrapper }} />
        <Tab label="Loan To Value (LTV)" classes={{ root: styles.tabs__tab__wrapper }} />
        <Tab label="Repayments By Year" classes={{ root: styles.tabs__tab__wrapper }} />
      </Tabs>
      {hasDescription && (
        <TabPanel value={tab} index={0}>
          <Typography>{description}</Typography>
        </TabPanel>
      )}
      <TabPanel value={tab} index={hasDescription ? 1 : 0}>
        <LoanScreeningTable data={loanScreening?.originationYear} currency={displayCurrency} />
      </TabPanel>
      <TabPanel value={tab} index={hasDescription ? 2 : 1}>
        <LoanScreeningTable data={loanScreening?.interestRate} currency={displayCurrency} />
      </TabPanel>
      <TabPanel value={tab} index={hasDescription ? 3 : 2}>
        <LoanScreeningTable data={loanScreening?.maturityYear} currency={displayCurrency} />
      </TabPanel>
      <TabPanel value={tab} index={hasDescription ? 4 : 3}>
        <LoanScreeningTable data={loanScreening?.legalVintage} currency={displayCurrency} />
      </TabPanel>
      <TabPanel value={tab} index={hasDescription ? 5 : 4}>
        <LoanScreeningTable data={loanScreening?.loanSizeDistribution} currency={displayCurrency} />
      </TabPanel>
      <TabPanel value={tab} index={hasDescription ? 6 : 5}>
        <LoanScreeningTable data={loanScreening?.loanToValue} currency={displayCurrency} />
      </TabPanel>
      <TabPanel value={tab} index={hasDescription ? 7 : 6}>
        <RepaymentScreeningTable data={loanScreening?.loanRepaymentsByYear} currency={displayCurrency} />
      </TabPanel>
    </div>
  );
}
