import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import ArrowRight from '../icons/ArrowRight';
import styles from './link.module.scss';

interface Props {
  label: string;
  linkText: string;
  linkUrl: string;
}

export default function CustomLink({ label, linkText, linkUrl }: Props) {
  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="baseline" className={styles.linkContainer}>
      <Typography>{label}</Typography>
      <Button className={styles.linkButton} onClick={() => navigate(linkUrl)} endIcon={<ArrowRight />}>
        {linkText}
      </Button>
    </Box>
  );
}
