import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import AuthLayout from '../AuthLayout';
import styles from '../auth.module.scss';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import RegisterForm from './RegisterForm';

export default function Register() {
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [created, setCreated] = useState(false);

  const { search } = useLocation();
  const refCode = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('ref');
  }, [search]);

  return (
    <AuthLayout
      title="Create Account"
      topLink={
        <Box display="flex" alignItems="center" justifyContent="center" className={styles.linkContainer}>
          <Typography>Already a user?</Typography>
          <Button
            className={styles.linkButton}
            color="grey"
            onClick={() => navigate(`/login${refCode ? `/?ref=${refCode}` : ''}`)}
            endIcon={<ArrowRight />}
          >
            Log in to your account
          </Button>
        </Box>
      }
      errorMsg={error ? 'Unable to create account with provided credentials.' : ''}
      successMsg={created ? 'Account created. A confirmation has been sent to your email.' : ''}
    >
      <RegisterForm setSuccess={setCreated} setError={setError} />
      <Grid item xs={12} className={styles.registerMessage}>
        <Typography>
          Registering for and logging into the wideStreet Marketplace requires the installation of a third-party
          authentication app on a smart phone. We recommend using Microsoft Authenticator or Google Authenticator, but
          other applications are feasible as well.
        </Typography>
      </Grid>
    </AuthLayout>
  );
}
