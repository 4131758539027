import React from 'react';
import { useQuery } from '@tanstack/react-query';
import QRCode from 'react-qr-code';
import { Skeleton } from '@mui/lab';
import { Field, Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import { useAuth } from '../../shared/hooks/useAuth';
import styles from '../auth.module.scss';

interface Activate2FAResponse {
  qrLink: string;
}

interface Confirm2FAResponse {
  backupCodes: string[];
}

interface Props {
  setError: (value: boolean) => void;
  token: string;
}

export default function Confirm2FAForm({ setError, token }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const activate2FA = async () =>
    axios
      .post('/api/auth/app/activate/', { method: 'app' }, { headers: { Authorization: `JWT ${token}` } })
      .then((res) => res.data as Activate2FAResponse);
  const activate2FAConfirm = async (code: string) =>
    axios
      .post('/api/auth/app/activate/confirm/', { code }, { headers: { Authorization: `JWT ${token}` } })
      .then((res) => res.data as Confirm2FAResponse);

  const { data } = useQuery(['activate2FA'], activate2FA);

  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    code: Yup.string()
      .matches(/[0-9]{6}/, 'Must be valid 6-digit code')
      .required(requiredMsg),
  });

  const { logout } = useAuth();

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async ({ code }, { setSubmitting }) => {
        setError(false);
        try {
          const response = await activate2FAConfirm(code);
          if (response.backupCodes) {
            enqueueSnackbar(
              'Your 2-factor authentication method has successfully been registered. Please log into your account using your credentials.',
              { variant: 'success', onClose: () => logout(), disableWindowBlurListener: true },
            );
          }
        } catch (e) {
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {() => (
        <Form className={styles.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={styles.setup2FaExplain}>
              <Typography>
                Please scan the QR code with your phone&apos;s camera or with the authenticator app directly and enter
                the six-digit numerical code displayed in the application. If successful, a field with 2FA Backup codes
                will appear. Please save these codes in a secure location. You will need these in case of losing access
                to your email.
              </Typography>
            </Grid>
            <Grid className={styles.qrCodeContainer} item xs={12}>
              {data ? (
                <QRCode value={data.qrLink} size={150} />
              ) : (
                <Skeleton variant="rectangular" width={150} height={150} />
              )}
            </Grid>
            <Grid item xs={12}>
              <Field component={TextField} variant="standard" name="code" label="2FA Code" fullWidth autoFocus />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" size="large" variant="outlined" color="primary" fullWidth endIcon={<ArrowRight />}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
