import React, { useEffect, useMemo } from 'react';
import debouce from 'lodash.debounce';
import { Box, InputLabel, ListSubheader, MenuItem, OutlinedInput, Select } from '@mui/material';
import styles from '../../../styles.module.scss';
import { useQuery } from '@tanstack/react-query';
import useAdminApi from '../../../../shared/hooks/useAdminApi';

interface Props {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  filterValue?: string;
  setFilterValue: (value: string | undefined) => void;
  disabledSearch?: boolean;
  disabledShowType?: boolean;
}

export default function Filters({
  searchTerm,
  setSearchTerm,
  filterValue,
  setFilterValue,
  disabledSearch = false,
  disabledShowType = false,
}: Props) {
  const debouncedSearch = useMemo(() => {
    const handleChange = (e: any) => {
      setSearchTerm(e.target.value);
    };
    return debouce(handleChange, 500);
  }, [setSearchTerm]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  const api = useAdminApi();
  const { data: buyerGroups } = useQuery(['adminFetchBuyerGroups'], () => api.users.buyers.fetchBuyerGroups());

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <InputLabel id="label" style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}>
          Search:
        </InputLabel>
        <OutlinedInput
          className={styles.buyerSearchInput}
          defaultValue={searchTerm}
          onChange={debouncedSearch}
          disabled={disabledSearch}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <InputLabel id="label" style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}>
          Show:
        </InputLabel>
        <Select
          variant="outlined"
          labelId="label"
          disableUnderline
          id="show-filter"
          value={filterValue}
          displayEmpty
          onChange={(event) => {
            setFilterValue(event.target.value);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          className={styles.buyerFilter__select}
          SelectDisplayProps={{ className: styles.statusFilter__select__display }}
          disabled={disabledShowType}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="INVITED">Invited</MenuItem>
          <MenuItem value="UNINVITED">Uninvited</MenuItem>
          {buyerGroups && [
            <ListSubheader>Groups</ListSubheader>,
            buyerGroups.map((group) => (
              <MenuItem className={styles.buyerFilter__groupOption} value={group.name}>
                {group.name}
              </MenuItem>
            )),
          ]}
        </Select>
      </Box>
    </Box>
  );
}
