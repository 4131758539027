import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  hasUnreadMessages?: boolean;
}

const QaTabLink = ({ hasUnreadMessages = false }: Props) => {
  const theme = useTheme();

  return (
    <div style={{ position: 'relative' }}>
      <p>Questions & Answers</p>
      {hasUnreadMessages && (
        <Box component="div" sx={{ position: 'absolute', top: -5, right: -15 }}>
          <Box
            component="div"
            sx={{
              width: 12,
              height: 12,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: 10, color: 'white' }}>!</span>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default QaTabLink;
