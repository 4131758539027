import styles from './message.center.module.scss';
import { Field, Form, Formik } from 'formik';
import MessageAttachmentButton from '../shared/components/chat/MessageAttachmentButton';
import PostMessageInput from '../shared/components/chat/PostMessageInput';
import { Button, CircularProgress } from '@mui/material';
import DeleteAttachmentIcon from '../shared/components/icons/DeleteAttachmentIcon';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from 'yup';

interface Props {
  postConversation: (form: FormData) => Promise<void>;
  onCancel: () => void;
  postMessageCallback?: () => void;
}

export default function PostConversationForm({ onCancel, postConversation, postMessageCallback }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);

  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    title: Yup.string().required(requiredMsg),
    text: Yup.string().required(requiredMsg),
  });

  const isDisabled = isLoading;

  const Loader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: 64, height: 25, marginTop: 3 }}>
        <CircularProgress size={24} />
      </div>
    );
  };

  return (
    <div className={styles.post_conversation_form}>
      <Formik
        initialValues={{
          title: '',
          text: '',
          attachments: [],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);

          try {
            const form = new FormData();
            form.append('title', values.title.trim());
            form.append('text', values.text.trim());

            for (let attachment of values.attachments) {
              form.append('attachments[]', attachment);
            }

            await postConversation(form);
            resetForm();

            if (postMessageCallback) {
              postMessageCallback();
            }
          } catch (e) {
            enqueueSnackbar('Failed post the message', {
              variant: 'error',
            });
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ values, setFieldValue, submitForm, isValid, dirty }) => (
          <Form className={styles.qna__form} autoComplete="off">
            <div className={styles.conversation_form}>
              <div style={{ marginLeft: 63, paddingBottom: 5, borderBottom: '1px solid #C4C4C4' }}>
                <Field
                  component={PostMessageInput}
                  name="title"
                  type="text"
                  placeholder="Title"
                  disabled={isDisabled}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  minHeight: 250,
                  paddingTop: 5,
                }}
                className={styles.qna__form__input}
              >
                {isLoading ? <Loader /> : <MessageAttachmentButton />}
                <div className={styles.qna__message_attachment}>
                  <Field
                    component={PostMessageInput}
                    variant="standard"
                    name="text"
                    type="text"
                    placeholder="Message"
                    disabled={isDisabled}
                  />
                  {Boolean(values.attachments.length) && (
                    <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 5, marginLeft: 5 }}>
                      {values.attachments.map((attachment, index) => {
                        return (
                          <div key={URL.createObjectURL(attachment)}>
                            <Button
                              // @ts-ignore
                              key={attachment.name}
                              size="small"
                              style={{ paddingLeft: 0 }}
                              endIcon={<DeleteAttachmentIcon />}
                              onClick={() => {
                                // Clear input files so that the removed file can be added again
                                const attachmentUploadInput = document?.getElementById(
                                  'qaAddAttachmentInput',
                                ) as HTMLInputElement | null;
                                if (attachmentUploadInput) {
                                  attachmentUploadInput.value = '';
                                }
                                setFieldValue(
                                  'attachments',
                                  values.attachments.filter((_item, i) => i !== index),
                                );
                              }}
                            >
                              {/* @ts-ignore */}
                              <span className={styles.qna__message_attachment_name}>{attachment.name}</span>
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 20 }}
                onClick={submitForm}
                disabled={!(isValid && dirty) || isDisabled}
              >
                Send message
              </Button>
              <Button variant="outlined" color="grey" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
