import React, { useMemo, useState } from 'react';
import { MenuItem, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { useSnackbar } from 'notistack';
import { CompanyUser, CompanyUserStatusEnum, CompanyUserUpdateData } from '../shared/types';
import { Field, Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { PhoneNumberInputRow, TextInputRow } from '../shared/components/form_inputs/common';
import { Select } from 'formik-mui';
import useApi from '../shared/hooks/useApi';
import SingleButtonModal from '../shared/components/SingleButtonModal';
import { useTheme } from '@mui/material/styles';

interface RowProps {
  fieldName: string;
  label: string;
  editMode: boolean;
}

export const StatusSelectRow: React.FC<RowProps> = ({ fieldName, label, editMode }) => {
  const [{ value }] = useField(fieldName);

  const getFormattedValue = (status: CompanyUserStatusEnum) => {
    if (status === CompanyUserStatusEnum.ACTIVE) {
      return 'Active';
    }
    if (status === CompanyUserStatusEnum.INVITED) {
      return 'Invited';
    }
    return 'Inactive';
  };

  const theme = useTheme();

  const getStatusStyle = (status: CompanyUserStatusEnum) => {
    if (status === CompanyUserStatusEnum.ACTIVE) {
      return { color: theme.palette.accent1.main };
    }
    if (status === CompanyUserStatusEnum.INVITED) {
      return { color: theme.palette.accent2.main };
    }
    return {};
  };

  return (
    <div className={styles.row}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <Field
          component={Select}
          name={fieldName}
          variant="outlined"
          className={styles.formField}
          SelectDisplayProps={{ className: styles.modal_selectDisplay, style: getStatusStyle(value) }}
        >
          <MenuItem sx={getStatusStyle(value)} value={CompanyUserStatusEnum.ACTIVE}>
            Active
          </MenuItem>
          <MenuItem value={CompanyUserStatusEnum.INACTIVE}>Inactive</MenuItem>
        </Field>
      ) : (
        <Typography className={styles.rowValue} style={getStatusStyle(value)}>
          {getFormattedValue(value)}
        </Typography>
      )}
    </div>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  user: CompanyUser;
}

export default function UserDetailsModal({ open, onClose, user }: Props) {
  const { id, fullName, email, companyStatus } = user;

  const [editMode, setEditMode] = useState(false);

  const isInvited = useMemo(() => companyStatus === CompanyUserStatusEnum.INVITED, [companyStatus]);

  const api = useApi();

  const { data: userDetails, isLoading } = useQuery(
    ['fetchCompanyUserDetails', id],
    () => api.company.fetchCompanyUserDetails(id),
    { enabled: !isInvited },
  );

  const initialValues = useMemo(() => {
    if (isLoading) {
      return {
        id: 0,
        fullName: '',
        email: '',
        companyName: '',
        jobTitle: '',
        phone: '',
        mobilePhone: '',
        companyStatus: '',
      };
    }
    if (userDetails) {
      return {
        id: userDetails.id,
        fullName: userDetails.fullName,
        email: userDetails.email,
        companyName: userDetails.companyName,
        jobTitle: userDetails.jobTitle,
        phone: userDetails.phone,
        mobilePhone: userDetails.mobilePhone,
        companyStatus: userDetails.companyStatus,
      };
    }
    return {
      id,
      fullName,
      email,
      companyName: '',
      jobTitle: '',
      phone: '',
      mobilePhone: '',
      companyStatus: '',
    };
  }, [id, userDetails, isLoading, fullName, email]);

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object({
    fullName: Yup.string().required(requiredMsg),
    email: Yup.string().email().required(requiredMsg),
    jobTitle: Yup.string().required(requiredMsg),
    phone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
    mobilePhone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        if (isInvited) {
          api.company
            .resendInvite({ name: fullName, email })
            .then(async () => {
              enqueueSnackbar('User invite resent', { variant: 'success' });
              handleClose();
            })
            .catch((e) => {
              console.error(e);
              enqueueSnackbar('Failed to resend invite', { variant: 'error' });
            });
        } else {
          api.company
            .updateCompanyUserDetails(id, values as CompanyUserUpdateData)
            .then(async () => {
              await queryClient.invalidateQueries(['fetchCompanyUserDetails', id]);
              await queryClient.invalidateQueries(['fetchCompanyUsers']);
              enqueueSnackbar('User details updated', { variant: 'success' });
              setEditMode(false);
            })
            .catch((e) => {
              console.error(e);
              enqueueSnackbar('Failed to update user details', { variant: 'error' });
            });
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, dirty, isValid, handleSubmit, submitForm }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={handleClose}
            title={isInvited ? 'Resend user invite' : 'User details'}
            content={
              <>
                <TextInputRow editMode={editMode} fieldName="fullName" label="Name" />
                <TextInputRow editMode={false} fieldName="email" label="Email" />
                {!isInvited && [
                  <TextInputRow key="companyName" editMode={false} fieldName="companyName" label="Company" />,
                  <TextInputRow key="jobTitle" editMode={editMode} fieldName="jobTitle" label="Job title" />,
                  <PhoneNumberInputRow key="phone" editMode={editMode} fieldName="phone" label="Phone" />,
                  <PhoneNumberInputRow
                    key="mobilePhone"
                    editMode={editMode}
                    fieldName="mobilePhone"
                    label="Mobile phone"
                  />,
                  <StatusSelectRow key="companyStatus" editMode={editMode} fieldName="companyStatus" label="Status" />,
                ]}
              </>
            }
            buttonLabel={!isInvited && !editMode ? 'Edit details' : isInvited ? 'Resend invitation' : 'Save changes'}
            onClick={!isInvited && !editMode ? () => setEditMode(true) : submitForm}
            isSubmitting={isSubmitting}
            disabled={!isInvited && !editMode ? isSubmitting : isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
