import { Typography } from '@mui/material';
import SingleButtonModal from '../shared/components/SingleButtonModal';
import { useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function KycRequiredModal({ open, onClose }: Props) {
  const navigate = useNavigate();

  return (
    <SingleButtonModal
      open={open}
      onClose={() => {
        onClose();
      }}
      title="KYC process not complete"
      content={
        <>
          <Typography
            style={{ marginTop: 40, marginBottom: 20, textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}
          >
            Your KYC process is not yet complete, if you would like to start it now, please use the button below or come
            back later. If you think this is some kind of error, please{' '}
            <span style={{ fontWeight: 'bold' }}>use our chat</span> or contact us by email:{' '}
            <a href="mailto:info@widestreet.com" style={{ textDecoration: 'underline', color: '#303334' }}>
              info@widestreet.com
            </a>
          </Typography>
        </>
      }
      buttonLabel="START KYC PROCESS"
      onClick={() => navigate('/company_kyc')}
    />
  );
}
