import React from 'react';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import styles from './styles.module.scss';

interface Props extends ButtonProps {
  component?: string;
  testId?: string;
}

export default function TableButton(props: Props) {
  const { startIcon, endIcon } = props;
  const hasIcon = Boolean(startIcon || endIcon);

  return (
    <Button
      {...props}
      className={`${styles.btn}${hasIcon ? ` ${styles.btn__icon}` : ''}`}
      size="small"
      variant="outlined"
      color="grey"
      data-testid={props.testId}
    />
  );
}
