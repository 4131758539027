import React from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { LoanDocument } from '../../../../../shared/types';
import styles from './loanDetails.module.scss';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { downloadFileURLResponse } from '../../../../../shared/utils/helpers';
import { useSnackbar } from 'notistack';
import useClient from '../../../../../shared/hooks/useClient';

interface Props {
  documents: LoanDocument[];
}

export default function LoanDocuments({ documents }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const client = useClient();
  const handleLoanDocumentDownload = (id: number) => {
    client
      .get(`eba/loan_documents/${id}/download/`)
      .then((res) => {
        downloadFileURLResponse(res.data.url);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download loan document', { variant: 'error' });
      });
  };

  return (
    <>
      <Typography className={styles.section__title} variant="h2">
        Loan documents
      </Typography>
      <Grid container>
        {documents.map(({ id, name, url }) => (
          <Grid key={id} item xs={4}>
            <Button onClick={() => handleLoanDocumentDownload(id)}>
              <DescriptionOutlinedIcon />
              <Tooltip title={name}>
                <Typography className={styles.fileName}>{name}</Typography>
              </Tooltip>
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
