import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import styles from '../../styles.module.scss';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material/styles';
import { BaseBuyerData, RegisteredUser } from '../../../shared/types';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import CustomTableBody from '../../../shared/components/table/CustomTableBody';
import ListPagination from '../../../shared/components/ListPagination';
import TableButton from '../../../shared/components/table/TableButton';
import AssignExistingBuyerModal from './AssignExistingBuyerModal';
import AssignNewBuyerModal from './AssignNewBuyerModal';

interface RowProps {
  user: RegisteredUser;
  isSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, userId: number) => void;
}

function RegisteredUserTableRow({ user, isSelected, handleClick }: RowProps) {
  const { id, fullName, email, companyName, jobTitle, phone, isActive } = user;

  const [openExistingBuyerModal, setOpenExistingBuyerModal] = useState(false);
  const [openNewBuyerModal, setOpenNewBuyerModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAssignExistingClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenExistingBuyerModal(true);
    setAnchorEl(null);
  };
  const closeAssignExistingModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenExistingBuyerModal(false);
  };
  const handleAssignNewClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenNewBuyerModal(true);
    setAnchorEl(null);
  };
  const closeAssignNewModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenNewBuyerModal(false);
  };

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleActivate = async (buyerId?: string, newBuyer?: BaseBuyerData) => {
    api.users.registeredUsers
      .activateRegisteredUser(id, buyerId, newBuyer)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchRegisteredUsers']);
        enqueueSnackbar(`Activated user ${email}`, { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(`Failed to activate user ${email}`, { variant: 'error' });
      });
  };

  const theme = useTheme();

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={id}
        tabIndex={-1}
        selected={isSelected}
        onClick={(event) => handleClick(event, id)}
      >
        <TableCell padding="checkbox">
          <Checkbox color="primary" checked={isSelected} />
        </TableCell>
        <TableCell>{fullName}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{companyName}</TableCell>
        <TableCell>{jobTitle}</TableCell>
        <TableCell>{phone}</TableCell>
        <TableCell sx={{ color: isActive ? theme.palette.accent1.main : theme.palette.accent2.main }}>
          {isActive ? 'Active' : 'Inactive'}
        </TableCell>
        <TableCell align="right">
          <TableButton onClick={handleActionClick}>Activate</TableButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem onClick={handleAssignExistingClick}>Activate & assign to existing buyer</MenuItem>
            <MenuItem onClick={handleAssignNewClick}>Activate & assign to new buyer</MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <AssignExistingBuyerModal
        open={openExistingBuyerModal}
        onClose={closeAssignExistingModal}
        onClick={handleActivate}
      />
      <AssignNewBuyerModal open={openNewBuyerModal} onClose={closeAssignNewModal} onClick={handleActivate} />
    </>
  );
}

export default function RegisteredUsersManager() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = React.useState<number[]>([]);

  const [openExistingBuyerModal, setOpenExistingBuyerModal] = useState(false);
  const [openNewBuyerModal, setOpenNewBuyerModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openBulkMenu = Boolean(anchorEl);
  const handleBulkActionClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleAssignExistingClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenExistingBuyerModal(true);
    setAnchorEl(null);
  };
  const closeAssignExistingModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenExistingBuyerModal(false);
  };
  const handleAssignNewClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenNewBuyerModal(true);
    setAnchorEl(null);
  };
  const closeAssignNewModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenNewBuyerModal(false);
  };

  const api = useAdminApi();
  const { data, isLoading, isFetching } = useQuery(
    ['adminFetchRegisteredUsers', page, pageSize],
    () => api.users.registeredUsers.fetchRegisteredUsers(page, pageSize),
    {
      keepPreviousData: true,
    },
  );

  const users = data?.results || [];
  const userCount = data?.count || 0;
  const numSelected = selected.length;
  const rowCount = users.length;

  const handleClick = (event: React.MouseEvent<unknown>, userId: number) => {
    const selectedIndex = selected.indexOf(userId);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = users.map(({ id }) => id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const isSelected = (userId: number) => selected.indexOf(userId) !== -1;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleBulkActivate = async (buyerId?: string, newBuyer?: BaseBuyerData) => {
    api.users.registeredUsers
      .bulkActivateRegisteredUsers(selected, buyerId, newBuyer)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchRegisteredUsers']);
        enqueueSnackbar('Activated selected users', { variant: 'success' });
        setSelected([]);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to activate selected users', { variant: 'error' });
      });
  };

  return (
    <>
      <div className={styles.tabContent__header}>
        <Typography variant="h2">Activation requests</Typography>
        <Button variant="outlined" color="grey" onClick={handleBulkActionClick} disabled={!selected.length}>
          Activate selected users
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={openBulkMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleAssignExistingClick}>Activate & assign to existing buyer</MenuItem>
          <MenuItem onClick={handleAssignNewClick}>Activate & assign to new buyer</MenuItem>
        </Menu>
      </div>
      <TableContainer className={styles.tabContent__tableContainer}>
        <Table size="small" className={styles.tabContent__table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Job title</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <CustomTableBody
            isLoading={isLoading || isFetching}
            data={users}
            renderRow={(user: RegisteredUser) => (
              <RegisteredUserTableRow
                key={user.id}
                user={user}
                isSelected={isSelected(user.id)}
                handleClick={handleClick}
              />
            )}
          />
        </Table>
        <ListPagination
          count={Math.ceil(userCount / pageSize)}
          page={page}
          pageSize={pageSize}
          onChange={handlePageChange}
          onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
        />
      </TableContainer>
      <AssignExistingBuyerModal
        open={openExistingBuyerModal}
        onClose={closeAssignExistingModal}
        onClick={handleBulkActivate}
      />
      <AssignNewBuyerModal open={openNewBuyerModal} onClose={closeAssignNewModal} onClick={handleBulkActivate} />
    </>
  );
}
