import { LoanDocumentsImport } from '../../../shared/types';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styles from '../../styles.module.scss';
import { Button, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { formatDateTime, getFileNameFromPath } from '../../../shared/utils/helpers';
import ImportStatus from '../ImportStatus';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { useNavigate } from 'react-router-dom';
import CustomTableBody from '../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../../shared/components/table/TableButton';
import { allowedExtensions } from '../../../shared/utils/constants';

interface Props {
  snapshotId: number;
  loanDocuments: LoanDocumentsImport[];
  disabled?: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function LoanDocuments({ snapshotId, loanDocuments, disabled = false, loading, setLoading }: Props) {
  const api = useAdminApi();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const uploadMutation = useMutation({
    mutationFn: (form: FormData) => api.snapshots.uploadLoanDocuments(snapshotId, form),
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: async (newId) => {
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshotDetails', snapshotId]);
      enqueueSnackbar('Loan documents import scheduled', { variant: 'success' });
      if (newId && newId !== snapshotId) {
        enqueueSnackbar('New snapshot draft created', { variant: 'success' });
        navigate(`/portfolio_manager/snapshots/${newId}`);
      }
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to schedule loan documents import', { variant: 'error' });
    },
  });

  const handleUpload = (e: React.FormEvent) => {
    setLoading(true);
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      const form = new FormData();
      form.append('file', file);
      uploadMutation.mutate(form);
    }
    setLoading(false);
  };

  const handleRunImport = async (importId: number) => {
    setLoading(true);
    api.snapshots
      .runLoanDocumentsImport(importId)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioSnapshotDetails', snapshotId]);
        enqueueSnackbar('Loan documents import scheduled', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to schedule loan documents import', { variant: 'error' });
      });
    setLoading(false);
  };

  const downloadLoanDocument = (loanDocumentId: number, fileName: string) => {
    api.snapshots.downloadLoanDocument(loanDocumentId, fileName);
  };

  const theme = useTheme();

  return (
    <div className={styles.filesSection__container}>
      <div className={styles.filesSection__header}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h2">
          Loan documents
        </Typography>
      </div>
      <TableContainer className={styles.filesSection__tableContainer}>
        <Table size="small">
          <TableHead>
            <TableCell sx={{ width: '30%' }}>File Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Uploaded at</TableCell>
            <TableCell>Imported at</TableCell>
            <TableCell align="right"></TableCell>
          </TableHead>
          <CustomTableBody
            data={loanDocuments}
            renderRow={({ id, creationDate, url, status, end }) => (
              <TableRow key={id}>
                <TableCell>
                  <Button
                    className={styles.filesSection__downloadButton}
                    fullWidth
                    onClick={() => downloadLoanDocument(id, getFileNameFromPath(url))}
                    variant="text"
                    endIcon={<DownloadIcon />}
                  >
                    {getFileNameFromPath(url)}
                  </Button>
                </TableCell>
                <TableCell>
                  <ImportStatus status={status} />
                </TableCell>
                <TableCell>{creationDate && formatDateTime(creationDate)}</TableCell>
                <TableCell>{end && formatDateTime(end)}</TableCell>
                <TableCell align="right">
                  <TableButton onClick={() => handleRunImport(id)} disabled={disabled || loading}>
                    Import
                  </TableButton>
                </TableCell>
              </TableRow>
            )}
          />
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        color="grey"
        component="label"
        startIcon={<UploadIcon />}
        onChange={handleUpload}
        disabled={disabled || loading}
      >
        Upload loan documents
        <input type="file" hidden accept={allowedExtensions} />
      </Button>
    </div>
  );
}
