import React, { useState } from 'react';
import { Box, Grid, Typography, InputLabel, Select, MenuItem, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import PortfolioCard from './PortfolioCard';
import styles from './list.module.scss';
import SiderLayout from '../../shared/components/SiderLayout';
import ListPagination from '../../shared/components/ListPagination';
import useApi from '../../shared/hooks/useApi';
import { makeStyles } from '@mui/styles';
import useUser from '../../shared/hooks/useUser';

const useStyles = makeStyles(() => ({
  selectRoot: {
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}));

export default function PortfolioList() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [ordering, setOrdering] = useState<string>('-creation_date');
  const [statusFilter, setStatusFilter] = useState<string>('');

  const api = useApi();
  const { user } = useUser();

  const filters = statusFilter ? { portfolio__is_sold: statusFilter === 'sold' } : {};

  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['fetchPortfolioSnapshots', page, pageSize, ordering, filters],
    () => api.portfolio_snapshot.fetchList(page, pageSize, ordering, filters),
    { keepPreviousData: true },
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const classes = useStyles();

  const portfolios = data?.results;
  const portfolioCount = data?.count;

  const displayPortfolioPlaceholder = isLoading || (isFetching && isPreviousData);

  return (
    <SiderLayout
      content={
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <Typography className={styles.title} variant="h2">
                  Portfolios
                </Typography>
                {_.isNumber(portfolioCount) && (
                  <Typography className={styles.portfolioCount} variant="h2">{`(${portfolioCount})`}</Typography>
                )}
              </Box>
              <Box display="flex">
                {user?.isAdmin && (
                  <>
                    <InputLabel
                      key="label"
                      id="label"
                      style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}
                    >
                      Filter:
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="label"
                      disableUnderline
                      key="status-filter"
                      id="status-filter"
                      value={statusFilter}
                      displayEmpty
                      onChange={(event) => {
                        setStatusFilter(event.target.value as unknown as any);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                      }}
                      className={styles.statusFilter__select}
                      SelectDisplayProps={{ className: styles.statusFilter__select__display }}
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="sold">Sold</MenuItem>
                      <MenuItem value="not_sold">Not sold</MenuItem>
                    </Select>
                    <InputLabel id="label" style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}>
                      Sort by
                    </InputLabel>
                  </>
                )}
                <Select
                  variant="standard"
                  labelId="label"
                  disableUnderline
                  id="select-ordering"
                  value={ordering}
                  onChange={(event) => {
                    setOrdering(event.target.value as string);
                  }}
                  classes={{ select: classes.selectRoot }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  }}
                  style={{
                    backgroundColor: 'white',
                    padding: 5,
                  }}
                >
                  <MenuItem value="-cutoff_date">Cut-off date</MenuItem>
                  <MenuItem value="-creation_date">Creation date</MenuItem>
                  <MenuItem value="-last_change_date">Last update</MenuItem>
                </Select>
              </Box>
            </Box>
          </Grid>
          {displayPortfolioPlaceholder &&
            _.range(pageSize).map((item) => (
              <Grid key={item} item xs={12}>
                <Skeleton variant="rounded" animation="pulse" height={355} />
              </Grid>
            ))}
          {!displayPortfolioPlaceholder &&
            portfolios?.map((portfolio) => (
              <Grid key={portfolio.id} item xs={12}>
                <PortfolioCard portfolio={portfolio} />
              </Grid>
            ))}
          {_.isNumber(portfolioCount) && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <ListPagination
                  count={Math.ceil(portfolioCount / pageSize)}
                  page={page}
                  pageSize={pageSize}
                  onChange={handleChange}
                  onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      }
    />
  );
}
