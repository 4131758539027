import {
  AuctionStage,
  AuctionType,
  BidStatus,
  PortfolioSnapshotStatus,
  PriceVisibility,
} from '../../../../shared/types';

export const getAuctionTypeLabel = (type: string) => {
  if (type === AuctionType.NON_BINDING) {
    return 'Non-binding';
  }
  if (type === AuctionType.BINDING) {
    return 'Binding';
  }
  return 'Test';
};

export const getVisibilityLabel = (type: string) => {
  if (type === PriceVisibility.PUBLIC) {
    return 'Public';
  }
  return 'Anonymous';
};

export const getAuctionStageLabel = (stage: string) => {
  if (stage === AuctionStage.FINISHED) {
    return 'Finished';
  }
  if (stage === AuctionStage.ONGOING) {
    return 'Ongoing';
  }
  return 'Upcoming';
};

export const getBidStatusLabel = (status: string) => {
  if (status === BidStatus.CONFIRMED) {
    return 'Confirmed';
  }
  if (status === BidStatus.ACCEPTED) {
    return 'Accepted';
  }
  if (status === BidStatus.REJECTED) {
    return 'Rejected';
  }
  return 'New';
};

export const getSnapshotStatusLabel = (status: string) => {
  if (status === PortfolioSnapshotStatus.PUBLISHED) {
    return 'Published';
  }
  if (status === PortfolioSnapshotStatus.DRAFT) {
    return 'Draft';
  }
  if (status === PortfolioSnapshotStatus.INACTIVE) {
    return 'Inactive';
  }
  return 'Processing';
};
