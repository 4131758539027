import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import BidModal from '../Portfolios/PortfolioList/PortfolioCard/BidModal';
import ListPagination from '../shared/components/ListPagination';
import { OrderbookOrdering, PortfolioSnapshot } from '../shared/types';
import OrderbookTable from './OrderbookTable/OrderbookTable';
import styles from './orderbook.module.scss';
import useApi from '../shared/hooks/useApi';
import { SelectChangeEvent } from '@mui/material/Select';
import PageLayout from '../shared/components/PageLayout';
import { Typography } from '@mui/material';

export interface BidModalState {
  open: boolean;
  portfolio: PortfolioSnapshot;
}

const OrderbookPage: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [ordering, setOrdering] = useState<OrderbookOrdering>(OrderbookOrdering.madeAtDesc);
  const [bidModalData, setBidModalData] = useState<BidModalState>({} as BidModalState);

  const api = useApi();
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['fetchOrderbook', page, pageSize, ordering],
    () => api.orderbook.fetchList(page, pageSize, ordering),
    { keepPreviousData: true },
  );

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    setPage(1);
    setPageSize(Number(event.target.value));
  };

  return (
    <PageLayout>
      <div className={styles.header}>
        <Typography className={styles.title} variant="h2">
          Orderbook
        </Typography>
      </div>
      <OrderbookTable
        data={data?.results}
        isLoading={isLoading || (isFetching && isPreviousData)}
        setOrdering={setOrdering}
        ordering={ordering}
        setBidModalData={setBidModalData}
      />
      <section className={styles.pagination}>
        <ListPagination
          count={Math.ceil((data?.count || 0) / pageSize)}
          page={page}
          pageSize={pageSize}
          onChange={handlePageChange}
          onPageSizeChange={handleRowsPerPageChange}
        />
      </section>
      {bidModalData.portfolio && bidModalData.portfolio.auction ? (
        <BidModal
          portfolio={bidModalData.portfolio}
          auction={bidModalData.portfolio.auction}
          open={bidModalData.open}
          onClose={() => {
            setBidModalData({ portfolio: {} as PortfolioSnapshot, open: false });
          }}
        />
      ) : null}
    </PageLayout>
  );
};

export default OrderbookPage;
