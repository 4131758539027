import * as React from 'react';

const SaveChangesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.786" height="20" viewBox="0 0 20.786 20">
    <g id="save-changes-icon" transform="translate(-0.075 -2.832)">
      <rect
        id="Rectangle_2834"
        data-name="Rectangle 2834"
        width="20"
        height="20"
        transform="translate(0.469 2.832)"
        fill="none"
      />
      <path
        id="Path_5584"
        data-name="Path 5584"
        d="M2.469,10.466l8.888,11.389,11.112-14"
        transform="translate(-2 -2.023)"
        fill="none"
        stroke="#40c4b2"
        stroke-width="1"
      />
    </g>
  </svg>
);

export default SaveChangesIcon;
