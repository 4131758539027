import styles from './message.center.module.scss';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { GeneralChatConversationList } from '../shared/types';
import useApi from '../shared/hooks/useApi';
import PostConversationForm from './PostConversationForm';
import ChatConversationList from './ChatConversationList';
import GeneralChatConversationTitle from './GeneralChatConversationTitle';
import GeneralChatMessages from './GeneralChatMessages';

export default function MessageCenterMessages() {
  const [selectedConversation, selectConversation] = useState<GeneralChatConversationList | null>(null);
  const [isFormShown, showForm] = useState(false);

  const api = useApi();

  const postConversation = async (form: FormData) => {
    await api.messageCenter.postGeneralChatConversation(form);
  };

  return (
    <Paper variant="outlined" className={styles.tabContent}>
      <div className={styles.tabContent__header}>
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" flexGrow={1} gap="10px">
          <Typography variant="h2">Messages</Typography>
        </Box>
        <Box display="flex" gap="10px" justifyContent="flex-end" flexWrap="wrap-reverse">
          {selectedConversation !== null || isFormShown ? (
            <Button
              variant="outlined"
              color="grey"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => (isFormShown ? showForm(false) : selectConversation(null))}
            >
              Back to message list
            </Button>
          ) : (
            <Button variant="outlined" color="grey" onClick={() => showForm(true)}>
              Write a message
            </Button>
          )}
        </Box>
      </div>

      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', minHeight: 690 }}>
          {isFormShown ? (
            <div style={{ padding: 12, display: 'flex', flex: 1 }}>
              <PostConversationForm
                onCancel={() => showForm(false)}
                postConversation={postConversation}
                postMessageCallback={() => {
                  showForm(false);
                }}
              />
            </div>
          ) : null}

          {!isFormShown && selectedConversation === null ? (
            <ChatConversationList selectConversation={selectConversation} />
          ) : null}

          {selectedConversation !== null && !isFormShown ? (
            <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column' }}>
              <GeneralChatConversationTitle
                title={selectedConversation.conversationTitle}
                date={selectedConversation.conversationCreatedAt}
              />
              <GeneralChatMessages
                selectedConversation={selectedConversation}
                deselectConversation={() => selectConversation(null)}
              />
            </div>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}
