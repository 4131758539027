import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import SiderLayout from '../../shared/components/SiderLayout';
import PortfolioSummary from './PortfolioSummary';
import PortfolioData from './PortfolioData';
import useApi from '../../shared/hooks/useApi';
import TableLoader from '../../shared/components/TableLoader';

export default function PortfolioDetails() {
  const { id } = useParams<{ id: string }>();
  const portfolioId = id && parseInt(id, 10);

  const api = useApi();

  // report data room access
  useQuery(['dataRoomAccess', portfolioId], () => api.portfolio_snapshot.dataRoomAccess(portfolioId as number), {
    enabled: Boolean(portfolioId),
    staleTime: Infinity,
    refetchOnMount: 'always',
  });

  const { data: portfolio, isLoading } = useQuery(
    ['fetchPortfolioDetails', portfolioId],
    () => api.portfolio_snapshot.fetchDetails(portfolioId as number),
    { enabled: Boolean(portfolioId) },
  );

  if (isLoading) {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <TableLoader text="Loading portfolio" />
      </div>
    );
  }

  if (!portfolio) {
    return <div />;
  }

  return (
    <SiderLayout sider={<PortfolioSummary portfolio={portfolio} />} content={<PortfolioData portfolio={portfolio} />} />
  );
}
