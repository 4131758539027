import React from 'react';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styles from './nav.module.scss';
import { useTheme } from '@mui/material/styles';

interface Props {
  to: string;
  children: string;
}

export default function NavButton(props: Props) {
  const { to, children } = props;

  const theme = useTheme();

  const activeStyle = { borderBottom: `2px solid ${theme.palette.primary.main}` };

  return (
    <NavLink className={styles.navLink} style={({ isActive }) => (isActive ? activeStyle : undefined)} to={to}>
      <Button className={styles.navBtn} sx={{ '&:hover': { color: theme.palette.primary.main } }} color="grey">
        {children}
      </Button>
    </NavLink>
  );
}
