import React, { useState } from 'react';
import PageLayout from '../shared/components/PageLayout';
import styles from './styles.module.scss';
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CompanyUser, CompanyUserStatusEnum } from '../shared/types';
import { useSnackbar } from 'notistack';
import Plus from '../shared/components/icons/Plus';
import InviteUserModal from './InviteUserModal';
import UserDetailsModal from './UserDetailsModal';
import useApi from '../shared/hooks/useApi';
import CustomTableBody from '../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';

export default function CompanyUsersPage() {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [detailsData, setDetailsData] = useState<CompanyUser | null>(null);

  const api = useApi();
  const { data: users = [], isLoading } = useQuery(['fetchCompanyUsers'], () => api.company.fetchCompanyUsers());

  const theme = useTheme();

  const getSelectDisplayStyle = (status: CompanyUserStatusEnum) => {
    if (status === CompanyUserStatusEnum.ACTIVE) {
      return { color: theme.palette.accent1.main };
    }
    if (status === CompanyUserStatusEnum.INVITED) {
      return { color: theme.palette.accent2.main };
    }
    return {};
  };

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleStatusChange = (userId: number, value: CompanyUserStatusEnum) => {
    setLoading(true);
    api.company
      .userStatusChange(userId, value)
      .then(async () => {
        await queryClient.invalidateQueries(['fetchCompanyUsers']);
        await queryClient.invalidateQueries(['fetchCompanyUserDetails', userId]);
        enqueueSnackbar('User status changed', { variant: 'success' });
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to change user status', { variant: 'error' });
        setLoading(false);
      });
  };

  return (
    <>
      <PageLayout>
        <div className={styles.header}>
          <Typography className={styles.title} variant="h2">
            Users
          </Typography>
          <Button variant="outlined" startIcon={<Plus />} onClick={() => setModalOpen(true)}>
            Add user
          </Button>
        </div>
        <TableContainer className={styles.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" padding="checkbox">
                  Details
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <CustomTableBody
              data={users}
              isLoading={isLoading}
              renderRow={(user) => {
                const { id, fullName, email, companyStatus } = user;
                return (
                  <TableRow key={id}>
                    <TableCell align="center" padding="checkbox">
                      <IconButton
                        onClick={() => {
                          setDetailsData(user);
                          setDetailsModalOpen(true);
                        }}
                        size="large"
                      >
                        <SearchIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.cellValue}>{fullName}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={styles.cellValue}>{email}</Typography>
                    </TableCell>
                    <TableCell className={styles.selectCell} align="center">
                      <Select
                        SelectDisplayProps={{
                          className: styles.selectDisplay,
                          style: getSelectDisplayStyle(companyStatus),
                        }}
                        fullWidth
                        variant="outlined"
                        value={companyStatus}
                        onChange={(e) => handleStatusChange(id, e.target.value as CompanyUserStatusEnum)}
                        disabled={loading || companyStatus === CompanyUserStatusEnum.INVITED}
                      >
                        <MenuItem sx={{ color: theme.palette.accent1.main }} value={CompanyUserStatusEnum.ACTIVE}>
                          Active
                        </MenuItem>
                        <MenuItem value={CompanyUserStatusEnum.INACTIVE}>Inactive</MenuItem>
                        <MenuItem
                          disabled
                          sx={{ color: theme.palette.accent2.main }}
                          value={CompanyUserStatusEnum.INVITED}
                        >
                          Invited
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                );
              }}
            />
          </Table>
        </TableContainer>
      </PageLayout>
      <InviteUserModal open={modalOpen} onClose={() => setModalOpen(false)} />
      {detailsData && (
        <UserDetailsModal open={detailsModalOpen} onClose={() => setDetailsModalOpen(false)} user={detailsData} />
      )}
    </>
  );
}
