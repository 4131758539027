import React from 'react';
import { Pagination } from '@mui/material';
import { Box, MenuItem, Typography } from '@mui/material';
import styles from './pagination.module.scss';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props {
  count: number;
  page: number;
  pageSize: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  onPageSizeChange: (event: SelectChangeEvent, value: unknown) => void;
  showPageSizeSelect?: boolean;
}

export default function ListPagination({
  count,
  page,
  pageSize,
  onChange,
  onPageSizeChange,
  showPageSizeSelect = true,
}: Props) {
  const handlePageSizeChange = (event: SelectChangeEvent, value: unknown) => {
    onPageSizeChange(event, value);
    onChange(event as React.ChangeEvent<unknown>, 1);
  };

  return (
    <Box className={styles.container} display="flex" justifyContent="space-between">
      <Pagination color="primary" variant="outlined" shape="rounded" count={count} page={page} onChange={onChange} />
      {showPageSizeSelect && (
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Show per page:</Typography>
          <Select variant="standard" value={pageSize.toString()} onChange={handlePageSizeChange}>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
      )}
    </Box>
  );
}
