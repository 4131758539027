import { useQuery } from '@tanstack/react-query';
import useApi from '../../../../shared/hooks/useApi';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  portfolioId: number;
}

const QaTabLink = ({ portfolioId }: Props) => {
  const api = useApi();

  const { data } = useQuery(
    ['fetchDataRoomPortfolioUnreadMessageCount', portfolioId],
    () => api.portfolio_snapshot.portfolioUnreadChatMessageCount(portfolioId as number),
    {
      enabled: Boolean(portfolioId),
      refetchInterval: 10000,
    },
  );

  const unreadMessageCount = data?.unreadMessageCount ?? 0;

  const theme = useTheme();

  return (
    <Box component="div" sx={{ position: 'relative' }}>
      <p>Questions & Answers</p>
      {unreadMessageCount > 0 ? (
        <Box component="div" sx={{ position: 'absolute', top: -5, right: -15 }}>
          <Box
            component="div"
            sx={{
              width: 12,
              height: 12,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span style={{ fontSize: 10, color: 'white' }}>!</span>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default QaTabLink;
