import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import UnreadMessageNotificationIcon from '../shared/components/chat/UnreadMessageNotificationIcon';
import useApi from '../shared/hooks/useApi';
import { GeneralChatConversationList } from '../shared/types';
import NoMessages from './NoMessages';
import ThreadsLoader from './ThreadsLoader';

interface Props {
  selectConversation: (conversation: GeneralChatConversationList) => void;
}

export default function ChatConversationList({ selectConversation }: Props) {
  const api = useApi();

  const { data, isInitialLoading } = useQuery(
    ['fetchGeneralChatConversationList'],
    api.messageCenter.fetchGeneralChatConversationList,
    {
      refetchInterval: 10000,
    },
  );

  if (isInitialLoading) {
    return <ThreadsLoader />;
  }

  if (!data?.length) {
    return <NoMessages />;
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        marginLeft: 10,
        marginRight: 10,
        paddingLeft: 24,
        paddingRight: 24,
        maxHeight: 690,
        overflowY: 'scroll',
      }}
    >
      {data?.map((item) => {
        return (
          <div
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              borderBottom: '1px solid #EBEBEB',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => selectConversation(item)}
          >
            <div style={{ color: '#5A676E', fontSize: 15, display: 'flex', flex: 1 }}>
              <span style={{ position: 'relative', fontWeight: item.unreadMessageCount > 0 ? 'bold' : 'normal' }}>
                {item.conversationTitle}{' '}
                {item.unreadMessageCount > 0 && (
                  <span style={{ paddingLeft: 5, position: 'absolute' }}>
                    <UnreadMessageNotificationIcon />
                  </span>
                )}
              </span>
            </div>
            <div style={{ color: '#5A676E', fontSize: 15, width: 100, display: 'flex', justifyContent: 'flex-end' }}>
              {moment(item.conversationCreatedAt).format('ddd, MMM DD')}
            </div>
          </div>
        );
      })}
    </div>
  );
}
