import React from 'react';
import _ from 'lodash';
import LabeledValue from '../shared/components/LabeledValue';
import { formatCurrency, formatDate } from '../shared/utils/helpers';
import { PortfolioSnapshot } from '../shared/types';

interface Props {
  portfolio: PortfolioSnapshot;
  labelSize?: string;
  valueSize?: string;
}

export default function BaseDetails({ portfolio: { kpis, cutoffDate }, labelSize, valueSize }: Props) {
  return (
    <>
      <LabeledValue label="Cut-off date" value={formatDate(cutoffDate)} labelSize={labelSize} valueSize={valueSize} />
      {kpis.weightedAverageMaturity && (
        <LabeledValue
          label="Weighted Average Maturity"
          value={kpis.weightedAverageMaturity}
          labelSize={labelSize}
          valueSize={valueSize}
        />
      )}
      <LabeledValue
        label="No. of Borrower Groups"
        value={kpis.borrowerGroupsCount.toString(10)}
        labelSize={labelSize}
        valueSize={valueSize}
      />
      {/* check if portfolio contains only real estate loans */}
      {kpis.totalCollateral === kpis.totalRev ? (
        <>
          {_.isNumber(kpis.totalRev) && (
            <LabeledValue
              label="Total REV"
              value={formatCurrency(kpis.totalRev, kpis.currency)}
              labelSize={labelSize}
              valueSize={valueSize}
            />
          )}
          {_.isNumber(kpis.waLtv) && (
            <LabeledValue
              label="WA LTV"
              value={`${kpis.waLtv.toFixed(1)} %`}
              labelSize={labelSize}
              valueSize={valueSize}
            />
          )}
        </>
      ) : (
        <>
          {_.isNumber(kpis.totalCollateral) && kpis.totalCollateral !== kpis.totalRev && (
            <LabeledValue
              label="Total Collateral"
              value={formatCurrency(kpis.totalCollateral, kpis.currency)}
              labelSize={labelSize}
              valueSize={valueSize}
            />
          )}
          {_.isNumber(kpis.totalCollateralisation) && (
            <LabeledValue
              label="Total Collateralisation"
              value={`${kpis.totalCollateralisation.toFixed(1)} %`}
              labelSize={labelSize}
              valueSize={valueSize}
            />
          )}
        </>
      )}
      {_.isNumber(kpis.propertiesCount) && (
        <LabeledValue
          label="No. of Properties"
          value={kpis.propertiesCount.toString(10)}
          labelSize={labelSize}
          valueSize={valueSize}
        />
      )}
    </>
  );
}
