import React, { useState } from 'react';
import styles from '../../../styles.module.scss';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddAuctionModal from './AddAuctionModal';

interface Props {
  portfolioId: number;
}

export default function Header({ portfolioId }: Props) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.tabContent__header}>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" flexGrow={1} gap="10px">
        <Typography variant="h2">Auctions</Typography>
      </Box>
      <Box display="flex" gap="10px" justifyContent="flex-end" flexWrap="wrap-reverse">
        <Button variant="outlined" color="grey" startIcon={<AddIcon />} onClick={() => setOpenModal(true)}>
          Add auction
        </Button>
        <AddAuctionModal open={openModal} onClose={() => setOpenModal(false)} portfolioId={portfolioId} />
      </Box>
    </div>
  );
}
