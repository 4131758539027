import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PortfolioSnapshot } from '../../../shared/types';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import BidModal from '../../PortfolioList/PortfolioCard/BidModal';
import moment from 'moment';
import { useState } from 'react';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function BiddingSection({ portfolio }: Props) {
  const {
    auction,
    totalBalance: { currency },
  } = portfolio;

  const [bidModalOpen, setBidModalOpen] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  if (!auction || !moment().isBetween(auction.startDate, auction.endDate)) return null;

  const highestBid = auction?.highestBid
    ? new Intl.NumberFormat('en', { maximumSignificantDigits: 4, currency, notation: 'compact' }).format(
        auction.highestBid,
      )
    : '-';
  const userBid = auction?.userHighestBid
    ? new Intl.NumberFormat('en', { maximumSignificantDigits: 4, currency, notation: 'compact' }).format(
        auction.userHighestBid,
      )
    : '-';

  const handlePlaceBidButton = () => {
    setBidModalOpen(true);
  };

  return (
    <>
      <Grid container marginTop="10px" paddingTop="10px" paddingBottom="20px" borderBottom={1} borderColor="#F1F1F1">
        <Grid item xs={4} md={5} xl={4}>
          <Typography style={{ color: '#5a676e', fontSize: 15, fontWeight: 400 }}>Highest Bid</Typography>
          <Typography style={{ color: '#5a676e', fontSize: 15, fontWeight: 400, marginTop: 10 }}>Your Bid</Typography>
        </Grid>
        <Grid item xs={4} xl={3}>
          <Typography style={{ color: '#303334', fontWeight: 'bold' }}>{highestBid}</Typography>
          <Typography
            style={{
              fontWeight: 'bold',
              marginTop: 5,
              color: !auction?.userHighestBid
                ? '#303334'
                : auction.isUserBidHighest
                ? theme.palette.accent1.main
                : theme.palette.accent2.main,
            }}
          >
            {userBid}
          </Typography>
        </Grid>
        <Grid
          marginTop={matches ? 0 : '20px'}
          item
          xs={12}
          md={12}
          xl={5}
          alignItems="center"
          justifyContent={matches ? 'flex-end' : 'flex-start'}
          style={{ display: 'flex', minWidth: 140 }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: 135 }}
            startIcon={<EuroOutlinedIcon />}
            onClick={handlePlaceBidButton}
          >
            Place a bid
          </Button>
        </Grid>
      </Grid>
      <BidModal
        portfolio={portfolio}
        auction={auction}
        open={bidModalOpen}
        onClose={() => {
          setBidModalOpen(false);
        }}
      />
    </>
  );
}
