import { CircularProgress } from '@mui/material';

const ChatMessagesLoader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress size={24} />
      <p style={{ marginTop: 10, color: '#5A676E', fontSize: 14 }}>Loading messages</p>
    </div>
  );
};

export default ChatMessagesLoader;
