import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import SiderLayout from '../../../shared/components/SiderLayout';
import PortfolioSummary from '../../../Portfolios/PortfolioDetails/PortfolioSummary';
import { PortfolioSnapshotAdmin, PortfolioSnapshotStatus } from '../../../shared/types';
import styles from '../../styles.module.scss';
import { Backdrop, Button, CircularProgress, Paper, Tab, Tabs, Typography } from '@mui/material';
import { formatDate } from '../../../shared/utils/helpers';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TermSheet from './TermSheet';
import PortfolioDocuments from './PortfolioDocuments';
import LoanDocuments from './LoanDocuments';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { useTheme } from '@mui/material/styles';
import { getSnapshotStatusLabel } from '../PortfolioDetailsPage/Auctions/utils';

interface FilesProps {
  snapshot: PortfolioSnapshotAdmin;
}

function SnapshotFiles({ snapshot }: FilesProps) {
  const {
    id,
    identifier,
    cutoffDate,
    portfolio: portfolioId,
    termSheet,
    documents,
    status,
    loanDocumentsImports,
    isLatest,
  } = snapshot;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const isEditable = useMemo(
    () => Boolean(isLatest && status !== PortfolioSnapshotStatus.PROCESSING),
    [status, isLatest],
  );

  const theme = useTheme();
  const getSnapshotStatusStyle = (status: string) => {
    const common = { fontSize: '18px', fontWeight: 600 };
    if (status === PortfolioSnapshotStatus.PUBLISHED) {
      return { ...common, color: theme.palette.accent1.main };
    }
    if (status === PortfolioSnapshotStatus.DRAFT) {
      return { ...common, color: theme.palette.accent2.main };
    }
    if (status === PortfolioSnapshotStatus.INACTIVE) {
      return { ...common, color: '#c4c4c4' };
    }
    return { color: theme.palette.primary.main };
  };

  return (
    <div>
      <div className={styles.tabs__container}>
        <Tabs className={styles.tabs} value="details" indicatorColor="primary" textColor="primary">
          <Tab className={styles.tabs__tab} value="details" label="Snapshot details" />
        </Tabs>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<NavigateBeforeIcon fontSize="small" />}
          onClick={() => navigate(`/portfolio_manager/${portfolioId}`)}
        >
          Back to snapshot list
        </Button>
      </div>
      <Paper variant="outlined" className={styles.tabContent}>
        <Backdrop
          open={loading}
          sx={{
            position: 'absolute',
            color: '#fff',
            height: '100%',
            opacity: 0.1,
            borderRadius: '4px',
            zIndex: (theme) => theme.zIndex.drawer - 1,
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <div className={styles.tabContent__header}>
          <div>
            <Typography variant="h2">{`Snapshot ${identifier} (${formatDate(cutoffDate)})`}</Typography>
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
              Status: <span style={getSnapshotStatusStyle(status)}>{getSnapshotStatusLabel(status)}</span>
            </Typography>
          </div>
        </div>
        <TermSheet
          snapshotId={id}
          termSheet={termSheet}
          disabled={!isEditable}
          loading={loading}
          setLoading={setLoading}
        />
        <PortfolioDocuments
          snapshotId={id}
          documents={documents}
          disabled={!isEditable}
          loading={loading}
          setLoading={setLoading}
        />
        <LoanDocuments
          snapshotId={id}
          loanDocuments={loanDocumentsImports}
          disabled={!isEditable}
          loading={loading}
          setLoading={setLoading}
        />
      </Paper>
    </div>
  );
}

export default function PortfolioSnapshotDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const snapshotId = id && parseInt(id, 10);

  const api = useAdminApi();

  const { data: snapshotDetails } = useQuery(
    ['adminFetchPortfolioSnapshotDetails', snapshotId],
    () => api.snapshots.fetchSnapshotDetails(snapshotId as number),
    {
      enabled: Boolean(snapshotId),
      refetchInterval: 30000,
    },
  );

  if (!snapshotDetails) {
    return <div />;
  }

  return (
    <SiderLayout
      sider={<PortfolioSummary portfolio={snapshotDetails} isPortfolioManager />}
      content={<SnapshotFiles snapshot={snapshotDetails} />}
    />
  );
}
