import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import Messages from '../shared/components/chat/Messages';
import useAdminApi from '../shared/hooks/useAdminApi';
import useApi from '../shared/hooks/useApi';
import { GeneralChatMessageTypeEnum, GeneralChatAnnouncementConversationList } from '../shared/types';

interface Props {
  readOnly?: boolean;
  selectedConversation: GeneralChatAnnouncementConversationList;
  deselectConversation: () => void;
}

const pageSize = 20;

export default function GeneralChatAnnouncementMessages({
  selectedConversation,
  deselectConversation,
  readOnly = false,
}: Props) {
  const apiAdmin = useAdminApi();
  const api = useApi();
  const queryClient = useQueryClient();

  const [calledCount, setCalledCount] = useState(0);
  const [scrollToBottomOnInitialLoad, setScrollToBottomOnInitialLoad] = useState(false);

  const postMessage = async (form: FormData) => {
    if (!selectedConversation.conversationId) return;
    await apiAdmin.messageCenter.postGeneralChatAnnouncement(selectedConversation.conversationId, form);
    await queryClient.invalidateQueries(['fetchGeneralChatAnnouncements', selectedConversation.conversationId]);
  };

  const infiniteQuery = useInfiniteQuery(
    ['fetchGeneralChatAnnouncements', selectedConversation.conversationId],
    ({ pageParam = 1 }) => {
      return api.messageCenter.fetchGeneralChatAnnouncements(selectedConversation.conversationId, pageParam, pageSize);
    },
    {
      keepPreviousData: true,
      refetchInterval: 10000,
      getNextPageParam: (lastPage, pages) => {
        const currentPage = pages.length;
        const nextPageAvailavble = Boolean(lastPage?.next);
        if (nextPageAvailavble) {
          return currentPage + 1;
        }
      },
      onSuccess: () => {
        if (calledCount === 0) {
          setScrollToBottomOnInitialLoad(true);
          setCalledCount(calledCount + 1);
          queryClient.invalidateQueries(['fetchGeneralChatConversationList']);
        }
      },
    },
  );

  return (
    <Messages
      adminView
      limitHeight={false}
      showFormButtons={!readOnly}
      infiniteQuery={infiniteQuery}
      shouldDisplayForm={!readOnly}
      scrollToBottomOnInitialLoad={scrollToBottomOnInitialLoad}
      setScrollToBottomOnInitialLoad={setScrollToBottomOnInitialLoad}
      readOnly={readOnly}
      postMessage={postMessage}
      showSelectThreadMessage={false}
      setInitialHeight={infiniteQuery.isInitialLoading}
      highlightedMessageType={GeneralChatMessageTypeEnum.USER}
      onCancel={deselectConversation}
      downloadAttachment={api.messageCenter.downloadGeneralChatAnnouncementAttachment}
    />
  );
}
