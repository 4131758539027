const REFRESH_TOKEN_KEY = 'wsRefreshToken';
const ACCESS_TOKEN_KEY = 'wsAccessToken';

export const getStoredAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);
export const getStoredRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const storeAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token);
export const storeRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_KEY, token);

export const removeStoredAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY);
export const removeStoredRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY);
