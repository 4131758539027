import { useTheme } from '@mui/material/styles';

interface Props {
  text: string;
  showIndicator?: boolean;
}

const TabLink = ({ text, showIndicator = false }: Props) => {
  const theme = useTheme();

  return (
    <div style={{ position: 'relative' }}>
      <p>{text}</p>
      {showIndicator && (
        <div style={{ position: 'absolute', top: -5, right: -15 }}>
          <div
            style={{
              width: 13,
              height: 13,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{ fontSize: 10, color: 'white' }}>!</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TabLink;
