import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  createFilterOptions,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ListPagination from '../../../shared/components/ListPagination';
import styles from '../../styles.module.scss';
import { useSnackbar } from 'notistack';
import { Buyer, BuyerGroup, CompanyKycDataStatusEnum, KycStatusEnum } from '../../../shared/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import BuyerDetailsModal from './BuyerDetailsModal';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import debouce from 'lodash.debounce';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import BuyerGroupManagerModal from '../BuyerGroupManagerModal';
import CustomTableBody from '../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';

interface BuyerGroupOption {
  inputValue?: string;
  id: number;
  name: string;
}
const filter = createFilterOptions<BuyerGroupOption>();

interface RowProps {
  buyer: Buyer;
  selectBuyer: (buyer: Buyer) => void;
  openBuyerManager: () => void;
}

function BuyersTableRow({ buyer, selectBuyer, openBuyerManager }: RowProps) {
  const {
    id,
    companyName,
    legalForm,
    website,
    kycConfirmed,
    kycDataStatus,
    kycStatus,
    isActive,
    hasUsers,
    buyerGroups,
  } = buyer;

  const [openModal, setOpenModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(['adminFetchBuyerGroups'], () => api.users.buyers.fetchBuyerGroups());
  const groups = data || [];

  const handleOpenModal = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleKycReview = () => {
    navigate(`/company_kyc/${id}`);
  };

  const handleActivate = async () => {
    api.users.buyers
      .toggleBuyerActive(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyers']);
        enqueueSnackbar(`${isActive ? 'Deactivated' : 'Activated'} buyer ${companyName}`, { variant: 'success' });
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(`Failed to ${isActive ? 'deactivate' : 'activate'} buyer ${companyName}`, { variant: 'error' });
      });
  };

  const handleKycToggle = async () => {
    api.users.buyers
      .toggleBuyerKycStatus(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyers']);
        enqueueSnackbar('Buyer KYC status changed', { variant: 'success' });
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to change buyer KYC status', { variant: 'error' });
      });
  };

  const debouncedGroupChange = useMemo(() => {
    const handleGroupChange = async (event: React.SyntheticEvent, value: (string | BuyerGroupOption)[]) => {
      api.users.buyers
        .setBuyerGroups(id, value as BuyerGroup[])
        .then(async () => {
          await queryClient.invalidateQueries(['adminFetchBuyers']);
          await queryClient.invalidateQueries(['adminFetchBuyerGroups']);
          enqueueSnackbar('Buyer groups updated', { variant: 'success' });
        })
        .catch((e) => {
          console.error(e);
          enqueueSnackbar('Failed to update buyer groups', { variant: 'error' });
        });
    };
    return debouce(handleGroupChange, 300);
  }, [api.users.buyers, enqueueSnackbar, id, queryClient]);

  useEffect(() => {
    return () => {
      debouncedGroupChange.cancel();
    };
  });

  const theme = useTheme();

  const getStatusStyle = () => {
    if (kycStatus === KycStatusEnum.CONFIRMED) return { color: theme.palette.accent1.main };
    if (kycStatus === KycStatusEnum.PENDING) return { color: theme.palette.primary.main };
    return { color: theme.palette.accent2.main };
  };

  return (
    <TableRow key={id}>
      <TableCell>
        <Box display="flex" alignItems="center" gap="10px">
          {companyName}
          {!hasUsers && (
            <Tooltip title={'Buyer has no users'}>
              <WarningAmberOutlinedIcon color="warning" fontSize="small" />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>{legalForm}</TableCell>
      <TableCell>{website}</TableCell>
      <TableCell sx={{ color: isActive ? theme.palette.accent1.main : theme.palette.accent2.main }}>
        {isActive ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell sx={getStatusStyle()}>{_.capitalize(kycStatus)}</TableCell>
      <TableCell>
        <Autocomplete
          multiple
          limitTags={2}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableCloseOnSelect
          freeSolo
          value={buyerGroups as BuyerGroupOption[]}
          options={groups as BuyerGroupOption[]}
          onChange={debouncedGroupChange}
          filterOptions={(options, params) => {
            const filtered = _.remove(
              filter(options, params),
              (option) => !buyerGroups.some((group) => group.name === option.name),
            );

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.name);
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                id: 0,
                name: inputValue,
              });
            }

            // Add button to open manager
            filtered.unshift({
              id: -1,
              name: 'Manage buyer groups',
            });

            return filtered;
          }}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return `Add "${option.inputValue}"`;
            }
            // Regular option & Add "xxx" option created dynamically
            return option.name;
          }}
          renderInput={(params) => <TextField {...params} size="small" variant="outlined" placeholder="Groups" />}
          renderOption={(props: object, option, state: object) => {
            let optionName = option.name;

            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              optionName = option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              optionName = `Add "${option.inputValue}"`;
            }

            if (option.id === -1) {
              return (
                <>
                  <li {...props} onClick={openBuyerManager}>
                    {optionName}
                  </li>
                  <Divider />
                </>
              );
            }

            return <li {...props}>{optionName}</li>;
          }}
          loading={isLoading}
          sx={{ width: 300 }}
          ChipProps={{ size: 'small' }}
        />
      </TableCell>
      <TableCell align="right" className={styles.actionCell}>
        <IconButton onClick={handleActionClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleOpenModal}>Details</MenuItem>
          <MenuItem onClick={() => selectBuyer(buyer)}>Users</MenuItem>
          <MenuItem onClick={handleActivate}>{isActive ? 'Deactivate' : 'Activate'}</MenuItem>
          {Boolean(kycDataStatus) && kycDataStatus !== CompanyKycDataStatusEnum.DRAFT && (
            <MenuItem onClick={handleKycReview}>Review KYC</MenuItem>
          )}
          <MenuItem onClick={handleKycToggle}>{kycConfirmed ? 'Revoke KYC' : 'Confirm KYC'}</MenuItem>
        </Menu>
      </TableCell>
      <BuyerDetailsModal buyer={buyer} open={openModal} onClose={() => setOpenModal(false)} />
    </TableRow>
  );
}

interface Props {
  selectBuyer: (buyer: Buyer) => void;
}

export default function BuyersTable({ selectBuyer }: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openGroupModal, setOpenGroupModal] = useState(false);

  const api = useAdminApi();
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['adminFetchBuyers', page, pageSize],
    () => api.users.buyers.fetchBuyers(page, pageSize),
    {
      keepPreviousData: true,
    },
  );

  const buyers = data?.results || [];
  const buyerCount = data?.count || 0;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <TableContainer className={styles.tabContent__tableContainer}>
      <Table size="small" className={styles.tabContent__table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Legal form</TableCell>
            <TableCell>Website</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>KYC Status</TableCell>
            <TableCell>Groups</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <CustomTableBody
          data={buyers}
          isLoading={isLoading || (isFetching && isPreviousData)}
          renderRow={(buyer) => (
            <BuyersTableRow buyer={buyer} selectBuyer={selectBuyer} openBuyerManager={() => setOpenGroupModal(true)} />
          )}
        />
      </Table>
      <ListPagination
        count={Math.ceil(buyerCount / pageSize)}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
        onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
      />
      <BuyerGroupManagerModal open={openGroupModal} onClose={() => setOpenGroupModal(false)} />
    </TableContainer>
  );
}
