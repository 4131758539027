import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import { useAuth } from '../../shared/hooks/useAuth';
import { useTheme } from '@mui/material/styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AutoLogoutModal({ open, onClose }: Props) {
  const [end] = useState(moment().add(1, 'minutes'));
  const [remaining, setRemaining] = useState(moment.duration(end.diff(moment())));

  useEffect(() => {
    const timer = setTimeout(() => {
      setRemaining(moment.duration(end.diff(moment())));
    }, 1000);
    return () => clearTimeout(timer);
  });

  const seconds = remaining.seconds();
  const minutes = remaining.minutes();

  const { logout } = useAuth();

  if (remaining <= moment.duration(0, 'seconds')) {
    logout();
  }

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Inactivity detected</DialogTitle>
      <DialogContent>
        You will be logged out in{' '}
        <span style={{ color: theme.palette.primary.main, fontWeight: 600 }}>{`${
          minutes ? `${minutes}m ` : ''
        }${seconds}s`}</span>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" fullWidth onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
