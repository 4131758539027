import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UnreadMessageNotificationIcon from '../shared/components/chat/UnreadMessageNotificationIcon';
import MessageCenterIcon from '../shared/components/icons/MessageCenterIcon';

interface Props {
  hasUnreadmessages: boolean;
}

export default function MessageCenterButton({ hasUnreadmessages }: Props) {
  const navigate = useNavigate();

  return (
    <IconButton onClick={() => navigate('/message_center')} style={{ marginRight: 15 }}>
      <MessageCenterIcon />
      {hasUnreadmessages && (
        <div style={{ position: 'absolute', top: 5, right: 5 }}>
          <UnreadMessageNotificationIcon />
        </div>
      )}
    </IconButton>
  );
}
