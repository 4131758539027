import { Typography } from '@mui/material';
import { Backdrop, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type Props = {
  text?: string;
  open?: boolean;
};

const LoadingBackdrop = ({ text, open = false }: Props) => {
  const theme = useTheme();

  return (
    <Backdrop
      open={open}
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="inherit" />
      {text ? <Typography sx={{ marginTop: 10, color: theme.palette.primary.main }}>{text}</Typography> : null}
    </Backdrop>
  );
};

export default LoadingBackdrop;
