import React, { useState } from 'react';
import { Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ListPagination from '../../shared/components/ListPagination';
import styles from '../styles.module.scss';
import { formatDateTime } from '../../shared/utils/helpers';
import { PortfolioImport, PortfolioImportStatus } from '../../shared/types';
import ImportDetailsModal from './ImportDetailsModal';
import ImportStatus from './ImportStatus';
import useAdminApi from '../../shared/hooks/useAdminApi';
import { Link } from 'react-router-dom';
import CustomTableBody from '../../shared/components/table/CustomTableBody';
import TableButton from '../../shared/components/table/TableButton';

interface Props {
  searchTerm: string;
  statusFilter?: PortfolioImportStatus;
}

export default function ImportLogTable({ searchTerm, statusFilter }: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [currentImport, setCurrentImport] = useState<PortfolioImport | undefined>(undefined);

  const api = useAdminApi();
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['adminFetchPortfolioImports', page, pageSize, searchTerm, statusFilter],
    () => api.imports.fetchPortfolioImports(page, pageSize, searchTerm, statusFilter),
    {
      keepPreviousData: true,
      refetchInterval: 5000,
    },
  );

  const portfolioImports = data?.results || [];
  const portfolioImportCount = data?.count || 0;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <TableContainer className={styles.tabContent__tableContainer}>
      <Table size="small" className={styles.tabContent__table}>
        <TableHead>
          <TableRow>
            <TableCell>Portfolio</TableCell>
            <TableCell>Snapshot</TableCell>
            <TableCell>File</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <CustomTableBody
          data={portfolioImports}
          isLoading={isLoading || (isFetching && isPreviousData)}
          renderRow={(portfolioImport) => {
            const { id, portfolio, portfolioId, portfolioSnapshot, fileName, user, status, start, end } =
              portfolioImport;
            return (
              <TableRow key={id}>
                <TableCell>
                  {portfolio && portfolioId && (
                    <Link to={portfolioId.toString()} className={styles.portfolioLink}>
                      {portfolio}
                    </Link>
                  )}
                </TableCell>
                <TableCell>{portfolioSnapshot}</TableCell>
                <TableCell>{fileName}</TableCell>
                <TableCell>{user}</TableCell>
                <TableCell>{start && formatDateTime(start)}</TableCell>
                <TableCell>{end && formatDateTime(end)}</TableCell>
                <TableCell>
                  <ImportStatus status={status} />
                </TableCell>
                <TableCell align="right">
                  <TableButton
                    onClick={() => {
                      setCurrentImport(portfolioImport);
                      setOpen(true);
                    }}
                  >
                    Details
                  </TableButton>
                </TableCell>
              </TableRow>
            );
          }}
        />
      </Table>
      <ListPagination
        count={Math.ceil(portfolioImportCount / pageSize)}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
        onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
      />
      <ImportDetailsModal
        open={open}
        onClose={() => {
          setCurrentImport(undefined);
          setOpen(false);
        }}
        portfolioImport={currentImport}
      />
    </TableContainer>
  );
}
