import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

export default function UnreadMessageNotificationIcon() {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        width: 12,
        height: 12,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <span style={{ fontSize: 10, color: 'white' }}>!</span>
    </Box>
  );
}
