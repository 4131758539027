import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ListPagination from '../../../../shared/components/ListPagination';
import styles from '../../../styles.module.scss';
import { useSnackbar } from 'notistack';
import { Buyer, BuyerUser } from '../../../../shared/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UserDetailsModal from './UserDetailsModal';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import CustomTableBody from '../../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';

interface RowProps {
  user: BuyerUser;
}

function UserTableRow({ user }: RowProps) {
  const { id, fullName, email, isActive, has2fa } = user;

  const [openModal, setOpenModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleOpenModal = () => {
    setOpenModal(true);
    handleClose();
  };

  const handle2faReset = async () => {
    api.users.users
      .resetUser2fa(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyerUsers']);
        enqueueSnackbar(`2FA method reset for user ${email}`, { variant: 'success' });
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(`Failed to reset 2FA method for user ${email}`, { variant: 'error' });
      });
  };

  const handleActivate = async () => {
    api.users.users
      .toggleUserActive(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyerUsers']);
        enqueueSnackbar(`${isActive ? 'Deactivated' : 'Activated'} user ${email}`, { variant: 'success' });
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(`Failed to ${isActive ? 'deactivate' : 'activate'} user ${email}`, { variant: 'error' });
      });
  };

  const handlePasswordReset = async () => {
    api.users.users
      .resetUserPassword(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyerUsers']);
        enqueueSnackbar(`Password reset link sent to user ${email}`, { variant: 'success' });
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(`Failed to send password reset link to user ${email}`, { variant: 'error' });
      });
  };

  const handlePasswordSetupResend = async () => {
    api.users.users
      .resendPasswordSetup(id)
      .then(async () => {
        enqueueSnackbar(`Password setup link sent to user ${email}`, { variant: 'success' });
        handleClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(`Failed to send password setup link to user ${email}`, { variant: 'error' });
      });
  };

  const theme = useTheme();

  return (
    <TableRow key={id}>
      <TableCell>{fullName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell sx={{ color: isActive ? theme.palette.accent1.main : theme.palette.accent2.main }}>
        {isActive ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell sx={{ color: has2fa ? theme.palette.accent1.main : theme.palette.accent2.main }}>
        {has2fa ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell align="right">
        <IconButton onClick={handleActionClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleOpenModal}>Details</MenuItem>
          <MenuItem onClick={handleActivate}>{isActive ? 'Deactivate' : 'Activate'}</MenuItem>
          <MenuItem onClick={handlePasswordReset}>Reset password</MenuItem>
          {!isActive && <MenuItem onClick={handlePasswordSetupResend}>Resend password setup email</MenuItem>}
          <MenuItem onClick={handle2faReset}>Reset 2FA</MenuItem>
        </Menu>
      </TableCell>
      <UserDetailsModal user={user} open={openModal} onClose={() => setOpenModal(false)} />
    </TableRow>
  );
}

interface Props {
  buyer: Buyer;
}

export default function UsersTable({ buyer }: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const api = useAdminApi();
  const { data, isLoading, isFetching } = useQuery(
    ['adminFetchBuyerUsers', buyer.id, page, pageSize],
    () => api.users.buyers.fetchUsers(buyer.id, page, pageSize),
    {
      keepPreviousData: true,
    },
  );

  const users = data?.results || [];
  const userCount = data?.count || 0;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <TableContainer className={styles.tabContent__tableContainer}>
      <Table size="small" className={styles.tabContent__table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>2FA</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <CustomTableBody
          isLoading={isLoading || isFetching}
          data={users}
          renderRow={(user) => <UserTableRow key={user.id} user={user} />}
        />
      </Table>
      <ListPagination
        count={Math.ceil(userCount / pageSize)}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
        onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
      />
    </TableContainer>
  );
}
