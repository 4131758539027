import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Collapse, Typography } from '@mui/material';
import { useField } from 'formik';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useApi from '../shared/hooks/useApi';
import { useTheme } from '@mui/material/styles';
import { allowedExtensions } from '../shared/utils/constants';

interface RowProps {
  fieldName: string;
  label: string;
  editDisabled: boolean;
  kycId?: number;
}

export const FileUploadRow: React.FC<RowProps> = ({ fieldName, label, editDisabled, kycId }) => {
  const [{ value }] = useField(fieldName);

  const fileName = useMemo(() => {
    if (!value) {
      return '';
    }
    return value.split('/').at(-1);
  }, [value]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const api = useApi();

  const handleUpload = async (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      const form = new FormData();
      form.append('field', fieldName);
      form.append('file', file);
      api.company
        .uploadKycFile(form)
        .then(async () => {
          await queryClient.invalidateQueries(['fetchKycData']);
          enqueueSnackbar('File uploaded', { variant: 'success' });
        })
        .catch((e) => {
          console.error(e);
          enqueueSnackbar('Failed to upload file', { variant: 'error' });
        });
    }
  };

  const downloadFile = () => {
    if (!kycId) return;
    api.company.downloadKycFile(kycId, fieldName, fileName);
  };

  return (
    <div className={styles.fileRow}>
      <Typography className={styles.fileLabel} variant="h3">
        {label}
      </Typography>
      {value && (
        <Button
          className={styles.downloadButton}
          onClick={downloadFile}
          variant="text"
          startIcon={<CloudDownloadIcon />}
        >
          {fileName}
        </Button>
      )}
      {value ? (
        <Button
          className={styles.reUploadButton}
          variant="outlined"
          color="primary"
          startIcon={<CheckIcon fontSize="large" />}
          component="label"
          onChange={handleUpload}
          disabled={editDisabled}
        >
          Reupload
          <input type="file" hidden accept={allowedExtensions} />
        </Button>
      ) : (
        <Button
          className={styles.uploadButton}
          variant="outlined"
          startIcon={<CloudUploadIcon />}
          component="label"
          onChange={handleUpload}
          disabled={editDisabled}
        >
          Browse
          <input type="file" hidden accept={allowedExtensions} />
        </Button>
      )}
    </div>
  );
};

interface Props {
  editDisabled: boolean;
  kycId?: number;
}

export default function DocumentsSection({ editDisabled, kycId }: Props) {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionHeader}>
        <Typography className={styles.sectionTitle} sx={{ color: theme.palette.primary.main }} variant="h2">
          Please upload the following documents
        </Typography>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.expandButton}
            variant={expanded ? 'contained' : 'outlined'}
            color={expanded ? 'primary' : 'grey'}
            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Hide details' : 'Show details'}
          </Button>
        </div>
      </div>

      <Collapse in={expanded}>
        <FileUploadRow
          fieldName="listOfAuthorizedRepresentatives"
          label="List of authorized representatives (pdf, doc, docx, xls, xlsx )"
          editDisabled={editDisabled}
          kycId={kycId}
        />
        <FileUploadRow
          fieldName="listOfAuthorizedSignatories"
          label="List of authorized signatories (pdf, doc, docx, xls, xlsx )"
          editDisabled={editDisabled}
          kycId={kycId}
        />
        <FileUploadRow
          fieldName="structureChart"
          label="Structure chart disclosing percentages of holdings up to individuals at the top tier (pdf, doc, docx, xls, xlsx ) "
          editDisabled={editDisabled}
          kycId={kycId}
        />
        <FileUploadRow
          fieldName="commercialRegisterExcerpt"
          label="Excerpt from the commercial register not older than 3 months (pdf, doc, docx, xls, xlsx )"
          editDisabled={editDisabled}
          kycId={kycId}
        />
        <FileUploadRow
          fieldName="personalDocuments"
          label="ID / passport copies of authorized representatives / signatories and UBOs (pdf, doc, jpg, png)"
          editDisabled={editDisabled}
          kycId={kycId}
        />
      </Collapse>
    </div>
  );
}
