import { CircularProgress } from '@mui/material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  text: string;
}

const TableLoader = ({ text }: Props) => {
  const theme = useTheme();

  return (
    <div
      style={{
        height: 200,
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={32} />
      <Typography style={{ marginTop: 10, color: theme.palette.primary.main }}>{text}</Typography>
    </div>
  );
};

export default TableLoader;
