import React from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import SingleButtonModal from '../../shared/components/SingleButtonModal';
import { PhoneNumberInputRow, TextInputRow } from '../../shared/components/form_inputs/common';
import useAdminApi from '../../shared/hooks/useAdminApi';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AddAdminModal({ open, onClose }: Props) {
  const api = useAdminApi();

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(requiredMsg),
    email: Yup.string().email('E-mail is not valid.').required(requiredMsg),
    phone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
    mobilePhone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
    companyName: Yup.string().required(requiredMsg),
    jobTitle: Yup.string().required(requiredMsg),
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return (
    <Formik
      initialValues={{
        fullName: '',
        email: '',
        phone: '',
        mobilePhone: '',
        companyName: '',
        jobTitle: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        return api.users.admins
          .createAdminUser(values)
          .then(async () => {
            await queryClient.invalidateQueries(['adminFetchAdminUsers']);
            enqueueSnackbar('New admin added', { variant: 'success' });
            onClose();
            resetForm();
          })
          .catch((e) => {
            console.error(e);
            enqueueSnackbar('Failed to add new admin', { variant: 'error' });
          });
      }}
    >
      {({ isSubmitting, isValid, submitForm, resetForm }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={() => {
              onClose();
              resetForm();
            }}
            title="Add new admin"
            content={
              <>
                <TextInputRow key="fullName" editMode fieldName="fullName" label="Name" />
                <TextInputRow key="email" editMode fieldName="email" label="Email" />
                <TextInputRow key="companyName" editMode fieldName="companyName" label="Company" />
                <TextInputRow key="jobTitle" editMode fieldName="jobTitle" label="Job title" />
                <PhoneNumberInputRow key="phone" editMode fieldName="phone" label="Phone" />
                <PhoneNumberInputRow key="mobilePhone" editMode fieldName="mobilePhone" label="Mobile phone" />
              </>
            }
            buttonLabel="Add admin"
            onClick={submitForm}
            isSubmitting={isSubmitting}
            disabled={isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
