import React from 'react';
import { Grid } from '@mui/material';
import { Loan } from '../../../../../shared/types';
import { countryCodeToName, formatConvertedAmount, formatDate } from '../../../../../shared/utils/helpers';
import styles from './loanDetails.module.scss';
import LabeledValue from '../../../../../shared/components/LabeledValue';

interface Props {
  loan: Loan;
}

export default function BasicInfo({ loan }: Props) {
  const {
    contractIdentifier,
    originationAmount,
    originationAmountConverted,
    loanCurrency,
    dateOfOrigination,
    legalBalance,
    legalBalanceConverted,
    governingLawOfLoanAgreement,
    countryOfOrigination,
    currentMaturityDate,
  } = loan;

  const basicInfo = [
    {
      label: 'Contract Identifier',
      value: contractIdentifier,
    },
    {
      label: 'Origination Amount',
      value: formatConvertedAmount(originationAmount, loanCurrency, originationAmountConverted),
    },
    {
      label: 'Date Of Origination',
      value: formatDate(dateOfOrigination),
    },
    {
      label: 'Legal Balance',
      value: formatConvertedAmount(legalBalance, loanCurrency, legalBalanceConverted),
    },
    {
      label: 'Loan Currency',
      value: loanCurrency,
    },
    {
      label: 'Governing Law Of Loan Agreement',
      value: countryCodeToName(governingLawOfLoanAgreement),
    },
    {
      label: 'Country Of Origination',
      value: countryCodeToName(countryOfOrigination || governingLawOfLoanAgreement),
    },
    {
      label: 'Current Maturity Date',
      value: formatDate(currentMaturityDate),
    },
  ];

  return (
    <Grid className={styles.basics} container spacing={3}>
      {basicInfo.map(({ label, value }) => (
        <Grid key={label} item xs={3}>
          <LabeledValue variant="vertical" label={label} value={value} labelSize="15px" valueSize="24px" />
        </Grid>
      ))}
    </Grid>
  );
}
