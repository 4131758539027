import UnreadMessageNotificationIcon from '../shared/components/chat/UnreadMessageNotificationIcon';
import ChevronRightIcon from '../shared/components/icons/ChevronRight';

interface Props {
  isSelected: boolean;
  hasUnreadMessages: boolean;
  onSelect: () => void;
}

export function AnnouncementItem({ isSelected, hasUnreadMessages, onSelect }: Props) {
  return (
    <div
      onClick={onSelect}
      style={{
        height: 50,
        backgroundColor: isSelected ? '#F4FAFF' : 'white',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid #F1F1F1',
        borderTop: '1px solid #F1F1F1',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span
          style={{
            fontSize: '12px',
            fontWeight: isSelected ? 'bold' : 'normal',
            color: isSelected ? '#303334' : '#A4A9AB',
          }}
        >
          General Announcements
        </span>
        <span
          style={{
            fontSize: 12,
            color: '#5A676E',
            marginTop: 3,
          }}
        >
          All Buyers
        </span>
      </div>
      <div>{isSelected ? <ChevronRightIcon /> : hasUnreadMessages && <UnreadMessageNotificationIcon />}</div>
    </div>
  );
}
