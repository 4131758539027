import parseMax from 'libphonenumber-js/max';
import * as Yup from 'yup';
import { StringSchema } from 'yup';

Yup.addMethod<StringSchema>(Yup.string, 'phone', function (message = 'Please enter a valid phone number.') {
  return this.test({
    name: 'validatePhoneNumber',
    message,
    test: (value) => {
      // allow phone field to be optional
      if (!Boolean(value)) {
        return true;
      }
      return !!parseMax(value ?? '')?.isValid();
    },
  });
});
