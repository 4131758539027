import React, { useEffect, useMemo, useState } from 'react';
import styles from '../styles.module.scss';
import { Table, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import CustomTableBody from '../../shared/components/table/CustomTableBody';
import ListPagination from '../../shared/components/ListPagination';
import useAdminApi from '../../shared/hooks/useAdminApi';
import { useQuery } from '@tanstack/react-query';
import { formatDateTime } from '../../shared/utils/helpers';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import debouce from 'lodash.debounce';

export default function AuditLog() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  // filter values
  const [fromTimestamp, setFromTimestamp] = React.useState<Moment | null>(null);
  const [toTimestamp, setToTimestamp] = React.useState<Moment | null>(null);
  const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
  const debouncedSearch = useMemo(() => {
    const handleChange = (e: any) => {
      setSearchTerm(e.target.value);
    };
    return debouce(handleChange, 500);
  }, [setSearchTerm]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  const api = useAdminApi();
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['adminFetchAuditLog', page, pageSize, searchTerm, fromTimestamp, toTimestamp],
    () => api.fetchAuditLog(page, pageSize, searchTerm, fromTimestamp, toTimestamp),
    {
      keepPreviousData: true,
    },
  );

  const events = data?.results || [];
  const eventCount = data?.count || 0;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <>
      <div className={styles.tabContent__header}>
        <Typography variant="h2">Audit log</Typography>
        <div className={styles.logFilters__controls}>
          <DateTimePicker
            renderInput={(props) => <TextField variant="standard" {...props} />}
            label="From timestamp"
            ampm={false}
            value={fromTimestamp}
            onChange={(newValue) => {
              setFromTimestamp(newValue);
            }}
          />
          <DateTimePicker
            renderInput={(props) => <TextField variant="standard" {...props} />}
            label="To timestamp"
            ampm={false}
            value={toTimestamp}
            onChange={(newValue) => {
              setToTimestamp(newValue);
            }}
          />
          <TextField label="Search" variant="standard" defaultValue={searchTerm} onChange={debouncedSearch} />
        </div>
      </div>
      <TableContainer className={styles.tabContent__tableContainer}>
        <Table size="small" className={styles.tabContent__table}>
          <TableHead>
            <TableRow>
              <TableCell width="10%">Timestamp</TableCell>
              <TableCell width="15%">User</TableCell>
              <TableCell width="15%">Buyer</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <CustomTableBody
            data={events}
            isLoading={isLoading || (isFetching && isPreviousData)}
            renderRow={({ id, timestamp, user, buyer, event, description }) => (
              <TableRow key={id}>
                <TableCell>{formatDateTime(timestamp)}</TableCell>
                <TableCell>{user}</TableCell>
                <TableCell>{buyer}</TableCell>
                <TableCell>{event}</TableCell>
                <TableCell>{description}</TableCell>
              </TableRow>
            )}
          />
        </Table>
        <ListPagination
          count={Math.ceil(eventCount / pageSize)}
          page={page}
          pageSize={pageSize}
          onChange={handlePageChange}
          onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
        />
      </TableContainer>
    </>
  );
}
