import axios from 'axios';
import { getStoredAccessToken } from '../utils/authToken';
import { useAuth } from './useAuth';
import { ListResponse } from '../types';

const client = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});
client.defaults.headers.common['Content-Type'] = 'application/json';

interface APIObject {
  id?: number;
}

export function CRUDActions<T extends APIObject>(endpoint: string) {
  return {
    fetchList: async (page = 1, pageSize = 3, ordering: string | null = null, filters: any = {}) =>
      client
        .get(endpoint, { params: { page, page_size: pageSize, ordering, ...filters } })
        .then((response) => response.data as ListResponse<T>),
    fetchDetails: async (id?: number) => {
      if (id) {
        return client.get(`${endpoint}${id}/`).then((response) => response.data as T);
      }
      return undefined;
    },
  };
}

export default function useClient() {
  const { logout, refreshAccessToken } = useAuth();

  client.interceptors.request.use(
    (config) => {
      const accessToken = getStoredAccessToken();
      if (accessToken) {
        config.headers['Authorization'] = `JWT ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(undefined, async (error) => {
    const originalConfig = error.config;
    // try to refresh access token if current one is expired
    // avoid trying to refresh on invalid login
    // _retry flag is added to avoid infinite refresh loop
    if (error.response.status === 401 && error.config.url !== '/api/auth/login/' && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        await refreshAccessToken();
        return client(originalConfig);
      } catch (err) {
        logout();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  });
  return client;
}
