import React, { useState } from 'react';
import styles from '../../styles.module.scss';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddBuyerModal from './AddBuyerModal';

export default function BuyerHeader() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className={styles.tabContent__header}>
        <Typography variant="h2">Buyers</Typography>
        <Button variant="outlined" color="grey" startIcon={<AddIcon />} onClick={() => setOpenModal(true)}>
          Add buyer
        </Button>
      </div>
      <AddBuyerModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
}
