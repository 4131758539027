import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    marginTop: ({ marginTop }: StyleProps) => marginTop || '10px',
  },
  label: {
    color: ({ labelColor }: StyleProps) => labelColor || '#5a676e',
    fontSize: ({ labelSize }: StyleProps) => labelSize || '12px',
    fontWeight: 400,
  },
  value: {
    color: ({ valueColor }: StyleProps) => valueColor || '#303334',
    fontSize: ({ valueSize }: StyleProps) => valueSize || '15px',
    fontWeight: 600,
    marginLeft: ({ variant }: StyleProps) => (variant === 'horizontal' ? '5px' : '0px'),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fullWidth: {
    width: '100%',
  },
});

interface StyleProps {
  labelSize?: string;
  labelColor?: string;
  valueSize?: string;
  valueColor?: string;
  variant?: 'horizontal' | 'vertical';
  marginTop?: string;
}

interface Props extends StyleProps {
  label: string;
  value: string | React.ReactNode;
  justifyContent?: string;
  alignItems?: string;
}

export default function LabeledValue(props: Props) {
  const { label, value, alignItems, justifyContent = 'space-between', variant = 'horizontal' } = props;
  const classes = useStyles({ ...props, variant });

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection={variant === 'horizontal' ? 'row' : 'column'}
      justifyContent={justifyContent}
      alignItems={alignItems || variant === 'horizontal' ? 'center' : 'start'}
    >
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={`${classes.value} ${variant === 'horizontal' ? '' : classes.fullWidth}`}>
        {value}
      </Typography>
    </Box>
  );
}
