import React, { useEffect, useMemo, useState } from 'react';
import styles from '../styles.module.scss';
import { Box, Button, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import PortfolioImportModal from './PortfolioImportModal';
import { PortfolioImportStatus } from '../../shared/types';
import debouce from 'lodash.debounce';

interface FilterProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  statusFilter?: PortfolioImportStatus;
  setStatusFilter: (value: PortfolioImportStatus | undefined) => void;
  showStatusFilter?: boolean;
}

function Filters({ searchTerm, setSearchTerm, statusFilter, setStatusFilter, showStatusFilter = false }: FilterProps) {
  const debouncedSearch = useMemo(() => {
    const handleChange = (e: any) => {
      setSearchTerm(e.target.value);
    };
    return debouce(handleChange, 500);
  }, [setSearchTerm]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  return (
    <Box display="flex" alignItems="center">
      <InputLabel id="label" style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}>
        Search:
      </InputLabel>
      <OutlinedInput className={styles.searchInput} defaultValue={searchTerm} onChange={debouncedSearch} />
      {showStatusFilter && [
        <InputLabel key="label" id="label" style={{ display: 'flex', alignSelf: 'center', marginRight: 20 }}>
          Status:
        </InputLabel>,
        <Select
          variant="outlined"
          labelId="label"
          disableUnderline
          key="status-filter"
          id="status-filter"
          value={statusFilter}
          onChange={(event) => {
            setStatusFilter(event.target.value as PortfolioImportStatus);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          }}
          className={styles.statusFilter__select}
          SelectDisplayProps={{ className: styles.statusFilter__select__display }}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value={PortfolioImportStatus.SUCCESS}>Success</MenuItem>
          <MenuItem value={PortfolioImportStatus.FAILED}>Failed</MenuItem>
          <MenuItem value={PortfolioImportStatus.IN_PROGRESS}>In progress</MenuItem>
          <MenuItem value={PortfolioImportStatus.NEW}>New</MenuItem>
        </Select>,
      ]}
    </Box>
  );
}

interface Props {
  title: string;
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  statusFilter?: PortfolioImportStatus;
  setStatusFilter: (value: PortfolioImportStatus | undefined) => void;
  showStatusFilter?: boolean;
}

export default function Header({
  title,
  searchTerm,
  setSearchTerm,
  statusFilter,
  setStatusFilter,
  showStatusFilter = false,
}: Props) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.tabContent__header}>
      <Box display="flex" justifyContent="space-between" flexGrow={1} gap="10px">
        <Typography variant="h2">{title}</Typography>
        <Filters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          showStatusFilter={showStatusFilter}
        />
      </Box>
      <Button variant="outlined" color="grey" startIcon={<UploadIcon />} onClick={() => setOpenModal(true)}>
        Import portfolio
      </Button>
      <PortfolioImportModal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
}
