export const allowedExtensions = [
  'image/png',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/gzip',
  'application/zip',
  'application/x-7z-compressed',
].join(',');
