const ChevronRightIcon = () => (
  <svg width={16} height={16}>
    <g data-name="Group 1163">
      <g data-name="Group 13" fill="none" stroke="#303334" strokeLinecap="square" strokeWidth={2}>
        <path data-name="Line 11" d="m6.167 12.5 4-4" />
        <path data-name="Line 12" d="m6.167 4 4 4" />
      </g>
    </g>
  </svg>
);

export default ChevronRightIcon;
