import React from 'react';
import styles from './styles.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';

interface Props {
  title: string;
  subHeader?: JSX.Element;
  onClose: () => void;
}

export default function ModalHeader({ title, subHeader, onClose }: Props) {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography className={styles.header__title}>{title}</Typography>
        {Boolean(subHeader) && subHeader}
      </Box>
      <IconButton className={styles.header__btnClose} onClick={onClose} size="large" data-testid="close-modal">
        <CloseIcon fontSize="large" />
      </IconButton>
    </Box>
  );
}
