import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles.module.scss';
import { Button, Tab, Tabs } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import PortfolioDetails from './PortfolioDetails';
import TabPanel from '../../../shared/components/TabPanel';
import AccessManagement from './AccessManagement';
import Auctions from './Auctions';
import QaTabLinkAdmin from './QuestionsAndAnswersTab/QATabLinkAdmin';
import QuestionsAndAnswersTab from '../../../shared/components/chat';
import TabLink from './TabLink';
import TableLoader from '../../../shared/components/TableLoader';

export default function PortfolioDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const portfolioId = id && parseInt(id, 10);

  const navigate = useNavigate();

  const api = useAdminApi();

  const { data: portfolio, isLoading: isLoadingPortfolio } = useQuery(
    ['adminFetchPortfolioDetails', portfolioId],
    () => api.portfolios.fetchPortfolioDetails(portfolioId as number),
    {
      enabled: Boolean(portfolioId),
    },
  );

  const { data } = useQuery(
    ['adminFetchPortfolioSnapshots', portfolioId],
    () => api.portfolios.fetchPortfolioSnapshots(portfolioId as number),
    {
      enabled: Boolean(portfolioId),
      refetchInterval: 10000,
    },
  );

  const snapshots = data || [];

  const [tab, setTab] = useState('details');
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <main className={styles.main}>
      <div className={styles.tabs__container}>
        <Tabs
          className={styles.tabs}
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            className={styles.tabs__tab}
            value="details"
            label={<TabLink text="Details" showIndicator={portfolio?.hasUnpublishedChanges} />}
          />
          <Tab className={styles.tabs__tab} value="access" label="Access management" />
          <Tab
            className={styles.tabs__tab}
            value="auctions"
            label={<TabLink text="Auctions" showIndicator={portfolio?.hasUnpublishedAuctionChanges} />}
          />
          {portfolio ? (
            <Tab
              className={styles.tabs__tab}
              value="questionsAndAnswers"
              label={<QaTabLinkAdmin portfolioId={portfolio.id} />}
            />
          ) : null}
        </Tabs>
        <Button
          className={styles.tabs__backBtn}
          variant="outlined"
          color="primary"
          startIcon={<NavigateBeforeIcon fontSize="small" />}
          onClick={() => navigate('/portfolio_manager')}
        >
          Back to Portfolios
        </Button>
      </div>
      <div>
        <TabPanel value={tab} index="details">
          {portfolio && <PortfolioDetails portfolio={portfolio} snapshots={snapshots} />}
          {isLoadingPortfolio && (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 100 }}>
              <TableLoader text="Loading portfolio" />
            </div>
          )}
        </TabPanel>
        <TabPanel value={tab} index="access">
          {portfolio && (
            <AccessManagement portfolio={portfolio} defaultFilter={portfolio.hasInvitedBuyers ? 'INVITED' : ''} />
          )}
        </TabPanel>
        <TabPanel value={tab} index="auctions">
          {portfolio && <Auctions portfolioId={portfolio.id} portfolio={portfolio} />}
        </TabPanel>
        <TabPanel value={tab} index="questionsAndAnswers">
          {portfolio && <QuestionsAndAnswersTab adminView portfolioId={portfolio.id} />}
        </TabPanel>
      </div>
    </main>
  );
}
