import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { Paper, Tab, Tabs } from '@mui/material';
import BuyersTable from './BuyerManager/BuyersTable';
import BuyerHeader from './BuyerManager/BuyerHeader';
import { Buyer } from '../../shared/types';
import UserHeader from './BuyerManager/UserManager/UserHeader';
import UsersTable from './BuyerManager/UserManager/UsersTable';
import { useLocation, useNavigate } from 'react-router-dom';
import TabPanel from '../../shared/components/TabPanel';
import RegisteredUsersManager from './RegisteredUsersManager';
import AuditLog from './AuditLog';

export default function BuyerManagerPage() {
  const location = useLocation();
  const [tab, setTab] = useState(location.hash.length ? location.hash.slice(1) : 'buyers');
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | undefined>(undefined);

  const navigate = useNavigate();
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    if (newValue !== tab) {
      setTab(newValue);
      navigate(`#${newValue}`);
    }
  };

  return (
    <main className={styles.main}>
      <div className={styles.tabs__container}>
        <Tabs
          className={styles.tabs}
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className={styles.tabs__tab} value="buyers" label="Buyers" />
          <Tab className={styles.tabs__tab} value="registered_users" label="Activation requests" />
          <Tab className={styles.tabs__tab} value="audit_log" label="Audit log" />
        </Tabs>
      </div>
      <Paper variant="outlined" className={styles.tabContent}>
        <TabPanel value={tab} index="buyers">
          {selectedBuyer ? (
            <>
              <UserHeader buyer={selectedBuyer} clearSelectedBuyer={() => setSelectedBuyer(undefined)} />
              <UsersTable buyer={selectedBuyer} />
            </>
          ) : (
            <>
              <BuyerHeader />
              <BuyersTable selectBuyer={setSelectedBuyer} />
            </>
          )}
        </TabPanel>
        <TabPanel value={tab} index="registered_users">
          <RegisteredUsersManager />
        </TabPanel>
        <TabPanel value={tab} index="audit_log">
          <AuditLog />
        </TabPanel>
      </Paper>
    </main>
  );
}
