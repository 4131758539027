import React from 'react';
import { CircularProgress } from '@mui/material';
import styles from './styles.module.scss';

interface Props {
  falseLabel: string;
  trueLabel: string;
  value: boolean;
  isLoading: boolean;
  handleSwitch: () => void;
}

export default function TableSwitch({ falseLabel, trueLabel, value, isLoading, handleSwitch }: Props) {
  return (
    <div className={styles.switch__container}>
      {isLoading && (
        <div className={styles.switch__loader}>
          <CircularProgress size={16} />
        </div>
      )}
      <div className={value ? styles.switch__value : styles.switch__selectedValue} onClick={handleSwitch}>
        <span>{falseLabel}</span>
      </div>
      <div className={value ? styles.switch__selectedValue : styles.switch__value} onClick={handleSwitch}>
        <span>{trueLabel}</span>
      </div>
    </div>
  );
}
