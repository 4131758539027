import { PortfolioSnapshot } from '../shared/types';
import { formatCurrency } from '../shared/utils/helpers';

// eslint-disable-next-line import/prefer-default-export
export const getPortfolioTitle = (portfolio: PortfolioSnapshot) => {
  const {
    originatorType,
    hasCollateral,
    servicing,
    totalBalance: { original, currency },
  } = portfolio;

  return `${originatorType}, ${hasCollateral ? '' : 'No '}Collateral, Servicing ${
    servicing === 'No' ? 'Not ' : ''
  }Provided - ${formatCurrency(original, currency)}`;
};
