import { PortfolioImportStatus } from '../../shared/types';
import { Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

interface Props {
  status: PortfolioImportStatus;
}

export default function ImportStatus({ status }: Props) {
  const theme = useTheme();
  const getColor = (status: PortfolioImportStatus) => {
    if (status === PortfolioImportStatus.SUCCESS) {
      return theme.palette.accent1.main;
    }
    if (status === PortfolioImportStatus.FAILED) {
      return theme.palette.accent2.main;
    }
    return theme.palette.primary.main;
  };

  const getLabel = (status: PortfolioImportStatus) => {
    if (status === PortfolioImportStatus.SUCCESS) {
      return 'Success';
    }
    if (status === PortfolioImportStatus.FAILED) {
      return 'Failed';
    }
    if (status === PortfolioImportStatus.NEW) {
      return 'New';
    }
    return 'In progress';
  };

  return <Typography sx={{ color: getColor(status) }}>{getLabel(status)}</Typography>;
}
