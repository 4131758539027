import { Button } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from 'yup';
import DeleteAttachmentIcon from '../icons/DeleteAttachmentIcon';
import MessageAttachmentButton from './MessageAttachmentButton';
import PostMessageInput from './PostMessageInput';
import styles from './qna.module.scss';

interface Props {
  readOnly?: boolean;
  showFormButtons?: boolean;
  postMessageCallback?: () => void;
  postMessage: (form: FormData) => Promise<void>;
  onCancel?: () => void;
}

export default function PostMessageForm({
  postMessageCallback,
  postMessage,
  readOnly = false,
  showFormButtons = false,
  onCancel = () => {},
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    text: Yup.string().required(requiredMsg),
  });

  const Loader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: 64, height: 25, marginTop: 3 }}>
        <CircularProgress size={24} />
      </div>
    );
  };

  const isDisabled = readOnly || isLoading;

  return (
    <div>
      <Formik
        initialValues={{
          text: '',
          attachments: [],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true);

          try {
            const form = new FormData();
            form.append('text', values.text.trim());

            for (let attachment of values.attachments) {
              form.append('attachments[]', attachment);
            }

            await postMessage(form);
            resetForm();

            if (postMessageCallback) {
              postMessageCallback();
            }
          } catch (e) {
            enqueueSnackbar('Failed post the message', {
              variant: 'error',
            });
          } finally {
            setLoading(false);
          }
        }}
      >
        {({ values, setFieldValue, submitForm, dirty, isValid }) => (
          <Form className={styles.qna__form} autoComplete="off">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: isDisabled ? 'rgba(239, 239, 239, 0.3' : 'white',
              }}
              className={styles.qna__form__input}
            >
              {isLoading ? <Loader /> : <MessageAttachmentButton readyOnly={readOnly} />}
              <div className={styles.qna__message_attachment}>
                <Field
                  component={PostMessageInput}
                  variant="standard"
                  name="text"
                  type="text"
                  placeholder={readOnly ? 'Chat is in read-only mode because the Q&A period is inactive.' : 'Message'}
                  disabled={isDisabled}
                />
                {Boolean(values.attachments.length) && (
                  <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 5, marginLeft: 5 }}>
                    {values.attachments.map((attachment, index) => {
                      return (
                        <div key={URL.createObjectURL(attachment)}>
                          <Button
                            // @ts-ignore
                            key={attachment.name}
                            size="small"
                            style={{ paddingLeft: 0 }}
                            endIcon={<DeleteAttachmentIcon />}
                            onClick={() => {
                              // Clear input files so that the removed file can be added again
                              const attachmentUploadInput = document?.getElementById(
                                'qaAddAttachmentInput',
                              ) as HTMLInputElement | null;
                              if (attachmentUploadInput) {
                                attachmentUploadInput.value = '';
                              }
                              setFieldValue(
                                'attachments',
                                values.attachments.filter((_item, i) => i !== index),
                              );
                            }}
                          >
                            {/* @ts-ignore */}
                            <span className={styles.qna__message_attachment_name}>{attachment.name}</span>
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {showFormButtons && (
              <div style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 20 }}
                  onClick={submitForm}
                  disabled={!(isValid && dirty) || isDisabled}
                >
                  Send message
                </Button>
                <Button variant="outlined" color="grey" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
