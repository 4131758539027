import React from 'react';

export default function ArrowRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-394 -679)">
        <rect
          style={{ fill: 'inherit', opacity: 0 }}
          className="a"
          width="24"
          height="24"
          transform="translate(394 679)"
        />
        <g transform="translate(-126 8)">
          <g transform="translate(448.5 2322.7) rotate(-90)">
            <line
              style={{ stroke: 'inherit', strokeWidth: '2px', strokeLinecap: 'square' }}
              className="b"
              x2="4"
              y2="4"
              transform="translate(1635.5 89.5)"
            />
            <line
              style={{ stroke: 'inherit', strokeWidth: '2px', strokeLinecap: 'square' }}
              className="b"
              x1="4"
              y2="4"
              transform="translate(1640 89.5)"
            />
          </g>
          <line
            style={{ stroke: 'inherit', strokeWidth: '2px' }}
            className="c"
            x1="20"
            transform="translate(521.5 683)"
          />
        </g>
      </g>
    </svg>
  );
}
