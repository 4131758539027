import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Paper, Typography } from '@mui/material';
import { PortfolioSnapshot } from '../../../shared/types';
import LoanScreeningTable from '../../LoanScreeningTable';
import CustomAccordion from '../../../shared/components/CustomAccordion';
import useApi from '../../../shared/hooks/useApi';
import RepaymentScreeningTable from '../../RepaymentScreeningTable';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function DetailsTab({ portfolio }: Props) {
  const api = useApi();

  const { data: loanScreening } = useQuery(['fetchPortfolioLoanScreening', portfolio.id], () =>
    api.portfolio_snapshot.loanScreening(portfolio.id),
  );

  const { baseCurrency, description } = portfolio;
  const displayCurrency = loanScreening?.currency || baseCurrency;

  const hasDescription = Boolean(description);

  let tabs = [
    {
      key: 'originationYear',
      label: 'Origination Year',
      component: <LoanScreeningTable data={loanScreening?.originationYear} currency={displayCurrency} />,
    },
    {
      key: 'interestRate',
      label: 'Interest Rate',
      component: <LoanScreeningTable data={loanScreening?.interestRate} currency={displayCurrency} />,
    },
    {
      key: 'maturityYear',
      label: 'Maturity Year',
      component: <LoanScreeningTable data={loanScreening?.maturityYear} currency={displayCurrency} />,
    },
    {
      key: 'legalVintage',
      label: 'Legal Vintage',
      component: <LoanScreeningTable data={loanScreening?.legalVintage} currency={displayCurrency} />,
    },
    {
      key: 'loanSize',
      label: 'Loan Size Distribution',
      component: <LoanScreeningTable data={loanScreening?.loanSizeDistribution} currency={displayCurrency} />,
    },
    {
      key: 'loanToValue',
      label: 'Loan To Value (LTV)',
      component: <LoanScreeningTable data={loanScreening?.loanToValue} currency={displayCurrency} />,
    },
    {
      key: 'loanRepaymentsByYear',
      label: 'Repayments By Year',
      component: <RepaymentScreeningTable data={loanScreening?.loanRepaymentsByYear} currency={displayCurrency} />,
    },
  ];

  if (hasDescription) {
    tabs = [
      {
        key: 'description',
        label: 'Portfolio Description',
        component: <Typography>{description}</Typography>,
      },
      ...tabs,
    ];
  }

  return (
    <Paper variant="outlined">
      <CustomAccordion tabs={tabs} />
    </Paper>
  );
}
