import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import PageLayout from '../shared/components/PageLayout';
import { Button, Typography } from '@mui/material';
import CompanyInfoSection from './CompanyInfoSection';
import { Form, Formik, FormikHelpers } from 'formik';
import { CompanyKycDataStatusEnum, NewCompanyKycData } from '../shared/types';
import { useSnackbar } from 'notistack';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AuthorizedRepresentativeSection from './AuthorizedRepresentativeSection';
import UltimateBeneficialOwnersSection from './UltimateBeneficialOwnersSection';
import DocumentsSection from './DocumentsSection';
import _ from 'lodash';
import StatusBadge from './StatusBadge';
import useApi from '../shared/hooks/useApi';
import useUser from '../shared/hooks/useUser';

const baseValues: any = {
  companyName: '',
  legalForm: '',
  competentRegistrationAuthority: '',
  commercialRegisterNumber: '',
  taxId: '',
  legalEntityIdentifier: '',
  typeOfBusiness: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  country: '',
  website: '',
  nameOfRepresentative: '',
  phone: '',
  email: '',
  ultimateBeneficialOwners: [],
  listOfAuthorizedRepresentatives: '',
  listOfAuthorizedSignatories: '',
  structureChart: '',
  commercialRegisterExcerpt: '',
  personalDocuments: '',
  noBeneficialOwners: false,
  noBeneficialOwnersExplanation: '',
};

// need to set this since we run validation on save and Formik does not display errors on untouched fields
const initialTouched: any = {
  companyName: true,
  legalForm: true,
  competentRegistrationAuthority: true,
  commercialRegisterNumber: true,
  taxId: true,
  legalEntityIdentifier: true,
  typeOfBusiness: true,
  address1: true,
  address2: true,
  zipCode: true,
  city: true,
  country: true,
  website: true,
  nameOfRepresentative: true,
  phone: true,
  email: true,
  noBeneficialOwnersExplanation: true,
};

export default function CompanyKycPage() {
  const [editorOpen, setEditorOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { user } = useUser();
  const companyExists = useMemo(() => Boolean(user?.company), [user]);

  const api = useApi();

  const { data: kycData } = useQuery(['fetchKycData'], api.company.fetchKycData, {
    enabled: companyExists,
  });

  const formStatus: CompanyKycDataStatusEnum = useMemo(() => {
    if (!kycData) {
      return CompanyKycDataStatusEnum.DRAFT;
    }
    return kycData.status;
  }, [kycData]);
  const isDraft = useMemo(() => formStatus === CompanyKycDataStatusEnum.DRAFT, [formStatus]);

  const isReadyForSubmission = useMemo(() => {
    if (!kycData) {
      return false;
    }
    if (user?.kycOverride) {
      return true;
    }
    return !!(
      kycData.authorizedRepresentative &&
      kycData.listOfAuthorizedRepresentatives &&
      kycData.listOfAuthorizedSignatories &&
      kycData.structureChart &&
      kycData.commercialRegisterExcerpt &&
      kycData.personalDocuments &&
      ((kycData.noBeneficialOwners && kycData.noBeneficialOwnersExplanation) ||
        kycData.ultimateBeneficialOwners.length > 0)
    );
  }, [kycData, user?.kycOverride]);

  const initialValues: any = useMemo(() => {
    if (kycData) {
      return kycData;
    }
    return baseValues;
  }, [kycData]);

  const dateFields = useMemo(() => ['dateOfBirth', 'documentValidFrom', 'documentValidUntil'], []);
  const fileFields = useMemo(
    () => [
      'listOfAuthorizedRepresentatives',
      'listOfAuthorizedSignatories',
      'structureChart',
      'commercialRegisterExcerpt',
      'personalDocuments',
    ],
    [],
  );

  const handleSubmit = async (values: NewCompanyKycData, { setSubmitting }: FormikHelpers<NewCompanyKycData>) => {
    try {
      // adjust date format for representative and UBOs
      const authorizedRepresentative = values.authorizedRepresentative;
      authorizedRepresentative &&
        dateFields.forEach((fieldName) => {
          const currentValue: any = _.get(authorizedRepresentative, fieldName);
          if (currentValue?._isAMomentObject) {
            _.set(authorizedRepresentative, fieldName, currentValue.format('YYYY-MM-DD').toString());
          }
        });
      const ultimateBeneficialOwners = values.ultimateBeneficialOwners || [];
      ultimateBeneficialOwners.forEach((owner) => {
        dateFields.forEach((fieldName) => {
          const currentValue: any = _.get(owner, fieldName);
          if (currentValue?._isAMomentObject) {
            _.set(owner, fieldName, currentValue.format('YYYY-MM-DD').toString());
          }
        });
      });

      // remove file fields since they are submitted separately
      fileFields.forEach((fieldName) => delete (values as any)[fieldName]);

      // remove UBOs if `no UBOs` toggle was checked
      if (values.noBeneficialOwners && values.ultimateBeneficialOwners.length > 0) {
        values.ultimateBeneficialOwners = [];
      }

      await api.company.saveKycData(values);
      await queryClient.invalidateQueries(['fetchKycData']);
      await queryClient.invalidateQueries(['fetchMe']);
      enqueueSnackbar('KYC data saved', { variant: 'success' });
      setSubmitting(false);
    } catch (e) {
      enqueueSnackbar('Failed to save KYC data', { variant: 'error' });
      setSubmitting(false);
      return Promise.reject(e);
    }
  };

  const submitForVerification = async () => {
    api.company
      .submitForKycVerification()
      .then(async () => {
        await queryClient.invalidateQueries(['fetchKycData']);
        await queryClient.invalidateQueries(['fetchMe']);
        enqueueSnackbar('KYC data submitted for verification', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to submit KYC data for verification', { variant: 'error' });
      });
  };

  return (
    <PageLayout>
      <Formik
        initialValues={kycData || initialValues}
        initialTouched={initialTouched}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnChange={false}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div className={styles.header}>
              <div className={styles.titleContainer}>
                <Typography className={styles.title} variant="h2">
                  Company KYC information & documents
                </Typography>
                <Typography variant="body2">
                  It is great that you want to work with wideStreet. To make sure we cooperate as seamlessly as
                  possible, please provide us with the following KYC information & documents:
                </Typography>
              </div>
              {companyExists && (
                <div className={styles.buttonContainer}>
                  <StatusBadge status={formStatus} />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting || !isDraft || !isReadyForSubmission}
                    onClick={submitForVerification}
                  >
                    Submit for KYC verification
                  </Button>
                </div>
              )}
            </div>
            <CompanyInfoSection
              editDisabled={editorOpen}
              onEdit={() => setEditorOpen(true)}
              onEditorClose={() => setEditorOpen(false)}
            />

            <AuthorizedRepresentativeSection
              editDisabled={editorOpen || !companyExists}
              onEdit={() => setEditorOpen(true)}
              onEditorClose={() => setEditorOpen(false)}
            />
            <UltimateBeneficialOwnersSection
              editDisabled={editorOpen || !companyExists}
              onEdit={() => setEditorOpen(true)}
              onEditorClose={() => setEditorOpen(false)}
            />
            <DocumentsSection editDisabled={editorOpen || !companyExists} kycId={kycData?.id} />

            <div className={styles.footer}>
              {companyExists && (
                <div className={styles.buttonContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting || !isDraft || !isReadyForSubmission}
                    onClick={submitForVerification}
                  >
                    Submit for KYC verification
                  </Button>
                  <StatusBadge status={formStatus} />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
}
