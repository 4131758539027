import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import LabeledValue from '../../../shared/components/LabeledValue';
import { PortfolioAuction } from '../../../shared/types';
import { formatDate } from '../../../shared/utils/helpers';
import styles from './card.module.scss';
import CardAuctionSeparator from './CardAuctionSeparator';
import { useTheme } from '@mui/material/styles';

interface Props {
  title: string;
  auction: PortfolioAuction;
}

export default function CardAuctionDates({ title, auction }: Props) {
  const { startDate, endDate, qaStartDate, qaEndDate } = auction;
  const now = moment();
  const isAuctionOngoing = now.isBetween(startDate, endDate);

  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <CardAuctionSeparator />
      <div className={styles.subheader__item}>
        <Tooltip title={formatDate(endDate)}>
          <LabeledValue
            label={title}
            value={
              <span>
                {formatDate(startDate)}—{formatDate(endDate)}
              </span>
            }
            valueSize="12px"
            valueColor={isAuctionOngoing ? theme.palette.primary.main : '#5A676E'}
            marginTop="0px"
          />
        </Tooltip>
      </div>
      <div className={styles.subheader__item} style={{ marginLeft: 10 }}>
        <Tooltip title={formatDate(endDate)}>
          <LabeledValue
            label="Q&A:"
            value={
              <span>
                {formatDate(qaStartDate)}—{formatDate(qaEndDate)}
              </span>
            }
            valueSize="12px"
            valueColor="#5A676E"
            marginTop="0px"
          />
        </Tooltip>
      </div>
    </Box>
  );
}
