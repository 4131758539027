import React from 'react';
import NumberFormat from 'react-number-format';
import styles from '../card.module.scss';
import { formatCurrency } from '../../../../shared/utils/helpers';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<number> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  currency: string;
}

export default function NumberFormatInput(props: NumberFormatCustomProps) {
  const { inputRef, onChange, currency, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      className={styles.modal__bidInput}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format={(value: string) => formatCurrency(parseInt(value, 10), currency)}
      thousandSeparator
      isNumericString
    />
  );
}
