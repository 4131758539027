export default function NoMessages() {
  return (
    <div
      style={{
        color: '#707070',
        fontSize: 18,
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span style={{ maxWidth: 450, textAlign: 'center' }}>
        There are no messages at the moment, but in the future you will be able to view important announcements and
        information here, or contact us if you need <span style={{ fontWeight: 'bold' }}>help.</span>
      </span>
    </div>
  );
}
