const DeleteAttachmentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12.71} height={12.704}>
    <g data-name="Group 4712" fill="none" stroke="inherit">
      <path data-name="Line 338" d="m12.353.355-11.719 12" />
      <path data-name="Line 370" d="m.353.355 12 11.918" />
    </g>
  </svg>
);

export default DeleteAttachmentIcon;
