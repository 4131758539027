import React, { useState } from 'react';
import { PortfolioAnnouncement } from '../../types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from './qna.module.scss';
import PostMessageForm from './PostMessageForm';
import useAdminApi from '../../hooks/useAdminApi';
import { useQueryClient } from '@tanstack/react-query';
import AnnouncementRow from './AnnouncementRow';
import useApi from '../../hooks/useApi';

interface Props {
  portfolioId: number;
  announcements: PortfolioAnnouncement[];
  adminView?: boolean;
}

export default function Announcements({ portfolioId, announcements, adminView = false }: Props) {
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<number | undefined>(undefined);

  const isSelected = (id: number) => id === selectedAnnouncementId;

  const api = useAdminApi();
  const apiUser = useApi();
  const queryClient = useQueryClient();

  const postAnnouncement = async (form: FormData) => {
    await api.portfolios.postAnnouncement(portfolioId, form);
    await queryClient.invalidateQueries(['fetchPortfolioAnnouncements', portfolioId]);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.announcement__table__headerCell} sx={{ width: '200px' }}>
              Date
            </TableCell>
            <TableCell className={styles.announcement__table__headerCell}>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminView && !announcements.length && (
            <TableRow>
              <TableCell sx={{ border: 'none' }} />
              <TableCell sx={{ border: 'none' }}>No announcements were made yet</TableCell>
            </TableRow>
          )}
          {announcements.map((announcement) => (
            <AnnouncementRow
              announcement={announcement}
              setSelectedAnnouncementId={setSelectedAnnouncementId}
              editMode={isSelected(announcement.id)}
              adminView={adminView}
              downloadAttachment={apiUser.portfolio_snapshot.downloadPortfolioAnnouncementAttachment(portfolioId)}
            />
          ))}
          {adminView && (
            <TableRow>
              <TableCell sx={{ border: 'none' }} />
              <TableCell sx={{ border: 'none' }}>
                <PostMessageForm readOnly={false} postMessage={postAnnouncement} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
