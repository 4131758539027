import React from 'react';
import { Tooltip } from '@mui/material';

interface Props {
  showTooltip: boolean;
  tooltip: string;
  children: JSX.Element;
}
/**
 * Optional tooltip wrapper for adding context to disabled buttons etc.
 *
 * Additional <span> wrapper is added to allow adding tooltips on disabled elements:
 * https://mui.com/material-ui/react-tooltip/#disabled-elements
 */
export default function ConditionalTooltip({ showTooltip, tooltip, children }: Props) {
  return showTooltip ? (
    <Tooltip title={tooltip}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
}
