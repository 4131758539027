import React from 'react';
import styles from '../styles.module.scss';
import { Paper } from '@mui/material';
import AdminUsersTable from './AdminUsersTable';
import AdminHeader from './AdminHeader';

export default function AdminUserManagerPage() {
  return (
    <main className={styles.main}>
      <Paper variant="outlined" className={styles.tabContent}>
        <AdminHeader />
        <AdminUsersTable />
      </Paper>
    </main>
  );
}
