import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { removeStoredRefreshToken } from '../shared/utils/authToken';
import styles from './profile.module.scss';
import useApi from '../shared/hooks/useApi';
import SingleButtonModal from '../shared/components/SingleButtonModal';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function ChangePasswordModal({ open, onClose }: Props) {
  const [error, setError] = useState(false);

  const { client } = useApi();

  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(requiredMsg),
    newPassword: Yup.string().required(requiredMsg),
    reNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords are not the same.')
      .required(requiredMsg),
  });

  return (
    <Formik
      initialValues={{
        currentPassword: '',
        newPassword: '',
        reNewPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        setError(false);
        try {
          await client.post('/auth/users/set_password/', values);
          window.location.replace('/login');
          removeStoredRefreshToken();
        } catch (e: any) {
          setErrors(e.response.data);
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <SingleButtonModal
          open={open}
          onClose={() => {
            resetForm();
            onClose();
          }}
          title="Change Password"
          content={
            <Grid container spacing={2} className={styles.passwordModalContainer}>
              {error && (
                <Grid item xs={12}>
                  <div className={styles.error}>Failed to change password</div>
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  type="password"
                  label="Current Password"
                  name="currentPassword"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field component={TextField} type="password" label="New Password" name="newPassword" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  type="password"
                  label="Repeat New Password"
                  name="reNewPassword"
                  fullWidth
                />
              </Grid>
            </Grid>
          }
          buttonLabel="Set new password"
          onClick={submitForm}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  );
}
