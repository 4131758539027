import moment from 'moment';

interface Props {
  title: string;
  date: string;
}

export default function GeneralChatConversationTitle({ title, date }: Props) {
  return (
    <div
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        borderBottom: '1px solid #EBEBEB',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: 34,
        marginRight: 34,
      }}
    >
      <div style={{ color: '#5A676E', fontSize: 15, display: 'flex', flex: 1, fontWeight: 'bold' }}>{title}</div>
      <div style={{ color: '#5A676E', fontSize: 15, width: 100, display: 'flex', justifyContent: 'flex-end' }}>
        {moment(date).format('ddd, MMM DD')}
      </div>
    </div>
  );
}
