import { TextField } from '@mui/material';
import styles from './qna.module.scss';

interface Props {
  field: any;
  form: any;
  disabled: boolean;
  placeholder: string;
  reference?: HTMLInputElement;
}

export default function PostMessageInput({ disabled, placeholder, field, form: { submitForm } }: Props) {
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitForm();
    }
  };

  return (
    <TextField
      id="outlined-textarea"
      variant="standard"
      placeholder={placeholder}
      style={{ border: 0, maxHeight: 200, overflowY: 'scroll', paddingRight: 10, width: '100%' }}
      className={styles.qna__form__input__field}
      onKeyDown={handleKeyDown}
      {...field}
      multiline
      disabled={disabled}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
}
