import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import Messages from '../../../../shared/components/chat/Messages';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import useApi from '../../../../shared/hooks/useApi';
import { PortfolioChatMessageTypeEnum } from '../../../../shared/types';

interface Props {
  portfolioId: number;
  readOnly?: boolean;
  selectedConversationId: number | null;
}

const pageSize = 20;

export default function MessagesAdmin({ portfolioId, selectedConversationId, readOnly = false }: Props) {
  const api = useAdminApi();
  const apiUser = useApi();
  const queryClient = useQueryClient();

  const [calledCount, setCalledCount] = useState(0);
  const [scrollToBottomOnInitialLoad, setScrollToBottomOnInitialLoad] = useState(false);

  const postMessage = async (form: FormData) => {
    if (!selectedConversationId) return;
    await api.portfolios.postChatMessage(portfolioId, selectedConversationId, form);
    await queryClient.invalidateQueries(['fetchPortfolioChatMessages', portfolioId, selectedConversationId]);
  };

  const infiniteQuery = useInfiniteQuery(
    ['fetchPortfolioChatMessages', portfolioId, selectedConversationId],
    ({ pageParam = 1 }) => {
      return api.portfolios.fetchChatMessages(portfolioId, selectedConversationId as number, pageParam, pageSize);
    },
    {
      keepPreviousData: true,
      refetchInterval: 10000,
      enabled: selectedConversationId !== null,
      getNextPageParam: (lastPage, pages) => {
        const currentPage = pages.length;
        const nextPageAvailavble = Boolean(lastPage?.next);
        if (nextPageAvailavble) {
          return currentPage + 1;
        }
      },
      onSuccess: () => {
        if (calledCount === 0) {
          setScrollToBottomOnInitialLoad(true);
          setCalledCount(calledCount + 1);
          queryClient.invalidateQueries(['fetchChatConversationList', portfolioId]);
        }
      },
    },
  );

  const shouldDisplayForm = !infiniteQuery.isInitialLoading && selectedConversationId !== null;

  return (
    <Messages
      adminView
      infiniteQuery={infiniteQuery}
      shouldDisplayForm={shouldDisplayForm}
      scrollToBottomOnInitialLoad={scrollToBottomOnInitialLoad}
      setScrollToBottomOnInitialLoad={setScrollToBottomOnInitialLoad}
      portfolioId={portfolioId}
      readOnly={readOnly}
      postMessage={postMessage}
      showSelectThreadMessage={selectedConversationId === null}
      setInitialHeight={selectedConversationId === null || infiniteQuery.isInitialLoading}
      highlightedMessageType={PortfolioChatMessageTypeEnum.BUYER}
      downloadAttachment={apiUser.portfolio_snapshot.downloadPortfolioChatAttachment(portfolioId)}
    />
  );
}
