import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import useAdminApi from '../../shared/hooks/useAdminApi';
import TableSwitch from '../../shared/components/table/TableSwitch';

interface Props {
  portfolioId: number;
  isSold: boolean;
}

export default function PortfolioSoldStatusSwitch({ portfolioId, isSold }: Props) {
  const api = useAdminApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutateMarkSold = useMutation({
    mutationFn: (id: number) => api.portfolios.markSoldPortfolio(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['adminFetchPortfolios']);
      enqueueSnackbar('Portfolio marked as sold', { variant: 'success' });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to mark portfolio as sold', { variant: 'error' });
    },
  });

  const mutateUnmarkSold = useMutation({
    mutationFn: (id: number) => api.portfolios.unmarkSoldPortfolio(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['adminFetchPortfolios']);
      enqueueSnackbar('Portfolio unmarked as sold', { variant: 'success' });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to unmark portfolio as sold', { variant: 'error' });
    },
  });

  const isLoading = mutateMarkSold.isLoading || mutateUnmarkSold.isLoading;

  const handleSwitch = () => {
    if (isLoading) return;

    if (isSold) {
      mutateUnmarkSold.mutate(portfolioId);
    } else {
      mutateMarkSold.mutate(portfolioId);
    }
  };

  return (
    <TableSwitch
      falseLabel="Unsold"
      trueLabel="Sold"
      value={isSold}
      isLoading={isLoading}
      handleSwitch={handleSwitch}
    />
  );
}
