import React from 'react';
import {
  AuctionBid,
  AuctionBidBuyer,
  AuctionStage,
  AuctionType,
  Portfolio,
  PortfolioAuctionAdmin,
} from '../../../../shared/types';
import SingleButtonModal from '../../../../shared/components/SingleButtonModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import styles from '../../../styles.module.scss';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { formatCurrency, formatDate, formatDateTime } from '../../../../shared/utils/helpers';
import { getAuctionStageLabel, getAuctionTypeLabel } from './utils';
import { useSnackbar } from 'notistack';
import { useSelectedItems } from '../../../../shared/hooks/useSelectedItems';
import ConditionalTooltip from '../../../../shared/components/ConditionalTooltip';
import { useConfirmationDialogStore } from '../../../../shared/components/ConfirmationModal';
import NoData from '../../../../shared/components/table/NoData';
import TableLoadingOverlay from '../../../../shared/components/table/TableLoadingOverlay';

interface Props {
  open: boolean;
  onClose: () => void;
  auction: PortfolioAuctionAdmin;
  portfolio: Portfolio;
}

export default function NonBindingAuctionBidListModal({ open, onClose, auction, portfolio }: Props) {
  const { id: auctionId, startDate, endDate, type, stage, isConcluded } = auction;

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  const { selected, setSelected, isSelected, handleSelect } = useSelectedItems();

  const api = useAdminApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useQuery(
    ['adminFetchAuctionBids', auctionId],
    () => api.auctions.fetchAuctionBids(auctionId),
    {
      enabled: open,
    },
  );
  const bids: AuctionBid[] = data?.bids || [];
  const extraBuyers: AuctionBidBuyer[] = data?.extraBuyers || [];

  const isAuctionFinished = stage === AuctionStage.FINISHED;
  const bidAcceptanceEnabled = isAuctionFinished && !isConcluded;

  const confirmAccess = async (buyerIds: string[]) => {
    api.auctions
      .confirmAccess(auctionId, buyerIds)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioAuctions', portfolio.id]);
        onClose();
        setSelected([]);
        enqueueSnackbar('Portfolio access confirmed', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to confirm portfolio access', { variant: 'error' });
      });
  };

  const handleSubmit = () => {
    confirmAccess(selected);
  };

  return (
    <SingleButtonModal
      maxWidth="md"
      open={open}
      onClose={onClose}
      title="Bidding"
      subHeader={
        <Typography variant="h3">
          <span className={type === AuctionType.NON_BINDING ? styles.typeNonBinding : styles.typeBinding}>
            {getAuctionTypeLabel(type)}
          </span>
          {` | ${formatDate(startDate)} - ${formatDate(endDate)} | ${
            isConcluded ? 'Concluded' : getAuctionStageLabel(stage)
          }`}
        </Typography>
      }
      content={
        <TableContainer className={styles.tabContent__tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Buyer</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Made at</TableCell>
                <TableCell align="right">Bid</TableCell>
                <TableCell padding="checkbox">Access</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ position: 'relative' }}>
              {bids.length || extraBuyers.length ? (
                <>
                  {bids.map(({ buyerName, user, madeAt, price, currency, buyerId, hasAccess }) => {
                    return (
                      <TableRow key={buyerId}>
                        <TableCell>{buyerName}</TableCell>
                        <TableCell>{user}</TableCell>
                        <TableCell>{formatDateTime(madeAt)}</TableCell>
                        <TableCell align="right">{formatCurrency(price, currency)}</TableCell>
                        <TableCell padding="checkbox">
                          <ConditionalTooltip
                            showTooltip={!bidAcceptanceEnabled}
                            tooltip={
                              isAuctionFinished
                                ? isConcluded
                                  ? 'Auction already concluded'
                                  : ''
                                : 'Auction has not finished yet'
                            }
                          >
                            <Checkbox
                              color="primary"
                              checked={isSelected(buyerId)}
                              disabled={!bidAcceptanceEnabled}
                              onClick={(e) => handleSelect(e, buyerId)}
                            />
                          </ConditionalTooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {extraBuyers.map(({ buyerId, buyerName, hasAccess }) => (
                    <TableRow key={buyerId}>
                      <TableCell>{buyerName}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell align="right">-</TableCell>
                      <TableCell padding="checkbox">
                        <ConditionalTooltip
                          showTooltip={!bidAcceptanceEnabled}
                          tooltip={
                            isAuctionFinished
                              ? isConcluded
                                ? 'Auction already concluded'
                                : ''
                              : 'Auction has not finished yet'
                          }
                        >
                          <Checkbox
                            color="primary"
                            checked={isSelected(buyerId)}
                            disabled={!bidAcceptanceEnabled}
                            onClick={(e) => handleSelect(e, buyerId)}
                          />
                        </ConditionalTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : isLoading ? (
                <div style={{ minHeight: 200 }}>
                  <TableLoadingOverlay isLoading transparent />
                </div>
              ) : (
                <NoData />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      buttonLabel="Confirm access"
      showButton={bidAcceptanceEnabled}
      onClick={() =>
        confirmAction(
          'Select winning bids',
          'Do you want to confirm portfolio access for selected buyers? Access for other buyers will be revoked. If you wish to grant or revoke access at a later point in time, please go to access management.',
          handleSubmit,
        )
      }
      disabled={isConcluded || !selected.length}
    />
  );
}
