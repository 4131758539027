import { Box, Grid, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { ReactNode, useMemo } from 'react';
import styles from './auth.module.scss';
import Logo from './Logo';
import useWorkspaceConfiguration from '../shared/hooks/useWorkspaceConfiguration';
import authBackground from './authBackground.jpg';

export interface FormProps {
  setError: (value: boolean) => void;
  setSuccess: (value: boolean) => void;
}

interface Props {
  children: ReactNode;
  title: string;
  topLink?: ReactNode;
  errorMsg?: string;
  successMsg?: string;
}

export default function AuthLayout({ children, title, topLink, errorMsg, successMsg }: Props) {
  const { data } = useWorkspaceConfiguration();
  const backgroundImage = data?.loginBackgroundImage;
  const logoImage = data?.loginLogoImage;

  const style = useMemo(() => {
    if (backgroundImage) {
      return {
        backgroundImage: `url(${backgroundImage})`,
      };
    }
    return {
      backgroundImage: `url(${authBackground})`,
    };
  }, [backgroundImage]);

  return (
    <Grid container component="main" className={styles.root}>
      <Grid item xs={false} sm={4} md={7} className={styles.image} sx={style}>
        <Link to="/">
          {logoImage ? (
            <Box
              component="img"
              sx={{
                width: 500,
              }}
              src={logoImage}
            />
          ) : (
            <Logo />
          )}
        </Link>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={styles.paper}>
        <div className={styles.contentHeader}>{topLink}</div>
        <Box display="flex" flexDirection="column" justifyContent="center" className={styles.content}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h1" className={styles.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {successMsg ? (
                <Grid item xs={12}>
                  <div className={styles.success}>{successMsg}</div>
                </Grid>
              ) : (
                <>
                  {errorMsg && (
                    <Grid item xs={12}>
                      <div className={styles.error}>{errorMsg}</div>
                    </Grid>
                  )}
                  {children}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
