import React from 'react';
import { Grid, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import styles from './summary.module.scss';
import LabeledValue from '../../../shared/components/LabeledValue';
import { SplitValue } from '../../../shared/types';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  title: string;
  splitValues?: SplitValue[];
}

export default function SplitStat({ title, splitValues }: Props) {
  const isNotEmpty = !_.isEmpty(splitValues);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography className={styles.subsection__title} variant="h2">
          {title}
        </Typography>
        {isNotEmpty ? (
          splitValues?.map(({ name, split }) => <LabeledValue key={name} label={name} value={`${split.toFixed(1)}%`} />)
        ) : (
          <Typography>N/A</Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        {isNotEmpty && (
          <Chart
            type="donut"
            series={splitValues?.map(({ split }) => split)}
            options={{
              chart: { type: 'donut', offsetX: 30, offsetY: 10 },
              legend: {
                show: false,
              },
              fill: {
                opacity: 1,
              },
              dataLabels: {
                enabled: false,
              },
              labels: splitValues?.map(({ name }) => name),
              tooltip: {
                y: {
                  formatter: (value: number) => `${value.toFixed(1)}%`,
                },
              },
            }}
            height="120px"
            width={matches ? '120px' : '100px'}
          />
        )}
      </Grid>
    </Grid>
  );
}
