import React, { useState } from 'react';
import {
  AuctionBid,
  AuctionBidBuyer,
  AuctionStage,
  AuctionType,
  BidStatus,
  PortfolioAuctionAdmin,
} from '../../../../shared/types';
import SingleButtonModal from '../../../../shared/components/SingleButtonModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import styles from '../../../styles.module.scss';
import {
  Box,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatCurrency, formatDate, formatDateTime } from '../../../../shared/utils/helpers';
import { getAuctionStageLabel, getAuctionTypeLabel } from './utils';
import { useSnackbar } from 'notistack';
import { useConfirmationDialogStore } from '../../../../shared/components/ConfirmationModal';
import ConditionalTooltip from '../../../../shared/components/ConditionalTooltip';
import NoData from '../../../../shared/components/table/NoData';
import { useTheme } from '@mui/material/styles';
import TableLoadingOverlay from '../../../../shared/components/table/TableLoadingOverlay';

interface Props {
  open: boolean;
  onClose: () => void;
  auction: PortfolioAuctionAdmin;
  portfolioId: number;
}

export default function BindingAuctionBidListModal({ open, onClose, auction, portfolioId }: Props) {
  const { id: auctionId, startDate, endDate, type, stage, isConcluded } = auction;

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  const api = useAdminApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading } = useQuery(
    ['adminFetchAuctionBids', auctionId],
    () => api.auctions.fetchAuctionBids(auctionId),
    {
      enabled: open,
    },
  );
  const bids: AuctionBid[] = data?.bids || [];
  const hasBids = Boolean(bids.length);
  const extraBuyers: AuctionBidBuyer[] = data?.extraBuyers || [];

  const [selectedBid, selectBid] = useState<number | null>(null);
  const [selectedRejectAllBids, selectRejectBids] = useState(false);

  const isAuctionFinished = stage === AuctionStage.FINISHED;
  const bidAcceptanceEnabled = isAuctionFinished && !isConcluded;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectRejectBids(false);
    selectBid(Number((event.target as HTMLInputElement).value));
  };

  const handleRejectAllBidsChange = () => {
    selectRejectBids(true);
    selectBid(null);
  };

  const selectWinningBid = async (selectedBid: number) => {
    api.auctions
      .selectWinningBid(auctionId, selectedBid)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioAuctions', portfolioId]);
        onClose();
        selectBid(null);
        enqueueSnackbar('Winning bid selected', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to select winning bid', { variant: 'error' });
      });
  };

  const rejectAllBids = async () => {
    api.auctions
      .rejectAllBids(auctionId)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioAuctions', portfolioId]);
        onClose();
        selectBid(null);
        enqueueSnackbar('All bids rejected', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to reject all bids', { variant: 'error' });
      });
  };

  const handleSubmit = () => {
    if (selectedRejectAllBids) {
      return rejectAllBids();
    }

    if (selectedBid) {
      return selectWinningBid(selectedBid);
    }
  };

  const theme = useTheme();

  return (
    <SingleButtonModal
      maxWidth="md"
      open={open}
      onClose={() => {
        selectRejectBids(false);
        selectBid(null);
        onClose();
      }}
      title="Bidding"
      subHeader={
        <Typography variant="h3">
          <Box
            component="span"
            sx={
              type === AuctionType.NON_BINDING
                ? { fontWeight: 600, color: '#5a676e' }
                : { fontWeight: 600, color: theme.palette.primary.main }
            }
          >
            {getAuctionTypeLabel(type)}
          </Box>
          {` | ${formatDate(startDate)} - ${formatDate(endDate)} | ${
            isConcluded ? 'Concluded' : getAuctionStageLabel(stage)
          }`}
        </Typography>
      }
      content={
        <TableContainer className={styles.tabContent__tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Buyer</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Made at</TableCell>
                <TableCell align="right">Bid</TableCell>
                <TableCell padding="checkbox">Accepted</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ position: 'relative' }}>
              {bids.length || extraBuyers.length ? (
                <>
                  {bids.map(({ id, buyerName, user, madeAt, price, currency, status }) => (
                    <TableRow key={id}>
                      <TableCell>{buyerName}</TableCell>
                      <TableCell>{user}</TableCell>
                      <TableCell>{formatDateTime(madeAt)}</TableCell>
                      <TableCell align="right">{formatCurrency(price, currency)}</TableCell>
                      <TableCell padding="checkbox">
                        <ConditionalTooltip
                          showTooltip={!bidAcceptanceEnabled}
                          tooltip={
                            isAuctionFinished
                              ? isConcluded
                                ? 'Auction already concluded'
                                : ''
                              : 'Auction has not finished yet'
                          }
                        >
                          <Radio
                            color="primary"
                            value={id}
                            checked={selectedBid === id || (isConcluded && status === BidStatus.ACCEPTED)}
                            disabled={!bidAcceptanceEnabled}
                            onChange={handleChange}
                          />
                        </ConditionalTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  {extraBuyers.map(({ buyerId, buyerName }) => (
                    <TableRow key={buyerId}>
                      <TableCell>{buyerName}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell align="right">-</TableCell>
                      <TableCell padding="checkbox">
                        <ConditionalTooltip
                          showTooltip={!bidAcceptanceEnabled}
                          tooltip={
                            isAuctionFinished
                              ? isConcluded
                                ? 'Auction already concluded'
                                : ''
                              : 'Auction has not finished yet'
                          }
                        >
                          <Radio color="primary" value={buyerId} checked={false} disabled />
                        </ConditionalTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : isLoading ? (
                <div style={{ minHeight: 250 }}>
                  <TableLoadingOverlay isLoading transparent />
                </div>
              ) : (
                <NoData />
              )}
              {bidAcceptanceEnabled && (
                <TableRow>
                  <TableCell style={{ border: 'none' }} />
                  <TableCell style={{ border: 'none' }} />
                  <TableCell style={{ border: 'none' }} />
                  <TableCell style={{ border: 'none' }} align="right">
                    <span>Reject all</span>
                  </TableCell>
                  <TableCell style={{ border: 'none' }} padding="checkbox">
                    <Radio
                      color="primary"
                      checked={selectedRejectAllBids}
                      disabled={!bidAcceptanceEnabled}
                      onChange={handleRejectAllBidsChange}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      }
      buttonLabel="Conclude"
      showButton={bidAcceptanceEnabled}
      disabled={isConcluded || !hasBids || (selectedBid === null && !selectedRejectAllBids)}
      onClick={() =>
        confirmAction(
          selectedRejectAllBids ? 'Reject all bids' : 'Select winning bid',
          selectedRejectAllBids
            ? 'Do you want to reject all bids? This will revoke portfolio access for all buyers.'
            : 'Do you want to accept the selected bid? This will revoke portfolio access for other buyers. Please note that you shall deactivate the winner manually once appropriate.',
          handleSubmit,
        )
      }
    />
  );
}
