import { CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useConfirmationDialogStore } from '../../../../shared/components/ConfirmationModal';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import { PortfolioSnapshotStatus } from '../../../../shared/types';
import TableButton from '../../../../shared/components/table/TableButton';

interface Props {
  portfolioId: number;
  snapshotId: number;
  status: PortfolioSnapshotStatus;
}

export default function SnapshotPublishButton({ portfolioId, snapshotId, status }: Props) {
  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  const api = useAdminApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => api.snapshots.publishSnapshot(snapshotId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshots', portfolioId]);
      enqueueSnackbar('Snapshot published', { variant: 'success' });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to publish snapshot', { variant: 'error' });
    },
  });

  return (
    <TableButton
      disabled={
        status === PortfolioSnapshotStatus.PUBLISHED || status === PortfolioSnapshotStatus.PROCESSING || isLoading
      }
      onClick={() => confirmAction('Publish snapshot', 'Do you want to publish selected draft?', () => mutate())}
    >
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            backgroundColor: 'white',
            width: '100%',
          }}
        >
          <CircularProgress size={14} />
        </div>
      )}
      <span>Publish</span>
    </TableButton>
  );
}
