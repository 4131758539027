import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import styles from './card.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page } from 'react-pdf';
import { useSnackbar } from 'notistack';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import useApi from '../../../shared/hooks/useApi';
import DownloadIcon from '@mui/icons-material/Download';
import ConfirmButton from '../../../shared/components/buttons/ConfirmButton';
import CancelButton from '../../../shared/components/buttons/CancelButton';
import { getFileNameFromContentDispositionHeader } from '../../../shared/utils/helpers';
import { saveAs } from 'file-saver';

interface Props {
  open: boolean;
  onClose: () => void;
  portfolioId: number;
  ndaFile: string;
  ndaFileId?: number;
  onAccepted?: () => void;
}

export default function NDAModal({ open, onClose, portfolioId, ndaFile, ndaFileId, onAccepted }: Props) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [ndaCompanySelected, selectNdaCompany] = useState(false);
  const [auctionInterestSelected, selectAuctionInterest] = useState(false);

  const hasFileFailedToLoad = numPages === null;
  const isAcceptDisabled = !ndaCompanySelected || !auctionInterestSelected || hasFileFailedToLoad || !ndaFile;
  const tooltipTitle = hasFileFailedToLoad
    ? 'NDA file needs to be loaded'
    : !ndaCompanySelected || !auctionInterestSelected
    ? 'Please select all checkboxes'
    : '';

  const api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation(() => api.portfolio_snapshot.acceptNda(portfolioId, ndaFileId!), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchPortfolioSnapshots']);
      enqueueSnackbar('NDA accepted', { variant: 'success' });
      onClose();
      if (onAccepted) {
        onAccepted();
      }
    },
    onError(e) {
      console.error(e);
      enqueueSnackbar('Failed to accept NDA', { variant: 'error' });
    },
  });

  const { data } = useQuery(
    ['fetchNdaFile', portfolioId],
    () => api.client.get(`eba/portfolio_snapshots/${portfolioId}/download_nda/`, { responseType: 'blob' }),
    { enabled: open },
  );

  const ndaUrl = useMemo(() => {
    const resData = data?.data;
    if (!resData) return null;

    const blob = new Blob([resData], { type: 'application/pdf' });
    return URL.createObjectURL(blob);
  }, [data]);

  const onAccept = () => {
    mutate();
  };

  const handleDownload = () => {
    api.client
      .get(`eba/portfolio_snapshots/${portfolioId}/download_nda/`, { responseType: 'blob' })
      .then((res) => {
        saveAs(res.data, getFileNameFromContentDispositionHeader(res.headers['content-disposition']));
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download NDA', { variant: 'error' });
      });
  };

  const Loader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: 64, height: 25, marginTop: 3 }}>
        <CircularProgress size={24} />
      </div>
    );
  };

  // @ts-ignore
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle style={{ marginTop: 15 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography>
            Prior to accessing the Data Room, please read and accept the terms of the Non-Disclosure Agreement.
          </Typography>
          {onClose ? (
            <IconButton className={styles.ndaModal__btnClose} onClick={onClose} size="large">
              <CloseIcon fontSize="large" />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent className={styles.ndaModal__content}>
        {/* @ts-ignore*/}
        {ndaUrl ? (
          <Document
            file={ndaUrl}
            loading={<Loader />}
            onLoadSuccess={({ numPages: nextNumPages }) => setNumPages(nextNumPages)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div key={`page_${index + 1}`}>
                {/* @ts-ignore*/}
                <Page key={`page_${index + 1}`} pageNumber={index + 1} width={900} />
              </div>
            ))}
          </Document>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: 'start', marginLeft: 15 }}>
        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            data-testid="nda-accept"
            label="I accept the NDA in the name of the company"
            control={<Checkbox checked={ndaCompanySelected} onChange={() => selectNdaCompany(!ndaCompanySelected)} />}
          />
          <FormControlLabel
            data-testid="nda-confirm-interest"
            label="I confirm that I am interested in the auction"
            control={
              <Checkbox
                checked={auctionInterestSelected}
                onChange={() => selectAuctionInterest(!auctionInterestSelected)}
              />
            }
          />
        </FormGroup>
      </DialogActions>
      <DialogActions style={{ justifyContent: 'space-between', marginBottom: 15, marginRight: 30, marginLeft: 15 }}>
        <Box>
          <Tooltip title={tooltipTitle} placement="top">
            <span style={{ marginRight: 30 }}>
              <ConfirmButton disabled={isAcceptDisabled} loading={isLoading} onClick={onAccept}>
                Accept
              </ConfirmButton>
            </span>
          </Tooltip>

          <CancelButton onClick={onClose} disabled={isLoading}>
            Decline
          </CancelButton>
        </Box>
        <Box>
          <Button
            color="grey"
            startIcon={<DownloadIcon style={{ marginRight: 10 }} />}
            className={styles.ndaModal__downloadBtn}
            variant="outlined"
            onClick={handleDownload}
          >
            Download
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
