import React, { ReactNode, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import AutoLogoutModal from './AutoLogoutModal';

interface Props {
  children: ReactNode;
}

export default function AutoLogout({ children }: Props) {
  const [open, setOpen] = useState(false);

  const handleOnIdle = () => {
    if (!process.env.REACT_APP_DISABLE_AUTO_LOGOUT) {
      setOpen(true);
    }
  };

  useIdleTimer({
    timeout: 14 * 60 * 1000, // 14 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      {children}
      {open && <AutoLogoutModal open={open} onClose={() => setOpen(false)} />}
    </>
  );
}
