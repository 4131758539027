const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={13.664}>
    <defs>
      <clipPath id="a">
        <path data-name="Rectangle 1256" fill="none" d="M0 0h18v13.664H0z" />
      </clipPath>
    </defs>
    <g data-name="Group 1605" clipPath="url(#a)">
      <path
        data-name="Path 754"
        d="M17 8.187v1.955a2.523 2.523 0 0 1-2.522 2.523H3.522A2.523 2.523 0 0 1 1 10.142V8.187"
        fill="#fff"
      />
      <path
        data-name="Path 755"
        d="M17 8.187v1.955a2.523 2.523 0 0 1-2.522 2.523H3.522A2.523 2.523 0 0 1 1 10.142V8.187"
        fill="none"
        stroke="inherit"
      />
      <path data-name="Line 338" fill="none" stroke="inherit" d="M8.75 1.414v9" />
      <path data-name="Path 756" d="m5.25 6.914 3.5 3.5 3.5-3.5" fill="none" stroke="inherit" />
    </g>
  </svg>
);

export default UploadIcon;
