import React from 'react';
import SingleButtonModal from '../../../shared/components/SingleButtonModal';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useConfirmationDialogStore } from '../../../shared/components/ConfirmationModal';
import { BaseBuyerData } from '../../../shared/types';
import { CountrySelectRow, TextInputRow } from '../../../shared/components/form_inputs/common';

interface Props {
  open: boolean;
  onClose: (event?: any) => void;
  onClick: (buyerId?: string, newBuyer?: BaseBuyerData) => void;
}

export default function AssignNewBuyerModal({ open, onClose, onClick }: Props) {
  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(requiredMsg),
    legalForm: Yup.string().required(requiredMsg),
    commercialRegisterNumber: Yup.string().required(requiredMsg),
    address1: Yup.string().required(requiredMsg),
    address2: Yup.string(),
    zipCode: Yup.string().required(requiredMsg),
    city: Yup.string().required(requiredMsg),
    country: Yup.string().required(requiredMsg),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Please enter a valid URL',
      )
      .required(requiredMsg),
  });

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  return (
    <Formik
      initialValues={{
        companyName: '',
        legalForm: '',
        commercialRegisterNumber: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        country: '',
        website: '',
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values) => {
        console.log('Submitting...');
        return onClick(undefined, values);
      }}
    >
      {({ isSubmitting, isValid, submitForm, dirty }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={onClose}
            title="Assign to new buyer"
            content={
              <>
                <TextInputRow fieldName="companyName" label="Company name" editMode />
                <TextInputRow fieldName="legalForm" label="Legal form" editMode />
                <TextInputRow fieldName="commercialRegisterNumber" label="Commercial register number" editMode />
                <TextInputRow fieldName="address1" label="Address line 1" editMode />
                <TextInputRow fieldName="address2" label="Address line 2" editMode />
                <TextInputRow fieldName="zipCode" label="ZIP code" editMode />
                <TextInputRow fieldName="city" label="City" editMode />
                <CountrySelectRow fieldName="country" label="Country" editMode />
                <TextInputRow fieldName="website" label="Website" editMode />
              </>
            }
            buttonLabel="Activate"
            isSubmitting={isSubmitting}
            disabled={isSubmitting || !(isValid && dirty)}
            onClick={() => confirmAction('Activate users', 'Do you want to activate selected users?', submitForm)}
          />
        </Form>
      )}
    </Formik>
  );
}
