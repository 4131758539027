import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Button, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { ValidationError } from 'yup';
import { authorizedRepresentativeSchema, authorizedRepresentativeSchemaOptional } from './validation';
import {
  CountrySelectRow,
  DatePickerRow,
  PhoneNumberInputRow,
  TextInputRow,
} from '../shared/components/form_inputs/common';
import { AuthorizedRepresentative, NewCompanyKycData } from '../shared/types';
import _ from 'lodash';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, TextField } from 'formik-mui';
import { useTheme } from '@mui/material/styles';
import useUser from '../shared/hooks/useUser';

interface PepProps {
  editMode: boolean;
  representative?: AuthorizedRepresentative;
}

const PepRow = ({ representative, editMode }: PepProps) => {
  return (
    <div className={styles.checkboxRow}>
      <Typography className={styles.checkboxLabel} variant="h3">
        Politically exposed persons (PEP) status
      </Typography>
      <Field
        component={Checkbox}
        className={styles.checkboxInput}
        name={'authorizedRepresentative.pepStatus'}
        type="checkbox"
        color="primary"
        disabled={!editMode}
      />
      {representative?.pepStatus && (
        <div className={styles.checkboxInfo}>
          {editMode ? (
            <Field
              component={TextField}
              name={'authorizedRepresentative.pepInfo'}
              placeholder="Please provide relevant info"
              multiline
              rows={3}
              variant="outlined"
              className={styles.formField}
            />
          ) : (
            <Typography className={styles.rowValue}>{representative.pepInfo}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

const authorizedRepresentativeInitialValues = {
  name: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  country: '',
  dateOfBirth: null,
  placeOfBirth: '',
  citizenship: [],
  documentNumber: '',
  documentValidFrom: null,
  documentValidUntil: null,
  typeOfAuthority: '',
  department: '',
  mobilePhone: '',
  pepStatus: false,
  pepInfo: '',
};

interface Props {
  editDisabled?: boolean;
  hideEditButton?: boolean;
  onEdit?: () => void;
  onEditorClose?: () => void;
}

export default function AuthorizedRepresentativeSection({
  editDisabled = false,
  hideEditButton = false,
  onEdit,
  onEditorClose,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { user } = useUser();
  const validationSchema = user?.kycOverride ? authorizedRepresentativeSchemaOptional : authorizedRepresentativeSchema;

  const { submitForm, resetForm, isSubmitting, values, setErrors, isValid, setFieldValue, setFieldTouched } =
    useFormikContext<NewCompanyKycData>();

  const handleEdit = () => {
    if (!expanded) {
      setExpanded(true);
    }
    if (!values.authorizedRepresentative) {
      setFieldValue('authorizedRepresentative', authorizedRepresentativeInitialValues);
    }
    setEditMode(true);
    onEdit && onEdit();
  };
  const handleCancel = () => {
    resetForm();
    setEditMode(false);
    onEditorClose && onEditorClose();
  };
  const handleSave = () => {
    // handle clearing phone input
    if (((values.authorizedRepresentative as any).mobilePhone as string) === '+') {
      (values.authorizedRepresentative as any).mobilePhone = '';
    }
    validationSchema
      .validate(values.authorizedRepresentative, { abortEarly: false })
      .then(() => {
        submitForm()
          .then(() => {
            setEditMode(false);
            onEditorClose && onEditorClose();
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch(({ inner }: ValidationError) => {
        const fieldErrors = inner.reduce((errors: any, { path, message }) => {
          _.set(errors, 'authorizedRepresentative.' + path, message);
          // make sure all errors are displayed
          setFieldTouched('authorizedRepresentative.' + path, true, false);
          return errors;
        }, {});
        setErrors(fieldErrors);
      });
  };

  const theme = useTheme();

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionHeader}>
        <Typography className={styles.sectionTitle} sx={{ color: theme.palette.primary.main }} variant="h2">
          Authorized representative
        </Typography>
        <div className={styles.buttonContainer}>
          {editMode ? (
            <>
              <Button variant="outlined" onClick={handleCancel} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="outlined" color="primary" onClick={handleSave} disabled={isSubmitting || !isValid}>
                {isSubmitting ? <CircularProgress color="primary" size={24} /> : 'Save changes'}
              </Button>
            </>
          ) : (
            !hideEditButton && (
              <Button variant="outlined" onClick={handleEdit} disabled={isSubmitting || editDisabled}>
                Edit section
              </Button>
            )
          )}
          <Button
            className={styles.expandButton}
            variant={expanded ? 'contained' : 'outlined'}
            color={expanded ? 'primary' : 'grey'}
            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Hide details' : 'Show details'}
          </Button>
        </div>
      </div>
      <Collapse in={expanded}>
        <Grid container>
          <Grid item xs={6}>
            <TextInputRow
              fieldName="authorizedRepresentative.name"
              label="Name (given name/surname/maiden name)"
              editMode={editMode}
            />
            <DatePickerRow fieldName="authorizedRepresentative.dateOfBirth" label="Date of birth" editMode={editMode} />
            <TextInputRow
              fieldName="authorizedRepresentative.placeOfBirth"
              label="Place of birth"
              editMode={editMode}
            />
            <TextInputRow
              fieldName="authorizedRepresentative.documentNumber"
              label="ID card or passport number"
              editMode={editMode}
            />
            <DatePickerRow
              fieldName="authorizedRepresentative.documentValidFrom"
              label="Valid from"
              editMode={editMode}
            />
            <DatePickerRow
              fieldName="authorizedRepresentative.documentValidUntil"
              label="Valid until"
              editMode={editMode}
            />
            <TextInputRow
              fieldName="authorizedRepresentative.typeOfAuthority"
              label="Type of authority"
              editMode={editMode}
            />
            <TextInputRow fieldName="authorizedRepresentative.department" label="Department" editMode={editMode} />
            <PhoneNumberInputRow fieldName="authorizedRepresentative.mobilePhone" label="Mobile" editMode={editMode} />
          </Grid>
          <Grid item xs={6}>
            <TextInputRow fieldName="authorizedRepresentative.address1" label="Address line 1" editMode={editMode} />
            <TextInputRow fieldName="authorizedRepresentative.address2" label="Address line 2" editMode={editMode} />
            <TextInputRow fieldName="authorizedRepresentative.zipCode" label="ZIP code" editMode={editMode} />
            <TextInputRow fieldName="authorizedRepresentative.city" label="City" editMode={editMode} />
            <CountrySelectRow fieldName="authorizedRepresentative.country" label="Country" editMode={editMode} />
            <CountrySelectRow
              fieldName="authorizedRepresentative.citizenship"
              label="Citizenship"
              editMode={editMode}
              multiple
            />
            <PepRow editMode={editMode} representative={values.authorizedRepresentative} />
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}
