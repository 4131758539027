import React from 'react';
import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StyledEngineProvider } from '@mui/material/styles';
import Router from './Router';
import { AuthProvider } from '../shared/hooks/useAuth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import { pdfjs } from 'react-pdf';
import { StylesProvider } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ConfirmationModal from '../shared/components/ConfirmationModal';
import BrandingProvider from './BrandingProvider';

const queryClient = new QueryClient();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <BrandingProvider>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <LocalizationProvider utils={MomentUtils} dateAdapter={AdapterMoment}>
                <AuthProvider>
                  <Router />
                  <ConfirmationModal />
                </AuthProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </BrandingProvider>
        </StyledEngineProvider>
      </StylesProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
