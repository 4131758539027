import React from 'react';
import styles from '../../../styles.module.scss';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import NDAUpload from './NDAUpload';
import SnapshotList from './SnapshotList';
import { Portfolio, PortfolioSnapshotBase } from '../../../../shared/types';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useConfirmationDialogStore } from '../../../../shared/components/ConfirmationModal';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import ConditionalTooltip from '../../../../shared/components/ConditionalTooltip';

interface Props {
  portfolio: Portfolio;
  snapshots?: PortfolioSnapshotBase[];
}

export default function PortfolioDetails({ portfolio, snapshots = [] }: Props) {
  const { id, identifier, ndaFile, hasInvitedBuyers } = portfolio;

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutationDeletePortfolio = useMutation({
    mutationFn: () => api.portfolios.deletePortfolio(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['adminFetchPortfolioDetails', id]);
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshots', id]);
      navigate('/portfolio_manager');
      enqueueSnackbar('Portfolio deleted', { variant: 'success' });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to delete portfolio', { variant: 'error' });
    },
  });

  return (
    <Paper variant="outlined">
      <div className={styles.tabContent__header}>
        <Typography variant="h2">{`Portfolio ${identifier}`}</Typography>
        <Box display="flex" gap="20px">
          <ConditionalTooltip
            showTooltip={hasInvitedBuyers}
            tooltip="Portfolio can only be deleted if it has no invited buyers."
          >
            <Button
              className={styles.publishButton}
              variant="outlined"
              color="grey"
              disabled={hasInvitedBuyers || mutationDeletePortfolio.isLoading}
              startIcon={!mutationDeletePortfolio.isLoading && <CloseIcon />}
              onClick={() =>
                confirmAction('Delete portfolio', 'Do you want to delete this portfolio?', () =>
                  mutationDeletePortfolio.mutate(),
                )
              }
            >
              {mutationDeletePortfolio.isLoading ? <CircularProgress color="inherit" size={24} /> : 'Delete portfolio'}
            </Button>
          </ConditionalTooltip>
        </Box>
      </div>
      <NDAUpload portfolioId={id} ndaFile={ndaFile} />
      <SnapshotList snapshots={snapshots} />
    </Paper>
  );
}
