import styles from './styles.module.scss';
import { Button, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { CountrySelectRow, PhoneNumberInputRow, TextInputRow } from '../shared/components/form_inputs/common';
import { useFormikContext } from 'formik';
import { ValidationError } from 'yup';
import { companyInfoSchema, companyInfoSchemaOptional } from './validation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useUser from '../shared/hooks/useUser';
import { useTheme } from '@mui/material/styles';

interface Props {
  editDisabled?: boolean;
  hideEditButton?: boolean;
  onEdit?: () => void;
  onEditorClose?: () => void;
}

export default function CompanyInfoSection({
  editDisabled = false,
  hideEditButton = false,
  onEdit,
  onEditorClose,
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const { submitForm, resetForm, isSubmitting, values, setErrors } = useFormikContext();
  const { user } = useUser();

  const validationSchema = user?.kycOverride ? companyInfoSchemaOptional : companyInfoSchema;

  const handleEdit = () => {
    if (!expanded) {
      setExpanded(true);
    }
    setEditMode(true);
    onEdit && onEdit();
  };
  const handleCancel = () => {
    resetForm();
    setEditMode(false);
    onEditorClose && onEditorClose();
  };
  const handleSave = () => {
    // handle clearing phone input
    if (((values as any)?.phone as string) === '+') {
      (values as any).phone = '';
    }
    validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        submitForm()
          .then(() => {
            setEditMode(false);
            onEditorClose && onEditorClose();
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch(({ inner }: ValidationError) => {
        const fieldErrors = inner.reduce((errors: any, { path, message }) => {
          if (path) {
            errors[path] = message;
          }
          return errors;
        }, {});
        setErrors(fieldErrors);
      });
  };

  const theme = useTheme();

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionHeader}>
        <Typography className={styles.sectionTitle} sx={{ color: theme.palette.primary.main }} variant="h2">
          Company information
        </Typography>
        <div className={styles.buttonContainer}>
          {editMode ? (
            <>
              <Button variant="outlined" onClick={handleCancel} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="outlined" color="primary" onClick={handleSave} disabled={isSubmitting}>
                {isSubmitting ? <CircularProgress color="primary" size={24} /> : 'Save changes'}
              </Button>
            </>
          ) : (
            !hideEditButton && (
              <Button variant="outlined" onClick={handleEdit} disabled={isSubmitting || editDisabled}>
                Edit section
              </Button>
            )
          )}
          <Button
            className={styles.expandButton}
            variant={expanded ? 'contained' : 'outlined'}
            color={expanded ? 'primary' : 'grey'}
            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Hide details' : 'Show details'}
          </Button>
        </div>
      </div>
      <Collapse in={expanded}>
        <Grid container>
          <Grid item xs={6}>
            <TextInputRow fieldName="companyName" label="Company name" editMode={editMode} />
            <TextInputRow fieldName="legalForm" label="Legal form" editMode={editMode} />
            <TextInputRow
              fieldName="competentRegistrationAuthority"
              label="Competent registration authority"
              editMode={editMode}
            />
            <TextInputRow fieldName="commercialRegisterNumber" label="Commercial register number" editMode={editMode} />
            <TextInputRow fieldName="typeOfBusiness" label="Type of business/activity/purpose" editMode={editMode} />
            <TextInputRow fieldName="nameOfRepresentative" label="Name of the Representative" editMode={editMode} />
            <TextInputRow fieldName="taxId" label="Tax ID" editMode={editMode} />
            <TextInputRow
              fieldName="legalEntityIdentifier"
              label="Legal entity identifier (LEI) (if available)"
              editMode={editMode}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInputRow fieldName="address1" label="Address line 1" editMode={editMode} />
            <TextInputRow fieldName="address2" label="Address line 2" editMode={editMode} />
            <TextInputRow fieldName="zipCode" label="ZIP code" editMode={editMode} />
            <TextInputRow fieldName="city" label="City" editMode={editMode} />
            <CountrySelectRow fieldName="country" label="Country" editMode={editMode} />
            <TextInputRow fieldName="website" label="Website" editMode={editMode} />
            <PhoneNumberInputRow fieldName="phone" label="Phone" editMode={editMode} />
            <TextInputRow fieldName="email" label="Email" editMode={editMode} />
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}
