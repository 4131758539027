import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import styles from '../auth.module.scss';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import AuthLayout from '../AuthLayout';
import LoginForm from './LoginForm';
import { useAuth } from '../../shared/hooks/useAuth';
import LoginCodeForm from './LoginCodeForm';
import useWorkspaceConfiguration from '../../shared/hooks/useWorkspaceConfiguration';

export default function Login() {
  const { isLoggedIn, ephemeralToken, tempAccessToken } = useAuth();
  const { registrationEnabled } = useWorkspaceConfiguration();

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  if (isLoggedIn) {
    navigate('/');
  }

  if (tempAccessToken) {
    navigate('/2fa');
  }

  const { search } = useLocation();
  const refCode = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('ref');
  }, [search]);

  return (
    <AuthLayout
      title="Account Login"
      topLink={
        registrationEnabled && (
          <Box display="flex" alignItems="baseline" className={styles.linkContainer}>
            <Typography>New to wideStreet?</Typography>
            <Button
              className={styles.linkButton}
              color="grey"
              onClick={() => navigate(`/register${refCode ? `/?ref=${refCode}` : ''}`)}
              endIcon={<ArrowRight />}
            >
              Create new account
            </Button>
          </Box>
        )
      }
      errorMsg={error ? errorMessage || 'Unable to log in with provided credentials.' : ''}
    >
      {ephemeralToken ? (
        <LoginCodeForm setError={setError} ephemeralToken={ephemeralToken} />
      ) : (
        <LoginForm setError={setError} setErrorMessage={setErrorMessage} />
      )}
    </AuthLayout>
  );
}
