import { Backdrop, CircularProgress } from '@mui/material';

export interface Props {
  isLoading?: boolean;
  transparent?: boolean;
}

export default function TableLoadingOverlay({ isLoading = false, transparent = true }: Props) {
  return (
    <Backdrop
      open={isLoading}
      timeout={0}
      sx={{
        backgroundColor: transparent ? 'rgba(255, 255, 255, 0.9)' : 'white',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      style={{ position: 'absolute', zIndex: 1000 }}
    >
      {isLoading && <CircularProgress color="primary" size={160} thickness={1} />}
    </Backdrop>
  );
}
