import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import useApi from '../shared/hooks/useApi';
import MessageCenterButton from './MessageCenterButton';

export default function MessageCenterNavBarButton() {
  const api = useApi();

  const { data } = useQuery(['fetchGeneralChatConversationList'], api.messageCenter.fetchGeneralChatConversationList, {
    refetchInterval: 10000,
  });

  const { data: announcementData } = useQuery(
    ['fetchGeneralChatAnnouncementConversationList'],
    api.messageCenter.fetchGeneralChatAnnouncementConversationList,
    {
      refetchInterval: 10000,
    },
  );

  const hasUnreadmessages = useMemo(() => {
    if (data || announcementData) {
      return (
        (data?.some((item) => item.unreadMessageCount > 0) ||
          announcementData?.some((item) => item.unreadMessageCount > 0)) ??
        false
      );
    }
    return false;
  }, [data, announcementData]);

  return <MessageCenterButton hasUnreadmessages={hasUnreadmessages} />;
}
