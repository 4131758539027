import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { CompanyKycDataStatusEnum } from '../shared/types';
import styles from './styles.module.scss';
import { useTheme } from '@mui/material/styles';

interface Props {
  status: CompanyKycDataStatusEnum;
}

export default function StatusBadge({ status }: Props) {
  const label = useMemo(() => {
    if (status === CompanyKycDataStatusEnum.DRAFT) return 'Draft';
    if (status === CompanyKycDataStatusEnum.SUBMITTED) return 'Submitted';
    if (status === CompanyKycDataStatusEnum.APPROVED) return 'Approved';
  }, [status]);

  const theme = useTheme();

  const style = useMemo(() => {
    if (status === CompanyKycDataStatusEnum.DRAFT) return { color: theme.palette.accent2.main };
    if (status === CompanyKycDataStatusEnum.SUBMITTED) return { color: theme.palette.accent2.main };
    if (status === CompanyKycDataStatusEnum.APPROVED) return { color: theme.palette.accent1.main };
  }, [status, theme.palette.accent1.main, theme.palette.accent2.main]);
  return (
    <Typography className={styles.statusBadge} sx={style}>
      {label}
    </Typography>
  );
}
