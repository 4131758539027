import * as Yup from 'yup';

const requiredMsg = 'This field cannot be empty.';

export const companyInfoSchema = Yup.object({
  companyName: Yup.string().required(requiredMsg),
  legalForm: Yup.string().required(requiredMsg),
  competentRegistrationAuthority: Yup.string().required(requiredMsg),
  commercialRegisterNumber: Yup.string().required(requiredMsg),
  taxId: Yup.string().required(requiredMsg),
  legalEntityIdentifier: Yup.string(),
  typeOfBusiness: Yup.string().required(requiredMsg),
  address1: Yup.string().required(requiredMsg),
  address2: Yup.string(),
  zipCode: Yup.string().required(requiredMsg),
  city: Yup.string().required(requiredMsg),
  country: Yup.string().required(requiredMsg),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Please enter a valid URL',
    )
    .required(requiredMsg),
  nameOfRepresentative: Yup.string().required(requiredMsg),
  phone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
  email: Yup.string().email().required(requiredMsg),
});

// additional fully optional schema for manually confirmed companies
export const companyInfoSchemaOptional = Yup.object({
  companyName: Yup.string(),
  legalForm: Yup.string(),
  competentRegistrationAuthority: Yup.string(),
  commercialRegisterNumber: Yup.string(),
  taxId: Yup.string(),
  legalEntityIdentifier: Yup.string(),
  typeOfBusiness: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
  zipCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    {
      message: 'Please enter a valid URL',
      excludeEmptyString: true,
    },
  ),
  nameOfRepresentative: Yup.string(),
  phone: Yup.string().phone('Please enter a valid phone number.'),
  email: Yup.string().email(),
});

export const authorizedRepresentativeSchema = Yup.object({
  name: Yup.string().required(requiredMsg),
  address1: Yup.string().required(requiredMsg),
  address2: Yup.string(),
  zipCode: Yup.string().required(requiredMsg),
  city: Yup.string().required(requiredMsg),
  country: Yup.string().required(requiredMsg),
  dateOfBirth: Yup.date().typeError('Please enter a date').required(requiredMsg),
  placeOfBirth: Yup.string().required(requiredMsg),
  citizenship: Yup.array().of(Yup.string()).min(1, 'Please choose at least one value'),
  documentNumber: Yup.string().required(requiredMsg),
  documentValidFrom: Yup.date().typeError('Please enter a date').required(requiredMsg),
  documentValidUntil: Yup.date()
    .min(Yup.ref('documentValidFrom'))
    .typeError('Please enter a date')
    .required(requiredMsg),
  typeOfAuthority: Yup.string().required(requiredMsg),
  department: Yup.string().required(requiredMsg),
  mobilePhone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
  pepStatus: Yup.boolean().required(requiredMsg),
  pepInfo: Yup.string().when('pepStatus', { is: true, then: Yup.string().required(requiredMsg) }),
});

export const authorizedRepresentativeSchemaOptional = Yup.object({
  name: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
  zipCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  dateOfBirth: Yup.date().nullable().typeError('Please enter a date'),
  placeOfBirth: Yup.string(),
  citizenship: Yup.array().of(Yup.string()),
  documentNumber: Yup.string(),
  documentValidFrom: Yup.date().nullable().typeError('Please enter a date'),
  documentValidUntil: Yup.date().nullable().typeError('Please enter a date').min(Yup.ref('documentValidFrom')),
  typeOfAuthority: Yup.string(),
  department: Yup.string(),
  mobilePhone: Yup.string().phone('Please enter a valid phone number.'),
  pepStatus: Yup.boolean(),
  pepInfo: Yup.string().when('pepStatus', { is: true, then: Yup.string().required(requiredMsg) }),
});

export const ultimateBeneficialOwnerSchema = Yup.object({
  name: Yup.string().required(requiredMsg),
  address1: Yup.string().required(requiredMsg),
  address2: Yup.string(),
  zipCode: Yup.string().required(requiredMsg),
  city: Yup.string().required(requiredMsg),
  country: Yup.string().required(requiredMsg),
  dateOfBirth: Yup.date().typeError('Please enter a date').required(requiredMsg),
  placeOfBirth: Yup.string().required(requiredMsg),
  citizenship: Yup.array().of(Yup.string()).min(1, 'Please choose at least one value'),
  documentNumber: Yup.string().required(requiredMsg),
  documentValidFrom: Yup.date().typeError('Please enter a date').required(requiredMsg),
  documentValidUntil: Yup.date()
    .min(Yup.ref('documentValidFrom'))
    .typeError('Please enter a date')
    .required(requiredMsg),
  taxpayerIdentificationNumber: Yup.string().required(requiredMsg),
  pepStatus: Yup.boolean().required(requiredMsg),
  pepInfo: Yup.string().when('pepStatus', { is: true, then: Yup.string().required(requiredMsg) }),
});

export const ultimateBeneficialOwnerSchemaOptional = Yup.object({
  name: Yup.string(),
  address1: Yup.string(),
  address2: Yup.string(),
  zipCode: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  dateOfBirth: Yup.date().nullable().typeError('Please enter a date'),
  placeOfBirth: Yup.string(),
  citizenship: Yup.array().of(Yup.string()),
  documentNumber: Yup.string(),
  documentValidFrom: Yup.date().nullable().typeError('Please enter a date'),
  documentValidUntil: Yup.date().nullable().min(Yup.ref('documentValidFrom')).typeError('Please enter a date'),
  taxpayerIdentificationNumber: Yup.string(),
  pepStatus: Yup.boolean(),
  pepInfo: Yup.string().when('pepStatus', { is: true, then: Yup.string().required(requiredMsg) }),
});

export const fullValidationSchema = Yup.object({
  companyName: Yup.string().required(requiredMsg),
  legalForm: Yup.string().required(requiredMsg),
  competentRegistrationAuthority: Yup.string().required(requiredMsg),
  commercialRegisterNumber: Yup.string().required(requiredMsg),
  taxId: Yup.string().required(requiredMsg),
  legalEntityIdentifier: Yup.string(),
  typeOfBusiness: Yup.string().required(requiredMsg),
  address1: Yup.string().required(requiredMsg),
  address2: Yup.string(),
  zipCode: Yup.string().required(requiredMsg),
  city: Yup.string().required(requiredMsg),
  country: Yup.string().required(requiredMsg),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Please enter a valid URL',
    )
    .required(requiredMsg),
  nameOfRepresentative: Yup.string().required(requiredMsg),
  phone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
  email: Yup.string().email().required(requiredMsg),
  authorizedRepresentative: authorizedRepresentativeSchema,
  ultimateBeneficialOwners: Yup.array().of(ultimateBeneficialOwnerSchema),
});
