import React, { useState } from 'react';
import SingleButtonModal from '../../../shared/components/SingleButtonModal';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { useConfirmationDialogStore } from '../../../shared/components/ConfirmationModal';
import { BaseBuyerData } from '../../../shared/types';

interface Props {
  open: boolean;
  onClose: (event?: any) => void;
  onClick: (buyerId?: string, newBuyer?: BaseBuyerData) => void;
}

export default function AssignExistingBuyerModal({ open, onClose, onClick }: Props) {
  const [value, setValue] = useState<string | undefined>(undefined);

  const api = useAdminApi();

  const { data, isLoading } = useQuery(['adminFetchAllBuyers'], () => api.users.buyers.fetchAllBuyers());
  const buyers = data || [];

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);
  const handleClick = () => value && onClick(value);

  return (
    <SingleButtonModal
      open={open}
      onClose={onClose}
      title="Assign to existing buyer"
      content={
        <Box display="flex" alignItems="center" gap="30px" sx={{ marginTop: '30px' }}>
          <Typography variant="h3">Buyer:</Typography>
          <Select
            variant="outlined"
            size="small"
            sx={{ minWidth: '300px' }}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          >
            {isLoading ? (
              <MenuItem>
                <CircularProgress color="primary" size={24} />
              </MenuItem>
            ) : (
              buyers.map(({ id, companyName }) => (
                <MenuItem key={id} value={id}>
                  {companyName}
                </MenuItem>
              ))
            )}
          </Select>
        </Box>
      }
      buttonLabel="Activate"
      disabled={!value}
      onClick={() => confirmAction('Activate users', 'Do you want to activate selected users?', handleClick)}
    />
  );
}
