import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { WorkspaceConfiguration } from '../types';

const fetchWorkspaceConfiguration = async () =>
  axios.get(`/api/configuration/`).then((response) => response.data as WorkspaceConfiguration);

export default function useWorkspaceConfiguration() {
  const { data, isLoading } = useQuery(['fetchWorkspaceConfiguration'], () => fetchWorkspaceConfiguration());

  const registrationEnabled = Boolean(data?.enableRegistration);

  return {
    data,
    isLoading,
    registrationEnabled,
  };
}
