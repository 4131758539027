import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Loan } from '../../../../../shared/types';
import { countryCodeToName, formatConvertedAmount, formatDate } from '../../../../../shared/utils/helpers';
import styles from './loanDetails.module.scss';
import LabeledValue from '../../../../../shared/components/LabeledValue';

interface Props {
  loan: Loan;
}

export default function DetailedInfo({ loan }: Props) {
  const { borrower } = loan;

  const detailSectionsLeft = [
    {
      title: 'Product Details',
      values: [
        {
          label: 'Asset Class',
          value: loan.assetClass,
        },
        {
          label: 'Loan Purpose',
          value: loan.loanPurpose,
        },
        {
          label: 'Product Type',
          value: loan.productType,
        },
      ],
    },
    {
      title: 'Borrower Details',
      values: [
        {
          label: 'Legal Type Of Counterparty',
          value: borrower.legalTypeOfCounterparty,
        },
        {
          label: 'Industry Segment',
          value: borrower.industrySegment,
        },
        {
          label: 'Country Of Registered Location',
          value: countryCodeToName(borrower.countryOfRegisteredLocation),
        },
      ],
    },
    {
      title: 'Repayment Details',
      values: [
        {
          label: 'Principal Payment Frequency',
          value: loan.principalPaymentFrequency,
        },
        {
          label: 'Final Bullet Repayment',
          value:
            loan.finalBulletRepayment &&
            formatConvertedAmount(loan.finalBulletRepayment, loan.loanCurrency, loan.finalBulletRepaymentConverted),
        },
        {
          label: 'Amortization Type',
          value: loan.amortisationType,
        },
        {
          label: 'Description Of Bespoke Repayment',
          value: loan.descriptionOfBespokeRepayment,
        },
        {
          label: 'Forbearance',
          value: loan.hasForbearance ? 'Yes' : 'No',
        },
        {
          label: 'Repayments Scheduled',
          value: loan.hasScheduledRepayments ? 'Yes' : 'No',
        },
      ],
    },
  ];

  const detailSectionsRight = [
    {
      title: 'Interest Rate Details',
      values: [
        {
          label: 'Current Interest Rate',
          value: loan.currentInterestRate,
        },
        {
          label: 'Current Interest Rate Type',
          value: loan.currentInterestRateType,
        },
        {
          label: 'Current Interest Rate Reference',
          value: loan.currentInterestRateReference,
        },
        {
          label: 'Current Interest Margin',
          value: loan.currentInterestMargin,
        },
        {
          label: 'Interest Cap Rate',
          value: loan.interestCapRate,
        },
        {
          label: 'Interest Floor Rate',
          value: loan.interestFloorRate,
        },
        {
          label: 'Interest Payment Frequency',
          value: loan.interestPaymentFrequency,
        },
      ],
    },
    {
      title: 'Performance Details',
      values: [
        {
          label: 'Loan Status',
          value: loan.loanStatus,
        },
        {
          label: 'Date Of Default',
          value: loan.dateOfDefault && formatDate(loan.dateOfDefault),
        },
        {
          label: 'Balance At Default',
          value:
            loan.balanceAtDefault &&
            formatConvertedAmount(loan.balanceAtDefault, loan.loanCurrency, loan.balanceAtDefaultConverted),
        },
        {
          label: 'Charge-Off Date',
          value: loan.chargeoffDate && formatDate(loan.chargeoffDate),
        },
        {
          label: 'Total Past Due Amount',
          value: loan.totalPastdueAmount,
        },
        {
          label: 'Days in Past-Due',
          value:
            loan.daysInPastdue &&
            (loan.daysInPastdue === 1 ? `${loan.daysInPastdue} day` : `${loan.daysInPastdue} days`),
        },
        {
          label: 'Last Payment Amount',
          value: formatConvertedAmount(loan.lastPaymentAmount, loan.loanCurrency, loan.lastPaymentAmountConverted),
        },
        {
          label: 'Last Payment Date',
          value: loan.lastPaymentDate && formatDate(loan.lastPaymentDate),
        },
      ],
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {detailSectionsLeft.map(({ title, values }) => (
          <div key={title} className={styles.section}>
            <Typography className={styles.section__title} variant="h2">
              {title}
            </Typography>
            {values.map(
              ({ label, value }) =>
                value && <LabeledValue key={title + label} label={label} value={value} labelSize="15px" />,
            )}
          </div>
        ))}
      </Grid>
      <Grid item xs={6}>
        {detailSectionsRight.map(({ title, values }) => (
          <div key={title} className={styles.section}>
            <Typography className={styles.section__title} variant="h2">
              {title}
            </Typography>
            {values.map(
              ({ label, value }) =>
                value && <LabeledValue key={title + label} label={label} value={value} labelSize="15px" />,
            )}
          </div>
        ))}
      </Grid>
    </Grid>
  );
}
