import React from 'react';
import { Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useApi from '../shared/hooks/useApi';
import SingleButtonModal from '../shared/components/SingleButtonModal';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function InviteUserModal({ open, onClose }: Props) {
  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object({
    name: Yup.string().required(requiredMsg),
    email: Yup.string().email().required(requiredMsg),
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const api = useApi();

  const { mutate, isLoading } = useMutation({
    mutationFn: api.company.inviteUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchCompanyUsers']);
      enqueueSnackbar('User invite sent', { variant: 'success' });
      onClose();
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar('Failed to invite user', { variant: 'error' });
    },
  });

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        mutate(values);
      }}
    >
      {({ dirty, isValid, submitForm }) => (
        <SingleButtonModal
          open={open}
          onClose={() => {
            onClose();
          }}
          title="Add new user"
          content={
            <Form>
              <Grid container>
                <Grid item xs={12} className={styles.modal__field}>
                  <Field component={TextField} label="Name" name="name" fullWidth />
                </Grid>
                <Grid item xs={12} className={styles.modal__field}>
                  <Field component={TextField} label="Email" name="email" fullWidth />
                </Grid>
              </Grid>
            </Form>
          }
          buttonLabel="Send invitation"
          onClick={submitForm}
          isSubmitting={isLoading}
          disabled={!(dirty && isValid) || isLoading}
        />
      )}
    </Formik>
  );
}
