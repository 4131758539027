import React from 'react';
import styles from '../../../styles.module.scss';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { getFileNameFromPath } from '../../../../shared/utils/helpers';
import UploadIcon from '@mui/icons-material/Upload';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../../../shared/components/table/TableButton';
import { allowedExtensions } from '../../../../shared/utils/constants';
import { saveAs } from 'file-saver';

interface Props {
  portfolioId: number;
  ndaFile?: string;
}

export default function NDAUpload({ portfolioId, ndaFile }: Props) {
  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const uploadMutation = useMutation({
    mutationFn: (form: FormData) => api.portfolios.uploadNda(portfolioId, form),
    onSuccess: async (newId) => {
      await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
      enqueueSnackbar('NDA uploaded', { variant: 'success' });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to upload NDA', { variant: 'error' });
    },
  });

  const handleUpload = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file.type !== 'application/pdf') {
      enqueueSnackbar('Failed to upload NDA. Must be a PDF file', { variant: 'error' });
      return;
    }

    if (file) {
      const form = new FormData();
      form.append('file', file);
      uploadMutation.mutate(form);
    }
  };

  const handleDownload = (filename: string) => {
    api.client
      .get(`admin/portfolios/${portfolioId}/download_nda/`, { responseType: 'blob' })
      .then((res) => {
        saveAs(res.data, filename);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download NDA', { variant: 'error' });
      });
  };

  const theme = useTheme();

  return (
    <div className={styles.filesSection__container}>
      <div className={styles.filesSection__header}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h2">
          NDA file
        </Typography>
      </div>
      <TableContainer className={styles.filesSection__tableContainer}>
        <Table size="small">
          <TableHead>
            <TableCell sx={{ width: '40%' }}>File Name</TableCell>
            <TableCell align="right" />
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>
                {ndaFile && (
                  <Button
                    className={styles.filesSection__downloadButton}
                    fullWidth
                    onClick={() => handleDownload(getFileNameFromPath(ndaFile))}
                    variant="text"
                    endIcon={<DownloadIcon />}
                  >
                    {getFileNameFromPath(ndaFile)}
                  </Button>
                )}
              </TableCell>
              <TableCell align="right">
                <TableButton
                  startIcon={<UploadIcon />}
                  component="label"
                  onChange={handleUpload}
                  disabled={uploadMutation.isLoading}
                >
                  Change file
                  <input type="file" hidden accept={allowedExtensions} />
                </TableButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
