import { useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import ThreadsLoader from '../../MessageCenterPage/ThreadsLoader';
import UnreadMessageNotificationIcon from '../../shared/components/chat/UnreadMessageNotificationIcon';
import ChevronRightIcon from '../../shared/components/icons/ChevronRight';
import useAdminApi from '../../shared/hooks/useAdminApi';
import styles from './message.center.module.scss';
import _ from 'lodash';

interface Props {
  searchTerm?: string;
  selectedCompanyId: string | null;
  selectCompanyId: (companyId: string) => void;
}

export default function ChatCompanyList({ selectCompanyId, selectedCompanyId, searchTerm = '' }: Props) {
  const theme = useTheme();
  const api = useAdminApi();

  const { data, isInitialLoading } = useQuery(
    ['fetchGeneralChatCompanyList'],
    api.messageCenter.fetchGeneralChatCompanyList,
    {
      refetchInterval: 10000,
    },
  );

  const filteredData = useMemo(() => {
    const buyers = data || [];
    const filteredBuyers = searchTerm
      ? buyers.filter((item) => item.companyName.toLocaleLowerCase().includes(searchTerm))
      : buyers;
    // put buyers with unread messages first
    return _.sortBy(filteredBuyers, ({ unreadMessageCount }) => !Boolean(unreadMessageCount));
  }, [searchTerm, data]);

  if (isInitialLoading) {
    return <ThreadsLoader />;
  }

  return (
    <div className={styles.companyList}>
      {filteredData.map((item) => {
        const isSelected = selectedCompanyId === item.companyId;
        return (
          <div
            key={item.conversationId}
            onClick={() => {
              selectCompanyId(item.companyId);
            }}
            style={{
              height: 50,
              backgroundColor: isSelected ? theme.palette.backgroundColor.main : 'white',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
              paddingBottom: 10,
              borderBottom: '1px solid #F1F1F1',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                fontSize: '12px',
                fontWeight: isSelected ? 'bold' : 'normal',
                color: isSelected ? '#303334' : '#5A676E',
              }}
            >
              {item.companyName}
            </span>
            <div>
              {isSelected ? <ChevronRightIcon /> : item.unreadMessageCount > 0 && <UnreadMessageNotificationIcon />}
            </div>
          </div>
        );
      })}
    </div>
  );
}
