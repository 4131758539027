import { CircularProgress } from '@mui/material';

const ChatOldMessagesLoader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={24} />
    </div>
  );
};

export default ChatOldMessagesLoader;
