import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { allowedExtensions } from '../../utils/constants';
import AttachmentIcon from '../icons/AttachmentIcon';

interface Props {
  readyOnly?: boolean;
}

export default function MessageAttachmentButton({ readyOnly = false }: Props) {
  const { setFieldValue, values } = useFormikContext();

  const handleUpload = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;

    if (target?.files?.length) {
      // @ts-ignore
      setFieldValue('attachments', [...target.files, ...values.attachments]);
    }
  };

  return (
    <div style={{ height: 32, display: 'flex', alignItems: 'center' }}>
      <Button
        disabled={readyOnly}
        size="small"
        onChange={handleUpload}
        component="label"
        startIcon={<AttachmentIcon />}
      >
        <input id="qaAddAttachmentInput" type="file" multiple hidden accept={allowedExtensions} />
      </Button>
    </div>
  );
}
