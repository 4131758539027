import { PortfolioSnapshotBase, PortfolioSnapshotStatus } from '../../../../shared/types';
import { useNavigate } from 'react-router-dom';
import styles from '../../../styles.module.scss';
import { Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { formatDate, formatDateTime } from '../../../../shared/utils/helpers';
import React from 'react';
import { getSnapshotStatusLabel } from '../Auctions/utils';
import SnapshotPublishButton from './SnapshotPublishButton';
import CustomTableBody from '../../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../../../shared/components/table/TableButton';

interface ListProps {
  snapshots: PortfolioSnapshotBase[];
}

export default function SnapshotList({ snapshots }: ListProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const getSnapshotStatusStyle = (status: string) => {
    if (status === PortfolioSnapshotStatus.PUBLISHED) {
      return { color: theme.palette.accent1.main };
    }
    if (status === PortfolioSnapshotStatus.DRAFT) {
      return { color: theme.palette.accent2.main };
    }
    if (status === PortfolioSnapshotStatus.INACTIVE) {
      return { color: '#c4c4c4' };
    }
    return { color: theme.palette.primary.main };
  };

  return (
    <div className={styles.filesSection__container}>
      <div className={styles.filesSection__header}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h2">
          Snapshot list
        </Typography>
      </div>
      <TableContainer className={styles.filesSection__tableContainer}>
        <Table size="small" className={styles.tabContent__table}>
          <TableHead>
            <TableRow>
              <TableCell>Cutoff Date</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <CustomTableBody
            data={snapshots}
            renderRow={({ id, cutoffDate, user, creationDate, status, portfolio }) => (
              <TableRow key={id}>
                <TableCell>{formatDate(cutoffDate)}</TableCell>
                <TableCell>{user}</TableCell>
                <TableCell>{formatDateTime(creationDate)}</TableCell>
                <TableCell sx={getSnapshotStatusStyle(status)}>{getSnapshotStatusLabel(status)}</TableCell>
                <TableCell align="right">
                  <SnapshotPublishButton snapshotId={id} status={status} portfolioId={portfolio} />
                </TableCell>
                <TableCell align="right">
                  <TableButton onClick={() => navigate(`/portfolio_manager/snapshots/${id}`)}>Details</TableButton>
                </TableCell>
              </TableRow>
            )}
          />
        </Table>
      </TableContainer>
    </div>
  );
}
