import React from 'react';
import { Grid } from '@mui/material';
import Chart from 'react-apexcharts';
import { PortfolioSnapshot } from '../shared/types';
import LabeledValue from '../shared/components/LabeledValue';
import { formatCurrency } from '../shared/utils/helpers';
import { useTheme } from '@mui/material/styles';

interface Props {
  portfolio: PortfolioSnapshot;
  labelSize?: string;
  valueSize?: string;
  wrap?: boolean;
}

export default function BalanceChart({
  portfolio: {
    totalBalance: { outstanding, original, currency },
    kpis,
  },
  labelSize,
  valueSize,
  wrap = false,
}: Props) {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={wrap ? 12 : 7} xl={7}>
        <LabeledValue
          variant="vertical"
          label="Total Outstanding Balance (UPB)"
          labelSize={labelSize || '12px'}
          value={formatCurrency(outstanding, currency)}
          valueSize={valueSize || '20px'}
          valueColor={theme.palette.accent1.main}
        />
        <LabeledValue
          variant="vertical"
          label="Total Original Balance"
          labelSize={labelSize || '12px'}
          value={formatCurrency(original, currency)}
          valueSize={valueSize || '20px'}
          valueColor={theme.palette.accent2.main}
        />
        <LabeledValue
          variant="vertical"
          label="No. of loans"
          labelSize={labelSize || '12px'}
          value={kpis.loansCount.toString(10)}
          valueSize={valueSize || '20px'}
        />
      </Grid>
      <Grid item xs={wrap ? 12 : 5} xl={5}>
        <Chart
          type="radialBar"
          series={[(outstanding / original) * 100]}
          options={{
            chart: {
              type: 'radialBar',
              redrawOnParentResize: false,
              redrawOnWindowResize: false,
              parentHeightOffset: 0,
            },
            legend: {
              show: false,
            },
            fill: {
              opacity: 1,
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  show: true,
                  name: {
                    show: false,
                  },
                  value: {
                    show: true,
                    offsetY: 5,
                    fontFamily: 'Saira Semi Condensed',
                    formatter: (value: number) => `${value.toFixed(1)}%`,
                  },
                },
                track: {
                  strokeWidth: '20%',
                  background: theme.palette.accent2.main,
                },
              },
            },
            tooltip: {
              enabled: true,
              theme: 'dark',
              y: {
                formatter: (value: number) => `${value.toFixed(1)}%`,
              },
            },
            labels: ['Outstanding Balance'],
            colors: [theme.palette.accent1.main],
          }}
          height="115%"
          width="115%"
        />
      </Grid>
    </Grid>
  );
}
