import React, { useMemo, useState } from 'react';
import { Box, Button, Grid, OutlinedInput, Typography } from '@mui/material';
import styles from '../styles.module.scss';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SingleButtonModal from '../../shared/components/SingleButtonModal';
import useAdminApi from '../../shared/hooks/useAdminApi';
import { useNavigate } from 'react-router-dom';
import { allowedExtensions } from '../../shared/utils/constants';

interface FileSelectProps {
  selectedFile?: string;
  onFileUpload: (file: File) => void;
}

export const FileSelectRow = ({ selectedFile, onFileUpload }: FileSelectProps) => {
  const fileName = useMemo(() => {
    if (!selectedFile) {
      return '';
    }
    return selectedFile.split('/').at(-1);
  }, [selectedFile]);

  const handleUpload = async (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      onFileUpload(file);
    }
  };

  return (
    <div className={styles.modal__fileRow}>
      <Typography className={styles.modal__fileLabel} variant="h3">
        Select file:
      </Typography>
      {selectedFile && (
        <Button
          className={styles.modal__downloadButton}
          href={selectedFile}
          target="_blank"
          variant="text"
          startIcon={<CloudDownloadIcon />}
        >
          {fileName}
        </Button>
      )}

      <Button
        className={styles.modal__uploadButton}
        variant="outlined"
        color="grey"
        startIcon={<CloudUploadIcon />}
        component="label"
        onChange={handleUpload}
      >
        Browse
        <input type="file" hidden accept={allowedExtensions} />
      </Button>
    </div>
  );
};

interface Props {
  snapshotId: number;
  open: boolean;
  onClose: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function AddPortfolioDocumentModal({ snapshotId, open, onClose, loading, setLoading }: Props) {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [name, setName] = useState('');

  const handleFileUpload = (file: File) => {
    setFile(file);
  };

  const handleChange = (e: any) => {
    setName(e.target.value);
  };

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const uploadMutation = useMutation({
    mutationFn: (form: FormData) => api.snapshots.uploadSnapshotDocument(snapshotId, form),
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: async (newId) => {
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshotDetails', snapshotId]);
      enqueueSnackbar('Portfolio document uploaded', { variant: 'success' });
      setFile(undefined);
      onClose();
      if (newId && newId !== snapshotId) {
        enqueueSnackbar('New snapshot draft created', { variant: 'success' });
        navigate(`/portfolio_manager/snapshots/${newId}`);
      }
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to upload portfolio document', { variant: 'error' });
    },
  });

  const handleSubmit = async () => {
    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('name', name);
      uploadMutation.mutate(form);
    }
  };

  return (
    <SingleButtonModal
      open={open}
      onClose={onClose}
      title="Add Portfolio Document"
      content={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FileSelectRow selectedFile={file?.name} onFileUpload={handleFileUpload} />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography className={styles.modal__fileLabel} variant="h3">
                Name:
              </Typography>
              <OutlinedInput onChange={handleChange} />
            </Box>
          </Grid>
        </Grid>
      }
      buttonLabel="Upload"
      onClick={handleSubmit}
      disabled={loading || !Boolean(file)}
      isSubmitting={loading}
    />
  );
}
