import styles from './message.center.module.scss';
import { Box, Button, Grid, Paper, Typography, OutlinedInput } from '@mui/material';
import ChatCompanyList from './ChatCompanyList';
import { useCallback, useState } from 'react';
import PostConversationForm from '../../MessageCenterPage/PostConversationForm';
import ChatConversationList from './ChatConversationList';
import { GeneralChatConversationList, GeneralChatAnnouncementConversationList } from '../../shared/types';
import GeneralChatMessages from './GeneralChatMessages';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import GeneralChatConversationTitle from '../../MessageCenterPage/GeneralChatConversationTitle';
import { AnnouncementItem } from '../../MessageCenterPage/AnnouncementItem';
import useAdminApi from '../../shared/hooks/useAdminApi';
import { useQueryClient } from '@tanstack/react-query';
import ChatAnnouncementConversationList from './ChatAnnouncementConversationList';
import GeneralChatAnnouncementMessages from '../../MessageCenterPage/GeneralChatAnnouncementMessages';

export default function MessageCenterAdminPage() {
  const [selectedCompanyId, selectCompanyId] = useState<string | null>(null);
  const [announcementsSelected, selectAnnouncements] = useState(false);
  const [selectedConversation, selectConversation] = useState<GeneralChatConversationList | null>(null);
  const [selectedAnnouncementConversation, selectAnnouncementConversation] =
    useState<GeneralChatAnnouncementConversationList | null>(null);
  const [isFormShown, showForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const api = useAdminApi();
  const queryClient = useQueryClient();

  const selectCompany = useCallback((companyId: string) => {
    selectCompanyId(companyId);
    selectAnnouncements(false);
    showForm(false);
    selectConversation(null);
    selectAnnouncementConversation(null);
  }, []);

  const postConversation = async (form: FormData) => {
    if (!selectedCompanyId) return;
    await api.messageCenter.postGeneralChatConversation(selectedCompanyId, form);
    await queryClient.invalidateQueries(['fetchGeneralChatCompanyList', selectedCompanyId]);
  };

  const postAnnouncementConversation = async (form: FormData) => {
    await api.messageCenter.postGeneralChatAnnouncementConversation(form);
    await queryClient.invalidateQueries(['fetchGeneralChatCompanyList']);
  };

  const isThreadSelected = announcementsSelected || selectedCompanyId !== null;

  return (
    <main className={styles.main}>
      <Paper variant="outlined" className={styles.tabContent}>
        <div className={styles.tabContent__header}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            flexGrow={1}
            gap="10px"
          >
            <Typography variant="h2">Message Center</Typography>
          </Box>
          <Box display="flex" gap="10px" justifyContent="flex-end" flexWrap="wrap-reverse">
            {selectedConversation !== null || selectedAnnouncementConversation !== null || isFormShown ? (
              <Button
                variant="outlined"
                color="grey"
                startIcon={<NavigateBeforeIcon />}
                onClick={() => {
                  if (isFormShown) {
                    showForm(false);
                  } else {
                    selectConversation(null);
                    selectAnnouncementConversation(null);
                  }
                }}
              >
                Back to message list
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="grey"
                disabled={selectedCompanyId === null && !announcementsSelected}
                onClick={() => showForm(true)}
              >
                Write a message
              </Button>
            )}
          </Box>
        </div>

        <Grid container flex={1}>
          <Grid
            item
            xs={3}
            style={{
              borderRight: 1,
              borderColor: '#EBEBEB',
              borderStyle: 'solid',
            }}
          >
            <OutlinedInput
              style={{
                height: 40,
                display: 'flex',
                margin: 10,
              }}
              defaultValue={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toLocaleLowerCase());
              }}
              placeholder="Search"
            />
            <AnnouncementItem
              isSelected={announcementsSelected}
              onSelect={() => {
                selectCompanyId(null);
                selectConversation(null);
                selectAnnouncements(true);
                showForm(false);
              }}
              hasUnreadMessages={false}
            />
            <ChatCompanyList
              selectedCompanyId={selectedCompanyId}
              selectCompanyId={selectCompany}
              searchTerm={searchTerm}
            />
          </Grid>

          <Grid
            item
            xs={9}
            style={{
              display: 'flex',
              maxHeight: 'calc(100vh - 79px - 60px - 81px)',
            }}
          >
            {isThreadSelected && isFormShown ? (
              <div style={{ padding: 12, display: 'flex', flex: 1 }}>
                <PostConversationForm
                  onCancel={() => showForm(false)}
                  postConversation={announcementsSelected ? postAnnouncementConversation : postConversation}
                  postMessageCallback={() => {
                    showForm(false);
                  }}
                />
              </div>
            ) : null}

            {selectedCompanyId !== null && !isFormShown && selectedConversation === null ? (
              <ChatConversationList selectedCompanyId={selectedCompanyId} selectConversation={selectConversation} />
            ) : null}

            {selectedAnnouncementConversation === null && announcementsSelected && !isFormShown && (
              <ChatAnnouncementConversationList selectConversation={selectAnnouncementConversation} />
            )}

            {selectedAnnouncementConversation !== null && !isFormShown && (
              <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column' }}>
                <GeneralChatConversationTitle
                  title={selectedAnnouncementConversation.conversationTitle}
                  date={selectedAnnouncementConversation.conversationCreatedAt}
                />
                <GeneralChatAnnouncementMessages
                  selectedConversation={selectedAnnouncementConversation}
                  deselectConversation={() => {
                    selectAnnouncementConversation(null);
                  }}
                />
              </div>
            )}

            {!announcementsSelected && selectedConversation !== null && !isFormShown ? (
              <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column' }}>
                <GeneralChatConversationTitle
                  title={selectedConversation.conversationTitle}
                  date={selectedConversation.conversationCreatedAt}
                />
                <GeneralChatMessages
                  selectedConversation={selectedConversation}
                  deselectConversation={() => selectConversation(null)}
                />
              </div>
            ) : null}

            {/* TODO: make it a component */}
            {!isThreadSelected && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <p style={{ marginTop: 10, color: '#5A676E', fontSize: 14 }}>Select a thread to view messages.</p>
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    </main>
  );
}
