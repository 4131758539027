import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import styles from '../auth.module.scss';
import { useAuth } from '../../shared/hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmButton from '../../shared/components/buttons/ConfirmButton';
import CancelButton from '../../shared/components/buttons/CancelButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import useApi from '../../shared/hooks/useApi';

export default function TOSModal() {
  const [tosAccepted, selectTosAccepted] = useState(false);
  const { logout } = useAuth();

  const tooltipTitle = !tosAccepted ? 'Please accept TOS to proceed' : '';

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { acceptTos } = useApi();

  const { mutate, isLoading } = useMutation(() => acceptTos(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchMe']);
      enqueueSnackbar('TOS accepted', { variant: 'success' });
    },
    onError(e) {
      console.error(e);
      enqueueSnackbar('Failed to accept TOS', { variant: 'error' });
    },
  });

  const onAccept = () => {
    mutate();
  };

  const onClose = () => {
    logout();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={true} onClose={onClose}>
      <DialogTitle style={{ marginTop: 15 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Prior to accessing the platform, please read and accept the TOS.</Typography>
          {onClose ? (
            <IconButton className={styles.tosModal__btnClose} onClick={onClose} size="large">
              <CloseIcon fontSize="large" />
            </IconButton>
          ) : null}
        </Box>
      </DialogTitle>
      <DialogContent className={styles.tosModal__content}>
        <h1>Terms Of Service</h1>
        <p>This is some temporary TOS content.</p>
        <p>It should be replaced by actual text.</p>
        <p>For now it's just a functionality POC.</p>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'start', marginLeft: 15 }}>
        <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            data-testid="nda-accept"
            label="I accept the Terms Of Service"
            control={<Checkbox checked={tosAccepted} onChange={() => selectTosAccepted(!tosAccepted)} />}
          />
        </FormGroup>
      </DialogActions>
      <DialogActions style={{ justifyContent: 'space-between', marginBottom: 15, marginRight: 30, marginLeft: 15 }}>
        <Box>
          <Tooltip title={tooltipTitle} placement="top">
            <span style={{ marginRight: 30 }}>
              <ConfirmButton disabled={!tosAccepted} loading={isLoading} onClick={onAccept}>
                Accept
              </ConfirmButton>
            </span>
          </Tooltip>

          <CancelButton onClick={onClose} disabled={isLoading}>
            Decline
          </CancelButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
