import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import QaTabLink from '../../../../shared/components/chat/QATabLink';
import useAdminApi from '../../../../shared/hooks/useAdminApi';

interface Props {
  portfolioId: number;
}

const QaTabLinkAdmin = ({ portfolioId }: Props) => {
  const api = useAdminApi();

  const { data } = useQuery(
    ['fetchChatConversationList', portfolioId],
    () => api.portfolios.fetchChatConversationList(portfolioId as number),
    {
      enabled: Boolean(portfolioId),
      refetchInterval: 10000,
    },
  );

  const hasUnreadMessages = useMemo(() => {
    return data?.some((item) => item.unreadMessageCount > 0);
  }, [data]);

  return <QaTabLink hasUnreadMessages={hasUnreadMessages} />;
};

export default QaTabLinkAdmin;
