import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ConfirmButton from './buttons/ConfirmButton';
import CancelButton from './buttons/CancelButton';
import create from 'zustand';

interface ConfirmationDialogStore {
  open: boolean;
  title: string;
  message: string;
  onConfirm?: () => void;
  close: () => void;
  confirmAction: (title: string, message: string, onConfirm: () => void) => void;
}

export const useConfirmationDialogStore = create<ConfirmationDialogStore>((set) => ({
  open: false,
  title: '',
  message: '',
  onConfirm: undefined,
  close: () => set({ open: false, title: '', message: '', onConfirm: undefined }),
  confirmAction: (title, message, onConfirm) => set({ open: true, title, message, onConfirm }),
}));

export default function ConfirmationModal() {
  const { open, close, title, message, onConfirm } = useConfirmationDialogStore((state) => state);

  const handleConfirm = () => {
    onConfirm && onConfirm();
    close();
  };

  const handleClose = () => {
    close();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
      </DialogActions>
    </Dialog>
  );
}
