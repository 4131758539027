import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';

import { PortfolioAuctionAdmin } from '../../../../shared/types';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import SingleButtonModal from '../../../../shared/components/SingleButtonModal';
import { DateRangePickerRow, NumberInputRow, SelectRow } from '../../../../shared/components/form_inputs/common';
import moment from 'moment';

interface Props {
  open: boolean;
  onClose: () => void;
  auction: PortfolioAuctionAdmin;
}

export default function AuctionDetailsModal({ open, onClose, auction }: Props) {
  const { id, startDate, endDate, qaStartDate, qaEndDate, type, priceVisibility, minimumBid, minimumBidIncrease } =
    auction;

  const [editMode, setEditMode] = useState(false);

  const api = useAdminApi();

  const now = moment();
  const isAuctionOngoing = now.isBetween(startDate, endDate) && editMode;

  const initialValues = useMemo(() => {
    return {
      id,
      startDate,
      endDate,
      qaStartDate,
      qaEndDate,
      type,
      priceVisibility,
      minimumBid,
      minimumBidIncrease,
    };
  }, [endDate, id, minimumBid, minimumBidIncrease, priceVisibility, qaEndDate, qaStartDate, startDate, type]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object().shape({
    startDate: Yup.date().max(Yup.ref('endDate'), 'Start must be before end date').required(requiredMsg),
    endDate: Yup.date()
      .test('ongoingAuctionEndDate', 'Ongoing auctions cannot be edited to be in the past.', (value) => {
        if (isAuctionOngoing) {
          return moment(value).isAfter(now);
        }
        return true;
      })
      .min(Yup.ref('startDate'), 'End must be after start date')
      .required(requiredMsg),
    qaStartDate: Yup.date().max(Yup.ref('qaEndDate'), 'Start must be before end date').required(requiredMsg),
    qaEndDate: Yup.date()
      .min(Yup.ref('qaStartDate'), 'End must be after start date')
      .max(Yup.ref('endDate'), 'QA end must be before auction end date')
      .required(requiredMsg),
    type: Yup.string().required(requiredMsg),
    priceVisibility: Yup.string().required(requiredMsg),
    minimumBid: Yup.number().required(requiredMsg),
    minimumBidIncrease: Yup.number().required(requiredMsg),
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setEditMode(false);
    onClose();
  };

  const typeOptions = [
    { label: 'Non-binding', value: 'NON_BINDING' },
    { label: 'Binding', value: 'BINDING' },
  ];

  const visibilityOptions = [
    { label: 'Public', value: 'PUBLIC' },
    { label: 'Anonymous', value: 'ANONYMOUS' },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { setErrors }) => {
        return api.auctions
          .updateAuction(id, values)
          .then(async () => {
            await queryClient.invalidateQueries(['adminFetchPortfolioAuctions']);
            await queryClient.invalidateQueries(['adminFetchPortfolioDetails']);
            enqueueSnackbar('Auction details updated', { variant: 'success' });
            handleClose();
          })
          .catch((e) => {
            console.error(e);
            if (e.response) {
              if (e.response.data?.['ERROR_CODE'] === 'DATE_OVERLAP') {
                setErrors({
                  startDate: 'Selected dates overlap with another auction.',
                });
                return;
              }
              if (e.response.data?.['ERROR_CODE'] === 'INCORRECT_DATE') {
                setErrors({
                  startDate: e.response.data?.['message'],
                });
                return;
              }
            }
            enqueueSnackbar('Failed to update auction details', { variant: 'error' });
          });
      }}
    >
      {({ isSubmitting, isValid, submitForm, resetForm }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={() => {
              handleClose();
              resetForm();
            }}
            title="Auction details"
            content={
              <>
                <DateRangePickerRow
                  key="auctionDurationRange"
                  editMode={editMode}
                  fieldNameDateStart="startDate"
                  fieldNameDateEnd="endDate"
                  label="Auction duration"
                  variant="large"
                />
                <DateRangePickerRow
                  key="qaRange"
                  editMode={editMode}
                  fieldNameDateStart="qaStartDate"
                  fieldNameDateEnd="qaEndDate"
                  label="Q&A period"
                  variant="large"
                />
                <SelectRow
                  key="type"
                  editMode={editMode && !isAuctionOngoing}
                  fieldName="type"
                  label="Auction type"
                  options={typeOptions}
                  highlightText
                  variant="large"
                />
                <SelectRow
                  key="priceVisibility"
                  editMode={editMode && !isAuctionOngoing}
                  fieldName="priceVisibility"
                  label="Price visibility"
                  options={visibilityOptions}
                  highlightText
                  variant="large"
                />
                <NumberInputRow
                  key="minimumBid"
                  editMode={editMode && !isAuctionOngoing}
                  fieldName="minimumBid"
                  label="Minimum bid"
                  tooltip="As portion of outstanding balance, e.g. 0.05 is 5% of outstanding balance (rounded up to nearest 1000)."
                  variant="large"
                />
                <NumberInputRow
                  key="minimumBidIncrease"
                  editMode={editMode && !isAuctionOngoing}
                  fieldName="minimumBidIncrease"
                  label="Minimum bid increase"
                  tooltip="As portion of outstanding balance, e.g. 0.0001 is 0.01% of outstanding balance (rounded up to nearest 1000)."
                  variant="large"
                />
              </>
            }
            buttonLabel={!editMode ? 'Edit details' : 'Save details'}
            onClick={!editMode ? () => setEditMode(true) : submitForm}
            isSubmitting={isSubmitting}
            disabled={!editMode ? isSubmitting : isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
