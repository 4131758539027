import React from 'react';
import styles from './styles.module.scss';
import { FormHelperText, MenuItem, Tooltip, Typography } from '@mui/material';
import { TextField, Select, TextFieldProps } from 'formik-mui';
import { Field, useField, useFormikContext } from 'formik';
import countryList from 'country-list';
import _ from 'lodash';
import { countryCodeToName, formatDate } from '../../utils/helpers';
import { DatePicker } from 'formik-mui-x-date-pickers';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { useTheme } from '@mui/material/styles';
import MuiPhoneNumber from 'material-ui-phone-number';

interface FieldRowVariantProps {
  variant?: 'compact' | 'large';
}

interface RowProps extends FieldRowVariantProps {
  fieldName: string;
  label: string;
  editMode: boolean;
  type?: string;
  tooltip?: string;
}

interface FieldRowProps extends FieldRowVariantProps {
  children: React.ReactNode;
}

const FieldRow: React.FC<FieldRowProps> = ({ children, variant = 'compact' }) => {
  const className = [styles.row, variant === 'large' ? styles.rowLarge : ''].join(' ');

  return <div className={className}>{children}</div>;
};

export const TextInputRow: React.FC<RowProps> = ({
  fieldName,
  label,
  editMode,
  type = 'text',
  variant = 'compact',
}) => {
  const [{ value }] = useField(fieldName);

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <Field component={TextField} name={fieldName} type={type} variant="outlined" className={styles.formField} />
      ) : (
        <Typography className={styles.rowValue}>{value}</Typography>
      )}
    </FieldRow>
  );
};

export const PhoneNumberInputRow: React.FC<RowProps> = ({
  fieldName,
  label,
  editMode,
  type = 'text',
  variant = 'compact',
}) => {
  const [{ value }] = useField(fieldName);

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <Field
          component={PhoneNumberField}
          name={fieldName}
          type={type}
          variant="outlined"
          className={styles.formField}
        />
      ) : (
        <Typography className={styles.rowValue}>{value}</Typography>
      )}
    </FieldRow>
  );
};

export const NumberInputRow: React.FC<RowProps> = ({ fieldName, label, editMode, tooltip, variant = 'compact' }) => {
  const [{ value }] = useField(fieldName);

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpOutlineIcon fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      {editMode ? (
        <Field
          component={TextField}
          name={fieldName}
          variant="outlined"
          inputProps={{ inputMode: 'numeric', pattern: '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$' }}
          className={styles.formField}
        />
      ) : (
        <Typography className={styles.rowValue}>{value}</Typography>
      )}
    </FieldRow>
  );
};

interface SelectOption<T> {
  label: string;
  value: T;
}

interface SelectProps extends FieldRowVariantProps {
  fieldName: string;
  label: string;
  editMode: boolean;
  options: SelectOption<any>[];
  highlightText?: boolean;
}

export const SelectRow: React.FC<SelectProps> = ({
  fieldName,
  label,
  editMode,
  options,
  highlightText = false,
  variant = 'compact',
}) => {
  const [{ value }] = useField(fieldName);

  const getValueLabel = (value: any) => _.find(options, { value })?.label || '';

  const theme = useTheme();

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <div>
          <Field
            component={Select}
            name={fieldName}
            variant="outlined"
            className={styles.formField}
            sx={highlightText ? { color: theme.palette.accent1.main } : {}}
            SelectDisplayProps={{ className: styles.countrySelect }}
          >
            {options.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
        </div>
      ) : (
        <Typography className={styles.rowValue}>{getValueLabel(value)}</Typography>
      )}
    </FieldRow>
  );
};

interface CountryRowProps extends FieldRowVariantProps {
  fieldName: string;
  label: string;
  editMode: boolean;
  multiple?: boolean;
}

const countries = _.sortBy(countryList.getData(), 'name');

export const CountrySelectRow: React.FC<CountryRowProps> = ({
  fieldName,
  label,
  editMode,
  multiple = false,
  variant = 'compact',
}) => {
  const [{ value }, { error }] = useField(fieldName);

  const getFormattedValue = (value: string | string[] | undefined) => {
    if (Array.isArray(value)) {
      return value.map((code) => countryCodeToName(code)).join(', ');
    } else {
      return value ? countryCodeToName(value) : '';
    }
  };

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <div>
          <Field
            component={Select}
            multiple={multiple}
            name={fieldName}
            variant="outlined"
            className={styles.formField}
            SelectDisplayProps={{ className: styles.countrySelect }}
          >
            {countries.map(({ code, name }) => (
              <MenuItem key={code} value={code}>
                {name}
              </MenuItem>
            ))}
          </Field>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </div>
      ) : (
        <Typography className={styles.rowValue}>{getFormattedValue(value)}</Typography>
      )}
    </FieldRow>
  );
};

interface DatePickerProps extends RowProps {
  openTo?: 'day' | 'month' | 'year';
}

export const DatePickerRow: React.FC<DatePickerProps> = ({
  fieldName,
  label,
  editMode,
  openTo = 'year',
  variant = 'compact',
}) => {
  const [{ value }] = useField(fieldName);

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <Field
          component={DatePicker}
          name={fieldName}
          inputFormat="D.MM.yyyy"
          placeholder={label}
          inputVariant="outlined"
          openTo={openTo}
          views={['year', 'month', 'day']}
          autoOk
          className={styles.formField}
        />
      ) : (
        <Typography className={styles.rowValue}>{value && formatDate(value)}</Typography>
      )}
    </FieldRow>
  );
};

interface DateRangePickerProps extends FieldRowVariantProps {
  label: string;
  editMode: boolean;
  fieldNameDateStart: string;
  fieldNameDateEnd: string;
}

export const DateRangePickerRow: React.FC<DateRangePickerProps> = ({
  fieldNameDateStart,
  fieldNameDateEnd,
  label,
  editMode,
  variant = 'compact',
}) => {
  const [{ value: valueStart }, { error: errorStartDate, touched: touchedStartDate }] = useField(fieldNameDateStart);
  const [{ value: valueEnd }, { error: errorEndDate, touched: touchedEndDate }] = useField(fieldNameDateEnd);

  const hasError = Boolean((errorStartDate && touchedStartDate) || (errorEndDate && touchedEndDate));

  const { values, setValues, setTouched } = useFormikContext();

  return (
    <FieldRow variant={variant}>
      <Typography className={styles.rowLabel} variant="h3">
        {label}
      </Typography>
      {editMode ? (
        <div className={styles.rangePickerContainer} data-testid={fieldNameDateStart}>
          <DateRangePicker
            value={[valueStart, valueEnd]}
            dayPlaceholder="dd"
            monthPlaceholder="mm"
            yearPlaceholder="yyyy"
            className={[styles.rangePicker, hasError ? styles.rangePickerError : '']}
            calendarIcon={<InsertInvitationIcon color="action" />}
            format="dd.MM.yyyy"
            clearIcon={null}
            onChange={(value) => {
              // @ts-ignore
              const [startDate, endDate] = value;
              setTouched(
                {
                  [fieldNameDateStart]: true,
                  [fieldNameDateEnd]: true,
                },
                false,
              );
              setValues({
                // @ts-ignore
                ...values,
                [fieldNameDateStart]: startDate,
                [fieldNameDateEnd]: endDate,
              });
            }}
          />
          {Boolean(errorStartDate && touchedStartDate) && <Typography>{errorStartDate}</Typography>}
          {Boolean(errorEndDate && touchedEndDate) && <Typography>{errorEndDate}</Typography>}
        </div>
      ) : (
        <Typography className={styles.rowValue}>
          {formatDate(valueStart)} - {formatDate(valueEnd)}
        </Typography>
      )}
    </FieldRow>
  );
};

export const PhoneNumberField = (props: TextFieldProps) => {
  const hasError = Boolean(props.form.touched[props.field.name] && props.form.errors[props.field.name]);

  return (
    <MuiPhoneNumber
      error={hasError}
      helperText={hasError ? (props.form.errors[props.field.name] as string) : ''}
      defaultCountry={'de'}
      {...props.field}
      // @ts-ignore
      onChange={(phone) => {
        props.form.setFieldValue(props.field.name, phone);
      }}
      {...props}
    />
  );
};
