import React, { useState } from 'react';
import SingleButtonModal from '../../shared/components/SingleButtonModal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import styles from '../styles.module.scss';
import { IconButton, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { BuyerGroup } from '../../shared/types';
import useAdminApi from '../../shared/hooks/useAdminApi';
import EditIcon from '../../shared/components/icons/EditIcon';
import SaveChangesIcon from '../../shared/components/icons/SaveChangesIcon';
import CancelIcon from '../../shared/components/icons/CancelIcon';
import DeleteGroupIcon from '../../shared/components/icons/DeleteGroupIcon';
import { useSnackbar } from 'notistack';
import { useConfirmationDialogStore } from '../../shared/components/ConfirmationModal';
import CustomTableBody from '../../shared/components/table/CustomTableBody';

interface RowProps {
  group: BuyerGroup;
}

function GroupsTableRow({ group }: RowProps) {
  const { id, name, buyersCount } = group;

  const [editMode, setEditMode] = useState(false);
  const [nameInputValue, setNameInputValue] = React.useState(name);

  const api = useAdminApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async () => {
    api.users.buyers
      .removeBuyerGroup(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyerGroups']);
        await queryClient.invalidateQueries(['adminFetchBuyers']);
        enqueueSnackbar('Buyer group removed', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to remove buyer group', { variant: 'error' });
      });
  };

  const handleUpdate = async () => {
    api.users.buyers
      .updateBuyerGroup(id, { id, name: nameInputValue })
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchBuyerGroups']);
        await queryClient.invalidateQueries(['adminFetchBuyers']);
        enqueueSnackbar('Buyer group updated', { variant: 'success' });
        setEditMode(false);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to update buyer group', { variant: 'error' });
      });
  };

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  return (
    <TableRow key={id}>
      <TableCell height="32px" width="50%">
        {editMode ? (
          <TextField
            fullWidth
            className={styles.groupNameInput}
            variant="outlined"
            value={nameInputValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNameInputValue(event.target.value);
            }}
          />
        ) : (
          name
        )}
      </TableCell>
      <TableCell>{buyersCount}</TableCell>
      <TableCell align="right" width="20%">
        {editMode ? (
          <>
            <IconButton size="small" onClick={handleUpdate}>
              <SaveChangesIcon />
            </IconButton>
            <IconButton size="small" onClick={() => setEditMode(false)}>
              <CancelIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton size="small" onClick={() => setEditMode(true)}>
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={() =>
                confirmAction('Remove group', `Are you sure you want to remove buyer group ${name}?`, handleDelete)
              }
            >
              <DeleteGroupIcon />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function BuyerGroupManagerModal({ open, onClose }: Props) {
  const api = useAdminApi();
  const { data } = useQuery(['adminFetchBuyerGroups'], () => api.users.buyers.fetchBuyerGroups());
  const groups = data || [];

  return (
    <SingleButtonModal
      open={open}
      onClose={onClose}
      title="Group Management"
      content={
        <TableContainer className={styles.groupTableContainer}>
          <Table stickyHeader size="small" className={styles.tabContent__table}>
            <TableHead>
              <TableRow>
                <TableCell>Group Name</TableCell>
                <TableCell>Buyers</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <CustomTableBody data={groups} renderRow={(group) => <GroupsTableRow group={group} />} />
          </Table>
        </TableContainer>
      }
      buttonLabel="Add new group"
      showButton={false}
      onClick={() => {}}
    />
  );
}
