import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LoanScreeningRow } from '../shared/types';

interface Props {
  data?: LoanScreeningRow[];
  currency?: string;
}

export default function LoanScreeningTable({ data = [], currency = 'EUR' }: Props) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Key figures</TableCell>
            <TableCell align="right">{`Balance (${currency} m)`}</TableCell>
            <TableCell align="right">No. of loans</TableCell>
            <TableCell align="right">% balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((row) => row.numberOfLoans > 0)
            .map((row) => (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row" align="left">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.balance.toFixed(1)}</TableCell>
                <TableCell align="right">{row.numberOfLoans}</TableCell>
                <TableCell align="right">{row.percentBalance.toFixed(1)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
