import MessageCenterPage from '.';
import MessageCenterAdminPage from '../Admin/MessageCenterPage';
import useUser from '../shared/hooks/useUser';

export default function MessageCenter() {
  const { user } = useUser();
  const isAdmin = user?.isAdmin;

  return isAdmin ? <MessageCenterAdminPage /> : <MessageCenterPage />;
}
