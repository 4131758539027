import React, { useState } from 'react';
import { Box, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import ListPagination from '../../shared/components/ListPagination';
import styles from '../styles.module.scss';
import { formatCurrency, formatDate } from '../../shared/utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import useAdminApi from '../../shared/hooks/useAdminApi';
import PortfolioSoldStatusSwitch from './PortfolioSoldStatusSwitch';
import CustomTableBody from '../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../shared/components/table/TableButton';
import { PortfolioAuction } from '../../shared/types';
import moment from 'moment/moment';

interface Props {
  searchTerm: string;
}

export default function PortfolioTable({ searchTerm }: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();
  const api = useAdminApi();
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['adminFetchPortfolios', page, pageSize, searchTerm],
    () => api.portfolios.fetchPortfolios(page, pageSize, searchTerm),
    {
      keepPreviousData: true,
    },
  );

  const portfolios = data?.results || [];
  const portfolioCount = data?.count || 0;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const theme = useTheme();

  const renderAuction = (auction?: PortfolioAuction) => {
    if (!auction) {
      return '';
    }

    const { startDate, endDate } = auction;
    const now = moment();
    const isAuctionOngoing = now.isBetween(startDate, endDate);

    return (
      <Box component="span" sx={isAuctionOngoing ? { color: theme.palette.primary.main } : {}}>
        {`${formatDate(startDate)} - ${formatDate(endDate)}`}
      </Box>
    );
  };

  const renderHighestBid = (auction?: PortfolioAuction) => {
    if (!auction?.highestBid) {
      return '';
    }

    const { highestBid, currency } = auction;
    return <Box component="span">{formatCurrency(highestBid, currency)}</Box>;
  };

  return (
    <TableContainer className={styles.tabContent__tableContainer}>
      <Table size="small" className={styles.tabContent__table}>
        <TableHead>
          <TableRow>
            <TableCell>Identifier</TableCell>
            <TableCell>Seller</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell>Latest snapshot</TableCell>
            <TableCell>Next auction</TableCell>
            <TableCell>Highest bid</TableCell>
            <TableCell>Winning bid selected</TableCell>
            <TableCell align="right" />
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <CustomTableBody
          data={portfolios}
          isLoading={isLoading || (isFetching && isPreviousData)}
          renderRow={({ id, identifier, sellerName, user, latestSnapshot, isSold, hasWinningBid, auction }) => (
            <TableRow key={id}>
              <TableCell>
                <Link to={id.toString()} className={styles.portfolioLink}>
                  {identifier}
                </Link>
              </TableCell>
              <TableCell>{sellerName}</TableCell>
              <TableCell>{user}</TableCell>
              <TableCell>{latestSnapshot}</TableCell>
              <TableCell>{renderAuction(auction)}</TableCell>
              <TableCell>{renderHighestBid(auction)}</TableCell>
              <TableCell align="center">
                {hasWinningBid ? (
                  <Box component="span" sx={{ color: theme.palette.accent1.main }}>
                    Yes
                  </Box>
                ) : (
                  <Box component="span" sx={{ color: theme.palette.accent1.main }}>
                    No
                  </Box>
                )}
              </TableCell>
              <TableCell align="right">
                <PortfolioSoldStatusSwitch portfolioId={id} isSold={isSold} />
              </TableCell>
              <TableCell align="right">
                <TableButton testId={`details-${identifier}`} onClick={() => navigate(`/portfolio_manager/${id}`)}>
                  Details
                </TableButton>
              </TableCell>
            </TableRow>
          )}
        />
      </Table>
      <ListPagination
        count={Math.ceil(portfolioCount / pageSize)}
        page={page}
        pageSize={pageSize}
        onChange={handlePageChange}
        onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
      />
    </TableContainer>
  );
}
