import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import Messages from '../../../../shared/components/chat/Messages';
import useApi from '../../../../shared/hooks/useApi';
import { PortfolioChatMessageTypeEnum } from '../../../../shared/types';

interface Props {
  portfolioId: number;
  readOnly?: boolean;
}

const pageSize = 20;

export default function MessagesDataRoom({ portfolioId, readOnly = false }: Props) {
  const api = useApi();
  const queryClient = useQueryClient();

  const [calledCount, setCalledCount] = useState(0);
  const [scrollToBottomOnInitialLoad, setScrollToBottomOnInitialLoad] = useState(false);

  const postMessage = async (form: FormData) => {
    await api.portfolio_snapshot.portfolioPostChatMessage(portfolioId, form);
    await queryClient.invalidateQueries(['fetchPortfolioChatMessages', portfolioId]);
  };

  const infiniteQuery = useInfiniteQuery(
    ['fetchPortfolioChatMessages', portfolioId],
    ({ pageParam = 1 }) => {
      return api.portfolio_snapshot.portfolioChatMessages(portfolioId, pageParam, pageSize);
    },
    {
      keepPreviousData: true,
      refetchInterval: 10000,
      getNextPageParam: (lastPage, pages) => {
        const currentPage = pages.length;
        const nextPageAvailavble = Boolean(lastPage?.next);
        if (nextPageAvailavble) {
          return currentPage + 1;
        }
      },
      onSuccess: () => {
        if (calledCount === 0) {
          setScrollToBottomOnInitialLoad(true);
          setCalledCount(calledCount + 1);
          queryClient.invalidateQueries(['fetchDataRoomPortfolioUnreadMessageCount', portfolioId]);
        }
      },
    },
  );

  const shouldDisplayForm = !infiniteQuery.isInitialLoading;

  return (
    <Messages
      infiniteQuery={infiniteQuery}
      shouldDisplayForm={shouldDisplayForm}
      scrollToBottomOnInitialLoad={scrollToBottomOnInitialLoad}
      setScrollToBottomOnInitialLoad={setScrollToBottomOnInitialLoad}
      portfolioId={portfolioId}
      readOnly={readOnly}
      postMessage={postMessage}
      setInitialHeight={infiniteQuery.isInitialLoading}
      highlightedMessageType={PortfolioChatMessageTypeEnum.SELLER}
      downloadAttachment={api.portfolio_snapshot.downloadPortfolioChatAttachment(portfolioId)}
    />
  );
}
