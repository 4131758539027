import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, CircularProgress, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import { FormProps } from '../AuthLayout';
import styles from '../auth.module.scss';
import useApi from '../../shared/hooks/useApi';

export default function ResetConfirmForm({ setSuccess, setError }: FormProps) {
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const navigate = useNavigate();

  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    newPassword: Yup.string().required(requiredMsg),
    reNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords are not the same.')
      .required(requiredMsg),
  });

  const { client } = useApi();

  return (
    <Formik
      initialValues={{
        newPassword: '',
        reNewPassword: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setError(false);
        try {
          await client.post('/auth/users/reset_password_confirm/', { uid, token, ...values });
          setSuccess(true);
          navigate('/login');
        } catch (e) {
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Field component={TextField} type="password" label="New Password" name="newPassword" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field component={TextField} type="password" label="Repeat New Password" name="reNewPassword" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" size="large" variant="outlined" fullWidth endIcon={!isSubmitting && <ArrowRight />}>
                {isSubmitting ? <CircularProgress color="primary" size={24} /> : 'Set new password'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
