import React from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { getPortfolioTitle } from '../../utils';
import { PortfolioAuction, PortfolioSnapshot } from '../../../shared/types';
import { formatDate } from '../../../shared/utils/helpers';
import LabeledValue from '../../../shared/components/LabeledValue';
import styles from './summary.module.scss';
import BiddingSection from './BiddingSection';
import useUser from '../../../shared/hooks/useUser';
import { useTheme } from '@mui/material/styles';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function Header({ portfolio }: Props) {
  const { identifier, auction, seller, servicer } = portfolio;
  const { user } = useUser();

  const theme = useTheme();

  const renderAuctionDates = (portfolioAuction: PortfolioAuction) => {
    const now = moment();
    if (now.isBetween(portfolioAuction.startDate, portfolioAuction.endDate)) {
      return (
        <Grid item xs={4}>
          <LabeledValue
            variant="vertical"
            label="Auction Ends"
            value={
              <Tooltip title={formatDate(portfolioAuction.endDate)}>
                <span>{now.to(portfolioAuction.endDate)}</span>
              </Tooltip>
            }
            labelSize="15px"
            valueSize="15px"
            valueColor={theme.palette.primary.main}
          />
        </Grid>
      );
    }
    return (
      <>
        <Grid item xs={4}>
          <LabeledValue
            variant="vertical"
            label="Auction Start"
            value={formatDate(portfolioAuction.startDate)}
            labelSize="15px"
            valueSize="15px"
            valueColor={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={4}>
          <LabeledValue
            variant="vertical"
            label="Auction End"
            value={formatDate(portfolioAuction.endDate)}
            labelSize="15px"
            valueSize="15px"
            valueColor={theme.palette.primary.main}
          />
        </Grid>
      </>
    );
  };

  return (
    <div className={styles.section}>
      <Typography variant="h2">{getPortfolioTitle(portfolio)}</Typography>
      <Grid container>
        <Grid item xs={4}>
          <LabeledValue variant="vertical" label="Portfolio ID" value={identifier} labelSize="15px" valueSize="15px" />
        </Grid>
        {auction && renderAuctionDates(auction)}
        <Grid
          item
          xs={12}
          marginTop="20px"
          paddingTop="10px"
          paddingBottom="20px"
          borderTop={1}
          borderBottom={1}
          borderColor="#F1F1F1"
        >
          <LabeledValue variant="horizontal" label="Seller" value={seller} labelSize="15px" valueSize="15px" />
          <LabeledValue variant="horizontal" label="Servicer" value={servicer} labelSize="15px" valueSize="15px" />
        </Grid>
        {!user?.isAdmin && <BiddingSection portfolio={portfolio} />}
      </Grid>
    </div>
  );
}
