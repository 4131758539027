import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import styles from '../../../styles.module.scss';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import ListPagination from '../../../../shared/components/ListPagination';
import { useSnackbar } from 'notistack';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Filters from './Filters';
import { Portfolio } from '../../../../shared/types';
import { useConfirmationDialogStore } from '../../../../shared/components/ConfirmationModal';
import CustomTableBody from '../../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../../../shared/components/table/TableButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  portfolio: Portfolio;
  defaultFilter?: string;
}

export default function AccessManagement({ portfolio, defaultFilter }: Props) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string | undefined>(defaultFilter);
  const [searchTerm, setSearchTerm] = useState('');

  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  const { id: portfolioId } = portfolio;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const api = useAdminApi();
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['adminFetchPortfolioBuyers', portfolioId, page, pageSize, searchTerm, filterValue],
    () => api.portfolios.fetchPortfolioBuyers(portfolioId, page, pageSize, searchTerm, filterValue),
  );

  const buyers = data?.results || [];
  const buyerCount = data?.count || 0;
  const numSelected = selected.length;
  const rowCount = buyers.length;

  const handleClick = (event: React.MouseEvent<unknown>, buyerId: string) => {
    const selectedIndex = selected.indexOf(buyerId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, buyerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = buyers.map(({ id }) => id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleGrantAccess = async (buyerId: string) => {
    api.portfolios
      .grantAccess(portfolioId, [buyerId])
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
        enqueueSnackbar('Portfolio access granted', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to grant portfolio access', { variant: 'error' });
      });
  };

  const handleRevokeAccess = async (buyerId: string) => {
    api.portfolios
      .revokeAccess(portfolioId, [buyerId])
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
        enqueueSnackbar('Portfolio access revoked', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to revoke portfolio access', { variant: 'error' });
      });
  };

  const handleBulkGrantAccess = async () => {
    api.portfolios
      .grantAccess(portfolioId, selected)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
        enqueueSnackbar('Portfolio access granted', { variant: 'success' });
        setSelected([]);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to grant portfolio access', { variant: 'error' });
      });
  };

  const handleBulkRevokeAccess = async () => {
    api.portfolios
      .revokeAccess(portfolioId, selected)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioBuyers']);
        await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
        enqueueSnackbar('Portfolio access revoked', { variant: 'success' });
        setSelected([]);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to revoke portfolio access', { variant: 'error' });
      });
  };

  const handleCancelSelection = () => {
    setSelected([]);
  };

  const isSelected = (buyerId: string) => selected.indexOf(buyerId) !== -1;
  const filtersShown = numSelected === 0;

  const theme = useTheme();

  return (
    <Paper variant="outlined">
      <div className={styles.tabContent__header}>
        <Typography variant="h2">Access management</Typography>
        {filtersShown ? (
          <Filters
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            disabledShowType={filterValue === '' && buyers.length === 0}
          />
        ) : (
          <Box display="flex" gap="10px" justifyContent="flex-end">
            <Button
              className={styles.tabContent__actionBtn}
              variant="contained"
              color="primary"
              onClick={() =>
                confirmAction(
                  'Grant access',
                  'Please confirm that you wish to grant access to selected buyers.',
                  handleBulkGrantAccess,
                )
              }
              disabled={numSelected === 0}
            >
              Grant access
            </Button>
            <Button
              className={styles.tabContent__actionBtn}
              variant="outlined"
              color="grey"
              onClick={() =>
                confirmAction(
                  'Revoke access',
                  'Please confirm that you wish to revoke access from selected buyers.',
                  handleBulkRevokeAccess,
                )
              }
              disabled={numSelected === 0}
            >
              Revoke access
            </Button>
            <Button
              className={styles.tabContent__actionBtn}
              variant="outlined"
              color="grey"
              onClick={handleCancelSelection}
              disabled={numSelected === 0}
            >
              Cancel
            </Button>
          </Box>
        )}
      </div>

      <TableContainer className={styles.tabContent__tableContainer}>
        <Table size="small" className={styles.tabContent__table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>Buyer</TableCell>
              <TableCell>KYC Status</TableCell>
              <TableCell>NDA Accepted</TableCell>
              <TableCell>Access</TableCell>
              <TableCell>Groups</TableCell>
              <TableCell align="right" width="118px" />
            </TableRow>
          </TableHead>
          <CustomTableBody
            data={buyers}
            isLoading={isLoading || (isFetching && isPreviousData)}
            renderRow={({ id, companyName, kycConfirmed, ndaAccepted, hasAccess, hasUsers, buyerGroups }) => {
              const isRowSelected = isSelected(id);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  key={id}
                  onClick={(event) => handleClick(event, id)}
                  tabIndex={-1}
                  selected={isRowSelected}
                  data-testid={`row-${companyName}`}
                >
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={isRowSelected} />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" gap="10px">
                      {companyName}
                      {!hasUsers && (
                        <Tooltip title={'Buyer has no users'}>
                          <WarningAmberOutlinedIcon color="warning" fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: kycConfirmed ? theme.palette.accent1.main : theme.palette.accent2.main }}>
                    {kycConfirmed ? 'Confirmed' : 'Unconfirmed'}
                  </TableCell>
                  <TableCell sx={{ color: ndaAccepted ? theme.palette.accent1.main : theme.palette.accent2.main }}>
                    {ndaAccepted ? 'Accepted' : 'Not Accepted'}
                  </TableCell>
                  <TableCell sx={{ color: hasAccess ? theme.palette.accent1.main : theme.palette.accent2.main }}>
                    {hasAccess ? 'Invited' : 'Not invited'}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap="5px" flexWrap="wrap">
                      {buyerGroups.map((group) => (
                        <Chip size="small" label={group.name} />
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <TableButton
                      fullWidth
                      testId={`access-${companyName}`}
                      startIcon={hasAccess ? <CloseIcon /> : <CheckIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        hasAccess
                          ? confirmAction(
                              'Revoke portfolio access',
                              'Please confirm that you wish to revoke access from the selected buyer.',
                              () => handleRevokeAccess(id),
                            )
                          : confirmAction(
                              'Grant portfolio access',
                              'Please confirm that you wish to grant access to the selected buyer.',
                              () => handleGrantAccess(id),
                            );
                      }}
                    >{`${hasAccess ? 'Revoke' : 'Grant'} access`}</TableButton>
                  </TableCell>
                </TableRow>
              );
            }}
          />
        </Table>
        <ListPagination
          count={Math.ceil(buyerCount / pageSize)}
          page={page}
          pageSize={pageSize}
          onChange={handlePageChange}
          onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
        />
      </TableContainer>
    </Paper>
  );
}
