import {
  AdminUser,
  AuctionBidsResponse,
  AuditLogEvent,
  BaseBuyerData,
  Buyer,
  BuyerGroup,
  BuyerOption,
  BuyerUser,
  CompanyKycData,
  GeneralChatConversationList,
  ListResponse,
  NewAdminUserData,
  NewBuyerData,
  NewBuyerUserData,
  NewPortfolioAuction,
  Portfolio,
  PortfolioAnnouncement,
  PortfolioAuctionAdmin,
  PortfolioBuyer,
  PortfolioChatConversationList,
  PortfolioChatMessage,
  PortfolioImport,
  PortfolioImportStatus,
  PortfolioSnapshotAdmin,
  PortfolioSnapshotBase,
  PortfolioSnapshotStatus,
  RegisteredUser,
  UpdateAdminUserData,
  UpdateBuyerData,
  UpdateBuyerGroup,
  UpdateBuyerUserData,
  UpdatePortfolioAuction,
} from '../types';
import useClient from './useClient';
import { Moment } from 'moment';
import { saveAs } from 'file-saver';

export default function useAdminApi() {
  const client = useClient();

  // Portfolio
  const importPortfolioFile = async (form: FormData) =>
    client.post('admin/portfolios/import_file/', form).then((response) => response.data);
  const fetchPortfolios = async (page = 1, pageSize = 3, searchTerm: string | undefined = undefined) => {
    let url = `admin/portfolios/?page_size=${pageSize}&page=${page}`;

    if (searchTerm) {
      url = url + `&search=${searchTerm}`;
    }

    return client.get(url).then((response) => response.data as ListResponse<Portfolio>);
  };
  const fetchPortfolioDetails = async (portfolioId: number) =>
    client.get(`admin/portfolios/${portfolioId}/`).then((response) => response.data as Portfolio);
  const fetchPortfolioSnapshots = async (portfolioId: number) =>
    client
      .get(`admin/portfolios/${portfolioId}/snapshots/`)
      .then((response) => response.data as PortfolioSnapshotBase[]);
  const uploadNda = async (portfolioId: number, form: FormData) =>
    client.post(`admin/portfolios/${portfolioId}/nda/`, form).then((response) => response.data);
  const fetchPortfolioBuyers = async (
    portfolioId: number,
    page = 1,
    pageSize = 3,
    searchTerm: string | undefined = undefined,
    filterValue: string | undefined = undefined,
  ) => {
    let url = `admin/portfolios/${portfolioId}/buyers/?page_size=${pageSize}&page=${page}`;

    if (searchTerm) {
      url = url + `&name=${searchTerm}`;
    }

    if (filterValue) {
      url = url + `&filter=${filterValue}`;
    }

    return client.get(url).then((response) => response.data as ListResponse<PortfolioBuyer>);
  };
  const grantAccess = async (portfolioId: number, buyerIds: string[]) =>
    client
      .post(`admin/portfolios/${portfolioId}/grant_access/`, { buyers: buyerIds })
      .then((response) => response.data as Portfolio);
  const revokeAccess = async (portfolioId: number, buyerIds: string[]) =>
    client
      .post(`admin/portfolios/${portfolioId}/revoke_access/`, { buyers: buyerIds })
      .then((response) => response.data as Portfolio);
  const publishLatestDraft = async (portfolioId: number) =>
    client.put(`admin/portfolios/${portfolioId}/publish/`).then((response) => response.data);
  const deletePortfolio = async (portfolioId: number) =>
    client.post(`admin/portfolios/${portfolioId}/remove/`).then((response) => response.data);
  const markSoldPortfolio = async (portfolioId: number) =>
    client.post(`admin/portfolios/${portfolioId}/mark_sold/`).then((response) => response.data);
  const unmarkSoldPortfolio = async (portfolioId: number) =>
    client.post(`admin/portfolios/${portfolioId}/unmark_sold/`).then((response) => response.data);
  const fetchChatConversationList = async (portfolioId: number) =>
    client
      .get(`admin/portfolios/${portfolioId}/chat_conversation_list/`)
      .then((response) => response.data as PortfolioChatConversationList[]);
  const fetchChatMessages = async (portfolioId: number, conversationId: number, page = 1, pageSize = 20) =>
    client
      .get(`admin/portfolios/${portfolioId}/chat_messages?conversation_id=${conversationId}`, {
        params: { page, page_size: pageSize },
      })
      .then((response) => response.data as ListResponse<PortfolioChatMessage>);
  const postChatMessage = async (portfolioId: number, conversationId: number, data: FormData) =>
    client
      .post(`admin/portfolios/${portfolioId}/post_message/?conversation_id=${conversationId}`, data)
      .then((res) => res.data as string);
  const fetchAnnouncements = async (portfolioId: number) =>
    client
      .get(`admin/portfolios/${portfolioId}/announcements/`)
      .then((response) => response.data as PortfolioAnnouncement[]);
  const postAnnouncement = async (portfolioId: number, data: FormData) =>
    client.post(`admin/portfolios/${portfolioId}/announcements/`, data).then((res) => res.data as string);
  const deleteAnnouncement = async (announcementId: number) =>
    client.delete(`admin/announcements/${announcementId}/`).then((res) => res.data as string);
  const updateAnnouncement = async (announcementId: number, data: FormData) =>
    client.put(`admin/announcements/${announcementId}/`, data).then((res) => res.data as string);

  // Auctions
  const fetchPortfolioAuctions = async (portfolioId: number) =>
    client
      .get(`admin/portfolios/${portfolioId}/auctions/`)
      .then((response) => response.data as PortfolioAuctionAdmin[]);
  const createPortfolioAuction = async (portfolioId: number, data: NewPortfolioAuction) =>
    client.post(`admin/portfolios/${portfolioId}/add_auction/`, data).then((response) => response.data);
  const updateAuction = async (auctionId: number, data: UpdatePortfolioAuction) =>
    client.put(`admin/auctions/${auctionId}/`, data).then((response) => response.data);
  const removeAuction = async (auctionId: number) =>
    client.delete(`admin/auctions/${auctionId}/`).then((response) => response.data);
  const publishAuction = async (auctionId: number) =>
    client.post(`admin/auctions/${auctionId}/publish/`).then((response) => response.data);
  const fetchAuctionBids = async (auctionId: number) =>
    client.get(`admin/auctions/${auctionId}/bids/`).then((response) => response.data as AuctionBidsResponse);
  const confirmAccess = async (auctionId: number, buyerIds: string[]) =>
    client
      .post(`admin/auctions/${auctionId}/confirm_access/`, { buyers: buyerIds })
      .then((response) => response.data as Portfolio);
  const selectWinningBid = async (auctionId: number, bidId: number) =>
    client
      .post(`admin/auctions/${auctionId}/select_winning_bid/`, { winning_bid: bidId })
      .then((response) => response.data);
  const rejectAllBids = async (auctionId: number) =>
    client.post(`admin/auctions/${auctionId}/reject_all_bids/`).then((response) => response.data);

  // Portfolio Snapshot
  const fetchSnapshotDetails = async (snapshotId: number) =>
    client.get(`admin/portfolio_snapshots/${snapshotId}/`).then((response) => response.data as PortfolioSnapshotAdmin);
  const updatePortfolioSnapshotStatus = async (portfolioSnapshotId: number, status: PortfolioSnapshotStatus) =>
    client
      .put(`admin/portfolio_snapshots/${portfolioSnapshotId}/update_status/`, { status })
      .then((response) => response.data);
  const uploadTermSheet = async (snapshotId: number, form: FormData) =>
    client
      .post(`admin/portfolio_snapshots/${snapshotId}/term_sheet/`, form)
      .then((response) => response.data as number);
  const deleteTermSheet = async (snapshotId: number) =>
    client.delete(`admin/portfolio_snapshots/${snapshotId}/term_sheet/`).then((response) => response.data as number);
  const uploadSnapshotDocument = async (snapshotId: number, form: FormData) =>
    client.post(`admin/portfolio_snapshots/${snapshotId}/documents/`, form).then((response) => response.data as number);
  const uploadLoanDocuments = async (snapshotId: number, form: FormData) =>
    client
      .post(`admin/portfolio_snapshots/${snapshotId}/loan_documents/`, form)
      .then((response) => response.data as number);
  const downloadLoanDocument = async (loanDocumentId: number, fileName: string) => {
    const response = await client.get(
      `admin/portfolio_snapshots/download_loan_document/?loan_document_id=${loanDocumentId}`,
      {
        responseType: 'blob',
      },
    );

    saveAs(response.data, fileName);
  };
  const downloadTermSheet = async (snapshotId: number, fileName: string) => {
    const response = await client.get(`admin/portfolio_snapshots/${snapshotId}/download_term_sheet/`, {
      responseType: 'blob',
    });

    saveAs(response.data, fileName);
  };
  const downloadDocument = async (documentId: number, fileName: string) => {
    const response = await client.get(`admin/portfolio_snapshots/download_document/?document_id=${documentId}`, {
      responseType: 'blob',
    });

    saveAs(response.data, fileName);
  };

  const editSnapshotDocument = async (documentId: number, form: FormData) =>
    client.put(`admin/portfolio_documents/${documentId}/`, form).then((response) => response.data);
  const deleteSnapshotDocument = async (documentId: number) =>
    client.delete(`admin/portfolio_documents/${documentId}/`).then((response) => response.data);
  const publishSnapshot = async (snapshotId: number) =>
    client.put(`admin/portfolio_snapshots/${snapshotId}/publish/`).then((response) => response.data);

  // Portfolio Imports
  const fetchPortfolioImports = async (
    page = 1,
    pageSize = 3,
    searchTerm: string | undefined = undefined,
    statusFilter: PortfolioImportStatus | undefined = undefined,
  ) => {
    let url = `admin/portfolio_imports/?page_size=${pageSize}&page=${page}`;

    if (searchTerm) {
      url = url + `&search=${searchTerm}`;
    }

    if (statusFilter) {
      url = url + `&status=${statusFilter}`;
    }

    return client.get(url).then((response) => response.data as ListResponse<PortfolioImport>);
  };
  const rerunImport = async (portfolioImportId: number, overwriteData = false) =>
    client
      .post(`admin/portfolio_imports/${portfolioImportId}/rerun/`, { overwriteData })
      .then((response) => response.data);
  const downloadImport = async (portfolioImportId: number, fileName: string) => {
    const response = await client.get(`admin/portfolio_imports/${portfolioImportId}/download_import/`, {
      responseType: 'blob',
    });

    saveAs(response.data, fileName);
  };

  const fetchImportErrors = async (portfolioImportId: number) =>
    client.get(`admin/portfolio_imports/${portfolioImportId}/errors/`).then((response) => response.data as string[]);

  const runLoanDocumentsImport = async (loanDocumentsImportId: number) =>
    client.post(`admin/loan_documents/${loanDocumentsImportId}/run_import/`).then((response) => response.data);

  // Admin Users
  const fetchAdminUsers = async (page = 1, pageSize = 10) =>
    client
      .get(`admin/admins/?page_size=${pageSize}&page=${page}`)
      .then((response) => response.data as ListResponse<AdminUser>);
  const resetAdmin2fa = async (adminId: number) =>
    client.post(`admin/admins/${adminId}/reset_2fa/`).then((response) => response.data);
  const toggleAdminActive = async (adminId: number) =>
    client.put(`admin/admins/${adminId}/toggle_active/`).then((response) => response.data);
  const resetAdminPassword = async (adminId: number) =>
    client.post(`admin/admins/${adminId}/reset_password/`).then((response) => response.data);
  const updateAdminUser = async (adminId: number, data: UpdateAdminUserData) =>
    client.put(`admin/admins/${adminId}/`, data).then((response) => response.data);
  const createAdminUser = async (data: NewAdminUserData) =>
    client.post(`admin/admins/`, data).then((response) => response.data);
  const resendAdminPasswordSetup = async (adminId: number) =>
    client.post(`admin/admins/${adminId}/resend_password_setup/`).then((response) => response.data);

  // Buyers
  const fetchBuyers = async (page = 1, pageSize = 10) =>
    client
      .get(`admin/buyers/?page_size=${pageSize}&page=${page}`)
      .then((response) => response.data as ListResponse<Buyer>);
  const fetchAllBuyers = async () =>
    client.get(`admin/buyers/fetch_all`).then((response) => response.data as BuyerOption[]);
  const toggleBuyerActive = async (buyerId: string) =>
    client.put(`admin/buyers/${buyerId}/toggle_active/`).then((response) => response.data);
  const updateBuyer = async (buyerId: string, data: UpdateBuyerData) =>
    client.put(`admin/buyers/${buyerId}/`, data).then((response) => response.data);
  const createBuyer = async (data: NewBuyerData) =>
    client.post(`admin/buyers/`, data).then((response) => response.data);
  const fetchCompanyKycData = async (buyerId: string) =>
    client.get(`admin/buyers/${buyerId}/company_kyc_data`).then((response) => response.data as CompanyKycData);
  const approveKycStatus = async (buyerId: string, kycId: number) =>
    client.put(`admin/buyers/${buyerId}/approve_kyc/${kycId}/`).then((response) => response.data);
  const toggleBuyerKycStatus = async (buyerId: string) =>
    client.put(`admin/buyers/${buyerId}/toggle_kyc/`).then((response) => response.data);
  const fetchUsers = async (buyerId: string, page = 1, pageSize = 10) =>
    client
      .get(`admin/users/?buyer=${buyerId}&page_size=${pageSize}&page=${page}`)
      .then((response) => response.data as ListResponse<BuyerUser>);
  const addUser = async (buyerId: string, data: NewBuyerUserData) =>
    client.post(`admin/buyers/${buyerId}/add_user/`, data).then((response) => response.data);
  const fetchBuyerGroups = async () =>
    client.get('admin/buyer_groups/').then((response) => response.data as BuyerGroup[]);
  const setBuyerGroups = async (buyerId: string, data: BuyerGroup[]) =>
    client.put(`admin/buyers/${buyerId}/set_groups/`, data).then((response) => response.data);
  const updateBuyerGroup = async (groupId: number, data: UpdateBuyerGroup) =>
    client.put(`admin/buyer_groups/${groupId}/`, data).then((response) => response.data);
  const removeBuyerGroup = async (groupId: number) =>
    client.delete(`admin/buyer_groups/${groupId}/`).then((response) => response.data);

  // Buyer Users
  const resetUser2fa = async (userId: number) =>
    client.post(`admin/users/${userId}/reset_2fa/`).then((response) => response.data);
  const toggleUserActive = async (userId: number) =>
    client.put(`admin/users/${userId}/toggle_active/`).then((response) => response.data);
  const resetUserPassword = async (userId: number) =>
    client.post(`admin/users/${userId}/reset_password/`).then((response) => response.data);
  const updateUser = async (userId: number, data: UpdateBuyerUserData) =>
    client.put(`admin/users/${userId}/`, data).then((response) => response.data);
  const resendPasswordSetup = async (userId: number) =>
    client.post(`admin/users/${userId}/resend_password_setup/`).then((response) => response.data);

  // Messsage Center
  const fetchGeneralChatCompanyList = async () =>
    client
      .get(`admin/message_center/chat_company_list/`)
      .then((response) => response.data as PortfolioChatConversationList[]);
  const fetchGeneralChatConversationList = async (companyId: string) =>
    client
      .get(`admin/message_center/chat_conversation_list/?company_id=${companyId}`)
      .then((response) => response.data as GeneralChatConversationList[]);
  const fetchGeneralChatMessages = async (conversationId: number, page = 1, pageSize = 20) =>
    client
      .get(`admin/message_center/chat_messages?conversation_id=${conversationId}`, {
        params: { page, page_size: pageSize },
      })
      .then((response) => response.data as ListResponse<PortfolioChatMessage>);
  const postGeneralChatMessage = async (conversationId: number, data: FormData) =>
    client
      .post(`admin/message_center/post_message/?conversation_id=${conversationId}`, data)
      .then((res) => res.data as string);
  const postGeneralChatConversation = async (companyId: string, data: FormData) =>
    client
      .post(`admin/message_center/post_conversation/?company_id=${companyId}`, data)
      .then((res) => res.data as string);
  const postGeneralChatAnnouncementConversation = async (data: FormData) =>
    client.post(`admin/message_center/post_announcement_conversation/`, data).then((res) => res.data as string);
  const postGeneralChatAnnouncement = async (conversationId: number, data: FormData) =>
    client
      .post(`admin/message_center/post_announcement/?conversation_id=${conversationId}`, data)
      .then((res) => res.data as string);

  // Registered users (not assigned to a buyer)
  const fetchRegisteredUsers = async (page = 1, pageSize = 10) =>
    client
      .get(`admin/registered_users/?page_size=${pageSize}&page=${page}`)
      .then((response) => response.data as ListResponse<RegisteredUser>);
  const activateRegisteredUser = async (userId: number, buyerId?: string, newBuyer?: BaseBuyerData) =>
    client
      .put(`admin/registered_users/${userId}/activate/`, { existingBuyer: buyerId, newBuyer })
      .then((response) => response.data);
  const bulkActivateRegisteredUsers = async (users: number[], buyerId?: string, newBuyer?: BaseBuyerData) =>
    client
      .post(`admin/registered_users/bulk_activate/`, { users, existingBuyer: buyerId, newBuyer })
      .then((response) => response.data);

  // Audit Log
  const fetchAuditLog = async (
    page = 1,
    pageSize = 10,
    searchTerm: string | null,
    fromTimestamp: Moment | null,
    toTimestamp: Moment | null,
  ) => {
    const searchParams = new URLSearchParams(`page_size=${pageSize}&page=${page}`);
    // optional query parameters
    if (searchTerm) {
      searchParams.set('search', searchTerm);
    }
    if (fromTimestamp) {
      searchParams.set('timestamp_after', fromTimestamp.toISOString());
    }
    if (toTimestamp) {
      searchParams.set('timestamp_before', toTimestamp.toISOString());
    }
    return client
      .get(`admin/audit_log/?${searchParams.toString()}`)
      .then((response) => response.data as ListResponse<AuditLogEvent>);
  };
  return {
    client,
    portfolios: {
      importPortfolioFile,
      fetchPortfolios,
      fetchPortfolioDetails,
      uploadNda,
      fetchPortfolioSnapshots,
      fetchPortfolioBuyers,
      grantAccess,
      revokeAccess,
      publishLatestDraft,
      deletePortfolio,
      markSoldPortfolio,
      unmarkSoldPortfolio,
      fetchChatConversationList,
      fetchChatMessages,
      postChatMessage,
      fetchAnnouncements,
      postAnnouncement,
      deleteAnnouncement,
      updateAnnouncement,
    },
    auctions: {
      fetchPortfolioAuctions,
      createPortfolioAuction,
      updateAuction,
      removeAuction,
      publishAuction,
      fetchAuctionBids,
      confirmAccess,
      selectWinningBid,
      rejectAllBids,
    },
    snapshots: {
      fetchSnapshotDetails,
      updatePortfolioSnapshotStatus,
      uploadTermSheet,
      deleteTermSheet,
      uploadSnapshotDocument,
      uploadLoanDocuments,
      editSnapshotDocument,
      deleteSnapshotDocument,
      runLoanDocumentsImport,
      downloadLoanDocument,
      downloadDocument,
      downloadTermSheet,
      publishSnapshot,
    },
    imports: {
      fetchPortfolioImports,
      rerunImport,
      fetchImportErrors,
      downloadImport,
    },
    messageCenter: {
      fetchGeneralChatCompanyList,
      postGeneralChatConversation,
      fetchGeneralChatConversationList,
      fetchGeneralChatMessages,
      postGeneralChatMessage,
      postGeneralChatAnnouncementConversation,
      postGeneralChatAnnouncement,
    },
    users: {
      admins: {
        fetchAdminUsers,
        resetAdmin2fa,
        toggleAdminActive,
        resetAdminPassword,
        updateAdminUser,
        createAdminUser,
        resendAdminPasswordSetup,
      },
      buyers: {
        fetchBuyers,
        fetchAllBuyers,
        toggleBuyerActive,
        updateBuyer,
        createBuyer,
        fetchCompanyKycData,
        toggleBuyerKycStatus,
        approveKycStatus,
        fetchUsers,
        addUser,
        fetchBuyerGroups,
        setBuyerGroups,
        updateBuyerGroup,
        removeBuyerGroup,
      },
      registeredUsers: {
        fetchRegisteredUsers,
        activateRegisteredUser,
        bulkActivateRegisteredUsers,
      },
      users: {
        resetUser2fa,
        toggleUserActive,
        resetUserPassword,
        updateUser,
        resendPasswordSetup,
      },
    },
    fetchAuditLog,
  };
}
