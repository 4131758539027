import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CollateralScreeningRow } from '../../../../shared/types';

interface Props {
  data?: CollateralScreeningRow[];
  currency?: string;
}

export default function NonPropertyTable({ data = [], currency = 'EUR' }: Props) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">Key figures</TableCell>
            <TableCell align="right">{`Value (${currency} m)`}</TableCell>
            <TableCell align="right">Value (%)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((row) => row.value > 0)
            .map((row) => (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row" align="left">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.value.toFixed(1)}</TableCell>
                <TableCell align="right">{row.percentValue.toFixed(1)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
