import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { Button, CircularProgress, Collapse, FormHelperText, Grid, Typography } from '@mui/material';
import { Field, FieldArray, useField, useFormikContext } from 'formik';
import { NewCompanyKycData, UltimateBeneficialOwner } from '../shared/types';
import { ultimateBeneficialOwnerSchema, ultimateBeneficialOwnerSchemaOptional } from './validation';
import { ValidationError } from 'yup';
import _ from 'lodash';
import { CountrySelectRow, DatePickerRow, TextInputRow } from '../shared/components/form_inputs/common';
import Plus from '../shared/components/icons/Plus';
import ClearIcon from '@mui/icons-material/Clear';
import { Checkbox, TextField } from 'formik-mui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import useUser from '../shared/hooks/useUser';

const newOwner = {
  name: '',
  address1: '',
  address2: '',
  zipCode: '',
  city: '',
  country: '',
  dateOfBirth: null,
  placeOfBirth: '',
  citizenship: [],
  documentNumber: '',
  documentValidFrom: null,
  documentValidUntil: null,
  taxpayerIdentificationNumber: '',
  pepStatus: false,
  pepInfo: '',
};

interface PepProps {
  index: number;
  editMode: boolean;
  owner: UltimateBeneficialOwner;
}

const PepRow = ({ owner, index, editMode }: PepProps) => {
  return (
    <div className={styles.checkboxRow}>
      <Typography className={styles.checkboxLabel} variant="h3">
        Politically exposed persons (PEP) status
      </Typography>
      <Field
        component={Checkbox}
        className={styles.checkboxInput}
        name={`ultimateBeneficialOwners.${index}.pepStatus`}
        type="checkbox"
        color="primary"
        disabled={!editMode}
      />
      {owner.pepStatus && (
        <div className={styles.checkboxInfo}>
          {editMode ? (
            <Field
              component={TextField}
              name={`ultimateBeneficialOwners.${index}.pepInfo`}
              placeholder="Please provide relevant info"
              multiline
              rows={3}
              variant="outlined"
              className={styles.formField}
            />
          ) : (
            <Typography className={styles.rowValue}>{owner.pepInfo}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

interface NoUBOsProps {
  editMode: boolean;
}

const NoUBOsRow = ({ editMode }: NoUBOsProps) => {
  const [{ value: toggle }] = useField('noBeneficialOwners');
  const [{ value: explanation }] = useField('noBeneficialOwnersExplanation');
  return (
    <div className={styles.checkboxRow}>
      <Typography className={styles.checkboxLabel} variant="h3">
        There are no ultimate beneficial owners
      </Typography>
      <Field
        component={Checkbox}
        className={styles.checkboxInput}
        name={'noBeneficialOwners'}
        type="checkbox"
        color="primary"
        disabled={!editMode}
      />
      {toggle && (
        <div className={styles.checkboxInfo}>
          {editMode ? (
            <Field
              component={TextField}
              name={'noBeneficialOwnersExplanation'}
              placeholder="Please provide relevant info"
              multiline
              rows={3}
              variant="outlined"
              className={styles.formField}
            />
          ) : (
            <Typography className={styles.rowValue}>{explanation}</Typography>
          )}
        </div>
      )}
    </div>
  );
};

interface Props {
  editDisabled?: boolean;
  hideEditButton?: boolean;
  onEdit?: () => void;
  onEditorClose?: () => void;
}

export default function UltimateBeneficialOwnersSection({
  editDisabled = false,
  hideEditButton = false,
  onEdit,
  onEditorClose,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { submitForm, resetForm, isSubmitting, values, errors, setErrors, isValid, setFieldTouched } =
    useFormikContext<NewCompanyKycData>();

  const noUBOs = useMemo(() => Boolean(values?.noBeneficialOwners), [values]);

  const { user } = useUser();
  const validationSchema = user?.kycOverride ? ultimateBeneficialOwnerSchemaOptional : ultimateBeneficialOwnerSchema;

  const handleEdit = () => {
    if (!expanded) {
      setExpanded(true);
    }
    setEditMode(true);
    onEdit && onEdit();
  };
  const handleCancel = () => {
    resetForm();
    setEditMode(false);
    onEditorClose && onEditorClose();
  };
  const handleSave = () => {
    let isSectionValid = true;
    let fieldErrors = {};
    if (values.noBeneficialOwners) {
      // must provide an explanation
      if (values.noBeneficialOwnersExplanation.length === 0) {
        _.set(fieldErrors, 'noBeneficialOwnersExplanation', 'You must provide an explanation');
        isSectionValid = false;
      }
    } else {
      // must provide at least one UBO
      if (values.ultimateBeneficialOwners.length < 1) {
        _.set(fieldErrors, 'ultimateBeneficialOwners', 'You must provide at least one ultimate beneficial owner');
        isSectionValid = false;
      }
      // validate each UBO
      for (const owner of values.ultimateBeneficialOwners) {
        const index = values.ultimateBeneficialOwners.indexOf(owner);
        try {
          validationSchema.validateSync(owner, { abortEarly: false });
        } catch (e) {
          (e as ValidationError).inner.forEach(({ path, message }) => {
            _.set(fieldErrors, `ultimateBeneficialOwners.[${index}].` + path, message);
            setFieldTouched(`ultimateBeneficialOwners.[${index}].` + path, true, false);
          });
          isSectionValid = false;
        }
      }
    }
    setErrors(fieldErrors);
    if (isSectionValid) {
      submitForm()
        .then(() => {
          setEditMode(false);
          onEditorClose && onEditorClose();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const theme = useTheme();

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionHeader}>
        <div className={styles.titleContainer}>
          <Typography className={styles.sectionTitle} sx={{ color: theme.palette.primary.main }} variant="h2">
            Ultimate beneficial owners
          </Typography>
          <Typography variant="body2">
            Information on ultimate beneficial owners (UBOs) (i.e. persons with interest of more than 25% through direct
            or indirect ownership, or control over the entity)
          </Typography>
        </div>
        <div className={styles.buttonContainer}>
          {editMode ? (
            <>
              <Button variant="outlined" onClick={handleCancel} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button variant="outlined" color="primary" onClick={handleSave} disabled={isSubmitting || !isValid}>
                {isSubmitting ? <CircularProgress color="primary" size={24} /> : 'Save changes'}
              </Button>
            </>
          ) : (
            !hideEditButton && (
              <Button variant="outlined" onClick={handleEdit} disabled={isSubmitting || editDisabled}>
                Edit section
              </Button>
            )
          )}
          <Button
            className={styles.expandButton}
            variant={expanded ? 'contained' : 'outlined'}
            color={expanded ? 'primary' : 'grey'}
            startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Hide details' : 'Show details'}
          </Button>
        </div>
      </div>
      <Collapse in={expanded}>
        <NoUBOsRow editMode={editMode} />

        {!noUBOs && (
          <FieldArray
            name="ultimateBeneficialOwners"
            render={(arrayHelpers) => (
              <div>
                {errors.ultimateBeneficialOwners && typeof errors.ultimateBeneficialOwners === 'string' && (
                  <FormHelperText error>{errors.ultimateBeneficialOwners}</FormHelperText>
                )}
                {values.ultimateBeneficialOwners?.map((owner, index) => (
                  <div key={index} className={styles.uboSectionContainer}>
                    <Grid container>
                      <Grid item xs={6}>
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.name`}
                          label="Name (given name/surname/maiden name)"
                          editMode={editMode}
                        />

                        <DatePickerRow
                          fieldName={`ultimateBeneficialOwners.${index}.dateOfBirth`}
                          label="Date of birth"
                          editMode={editMode}
                        />
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.placeOfBirth`}
                          label="Place of birth"
                          editMode={editMode}
                        />

                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.documentNumber`}
                          label="ID card or passport number"
                          editMode={editMode}
                        />
                        <DatePickerRow
                          fieldName={`ultimateBeneficialOwners.${index}.documentValidFrom`}
                          label="Valid from"
                          editMode={editMode}
                        />
                        <DatePickerRow
                          fieldName={`ultimateBeneficialOwners.${index}.documentValidUntil`}
                          label="Valid until"
                          editMode={editMode}
                        />
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.taxpayerIdentificationNumber`}
                          label="Taxpayer Identification Number"
                          editMode={editMode}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.address1`}
                          label="Address line 1"
                          editMode={editMode}
                        />
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.address2`}
                          label="Address line 2"
                          editMode={editMode}
                        />
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.zipCode`}
                          label="ZIP code"
                          editMode={editMode}
                        />
                        <TextInputRow
                          fieldName={`ultimateBeneficialOwners.${index}.city`}
                          label="City"
                          editMode={editMode}
                        />
                        <CountrySelectRow
                          fieldName={`ultimateBeneficialOwners.${index}.country`}
                          label="Country"
                          editMode={editMode}
                        />
                        <CountrySelectRow
                          fieldName={`ultimateBeneficialOwners.${index}.citizenship`}
                          label="Citizenship"
                          editMode={editMode}
                          multiple
                        />
                        <PepRow editMode={editMode} index={index} owner={owner} />
                      </Grid>
                    </Grid>

                    {editMode && (
                      <Button
                        className={styles.removeUboButton}
                        variant="outlined"
                        onClick={() => arrayHelpers.remove(index)}
                        startIcon={<ClearIcon />}
                        disabled={isSubmitting}
                      >
                        Remove owner
                      </Button>
                    )}
                  </div>
                ))}
                {editMode && (
                  <Button
                    className={styles.addUboButton}
                    variant="outlined"
                    onClick={() => arrayHelpers.push(newOwner)}
                    startIcon={<Plus />}
                    disabled={isSubmitting}
                  >
                    Add another owner (UBO)
                  </Button>
                )}
              </div>
            )}
          />
        )}
      </Collapse>
    </div>
  );
}
