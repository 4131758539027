import React, { useState } from 'react';
import { Card, CardContent, Collapse, Grid, Typography } from '@mui/material';
import styles from './card.module.scss';
import { PortfolioSnapshot } from '../../../shared/types';
import LabeledValue from '../../../shared/components/LabeledValue';
import Header from './Header';
import BalanceChart from '../../BalanceChart';
import PortfolioStatistics from './PortfolioStatistics';
import BidModal from './BidModal';
import BaseDetails from '../../BaseDetails';
import _ from 'lodash';
import Subheader from './Subheader';
import { useTheme } from '@mui/material/styles';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function PortfolioCard({ portfolio }: Props) {
  const { assetClassSplit, productTypeSplit, regionalSplit, auction } = portfolio;

  const [expanded, setExpanded] = useState(false);
  const [bidModalOpen, setBidModalOpen] = useState(false);

  const theme = useTheme();

  return (
    <Card
      sx={
        expanded
          ? { border: `2px solid ${theme.palette.primary.main}`, boxShadow: '0 10px 20px #0000001f' }
          : { border: '2px solid #ffffff' }
      }
      variant="outlined"
    >
      <Header portfolio={portfolio} expanded={expanded} setExpanded={setExpanded} setBidModalOpen={setBidModalOpen} />
      <Subheader portfolio={portfolio} />
      <CardContent className={styles.card__content}>
        <Grid container>
          <Grid className={styles.card__section} item xs={3}>
            <BalanceChart portfolio={portfolio} />
          </Grid>
          <Grid className={styles.card__section} item xs={3}>
            <BaseDetails portfolio={portfolio} />
          </Grid>
          <Grid className={styles.card__section} item xs={2}>
            <Typography className={styles.kpi__title} variant="h3">
              Asset Class Split
            </Typography>
            {_.isEmpty(assetClassSplit) ? (
              <Typography>N/A</Typography>
            ) : (
              assetClassSplit.map(({ name, split }) => (
                <LabeledValue key={name} label={name} value={`${split.toFixed(1)}%`} />
              ))
            )}
          </Grid>
          <Grid className={styles.card__section} item xs={2}>
            <Typography className={styles.kpi__title} variant="h3">
              Product Type Split
            </Typography>
            {_.isEmpty(productTypeSplit) ? (
              <Typography>N/A</Typography>
            ) : (
              productTypeSplit.map(({ name, split }) => (
                <LabeledValue key={name} label={name} value={`${split.toFixed(1)}%`} />
              ))
            )}
          </Grid>
          <Grid className={styles.card__section} item xs={2}>
            <Typography className={styles.kpi__title} variant="h3">
              Regional Split
            </Typography>
            {_.isEmpty(regionalSplit) ? (
              <Typography>N/A</Typography>
            ) : (
              regionalSplit
                ?.filter(({ split }) => split.toFixed(1) !== '0.0')
                .map(({ name, split }) => <LabeledValue key={name} label={name} value={`${split.toFixed(1)}%`} />)
            )}
          </Grid>
        </Grid>
        <Collapse in={expanded} unmountOnExit>
          <PortfolioStatistics portfolio={portfolio} />
        </Collapse>
        {auction && portfolio && portfolio.auction ? (
          <BidModal
            portfolio={portfolio}
            auction={portfolio.auction}
            open={bidModalOpen}
            onClose={() => {
              setBidModalOpen(false);
            }}
          />
        ) : null}
      </CardContent>
    </Card>
  );
}
