import React, { useState } from 'react';
import Header from './Header';
import styles from '../../../styles.module.scss';
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AuctionStage, AuctionStatus, AuctionType, Portfolio, PortfolioAuctionAdmin } from '../../../../shared/types';
import { formatDateVerbose, formatCurrency } from '../../../../shared/utils/helpers';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AuctionDetailsModal from './AuctionDetailsModal';
import ConditionalTooltip from '../../../../shared/components/ConditionalTooltip';
import { useConfirmationDialogStore } from '../../../../shared/components/ConfirmationModal';
import { useSnackbar } from 'notistack';
import BindingAuctionBidListModal from './BindingAuctionBidListModal';
import { getAuctionTypeLabel, getVisibilityLabel } from './utils';
import NonBindingAuctionBidListModal from './NonBindingAuctionBidListModal';
import CustomTableBody from '../../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';

interface AuctionRowProps {
  auction: PortfolioAuctionAdmin;
  portfolio: Portfolio;
}

function CurrentAuctionRow({ auction, portfolio }: AuctionRowProps) {
  const {
    id,
    type,
    snapshotName,
    startDate,
    endDate,
    qaStartDate,
    qaEndDate,
    minimumBid,
    minimumBidIncrease,
    priceVisibility,
    isEditable,
    highestBid,
    currency,
    stage,
    status,
    parentHasBids,
  } = auction;

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openBidModal, setOpenBidModal] = useState(false);
  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);

  const theme = useTheme();
  const getStageStyle = (stage: AuctionStage) => {
    switch (stage) {
      case AuctionStage.UPCOMING:
        return { color: '#303334' };
      case AuctionStage.ONGOING:
        return { color: theme.palette.accent1.main };
      default:
        return { color: theme.palette.accent2.main };
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openActionMenu = Boolean(anchorEl);
  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleOpenBidModal = () => {
    setOpenBidModal(true);
    handleCloseMenu();
  };

  const handleOpenConfirmModal = () => {
    confirmAction('Remove auction', 'Are you sure you want to remove an auction?', handleRemove);
    handleCloseMenu();
  };

  const handlePublishAuction = () => {
    confirmAction('Publish auction', 'Do you want to publish the draft auction?', handlePublish);
    handleCloseMenu();
  };

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleRemove = async () => {
    api.auctions
      .removeAuction(id)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioAuctions', portfolio.id]);
        await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolio.id]);
        enqueueSnackbar('Auction removed', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        if (e.response && e.response.data) {
          if (e.response.data.ERROR_CODE === 'AUCTION_BIDS_EXIST') {
            return enqueueSnackbar('Cannot remove auctions that have bids', { variant: 'error' });
          }
        }
        enqueueSnackbar('Failed to remove auction', { variant: 'error' });
      });
  };

  const { mutate: mutatePublish } = useMutation({
    mutationFn: () => api.auctions.publishAuction(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['adminFetchPortfolioAuctions', portfolio.id]);
      await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolio.id]);
      enqueueSnackbar('Auction published', { variant: 'success' });
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to publish auction', { variant: 'error' });
    },
  });

  const handlePublish = () => {
    mutatePublish();
  };

  return (
    <>
      <TableRow>
        <TableCell>{getAuctionTypeLabel(type)}</TableCell>
        <TableCell>{snapshotName}</TableCell>
        <TableCell sx={getStageStyle(stage)}>{`${formatDateVerbose(startDate)} - ${formatDateVerbose(
          endDate,
        )}`}</TableCell>
        <TableCell>{`${formatDateVerbose(qaStartDate)} - ${formatDateVerbose(qaEndDate)}`}</TableCell>
        <TableCell align="right">{minimumBid}</TableCell>
        <TableCell align="right">{minimumBidIncrease}</TableCell>
        <TableCell>{getVisibilityLabel(priceVisibility)}</TableCell>
        <TableCell align="center">{highestBid ? formatCurrency(highestBid, currency) : '-'}</TableCell>
        <TableCell align="center">{status}</TableCell>
        <TableCell align="right">
          <IconButton onClick={handleActionClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openActionMenu}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {((stage !== AuctionStage.UPCOMING && status !== AuctionStatus.DRAFT) ||
              ((parentHasBids || Boolean(highestBid)) && status === AuctionStatus.DRAFT)) && (
              <MenuItem onClick={handleOpenBidModal}>Bids</MenuItem>
            )}
            <ConditionalTooltip showTooltip={!isEditable} tooltip="Cannot edit auction after start date">
              <MenuItem onClick={handleOpenEditModal} disabled={!isEditable}>
                Edit
              </MenuItem>
            </ConditionalTooltip>
            <ConditionalTooltip showTooltip={!isEditable} tooltip="Cannot remove auction after start date">
              <MenuItem disabled={!isEditable} onClick={handleOpenConfirmModal}>
                Remove
              </MenuItem>
            </ConditionalTooltip>
            {status === AuctionStatus.DRAFT && stage !== AuctionStage.FINISHED && (
              <MenuItem onClick={handlePublishAuction}>Publish</MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
      <AuctionDetailsModal open={openEditModal} onClose={() => setOpenEditModal(false)} auction={auction} />
      {type === AuctionType.NON_BINDING ? (
        <NonBindingAuctionBidListModal
          open={openBidModal}
          onClose={() => setOpenBidModal(false)}
          auction={auction}
          portfolio={portfolio}
        />
      ) : (
        <BindingAuctionBidListModal
          open={openBidModal}
          onClose={() => setOpenBidModal(false)}
          auction={auction}
          portfolioId={portfolio.id}
        />
      )}
    </>
  );
}

interface Props {
  portfolioId: number;
  portfolio: Portfolio;
}

export default function Auctions({ portfolio }: Props) {
  const { id: portfolioId } = portfolio;

  const api = useAdminApi();
  const { data, isLoading } = useQuery(['adminFetchPortfolioAuctions', portfolioId], () =>
    api.auctions.fetchPortfolioAuctions(portfolioId),
  );

  const auctions: PortfolioAuctionAdmin[] = data || [];

  return (
    <Paper variant="outlined">
      <Header portfolioId={portfolioId} />
      <TableContainer className={styles.tabContent__tableContainer}>
        <Table size="small" className={styles.tabContent__table}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Snapshot</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>QA period</TableCell>
              <TableCell align="right">Minimum bid</TableCell>
              <TableCell align="right">Minimum bid increase</TableCell>
              <TableCell>Price visibility</TableCell>
              <TableCell>Highest bid</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <CustomTableBody
            data={auctions}
            isLoading={isLoading}
            renderRow={(auction) => <CurrentAuctionRow key={auction.id} auction={auction} portfolio={portfolio} />}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
}
