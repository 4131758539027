import { Box } from '@mui/system';
import { PortfolioSnapshot } from '../../../shared/types';
import styles from './card.module.scss';
import CardAuctionDates from './CardAuctionDates';
import CardAuctionPrice from './CardAuctionPrice';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function Subheader({ portfolio }: Props) {
  const { identifier } = portfolio;

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" padding="16px" borderBottom="1px solid #f1f1f1">
      <div className={styles.subheader__id}>{identifier}</div>
      {portfolio.nonBindingAuction ? (
        <CardAuctionDates title="Non-binding auction:" auction={portfolio.nonBindingAuction} />
      ) : null}
      {portfolio.bindingAuction ? (
        <CardAuctionDates title="Binding auction:" auction={portfolio.bindingAuction} />
      ) : null}
      {portfolio.auction ? (
        <CardAuctionPrice auction={portfolio.auction} baseCurrency={portfolio.baseCurrency} />
      ) : null}
    </Box>
  );
}
