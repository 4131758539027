import { createTheme, alpha } from '@mui/material/styles';
import { AlertClassKey } from '@mui/lab/Alert';
import { PaginationItemClassKey } from '@mui/lab/PaginationItem';
import { grey } from '@mui/material/colors';
import { WorkspaceConfiguration } from '../shared/types';

declare module '@mui/material/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiPaginationItem: PaginationItemClassKey;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    grey: true;
  }
}

declare module '@mui/material' {
  interface Color {
    main: string;
    dark: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    accent1: Palette['primary'];
    accent2: Palette['primary'];
    backgroundColor: Palette['primary'];
  }

  interface PaletteOptions {
    accent1: PaletteOptions['primary'];
    accent2: PaletteOptions['primary'];
    backgroundColor: PaletteOptions['primary'];
  }
}

export const generateTheme = (config: WorkspaceConfiguration) => {
  const theme = createTheme({
    palette: {
      primary: { main: config.primaryColor },
      accent1: {
        main: config.accentColor1,
      },
      accent2: {
        main: config.accentColor2,
      },
      backgroundColor: {
        main: config.backgroundColor,
      },
      // mode: "dark",
      grey: {
        main: grey[300],
        dark: grey[400],
      },
    },
  });

  return createTheme({
    palette: {
      ...theme.palette,
      secondary: theme.palette.accent1,
      error: {
        main: '#D55050',
      },
    },
    typography: {
      fontFamily: 'Saira Semi Condensed',
      h1: {
        fontWeight: 600,
      },
      h2: {
        fontSize: '28px',
        fontWeight: 600,
      },
      h3: {
        fontSize: '15px',
        color: '#5A676E',
      },
      body2: {
        fontSize: '12px',
        color: '#303334',
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained', color: 'grey' },
            style: {
              color: theme.palette.getContrastText(theme.palette.grey[300]),
            },
          },
          {
            props: { variant: 'contained', color: 'primary' },
            style: {
              color: '#FFFFFF',
            },
          },
          {
            props: { variant: 'outlined', color: 'grey' },
            style: {
              color: theme.palette.text.primary,
              borderColor: '#C4C4C4',
              '&.Mui-disabled': {
                border: `1px solid ${theme.palette.action.disabledBackground}`,
              },
              '&:hover': {
                color: '#FFFFFF',
                borderColor: '#C4C4C4',
                backgroundColor: '#C4C4C4',
              },
            },
          },
          {
            props: { color: 'grey', variant: 'text' },
            style: {
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            height: '40px',
            fontSize: '15px',
            fontWeight: 600,
          },
          sizeLarge: {
            height: '50px',
          },
          sizeSmall: {
            height: '25px',
            fontSize: '12px',
            textTransform: 'none',
            padding: '3px 16px',
          },
          outlined: {
            borderRadius: '5px',
            borderWidth: '2px',
            color: '#303334',
            borderColor: '#F1F1F1',
            '&:hover': {
              borderRadius: '5px',
              borderWidth: '2px',
            },
          },
          outlinedPrimary: {
            border: `2px solid ${theme.palette.primary.main}`,
            color: '#303334',
            borderRadius: '5px',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              color: '#FFFFFF',
              borderRadius: '5px',
              borderWidth: '2px',
              backgroundColor: theme.palette.primary.main,
              svg: {
                fill: '#FFFFFF',
                stroke: '#FFFFFF',
                color: '#FFFFFF',
              },
            },
          },
          startIcon: {
            color: 'inherit',
          },
          endIcon: {
            color: theme.palette.primary.main,
            svg: {
              fill: theme.palette.primary.main,
              stroke: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            paddingLeft: '5px',
            fontSize: '15px',
            color: '#5A676E',
          },
          underline: {
            '&::before': {
              borderColor: '#F1F1F1',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: '1px solid #F1F1F1',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: '80px',
            backgroundColor: '#FFFFFF',
            boxShadow: 'none',
            borderBottom: '1px solid #F1F1F1',
            zIndex: 1201,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            height: '80px',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          action: {
            marginTop: 0,
            marginRight: 0,
            alignSelf: 'center',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            width: '100%',
            color: '#5A676E',
            textTransform: 'none',
            fontSize: '12px',
            paddingLeft: '20px',
            border: '1px solid #F1F1F1',
            '&$selected': {
              backgroundColor: '#EFF7FE',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: 'Roboto Condensed',
            minWidth: '50px',
          },
          head: {
            color: '#A4A9AB',
            fontSize: '12px',
            '&:nth-child(1)': {
              color: '#303334',
            },
          },
          body: {
            color: '#5A676E',
            fontSize: '15px',
            borderBottom: '1px solid #F1F1F1',
            '&:nth-child(1)': {
              fontWeight: 700,
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            borderBottom: '2px solid #F1F1F1',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
          },
          paperWidthFalse: {
            maxWidth: '700px',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            fontSize: '12px',
            width: 'max-content',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
          },
          standardWarning: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: '#E2925F',
          },
          standardSuccess: {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: '#40C4B2',
          },
          icon: {
            color: 'inherit !important',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            boxShadow: 'none',
            border: '1px solid #efefef',
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            fontSize: '15px',
            fontWeight: 600,
          },
          outlined: {
            border: '2px solid #F1F1F1',
          },
        },
      },
    },
  });
};
