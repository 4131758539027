import * as React from 'react';

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21.215" viewBox="0 0 20 21.215">
    <g id="edit-icon" transform="translate(0 0.534)">
      <path
        id="Path_5583"
        data-name="Path 5583"
        d="M17.606,1.394A3.052,3.052,0,0,1,21.922,5.71L13.29,14.342,6.816,16.5l2.158-6.474Z"
        transform="translate(-4.337 -0.318)"
        fill="none"
        stroke="#000"
        stroke-width="1"
      />
      <line
        id="Line_371"
        data-name="Line 371"
        x2="20"
        transform="translate(0 20.182)"
        fill="none"
        stroke="#000"
        stroke-width="1"
      />
    </g>
  </svg>
);

export default EditIcon;
