import { Box, Button } from '@mui/material';
import moment from 'moment';
import { PortfolioChatMessage, PortfolioChatMessageTypeEnum } from '../../types';
import AttachmentIcon from '../icons/AttachmentIcon';
import UploadIcon from '../icons/UploadIcon';
import styles from './qna.module.scss';
import { useTheme } from '@mui/material/styles';

interface Props {
  message: PortfolioChatMessage;
  isMessageHighlighted?: boolean;
  hideSellerEmail?: boolean;
  downloadAttachment: (attachmentId: number, attachmentName: string) => Promise<void>;
}

export default function Message({
  message,
  downloadAttachment,
  isMessageHighlighted = false,
  hideSellerEmail = false,
}: Props) {
  const hasAttachments = Boolean(message.attachments?.length);

  const isSellerMessage = message.type === PortfolioChatMessageTypeEnum.SELLER;

  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{ border: isMessageHighlighted ? `1px solid ${theme.palette.primary.main}` : '1px solid #c4c4c4' }}
      className={`${styles.qna__messageContainer} ${
        isMessageHighlighted ? styles.qna__messageContainer__left : styles.qna__messageContainer__right
      }`}
    >
      <div style={{ width: 32, marginTop: 5 }}>{hasAttachments && <AttachmentIcon />}</div>
      <div className={styles.qna__message_data}>
        <div className={styles.qna__message}>{message.text}</div>
        {hasAttachments && (
          <div style={{ marginTop: 5 }}>
            {message.attachments.map((attachment) => (
              <Button
                key={attachment.id}
                size="small"
                onClick={() => downloadAttachment(attachment.id, attachment.name)}
                endIcon={<UploadIcon />}
                style={{ paddingLeft: 5 }}
              >
                <span className={styles.qna__message_attachment_name}>{attachment.name}</span>
              </Button>
            ))}
          </div>
        )}
      </div>
      <span className={styles.qna_message_time}>
        {isSellerMessage && hideSellerEmail ? '' : message.email} {moment(message.createdAt).format('hh:mm')}
      </span>
    </Box>
  );
}
