import React, { useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import { Paper, Tab, Tabs } from '@mui/material';
import TabPanel from '../../shared/components/TabPanel';
import Header from './Header';
import PortfolioTable from './PortfolioTable';
import ImportLogTable from './ImportLogTable';
import { PortfolioImportStatus } from '../../shared/types';
import { useLocation, useNavigate } from 'react-router-dom';

export default function PortfolioManagerPage() {
  const location = useLocation();

  const [tab, setTab] = useState(location.hash.length ? location.hash.slice(1) : 'portfolio_list');
  const [statusFilter, setStatusFilter] = useState<PortfolioImportStatus | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    if (newValue !== tab) {
      setTab(newValue);
      navigate(`#${newValue}`);
    }
  };

  useEffect(() => {
    const tabIndex = location.hash.slice(1);
    if (tabIndex.length && tabIndex !== tab) {
      setTab(tabIndex);
    }
  }, [location.hash, tab]);

  return (
    <main className={styles.main}>
      <div className={styles.tabs__container}>
        <Tabs
          className={styles.tabs}
          value={tab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className={styles.tabs__tab} value="portfolio_list" label="Portfolio list" />
          <Tab className={styles.tabs__tab} value="import_log" label="Import log" />
        </Tabs>
      </div>
      <Paper variant="outlined" className={styles.tabContent}>
        <TabPanel value={tab} index="portfolio_list">
          <Header
            title="Portfolio list"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
          />
          <PortfolioTable searchTerm={searchTerm} />
        </TabPanel>
        <TabPanel value={tab} index="import_log">
          <Header
            title="Import log"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            showStatusFilter
          />
          <ImportLogTable searchTerm={searchTerm} statusFilter={statusFilter} />
        </TabPanel>
      </Paper>
    </main>
  );
}
