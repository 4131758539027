import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import MessageCenterButton from '../../MessageCenterPage/MessageCenterButton';
import useAdminApi from '../../shared/hooks/useAdminApi';

export default function MessageCenterNavBarAdminButton() {
  const api = useAdminApi();

  const { data } = useQuery(['fetchGeneralChatCompanyList'], api.messageCenter.fetchGeneralChatCompanyList, {
    refetchInterval: 10000,
  });

  const hasUnreadmessages = useMemo(() => {
    if (data) {
      return data.some((item) => item.unreadMessageCount > 0);
    }
    return false;
  }, [data]);

  return <MessageCenterButton hasUnreadmessages={hasUnreadmessages} />;
}
