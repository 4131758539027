import styles from '../message.center.module.scss';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { GeneralChatAnnouncementConversationList } from '../../shared/types';
import ChatAnnouncementConversationList from '../../Admin/MessageCenterPage/ChatAnnouncementConversationList';
import GeneralChatConversationTitle from '../GeneralChatConversationTitle';
import GeneralChatAnnouncementMessages from '../GeneralChatAnnouncementMessages';

export default function MessageCenterAnnouncements() {
  const [selectedAnnouncementConversation, selectAnnouncementConversation] =
    useState<GeneralChatAnnouncementConversationList | null>(null);

  return (
    <Paper variant="outlined" className={styles.tabContent}>
      <div className={styles.tabContent__header}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          flexGrow={1}
          gap="10px"
          height={40}
        >
          <Typography variant="h2">Announcements</Typography>
        </Box>
        <Box display="flex" gap="10px" justifyContent="flex-end" flexWrap="wrap-reverse">
          {selectedAnnouncementConversation !== null && (
            <Button
              variant="outlined"
              color="grey"
              startIcon={<NavigateBeforeIcon />}
              onClick={() => selectAnnouncementConversation(null)}
            >
              Back to message list
            </Button>
          )}
        </Box>
      </div>

      <Grid container>
        <Grid item xs={12} style={{ display: 'flex', minHeight: 300 }}>
          {selectedAnnouncementConversation === null && (
            <ChatAnnouncementConversationList compact selectConversation={selectAnnouncementConversation} />
          )}

          {selectedAnnouncementConversation !== null && (
            <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column' }}>
              <GeneralChatConversationTitle
                title={selectedAnnouncementConversation.conversationTitle}
                date={selectedAnnouncementConversation.conversationCreatedAt}
              />
              <GeneralChatAnnouncementMessages
                readOnly
                selectedConversation={selectedAnnouncementConversation}
                deselectConversation={() => {
                  selectAnnouncementConversation(null);
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
