import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import styles from './styles.module.scss';
import ModalHeader from './ModalHeader';
import { useTheme } from '@mui/material/styles';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  subHeader?: JSX.Element;
  content: React.ReactNode;
  buttonLabel: string;
  onClick: (event?: any) => void;
  disabled?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  isSubmitting?: boolean;
  showButton?: boolean;
}

export default function SingleButtonModal({
  open,
  onClose,
  title,
  subHeader,
  content,
  buttonLabel,
  onClick,
  disabled = false,
  maxWidth = 'sm',
  fullWidth = true,
  isSubmitting = false,
  showButton = true,
}: Props) {
  const theme = useTheme();

  return (
    <Dialog maxWidth={maxWidth} fullWidth={fullWidth} open={open} onClose={onClose}>
      <DialogTitle className={styles.header__container}>
        <ModalHeader title={title} subHeader={subHeader} onClose={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content__container}>{content}</DialogContent>
      {showButton && (
        <DialogActions sx={{ padding: 0, backgroundColor: theme.palette.primary.main }}>
          <Button
            className={styles.btnSubmit}
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={disabled}
            id="submitFormButton"
          >
            {isSubmitting ? <CircularProgress color="primary" size={24} /> : buttonLabel}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
