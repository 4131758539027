import { Field, Form, Formik } from 'formik';
import { Button, CircularProgress, Grid } from '@mui/material';
import { TextField } from 'formik-mui';
import React from 'react';
import * as Yup from 'yup';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import styles from '../auth.module.scss';
import useApi from '../../shared/hooks/useApi';

interface Props {
  setError: (value: boolean) => void;
  setSuccess: (value: boolean) => void;
}

export default function ResetPasswordForm({ setError, setSuccess }: Props) {
  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    email: Yup.string().email().required(requiredMsg),
  });

  const { client } = useApi();

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setError(false);
        try {
          await client.post('/auth/users/reset_password/', values);
          setSuccess(true);
        } catch (e) {
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Field component={TextField} name="email" type="email" label="Email" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" size="large" variant="outlined" fullWidth endIcon={!isSubmitting && <ArrowRight />}>
                {isSubmitting ? <CircularProgress color="primary" size={24} /> : 'Request password reset'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
