/* eslint-disable react/prop-types */
import { Button, Table, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import React from 'react';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import dayjs from 'dayjs';
import { startCase, toLower } from 'lodash';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import { Orderbook, OrderbookOrdering } from '../../shared/types';
import portfolioStyles from '../../Portfolios/PortfolioList/PortfolioCard/card.module.scss';
import { BidModalState } from '../OrderbookPage';
import useApi from '../../shared/hooks/useApi';
import styles from '../orderbook.module.scss';
import { formatCurrency } from '../../shared/utils/helpers';
import useUser from '../../shared/hooks/useUser';
import CustomTableBody from '../../shared/components/table/CustomTableBody';

interface Props {
  data?: Orderbook[];
  isLoading?: boolean;
  setOrdering: (value: OrderbookOrdering) => void;
  ordering: OrderbookOrdering;
  setBidModalData: (data: BidModalState) => void;
}

const OrderbookTable: React.FC<Props> = ({ data, ordering, setOrdering, setBidModalData, isLoading = false }) => {
  const { isKycDone } = useUser();
  const {
    portfolio_snapshot: { fetchPortfolioSnapshot },
  } = useApi();

  const handleBidAction = async (id: number) => {
    const portfolio = await fetchPortfolioSnapshot(id);
    setBidModalData({ open: true, portfolio });
  };

  const bids = data || [];

  return (
    <section className={styles.orderbookTable}>
      <TableContainer className={styles.orderbookTable}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={ordering === OrderbookOrdering.madeAtAsc || ordering === OrderbookOrdering.madeAtDesc}
                  direction={ordering === OrderbookOrdering.madeAtDesc ? 'desc' : 'asc'}
                  onClick={() =>
                    setOrdering(
                      ordering === OrderbookOrdering.madeAtDesc
                        ? OrderbookOrdering.madeAtAsc
                        : OrderbookOrdering.madeAtDesc,
                    )
                  }
                >
                  Date of bid
                </TableSortLabel>
              </TableCell>
              <TableCell>Portfolio ID</TableCell>
              <TableCell>User</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Highest</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <CustomTableBody
            data={bids}
            isLoading={isLoading}
            renderRow={(row: Orderbook) => (
              <TableRow key={row.id}>
                <TableCell>{dayjs(row.madeAt).format('YYYY-MM-DD hh:mm')}</TableCell>
                <TableCell>
                  <Link to={`/marketplace/portfolio/${row.portfolioId}`}>{row.portfolioIdentifier}</Link>
                </TableCell>
                <TableCell>{row.user}</TableCell>
                <TableCell align="right">{formatCurrency(row.price, row.portfolioCurrency)}</TableCell>
                <TableCell>{startCase(toLower(row.status))}</TableCell>
                <TableCell>{startCase(toLower(row.auctionType.replace('_', ' ')))}</TableCell>
                <TableCell>{row.isHighest ? <CheckCircleOutlineIcon color="primary" /> : null}</TableCell>
                <TableCell>
                  {row.isOpen ? (
                    <Tooltip title={isKycDone ? '' : 'Must complete KYC process to place bids'}>
                      <span>
                        <Button
                          className={portfolioStyles.header__button}
                          variant="contained"
                          color="primary"
                          disabled={!isKycDone}
                          startIcon={<EuroOutlinedIcon />}
                          onClick={() => handleBidAction(row.portfolioId)}
                        >
                          Place a bid
                        </Button>
                      </span>
                    </Tooltip>
                  ) : null}
                </TableCell>
              </TableRow>
            )}
          />
        </Table>
      </TableContainer>
    </section>
  );
};

export default OrderbookTable;
