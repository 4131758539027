import { Typography } from '@mui/material';

const EmptyMessageList = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center' }}>
      <Typography>No messages.</Typography>
    </div>
  );
};

export default EmptyMessageList;
