import React from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import SingleButtonModal from '../../../shared/components/SingleButtonModal';
import { CountrySelectRow, PhoneNumberInputRow, TextInputRow } from '../../../shared/components/form_inputs/common';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { Grid, Typography } from '@mui/material';
import styles from '../../styles.module.scss';
import { useTheme } from '@mui/material/styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function AddBuyerModal({ open, onClose }: Props) {
  const api = useAdminApi();

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(requiredMsg),
    legalForm: Yup.string().required(requiredMsg),
    commercialRegisterNumber: Yup.string().required(requiredMsg),
    address1: Yup.string().required(requiredMsg),
    address2: Yup.string(),
    zipCode: Yup.string().required(requiredMsg),
    city: Yup.string().required(requiredMsg),
    country: Yup.string().required(requiredMsg),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Please enter a valid URL',
      )
      .required(requiredMsg),
    fullName: Yup.string().required(requiredMsg),
    email: Yup.string().email('E-mail is not valid.').required(requiredMsg),
    phone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
    mobilePhone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
    jobTitle: Yup.string().required(requiredMsg),
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const theme = useTheme();

  return (
    <Formik
      initialValues={{
        companyName: '',
        legalForm: '',
        commercialRegisterNumber: '',
        address1: '',
        address2: '',
        zipCode: '',
        city: '',
        country: '',
        website: '',
        fullName: '',
        email: '',
        phone: '',
        mobilePhone: '',
        jobTitle: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        return api.users.buyers
          .createBuyer(values)
          .then(async () => {
            await queryClient.invalidateQueries(['adminFetchBuyers']);
            await queryClient.invalidateQueries(['adminFetchAllBuyers']);
            enqueueSnackbar('New buyer added', { variant: 'success' });
            onClose();
            resetForm();
          })
          .catch((e) => {
            console.error(e);
            enqueueSnackbar('Failed to add new buyer', { variant: 'error' });
          });
      }}
    >
      {({ isSubmitting, isValid, submitForm, resetForm }) => (
        <Form>
          <SingleButtonModal
            maxWidth="lg"
            open={open}
            onClose={() => {
              onClose();
              resetForm();
            }}
            title="Add new buyer"
            content={
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    className={styles.formSection__title}
                    sx={{ color: theme.palette.primary.main }}
                    variant="h2"
                  >
                    Company information
                  </Typography>
                  <TextInputRow fieldName="companyName" label="Company name" editMode />
                  <TextInputRow fieldName="legalForm" label="Legal form" editMode />
                  <TextInputRow fieldName="commercialRegisterNumber" label="Commercial register number" editMode />
                  <TextInputRow fieldName="address1" label="Address line 1" editMode />
                  <TextInputRow fieldName="address2" label="Address line 2" editMode />
                  <TextInputRow fieldName="zipCode" label="ZIP code" editMode />
                  <TextInputRow fieldName="city" label="City" editMode />
                  <CountrySelectRow fieldName="country" label="Country" editMode />
                  <TextInputRow fieldName="website" label="Website" editMode />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={styles.formSection__title}
                    sx={{ color: theme.palette.primary.main }}
                    variant="h2"
                  >
                    Initial user
                  </Typography>
                  <TextInputRow key="fullName" editMode fieldName="fullName" label="Name" />
                  <TextInputRow key="email" editMode fieldName="email" label="Email" />
                  <TextInputRow key="jobTitle" editMode fieldName="jobTitle" label="Job title" />
                  <PhoneNumberInputRow key="phone" editMode fieldName="phone" label="Phone" />
                  <PhoneNumberInputRow key="mobilePhone" editMode fieldName="mobilePhone" label="Mobile phone" />
                </Grid>
              </Grid>
            }
            buttonLabel="Add buyer"
            onClick={submitForm}
            isSubmitting={isSubmitting}
            disabled={isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
