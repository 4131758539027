import React, { useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { BuyerUser } from '../../../../shared/types';
import SingleButtonModal from '../../../../shared/components/SingleButtonModal';
import { PhoneNumberInputRow, TextInputRow } from '../../../../shared/components/form_inputs/common';
import useAdminApi from '../../../../shared/hooks/useAdminApi';

interface Props {
  open: boolean;
  onClose: () => void;
  user: BuyerUser;
}

export default function UserDetailsModal({ open, onClose, user }: Props) {
  const { id, fullName, email, companyName, phone, mobilePhone, jobTitle } = user;

  const [editMode, setEditMode] = useState(false);

  const api = useAdminApi();

  const initialValues = useMemo(() => {
    return {
      id: id,
      fullName: fullName,
      email: email,
      companyName: companyName,
      jobTitle: jobTitle,
      phone: phone,
      mobilePhone: mobilePhone,
    };
  }, [id, fullName, email, companyName, phone, mobilePhone, jobTitle]);

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object({
    fullName: Yup.string().required(requiredMsg),
    email: Yup.string().email().required(requiredMsg),
    jobTitle: Yup.string().required(requiredMsg),
    phone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
    mobilePhone: Yup.string().phone('Please enter a valid phone number.').required(requiredMsg),
  });

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setEditMode(false);
    onClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        api.users.users
          .updateUser(id, values)
          .then(async () => {
            await queryClient.invalidateQueries(['adminFetchBuyerUsers']);
            enqueueSnackbar('User details updated', { variant: 'success' });
            handleClose();
          })
          .catch((e) => {
            console.error(e);
            enqueueSnackbar('Failed to update user details', { variant: 'error' });
          });
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, submitForm, resetForm }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={() => {
              handleClose();
              resetForm();
            }}
            title="User details"
            content={
              <>
                <TextInputRow editMode={editMode} fieldName="fullName" label="Name" />
                <TextInputRow editMode={false} fieldName="email" label="Email" />
                <TextInputRow key="companyName" editMode={editMode} fieldName="companyName" label="Company" />
                <TextInputRow key="jobTitle" editMode={editMode} fieldName="jobTitle" label="Job title" />
                <PhoneNumberInputRow key="phone" editMode={editMode} fieldName="phone" label="Phone" />
                <PhoneNumberInputRow
                  key="mobilePhone"
                  editMode={editMode}
                  fieldName="mobilePhone"
                  label="Mobile phone"
                />
              </>
            }
            buttonLabel={!editMode ? 'Edit details' : 'Save details'}
            onClick={!editMode ? () => setEditMode(true) : submitForm}
            isSubmitting={isSubmitting}
            disabled={!editMode ? isSubmitting : isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
