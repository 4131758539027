import { Button } from '@mui/material';
import { useState } from 'react';
import { PortfolioSnapshot } from '../shared/types';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import BidModal from './PortfolioList/PortfolioCard/BidModal';
import moment from 'moment';
import useUser from '../shared/hooks/useUser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import useApi from '../shared/hooks/useApi';
import NDAModal from './PortfolioList/PortfolioCard/NDAModal';
import KycRequiredModal from './KycRequiredModal';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function PlaceBidButton({ portfolio }: Props) {
  const { ndaFile, ndaAccepted, ndaFileId } = portfolio;

  const [bidModalOpen, setBidModalOpen] = useState(false);
  const [showNDAModal, setShowNDAModal] = useState(false);
  const [showKYCModal, setShowKYCModal] = useState(false);

  const api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { isKycDone } = useUser();

  const { mutate: mutateGenerateNda, data: ndaData } = useMutation(
    () => api.portfolio_snapshot.generateNda(portfolio.id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['fetchPortfolioSnapshots']);
      },
      onError(e) {
        console.error(e);
        enqueueSnackbar('Failed to generate NDA, please try again in a moment.', { variant: 'error' });
      },
    },
  );

  if (!portfolio.auction || !moment().isBetween(portfolio.auction.startDate, portfolio.auction.endDate)) return null;

  const handlePlaceBidButton = () => {
    if (!isKycDone) {
      return setShowKYCModal(true);
    }

    if (!ndaAccepted) {
      setShowNDAModal(true);
      if (!ndaFile || !ndaFileId) {
        mutateGenerateNda();
      }
    } else {
      setBidModalOpen(true);
    }
  };

  const nda = ndaFile ?? ndaData?.ndaFile;

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        variant="contained"
        color="primary"
        style={{ minWidth: 135 }}
        startIcon={<EuroOutlinedIcon />}
        onClick={handlePlaceBidButton}
        data-testid={`place-bid-${portfolio.identifier}`}
      >
        Place a bid
      </Button>
      <NDAModal
        open={showNDAModal}
        onClose={() => setShowNDAModal(false)}
        portfolioId={portfolio.id}
        ndaFile={nda}
        ndaFileId={ndaFileId}
        onAccepted={() => setBidModalOpen(true)}
      />
      <KycRequiredModal open={showKYCModal} onClose={() => setShowKYCModal(false)} />
      <BidModal
        portfolio={portfolio}
        auction={portfolio.auction}
        open={bidModalOpen}
        onClose={() => {
          setBidModalOpen(false);
        }}
      />
    </div>
  );
}
