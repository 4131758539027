import React from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styles from '../../styles.module.scss';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { getFileNameFromPath } from '../../../shared/utils/helpers';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../../shared/components/table/TableButton';
import { allowedExtensions } from '../../../shared/utils/constants';

interface Props {
  snapshotId: number;
  termSheet?: string;
  disabled?: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function TermSheet({ snapshotId, termSheet, disabled = false, loading, setLoading }: Props) {
  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const uploadMutation = useMutation({
    mutationFn: (form: FormData) => api.snapshots.uploadTermSheet(snapshotId, form),
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: async (newId) => {
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshotDetails', snapshotId]);
      enqueueSnackbar('Term sheet uploaded', { variant: 'success' });
      if (newId && newId !== snapshotId) {
        enqueueSnackbar('New snapshot draft created', { variant: 'success' });
        navigate(`/portfolio_manager/snapshots/${newId}`);
      }
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to upload term sheet', { variant: 'error' });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: () => api.snapshots.deleteTermSheet(snapshotId),
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: async (newId) => {
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshotDetails', snapshotId]);
      enqueueSnackbar('Term sheet deleted', { variant: 'success' });
      if (newId && newId !== snapshotId) {
        enqueueSnackbar('New snapshot draft created', { variant: 'success' });
        navigate(`/portfolio_manager/snapshots/${newId}`);
      }
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to delete term sheet', { variant: 'error' });
    },
  });

  const handleUpload = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      const form = new FormData();
      form.append('file', file);
      uploadMutation.mutate(form);
    }
  };

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  const downloadTermSheet = () => {
    if (!termSheet) return;
    api.snapshots.downloadTermSheet(snapshotId, getFileNameFromPath(termSheet));
  };

  const theme = useTheme();

  return (
    <div className={styles.filesSection__container}>
      <div className={styles.filesSection__header}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h2">
          Term sheet
        </Typography>
      </div>
      <TableContainer className={styles.filesSection__tableContainer}>
        <Table size="small">
          <TableHead>
            <TableCell sx={{ width: '40%' }}>File Name</TableCell>
            <TableCell align="right" />
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: '40%' }}>
                {termSheet && (
                  <Button
                    className={styles.filesSection__downloadButton}
                    fullWidth
                    onClick={downloadTermSheet}
                    variant="text"
                    endIcon={<DownloadIcon />}
                  >
                    {getFileNameFromPath(termSheet)}
                  </Button>
                )}
              </TableCell>
              <TableCell align="right">
                <Box display="flex" gap="10px" justifyContent="flex-end">
                  <TableButton
                    className={styles.filesSection__actionButton}
                    variant="outlined"
                    color="grey"
                    startIcon={<UploadIcon />}
                    component="label"
                    onChange={handleUpload}
                    disabled={disabled || loading}
                  >
                    {`${termSheet ? 'Change' : 'Upload'} file`}
                    <input type="file" hidden accept={allowedExtensions} />
                  </TableButton>
                  {termSheet && (
                    <TableButton
                      className={styles.filesSection__actionButton}
                      variant="outlined"
                      color="grey"
                      startIcon={<CloseIcon />}
                      onClick={handleDelete}
                      disabled={disabled || loading}
                    >
                      Delete
                    </TableButton>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
