import React, { useState } from 'react';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import styles from '../details.module.scss';
import DetailsTab from './DetailsTab';
import { PortfolioSnapshot } from '../../../shared/types';
import LoansTab from './LoansTab';
import CollateralTab from './CollateralTab';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import TabPanel from '../../../shared/components/TabPanel';
import QaTabLink from './QuestionsAndAnswersTab/QuestionsAndAnswersTabLink';
import QuestionsAndAnswersTab from '../../../shared/components/chat';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function PortfolioData({ portfolio }: Props) {
  const [tab, setTab] = useState('portfolio');

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <div className={styles.tabs__container}>
        <Tabs className={styles.tabs} value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab className={styles.tabs__tab} value="portfolio" label="Portfolio" />
          <Tab className={styles.tabs__tab} value="collateral" label="Collateral" />
          <Tab className={styles.tabs__tab} value="loans" label="Loans" />
          {portfolio.dataQuality && <Tab className={styles.tabs__tab} value="dataQuality" label="Data Quality" />}
          <Tab
            className={styles.tabs__tab}
            value="questionsAndAnswers"
            label={<QaTabLink portfolioId={portfolio.id} />}
          />
        </Tabs>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<NavigateBeforeIcon />}
          onClick={() => navigate('/marketplace')}
        >
          Back to portfolios
        </Button>
      </div>
      <TabPanel value={tab} index="portfolio">
        <DetailsTab portfolio={portfolio} />
      </TabPanel>
      <TabPanel value={tab} index="collateral">
        <CollateralTab portfolio={portfolio} />
      </TabPanel>
      <TabPanel value={tab} index="loans">
        <LoansTab portfolioId={portfolio.id} />
      </TabPanel>
      <TabPanel value={tab} index="dataQuality">
        <div className={styles.tabs__content}>
          <Typography>{portfolio.dataQuality}</Typography>
        </div>
      </TabPanel>
      <TabPanel value={tab} index="questionsAndAnswers">
        <QuestionsAndAnswersTab portfolioId={portfolio.id} readOnly={!portfolio.qaPeriodActive} />
      </TabPanel>
    </>
  );
}
