import { Button, IconButton, TableCell, TableRow } from '@mui/material';
import styles from './qna.module.scss';
import SaveChangesIcon from '../icons/SaveChangesIcon';
import CancelIcon from '../icons/CancelIcon';
import EditIcon from '../icons/EditIcon';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadIcon from '../icons/UploadIcon';
import React, { useMemo } from 'react';
import { PortfolioAnnouncement } from '../../types';
import useAdminApi from '../../hooks/useAdminApi';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useConfirmationDialogStore } from '../ConfirmationModal';
import moment from 'moment/moment';
import AnnouncementUpdateForm from './AnnouncementUpdateForm';
import { Formik } from 'formik';
import * as Yup from 'yup';

const formatTimestamp = (createdAt: Date) => moment(createdAt).format('YYYY-MM-DD | hh:mm');

interface Props {
  announcement: PortfolioAnnouncement;
  setSelectedAnnouncementId: (id: number | undefined) => void;
  editMode?: boolean;
  adminView?: boolean;
  downloadAttachment: (attachmentId: number, attachmentName: string) => Promise<void>;
}

export default function AnnouncementRow({
  announcement,
  setSelectedAnnouncementId,
  downloadAttachment,
  editMode = false,
  adminView = false,
}: Props) {
  const { id, createdAt, text, attachments } = announcement;

  const api = useAdminApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const confirmAction = useConfirmationDialogStore((state) => state.confirmAction);
  const deleteAnnouncement = async (announcementId: number) => {
    api.portfolios
      .deleteAnnouncement(announcementId)
      .then(async () => {
        await queryClient.invalidateQueries(['fetchPortfolioAnnouncements']);
        enqueueSnackbar('Announcement deleted', { variant: 'success' });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to delete announcement', { variant: 'error' });
      });
  };

  const updateAnnouncement = async (form: FormData) =>
    api.portfolios
      .updateAnnouncement(id, form)
      .then(async () => {
        await queryClient.invalidateQueries(['fetchPortfolioAnnouncements']);
        enqueueSnackbar('Announcement updated', { variant: 'success' });
        setSelectedAnnouncementId(undefined);
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to update announcement', { variant: 'error' });
      });

  const initialValues = useMemo(
    () => ({
      text: announcement.text,
      attachments: announcement.attachments,
    }),
    [announcement],
  );

  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    text: Yup.string().required(requiredMsg),
  });

  // downloadPortfolioAnnouncementAttachment

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        try {
          const form = new FormData();
          form.append('text', values.text.trim());

          for (let attachment of values.attachments) {
            // when updating attachments can be actual newly uploaded files
            // or previously uploaded files as JSON objects
            if ('id' in attachment) {
              form.append('attachments[]', attachment.id.toString());
            } else {
              form.append('attachments[]', attachment as unknown as File);
            }
          }

          await updateAnnouncement(form);
          resetForm();
        } catch (e) {
          enqueueSnackbar('Failed post the message', {
            variant: 'error',
          });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ submitForm }) => (
        <TableRow
          key={id}
          sx={{
            '&:last-child td': {
              borderBottom: 0,
            },
          }}
        >
          <TableCell className={styles.announcement__table__dateCell}>
            <div>
              {formatTimestamp(createdAt)}
              {adminView && (
                <div className={styles.announcement__table__buttonContainer}>
                  {editMode
                    ? [
                        <IconButton onClick={submitForm}>
                          <SaveChangesIcon />
                        </IconButton>,
                        <IconButton onClick={() => setSelectedAnnouncementId(undefined)}>
                          <CancelIcon />
                        </IconButton>,
                      ]
                    : [
                        <IconButton onClick={() => setSelectedAnnouncementId(id)}>
                          <EditIcon />
                        </IconButton>,
                        <IconButton
                          onClick={() =>
                            confirmAction('Delete announcement', 'Do you want to delete selected announcement?', () =>
                              deleteAnnouncement(id),
                            )
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>,
                      ]}
                </div>
              )}
            </div>
          </TableCell>
          <TableCell className={styles.announcement__table__textCell}>
            {adminView && editMode ? <AnnouncementUpdateForm /> : <span>{text}</span>}

            {Boolean(attachments.length) && !editMode && (
              <div style={{ marginTop: 5 }}>
                {attachments.map((attachment) => (
                  <Button
                    key={attachment.id}
                    size="small"
                    onClick={() => downloadAttachment(attachment.id, attachment.name)}
                    endIcon={<UploadIcon />}
                    style={{ paddingLeft: 0 }}
                  >
                    <span className={styles.qna__message_attachment_name}>{attachment.name}</span>
                  </Button>
                ))}
              </div>
            )}
          </TableCell>
        </TableRow>
      )}
    </Formik>
  );
}
