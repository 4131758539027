import React from 'react';
import { Button, Typography } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Loan } from '../../../../../shared/types';
import styles from './loanDetails.module.scss';
import BasicInfo from './BasicInfo';
import DetailedInfo from './DetailedInfo';
import CollateralTable from './CollateralTables';
import LoanDocuments from './LoanDocuments';

interface Props {
  loan: Loan;
  onClose: () => void;
}

export default function LoanDetails({ loan, onClose }: Props) {
  return (
    <div className={styles.container}>
      <Button className={styles.backButton} onClick={onClose}>
        <NavigateBeforeIcon fontSize="small" /> <span className={styles.backButton__label}>All loans</span>
      </Button>
      <Typography variant="h2">Loan Preview</Typography>
      <BasicInfo loan={loan} />
      <DetailedInfo loan={loan} />
      {Boolean(loan.documents.length) && <LoanDocuments documents={loan.documents} />}
      <CollateralTable loanId={loan.id} />
    </div>
  );
}
