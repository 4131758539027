import React from 'react';
import { useQuery } from '@tanstack/react-query';
import PropertyTable from './PropertyTable';
import NonPropertyTable from './NonPropertyTable';
import CustomAccordion from '../../../../shared/components/CustomAccordion';
import { PortfolioSnapshot } from '../../../../shared/types';
import useApi from '../../../../shared/hooks/useApi';
import { Paper } from '@mui/material';
import TableLoader from '../../../../shared/components/TableLoader';
import { NoDataIcon } from '../../../../shared/components/table/NoData';

interface Props {
  portfolio: PortfolioSnapshot;
}

export default function CollateralTab({ portfolio: { id, baseCurrency } }: Props) {
  const api = useApi();

  const { data: propertyCollateralScreening, isLoading: isLoadingPropertyCollateralScreening } = useQuery(
    ['fetchPortfolioPropertyCollateralScreening', id],
    () => api.portfolio_snapshot.propertyCollateralScreening(id),
  );

  const { data: nonPropertyCollateralScreening, isLoading: isLoadingNonPropertyCollateralScreening } = useQuery(
    ['fetchPortfolioNonPropertyCollateralScreening', id],
    () => api.portfolio_snapshot.nonPropertyCollateralScreening(id),
  );

  const displayCurrency = propertyCollateralScreening?.currency || baseCurrency;
  const isLoading = isLoadingPropertyCollateralScreening || isLoadingNonPropertyCollateralScreening;

  const propertyTabs = [
    {
      key: 'marketValue',
      label: 'Breakdown By Market Value Of Collateral',
      component: <PropertyTable data={propertyCollateralScreening?.marketValue} currency={displayCurrency} />,
    },
    {
      key: 'appraisalSeasoning',
      label: 'Appraisal Seasoning',
      component: <PropertyTable data={propertyCollateralScreening?.appraisalSeasoning} currency={displayCurrency} />,
    },
    {
      key: 'propertyConcentration',
      label: 'Property Concentration',
      component: <PropertyTable data={propertyCollateralScreening?.propertyConcentration} currency={displayCurrency} />,
    },
    {
      key: 'propertyType',
      label: 'Property Type',
      component: <PropertyTable data={propertyCollateralScreening?.propertyType} currency={displayCurrency} />,
    },
  ];

  const nonPropertyTabs = [
    {
      key: 'collateralType',
      label: 'Collateral Type',
      component: <NonPropertyTable data={nonPropertyCollateralScreening?.collateralType} currency={displayCurrency} />,
    },
    {
      key: 'currencyOfCollateral',
      label: 'Currency Of Collateral',
      component: (
        <NonPropertyTable data={nonPropertyCollateralScreening?.currencyOfCollateral} currency={displayCurrency} />
      ),
    },
    {
      key: 'latestValuation',
      label: 'Latest Valuation Amount',
      component: <NonPropertyTable data={nonPropertyCollateralScreening?.latestValuation} currency={displayCurrency} />,
    },
  ];

  return (
    <Paper variant="outlined">
      {isLoading ? (
        <TableLoader text="Loading collateral data" />
      ) : (
        <>
          {!(propertyCollateralScreening || nonPropertyCollateralScreening) && <NoDataIcon />}
          {propertyCollateralScreening && <CustomAccordion tabs={propertyTabs} title="Property Collateral" />}
          {nonPropertyCollateralScreening && <CustomAccordion tabs={nonPropertyTabs} title="Non-Property Collateral" />}
        </>
      )}
    </Paper>
  );
}
