import styles from './message.center.module.scss';
import { useQuery } from '@tanstack/react-query';
import useApi from '../shared/hooks/useApi';
import MessageCenterAnnouncements from './announcements/MessageCenterAnnouncements';
import MessageCenterMessages from './MessageCenterMessages';

export default function MessageCenterPage() {
  const api = useApi();

  const { data } = useQuery(
    ['fetchGeneralChatAnnouncementConversationList'],
    api.messageCenter.fetchGeneralChatAnnouncementConversationList,
    {
      refetchInterval: 10000,
    },
  );

  const announcements = data || [];

  return (
    <main className={styles.main}>
      {Boolean(announcements.length) && <MessageCenterAnnouncements />}
      <MessageCenterMessages />
    </main>
  );
}
