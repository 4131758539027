import React from 'react';
import styles from './styles.module.scss';
import PageLayout from '../shared/components/PageLayout';
import { Button, Typography } from '@mui/material';
import CompanyInfoSection from './CompanyInfoSection';
import { Form, Formik } from 'formik';
import { CompanyKycDataStatusEnum } from '../shared/types';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AuthorizedRepresentativeSection from './AuthorizedRepresentativeSection';
import UltimateBeneficialOwnersSection from './UltimateBeneficialOwnersSection';
import DocumentsSection from './DocumentsSection';
import { useNavigate, useParams } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import useAdminApi from '../shared/hooks/useAdminApi';

export default function CompanyKycAccept() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const api = useAdminApi();

  const { companyId } = useParams<{ companyId: string }>();

  const { data: kycData } = useQuery(
    ['fetchKycData', companyId],
    () => api.users.buyers.fetchCompanyKycData(companyId!),
    {
      enabled: Boolean(companyId),
    },
  );

  const kycId = kycData?.id;
  const kycStatus = kycData?.status;
  const isApproved = kycStatus === CompanyKycDataStatusEnum.APPROVED;
  const isSubmitted = kycStatus === CompanyKycDataStatusEnum.SUBMITTED;

  const { mutate, isLoading } = useMutation(() => api.users.buyers.approveKycStatus(companyId!, kycId!), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchKycData', companyId]);
      await queryClient.invalidateQueries(['adminFetchBuyers']);
      enqueueSnackbar('Buyer KYC status changed', { variant: 'success' });
    },
    onError: (e) => {
      console.error(e);
      enqueueSnackbar('Failed to change buyer KYC status', { variant: 'error' });
    },
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const submit = async () => {
    mutate();
  };

  const isDisabled = isLoading || !kycData || isApproved || !isSubmitted;

  return (
    <PageLayout>
      <Formik initialValues={kycData || {}} onSubmit={() => {}} disabled enableReinitialize validateOnChange={false}>
        {() => (
          <Form>
            <div className={styles.header}>
              <div className={styles.titleContainer} style={{ paddingBottom: 25 }}>
                <Typography className={styles.title} variant="h2">
                  Company KYC information & documents
                </Typography>
              </div>
              <div className={styles.buttonContainer}>
                <Button variant="outlined" color="primary" onClick={handleGoBack} startIcon={<NavigateBeforeIcon />}>
                  Back
                </Button>
                <Button variant="contained" color="primary" disabled={isDisabled} onClick={submit}>
                  {isApproved ? 'Accepted' : 'Accept KYC'}
                </Button>
              </div>
            </div>
            <CompanyInfoSection editDisabled hideEditButton />

            <AuthorizedRepresentativeSection editDisabled hideEditButton />
            <UltimateBeneficialOwnersSection editDisabled hideEditButton />
            <DocumentsSection editDisabled kycId={kycId} />

            <div className={styles.footer}>
              <div className={styles.buttonContainer}>
                <Button variant="outlined" color="primary" onClick={handleGoBack} startIcon={<NavigateBeforeIcon />}>
                  Back
                </Button>
                <Button variant="contained" color="primary" disabled={isDisabled} onClick={submit}>
                  {isApproved ? 'Accepted' : 'Accept KYC'}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
}
