import React, { useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Autocomplete } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import styles from './nav.module.scss';
import useApi, { CurrencyOption } from '../../shared/hooks/useApi';

export default function CurrencySelect() {
  const [currency, setCurrency] = useState<string | null>(null);

  const {
    portfolio_snapshot: { fetchCurrencies, setDisplayCurrency },
  } = useApi();
  const { data: currencies } = useQuery(['fetchCurrencies'], fetchCurrencies, {
    onSuccess: (response) => setCurrency(response.displayCurrency),
  });
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (event: unknown, value: CurrencyOption | null) => {
    try {
      const response = await setDisplayCurrency(value ? value.code : null);
      setCurrency(response);
      await queryClient.invalidateQueries();
    } catch {
      enqueueSnackbar('Failed to set display currency', { variant: 'error' });
    }
  };

  const displayCurrency = currencies ? _.find(currencies.options, (option) => option.code === currency) : null;

  return currencies ? (
    <>
      {displayCurrency && (
        <div className={styles.currencyTimestamp}>
          <Typography className={styles.currencyTimestamp__label}>Currency rate timestamp</Typography>
          <Typography className={styles.currencyTimestamp__value}>
            {moment(displayCurrency.timestamp).format('YYYY-MM-DD hh:mm')} {displayCurrency.timezone}
          </Typography>
        </div>
      )}
      <Autocomplete
        value={displayCurrency}
        style={{ width: 160 }}
        className={styles.currencySelect}
        onChange={handleChange}
        size="small"
        renderInput={(params) => (
          <TextField className={styles.currencySelect__input} {...params} label="Display currency" variant="outlined" />
        )}
        options={currencies.options}
        getOptionLabel={({ code, name }) => `${code} - ${name}`}
      />
    </>
  ) : null;
}
