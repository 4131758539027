import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './qna.module.scss';
import _ from 'lodash';
import moment from 'moment';
import { ListResponse, PortfolioChatMessage, PortfolioChatMessageTypeEnum } from '../../types';
import Message from './Message';
import ChatMessagesLoader from './loaders/ChatMessagesLoader';
import ChatOldMessagesLoader from './loaders/ChatOldMessagesLoader';
import EmptyMessageList from './EmptyMessageList';
import PostMessageForm from './PostMessageForm';

interface Props {
  portfolioId?: number;
  readOnly?: boolean;
  adminView?: boolean;
  infiniteQuery: UseInfiniteQueryResult<ListResponse<PortfolioChatMessage>, unknown>;
  scrollToBottomOnInitialLoad: boolean;
  postMessage: (form: FormData) => Promise<void>;
  setScrollToBottomOnInitialLoad: (v: boolean) => void;
  shouldDisplayForm: boolean;
  showSelectThreadMessage?: boolean;
  setInitialHeight?: boolean;
  highlightedMessageType: string;
  showFormButtons?: boolean;
  limitHeight?: boolean;
  onCancel?: () => void;
  downloadAttachment: (attachmentId: number, attachmentName: string) => Promise<void>;
}

export default function Messages({
  infiniteQuery,
  scrollToBottomOnInitialLoad,
  setScrollToBottomOnInitialLoad,
  postMessage,
  shouldDisplayForm,
  onCancel,
  readOnly = false,
  adminView = false,
  showSelectThreadMessage = false,
  setInitialHeight = true,
  showFormButtons = false,
  highlightedMessageType = PortfolioChatMessageTypeEnum.BUYER,
  limitHeight = true,
  downloadAttachment,
}: Props) {
  const bottomRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [postedNewMessage, setPostedNewMessage] = useState(false);
  const [previousChatHeight, setPreviousChatHeight] = useState(0);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);

  const { data, fetchNextPage, hasNextPage, isInitialLoading, isFetched, isFetchingNextPage } = infiniteQuery;

  const messageList = useMemo(() => {
    const list = data?.pages.flatMap((page) => page.results) ?? [];
    // TODO: an alternative is to use flex-direction: column-reverse - needs to be tested for any visual glitches
    return _.reverse(list);
  }, [data]);

  const messagesGroupedByDay = useMemo(() => {
    return _.groupBy(messageList, (item) => moment(item.createdAt).format('ddd, MMM D'));
  }, [messageList]);

  const hasMessages = Boolean(messageList.length);

  useEffect(() => {
    if (messageList.length && messagesContainerRef?.current && bottomRef?.current) {
      if (postedNewMessage) {
        bottomRef.current.scroll({ top: bottomRef.current.scrollHeight, behavior: 'smooth' });
        setPostedNewMessage(false);
        return;
      }

      if (scrollToBottomOnInitialLoad) {
        setScrollToBottomOnInitialLoad(false);
        bottomRef.current.scroll({ top: bottomRef.current.scrollHeight, behavior: 'auto' });
        return;
      }

      // Persist scroll position at the top of the screen when loading old messages
      const persistScrollTopThreshold = 100;
      if (previousScrollTop <= persistScrollTopThreshold) {
        const oldMessagesLoaderHeight = 44 - previousScrollTop;
        bottomRef.current.scrollTop =
          messagesContainerRef.current.clientHeight - previousChatHeight - oldMessagesLoaderHeight;
      }

      // A reply received: do not scroll down to the message if chat is scrolled up high enough
      const chatHeight = bottomRef.current.clientHeight;
      const scrollBottomThreshold = 150;
      const maxHeightChatWillNotScrollOnNewMessage =
        messagesContainerRef.current.clientHeight - scrollBottomThreshold - chatHeight;
      if (
        bottomRef.current.scrollTop >= maxHeightChatWillNotScrollOnNewMessage &&
        maxHeightChatWillNotScrollOnNewMessage > 0
      ) {
        bottomRef.current.scroll({ top: bottomRef.current.scrollHeight, behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageList]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (messagesContainerRef.current) {
      setPreviousChatHeight(messagesContainerRef.current.clientHeight);
    }

    if (bottomRef.current) {
      setPreviousScrollTop(bottomRef.current.scrollTop);
    }

    const loadOldMessagesTopThreshold = 400;
    if (event.currentTarget.scrollTop <= loadOldMessagesTopThreshold) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const postMessageCallback = () => {
    setPostedNewMessage(true);
  };

  // hide seller email in data room (buyer) view
  const hideSellerEmail = highlightedMessageType === PortfolioChatMessageTypeEnum.SELLER;

  return (
    <div
      className={styles.qna__messagesContainer}
      // TODO: refactor
      style={{ height: setInitialHeight ? 626 : undefined }}
    >
      {isInitialLoading && <ChatMessagesLoader />}
      <div
        ref={bottomRef}
        className={styles.qna__messages}
        style={{ maxHeight: limitHeight ? 500 : undefined, minHeight: limitHeight ? 500 : undefined }}
        onScroll={handleScroll}
      >
        {isFetchingNextPage && <ChatOldMessagesLoader />}
        {!hasMessages && isFetched && <EmptyMessageList />}
        <div ref={messagesContainerRef} className={styles.qna__messages_scroll}>
          {Object.keys(messagesGroupedByDay).map((day) => {
            return (
              <div key={day} className={styles.qna__messages_day_group_container}>
                <span className={styles.qna__messages_day_group__header}>{day}</span>
                <div className={styles.qna__messages_day_group}>
                  {messagesGroupedByDay[day].map((message) => (
                    <Message
                      key={message.id}
                      message={message}
                      isMessageHighlighted={message.type === highlightedMessageType}
                      hideSellerEmail={hideSellerEmail}
                      downloadAttachment={downloadAttachment}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {shouldDisplayForm && (
        <PostMessageForm
          readOnly={readOnly}
          postMessage={postMessage}
          showFormButtons={showFormButtons}
          onCancel={onCancel}
          postMessageCallback={postMessageCallback}
        />
      )}
      {showSelectThreadMessage && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p style={{ marginTop: 10, color: '#5A676E', fontSize: 14 }}>Select a thread to view messages.</p>
        </div>
      )}
    </div>
  );
}
