import React, { useMemo, useState } from 'react';
import { Box, Button, Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import styles from '../styles.module.scss';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SingleButtonModal from '../../shared/components/SingleButtonModal';
import useAdminApi from '../../shared/hooks/useAdminApi';
import { useNavigate } from 'react-router-dom';
import { allowedExtensions } from '../../shared/utils/constants';

interface FileSelectProps {
  selectedFile?: string;
  onFileUpload: (file: File) => void;
}

export const FileSelectRow = ({ selectedFile, onFileUpload }: FileSelectProps) => {
  const fileName = useMemo(() => {
    if (!selectedFile) {
      return '';
    }
    return selectedFile.split('/').at(-1);
  }, [selectedFile]);

  const handleUpload = async (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      onFileUpload(file);
    }
  };

  return (
    <div className={styles.modal__fileRow}>
      <Typography className={styles.modal__fileLabel} variant="h3">
        Select XLSX file:
      </Typography>
      {selectedFile && (
        <Tooltip title={fileName}>
          <Button
            className={styles.modal__downloadButton}
            href={selectedFile}
            target="_blank"
            variant="text"
            startIcon={<CloudDownloadIcon />}
          >
            <span className={styles.modal__downloadButtonLabel}>{fileName}</span>
          </Button>
        </Tooltip>
      )}

      <Button
        className={styles.modal__uploadButton}
        variant="outlined"
        color="grey"
        startIcon={<CloudUploadIcon />}
        component="label"
        onChange={handleUpload}
      >
        Browse
        <input type="file" hidden accept={allowedExtensions} />
      </Button>
    </div>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
}

export default function PortfolioImportModal({ open, onClose }: Props) {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [overwrite, setOverwrite] = useState(false);

  const handleFileUpload = (file: File) => {
    setFile(file);
  };

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (file) {
      const form = new FormData();
      form.append('file', file);
      form.append('overwrite_data', overwrite.toString());
      api.portfolios
        .importPortfolioFile(form)
        .then(async () => {
          await queryClient.invalidateQueries(['adminFetchPortfolios']);
          await queryClient.invalidateQueries(['adminFetchPortfolioImports']);
          enqueueSnackbar('File import scheduled', { variant: 'success' });
          setFile(undefined);
          setOverwrite(false);
          onClose();
          navigate('/portfolio_manager#import_log');
        })
        .catch((e) => {
          console.error(e);
          enqueueSnackbar('Failed to schedule file import', { variant: 'error' });
        });
    }
  };

  return (
    <SingleButtonModal
      open={open}
      onClose={onClose}
      title="Import Portfolio"
      content={
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FileSelectRow selectedFile={file?.name} onFileUpload={handleFileUpload} />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography className={styles.modal__fileLabel} variant="h3">
                Overwrite existing data:
              </Typography>
              <Checkbox color="primary" value={overwrite} onChange={(_, checked: boolean) => setOverwrite(checked)} />
            </Box>
          </Grid>
        </Grid>
      }
      buttonLabel="Import"
      onClick={handleSubmit}
      disabled={!Boolean(file)}
    />
  );
}
