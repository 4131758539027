import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`wrapped-tabpanel-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
