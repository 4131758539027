import React from 'react';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import useAdminApi from '../../../../shared/hooks/useAdminApi';
import SingleButtonModal from '../../../../shared/components/SingleButtonModal';
import { DateRangePickerRow, NumberInputRow, SelectRow } from '../../../../shared/components/form_inputs/common';
import { AuctionType, PriceVisibility } from '../../../../shared/types';

interface Props {
  open: boolean;
  onClose: () => void;
  portfolioId: number;
}

export default function AddAuctionModal({ open, onClose, portfolioId }: Props) {
  const api = useAdminApi();

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const requiredMsg = 'Field cannot be empty.';
  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .min(today, 'Start date cannot be in the past')
      .max(Yup.ref('endDate'), 'Start must be before end date')
      .required(requiredMsg),
    endDate: Yup.date().min(Yup.ref('startDate'), 'End must be after start date').required(requiredMsg),
    qaStartDate: Yup.date()
      .min(today, 'QA start date cannot be in the past')
      .max(Yup.ref('qaEndDate'), 'Start must be before end date')
      .required(requiredMsg),
    qaEndDate: Yup.date()
      .max(Yup.ref('endDate'), 'QA end must be before auction end date')
      .min(Yup.ref('qaStartDate'), 'End must be after start date')
      .required(requiredMsg),
    type: Yup.string().required(requiredMsg),
    priceVisibility: Yup.string().required(requiredMsg),
    minimumBid: Yup.number().required(requiredMsg),
    minimumBidIncrease: Yup.number().required(requiredMsg),
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const typeOptions = [
    { label: 'Non-binding', value: 'NON_BINDING' },
    { label: 'Binding', value: 'BINDING' },
  ];

  const visibilityOptions = [
    { label: 'Public', value: 'PUBLIC' },
    { label: 'Anonymous', value: 'ANONYMOUS' },
  ];

  return (
    <Formik
      initialValues={{
        startDate: '',
        endDate: '',
        qaStartDate: '',
        qaEndDate: '',
        type: '' as AuctionType,
        priceVisibility: '' as PriceVisibility,
        minimumBid: 0.05,
        minimumBidIncrease: 0.0001,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setErrors }) => {
        return api.auctions
          .createPortfolioAuction(portfolioId, values)
          .then(async () => {
            await queryClient.invalidateQueries(['adminFetchPortfolioAuctions', portfolioId]);
            await queryClient.invalidateQueries(['adminFetchPortfolioDetails', portfolioId]);
            enqueueSnackbar('New auction added', { variant: 'success' });
            onClose();
            resetForm();
          })
          .catch((e) => {
            console.error(e);
            if (e.response) {
              if (e.response.data?.['ERROR_CODE'] === 'DATE_OVERLAP') {
                setErrors({
                  startDate: 'Selected dates overlap with another auction.',
                });
                return;
              }
              if (e.response.data?.['ERROR_CODE'] === 'UNFINISHED_BINDING_AUCTION') {
                setErrors({
                  type: 'An ongoing or upcoming binding auction already exists.',
                });
                return;
              }
            }
            enqueueSnackbar('Failed to add new auction', { variant: 'error' });
          });
      }}
    >
      {({ isSubmitting, isValid, submitForm, resetForm }) => (
        <Form>
          <SingleButtonModal
            open={open}
            onClose={() => {
              onClose();
              resetForm();
            }}
            title="Add new auction"
            content={
              <>
                <DateRangePickerRow
                  key="auctionDurationRange"
                  editMode
                  fieldNameDateStart="startDate"
                  fieldNameDateEnd="endDate"
                  label="Auction duration"
                  variant="large"
                />
                <DateRangePickerRow
                  key="qaRange"
                  editMode
                  fieldNameDateStart="qaStartDate"
                  fieldNameDateEnd="qaEndDate"
                  label="Q&A period"
                  variant="large"
                />
                <SelectRow
                  key="type"
                  editMode
                  highlightText
                  fieldName="type"
                  label="Auction type"
                  options={typeOptions}
                  variant="large"
                />
                <SelectRow
                  key="priceVisibility"
                  editMode
                  highlightText
                  fieldName="priceVisibility"
                  label="Price visibility"
                  options={visibilityOptions}
                  variant="large"
                />
                <NumberInputRow
                  key="minimumBid"
                  editMode
                  fieldName="minimumBid"
                  label="Minimum bid"
                  tooltip="As portion of outstanding balance, e.g. 0.05 is 5% of outstanding balance (rounded up to nearest 1000)."
                  variant="large"
                />
                <NumberInputRow
                  key="minimumBidIncrease"
                  editMode
                  fieldName="minimumBidIncrease"
                  label="Minimum bid increase"
                  tooltip="As portion of outstanding balance, e.g. 0.0001 is 0.01% of outstanding balance (rounded up to nearest 1000)."
                  variant="large"
                />
              </>
            }
            buttonLabel="Add auction"
            onClick={submitForm}
            isSubmitting={isSubmitting}
            disabled={isSubmitting || !isValid}
          />
        </Form>
      )}
    </Formik>
  );
}
