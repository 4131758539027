interface BaseUser {
  id: number;
  fullName: string;
  companyName: string;
  jobTitle: string;
  email: string;
  phone: string;
  mobilePhone: string;
}

export interface User extends BaseUser {
  kycStatus: KycStatusEnum;
  kycOverride: boolean;
  company?: Company;
  isAdmin: boolean;
  acceptedTos: boolean;
}

export interface AdminUser extends BaseUser {
  isActive: boolean;
  has2fa: boolean;
}

export interface UpdateAdminUserData extends Omit<BaseUser, 'id'> {}

export interface NewAdminUserData extends UpdateAdminUserData {}

export interface BuyerUser extends BaseUser {
  isActive: boolean;
  has2fa: boolean;
  kycStatus: KycStatusEnum;
}

export interface UpdateBuyerUserData extends Omit<BaseUser, 'id' | 'companyName'> {}

export interface NewBuyerUserData extends UpdateBuyerUserData {}

export interface Company {
  id: string;
  name: string;
  kycStatus: KycStatusEnum;
  companyName: string;
  legalForm: string;
  commercialRegisterNumber: string;
  address1: string;
  address2?: string;
  zipCode: string;
  city: string;
  country: string;
  website: string;
}

export interface BaseBuyerData extends Omit<Company, 'id' | 'name' | 'kycStatus'> {
  companyName: string;
  legalForm: string;
  commercialRegisterNumber: string;
  address1: string;
  address2?: string;
  zipCode: string;
  city: string;
  country: string;
  website: string;
}

export interface NewBuyerData extends BaseBuyerData {
  fullName: string;
  jobTitle: string;
  email: string;
  phone: string;
  mobilePhone: string;
}

export interface Buyer extends BaseBuyerData {
  id: string;
  isActive: boolean;
  kycConfirmed: boolean;
  hasUsers: boolean;
  buyerGroups: BuyerGroup[];
  kycDataStatus?: CompanyKycDataStatusEnum;
  kycStatus?: KycStatusEnum;
}

export interface UpdateBuyerData extends BaseBuyerData {}

export interface BuyerOption {
  id: string;
  companyName: string;
}

export interface PortfolioBuyer extends Buyer {
  hasAccess: boolean;
  ndaAccepted: boolean;
  kycDataStatus: CompanyKycDataStatusEnum;
}

export interface NewBuyerGroup {
  name: string;
}

export interface UpdateBuyerGroup extends NewBuyerGroup {
  id: number;
}

export interface BuyerGroup extends UpdateBuyerGroup {
  buyersCount: number;
}

export interface RegisteredUser extends BaseUser {
  isActive: boolean;
}

export interface Portfolio {
  id: number;
  identifier: string;
  user?: string;
  seller: string;
  sellerName: string;
  creationDate: string;
  latestSnapshot: string;
  ndaFile: string;
  ndaFileId?: number;
  isSold: boolean;
  hasWinningBid: boolean;
  hasInvitedBuyers: boolean;
  hasUnpublishedChanges: boolean;
  hasUnpublishedAuctionChanges: boolean;
  auction?: PortfolioAuction;
}

export enum PortfolioImportStatus {
  NEW = 'NEW',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface PortfolioImport {
  id: number;
  fileName: string;
  user: string;
  status: PortfolioImportStatus;
  sourceFile: string;
  overwriteData: boolean;
  portfolio?: string;
  portfolioId?: number;
  portfolioSnapshot?: string;
  start?: string;
  end?: string;
  errorCount: number;
}

interface PortfolioBalance {
  original: number;
  outstanding: number;
  currency: string;
}

interface PortfolioKpis {
  weightedAverageMaturity: string;
  borrowerGroupsCount: number;
  waLtv?: number;
  totalCollateralisation?: number;
  totalRev?: number;
  totalCollateral?: number;
  propertiesCount?: number;
  loansCount: number;
  currency: string;
}

export interface PortfolioAuction {
  id: number;
  startDate: string;
  endDate: string;
  qaStartDate: string;
  qaEndDate: string;
  isNonBindingAuction: boolean;
  highestBid?: number;
  userHighestBid?: number;
  isUserBidHighest: boolean;
  minimumBid: number;
  currency: string;
}

export enum AuctionType {
  NON_BINDING = 'NON_BINDING',
  BINDING = 'BINDING',
}

export enum PriceVisibility {
  PUBLIC = 'PUBLIC',
  ANONYMOUS = 'ANONYMOUS',
}

export enum AuctionStage {
  UPCOMING = 'UPCOMING',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
}

export enum AuctionStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

export interface PortfolioAuctionAdmin {
  id: number;
  startDate: string;
  endDate: string;
  qaStartDate: string;
  qaEndDate: string;
  snapshotName: string;
  type: AuctionType;
  minimumBid: number;
  minimumBidIncrease: number;
  priceVisibility: PriceVisibility;
  endNotificationsSent: boolean;
  isEditable: boolean;
  highestBid?: number;
  currency: string;
  isConcluded: boolean;
  stage: AuctionStage;
  status: string;
  parentHasBids: boolean;
}

export interface UpdatePortfolioAuction
  extends Omit<
    PortfolioAuctionAdmin,
    | 'snapshotName'
    | 'endNotificationsSent'
    | 'isEditable'
    | 'isConcluded'
    | 'stage'
    | 'currency'
    | 'status'
    | 'parentHasBids'
  > {}

export interface NewPortfolioAuction extends Omit<UpdatePortfolioAuction, 'id'> {}

export enum BidStatus {
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface AuctionBidBuyer {
  buyerId: string;
  buyerName: string;
  hasAccess: boolean;
}

export interface AuctionBid extends AuctionBidBuyer {
  id: number;
  user: string;
  madeAt: string;
  price: number;
  currency: string;
  status: BidStatus;
}

export interface AuctionBidsResponse {
  bids: AuctionBid[];
  extraBuyers: AuctionBidBuyer[];
}

export interface SplitValue {
  name: string;
  split: number;
}

export interface PortfolioDocument {
  id: number;
  filename: string;
}

export interface PortfolioDocumentAdmin {
  id: number;
  name: string;
  url: string;
  filename: string;
}

export enum PortfolioSnapshotStatus {
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  PROCESSING = 'Processing',
  INACTIVE = 'Inactive',
}

export interface PortfolioSnapshotBase {
  id: number;
  identifier: string;
  description: string;
  name: string;
  originatorType: string;
  servicing: string;
  servicer: string;
  cutoffDate: string;
  status: PortfolioSnapshotStatus;
  user: string;
  creationDate: string;
  portfolio: number;
}

export interface PortfolioSnapshot extends PortfolioSnapshotBase {
  hasCollateral: boolean;
  totalBalance: PortfolioBalance;
  kpis: PortfolioKpis;
  assetClassSplit: SplitValue[];
  productTypeSplit: SplitValue[];
  regionalSplit?: SplitValue[];
  auction?: PortfolioAuction;
  bindingAuction?: PortfolioAuction;
  nonBindingAuction?: PortfolioAuction;
  documents: PortfolioDocument[];
  dataQuality?: string;
  baseCurrency: string;
  ndaFile: string;
  ndaAccepted: boolean;
  ndaFileId?: number;
  portfolio: number;
  qaPeriodActive: boolean;
  seller: string;
  hasTermSheet: boolean;
}

export interface PortfolioSnapshotAdmin extends Omit<PortfolioSnapshot, 'documents'> {
  documents: PortfolioDocumentAdmin[];
  loanDocumentsImports: LoanDocumentsImport[];
  isLatest: boolean;
  termSheet?: string;
  sourceFile: string;
}

export interface LoanDocumentsImport {
  id: number;
  url: string;
  status: PortfolioImportStatus;
  creationDate: string;
  start: string;
  end: string;
  user: string;
  documentCount: number;
}

export interface ConvertedBalance {
  balance: number;
  currency: string;
}

export interface LoanDocument {
  id: number;
  name: string;
  url: string;
}

export interface Loan {
  // mandatory fields
  id: number;
  contractIdentifier: string;
  dateOfOrigination: string;
  governingLawOfLoanAgreement: string;
  assetClass: string;
  loanPurpose: string;
  productType: string;
  amortisationType: string;
  currentMaturityDate: string;
  loanCurrency: string;
  originationAmount: number;
  originationAmountConverted: ConvertedBalance;
  principalBalance: number;
  legalBalance: number;
  legalBalanceConverted: ConvertedBalance;
  currentInterestRate: number;
  currentInterestRateType: string;
  lastPaymentDate: string;
  lastPaymentAmount: number;
  lastPaymentAmountConverted: ConvertedBalance;
  interestPaymentFrequency: string;
  principalPaymentFrequency: string;
  capitalisedPastdueAmount: number;
  capitalisedPastdueAmountConverted: ConvertedBalance;
  daysInPastdue: number;
  loanStatus: string;
  hasCollateral: boolean;
  hasForbearance: boolean;
  hasScheduledRepayments: boolean;
  // optional fields
  finalBulletRepayment?: number;
  finalBulletRepaymentConverted?: ConvertedBalance;
  currentInterestBaseRate?: number;
  currentInterestMargin?: number;
  descriptionOfBespokeRepayment?: string;
  currentInterestRateReference?: string;
  interestCapRate?: number;
  interestFloorRate?: number;
  dateOfDefault?: string;
  chargeoffDate?: string;
  totalPastdueAmount?: number;
  countryOfOrigination?: string;
  balanceAtDefault?: number;
  balanceAtDefaultConverted?: ConvertedBalance;
  // related objects
  borrower: Counterparty;
  documents: LoanDocument[];
}

export interface Counterparty {
  id: number;
  identifier: string;
  name: string;
  counterpartyRole: string;
  legalTypeOfCounterparty: string;
  countryOfRegisteredLocation: string;
  industrySegment?: string;
}

export interface Collateral {
  // mandatory fields
  id: number;
  protectionIdentifier: string;
  collateralType: string;
  hasEnforcement: boolean;
  // optional fields
  latestValuationAmount?: number;
  latestValuationAmountConverted?: ConvertedBalance;
  dateOfLatestValuation?: string;
  enforcementStatus?: string;
  enforcementStatusThirdParties?: string;
  lienPosition?: number;
  higherRankingLoan?: number;
  enforcementAmount?: number;
  enforcementAmountConverted?: ConvertedBalance;
  enforcementCost?: number;
  enforcementCostConverted?: ConvertedBalance;
}

export interface PropertyCollateral extends Collateral {
  // mandatory fields
  currencyOfProperty: string;
  propertyCountry: string;
  // optional fields
  sectorOfProperty?: string;
  typeOfProperty?: string;
  typeOfOccupancy?: string;
  geographicRegionOfProperty?: string;
  tenure?: string;
  currentNetOperatingIncome?: number;
  currentNetOperatingIncomeConverted?: ConvertedBalance;
  percentageComplete?: number;
  mortgageAmount?: number;
  mortgageAmountConverted?: ConvertedBalance;
}

export interface NonPropertyCollateral extends Collateral {
  // mandatory fields
  currencyOfCollateral: string;
  // optional fields
  guaranteeAmount?: number;
  guaranteeAmountConverted?: ConvertedBalance;
}

export interface LoanScreeningRow {
  key: string;
  balance: number;
  numberOfLoans: number;
  percentBalance: number;
}

export interface RepaymentScreeningRow {
  key: string;
  balance: number;
}

export interface LoanScreening {
  originationYear: LoanScreeningRow[];
  interestRate: LoanScreeningRow[];
  maturityYear: LoanScreeningRow[];
  legalVintage: LoanScreeningRow[];
  loanSizeDistribution: LoanScreeningRow[];
  loanToValue: LoanScreeningRow[];
  loanRepaymentsByYear: RepaymentScreeningRow[];
  currency: string;
}

export interface CollateralScreeningRow {
  key: string;
  value: number;
  percentValue: number;
}

export interface PropertyCollateralScreeningRow extends CollateralScreeningRow {
  numberOfProperties: number;
}

export interface PropertyCollateralScreening {
  marketValue: PropertyCollateralScreeningRow[];
  appraisalSeasoning: PropertyCollateralScreeningRow[];
  propertyConcentration: PropertyCollateralScreeningRow[];
  propertyType: PropertyCollateralScreeningRow[];
  currency: string;
}

export interface NonPropertyCollateralScreening {
  collateralType: CollateralScreeningRow[];
  currencyOfCollateral: CollateralScreeningRow[];
  latestValuation: CollateralScreeningRow[];
  currency: string;
}

export interface Orderbook {
  id: number;
  price: number;
  isHighest: boolean;
  status: string;
  portfolioId: number;
  portfolioIdentifier: string;
  portfolioCurrency: string;
  madeAt: Date;
  auctionType: string;
  isOpen: boolean;
  user: string;
}

export interface AuthorizedRepresentative {
  name: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  dateOfBirth: string | null;
  placeOfBirth: string;
  citizenship: string[];
  documentNumber: string;
  documentValidFrom: string | null;
  documentValidUntil: string | null;
  typeOfAuthority: string;
  department: string;
  mobilePhone: string;
  pepStatus: boolean;
  pepInfo?: string;
}

export interface UltimateBeneficialOwner {
  name: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  dateOfBirth: string;
  placeOfBirth: string;
  citizenship: string[];
  documentNumber: string;
  documentValidFrom: string;
  documentValidUntil: string;
  taxpayerIdentificationNumber: string;
  pepStatus: boolean;
  pepInfo?: string;
}

export interface BasicCompanyKycInfo {
  companyName: string;
  legalForm: string;
  competentRegistrationAuthority: string;
  commercialRegisterNumber: string;
  taxId: string;
  legalEntityIdentifier: string;
  typeOfBusiness: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  website: string;
  nameOfRepresentative: string;
  phone: string;
  email: string;
}

export interface NewCompanyKycData extends BasicCompanyKycInfo {
  authorizedRepresentative?: AuthorizedRepresentative;
  ultimateBeneficialOwners: UltimateBeneficialOwner[];
  listOfAuthorizedRepresentatives: string;
  listOfAuthorizedSignatories: string;
  structureChart: string;
  commercialRegisterExcerpt: string;
  personalDocuments: string;
  noBeneficialOwners: boolean;
  noBeneficialOwnersExplanation: string;
}

export interface CompanyKycData extends NewCompanyKycData {
  id: number;
  status: CompanyKycDataStatusEnum;
}

export interface CompanyUser {
  id: number;
  fullName: string;
  email: string;
  companyStatus: CompanyUserStatusEnum;
}

export interface CompanyUserDetails extends CompanyUser {
  companyName: string;
  jobTitle: string;
  phone: string;
  mobilePhone: string;
}

export interface CompanyUserUpdateData {
  fullName: string;
  jobTitle: string;
  phone: string;
  mobilePhone: string;
  companyStatus: CompanyUserStatusEnum;
}

export interface UserInviteRequestData {
  name: string;
  email: string;
}

// eslint-disable-next-line no-shadow
export enum OrderbookOrdering {
  madeAtAsc = 'made_at',
  madeAtDesc = '-made_at',
  priceAsc = 'price',
  priceDesc = '-price',
}

// eslint-disable-next-line no-shadow
export enum KycStatusEnum {
  UNCONFIRMED = 'UNCONFIRMED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
}

// eslint-disable-next-line no-shadow
export enum CompanyKycDataStatusEnum {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
}

// eslint-disable-next-line no-shadow
export enum CompanyUserStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}

// API types

export enum PortfolioChatMessageTypeEnum {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

export enum GeneralChatMessageTypeEnum {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export interface PortfolioMessageAttachment {
  id: number;
  name: string;
  size: number;
  url: string;
}

export interface PortfolioChatMessage {
  id: number;
  text: string;
  email: string;
  createdAt: Date;
  type: PortfolioChatMessageTypeEnum;
  attachments: PortfolioMessageAttachment[];
}

export interface AnnouncementAttachment extends PortfolioMessageAttachment {}

export interface PortfolioAnnouncement {
  id: number;
  text: string;
  createdAt: Date;
  attachments: AnnouncementAttachment[];
}

export interface ListResponse<T> {
  count: number;
  next?: string;
  results: T[];
}

export interface PortfolioChatConversationList {
  companyId: string;
  companyName: string;
  conversationId: number;
  unreadMessageCount: number;
}

export interface GeneralChatConversationBase {
  conversationId: number;
  conversationTitle: string;
  conversationCreatedAt: string;
  unreadMessageCount: number;
}

export interface GeneralChatConversationList extends GeneralChatConversationBase {
  companyId: string;
  companyName: string;
  // conversationId: number;
  // conversationTitle: string;
  // conversationCreatedAt: string;
  // unreadMessageCount: number;
}

export interface GeneralChatAnnouncementConversationList {
  conversationId: number;
  conversationTitle: string;
  conversationCreatedAt: string;
  unreadMessageCount: number;
}

export interface ListResponse<T> {
  count: number;
  next?: string;
  results: T[];
}

export interface GenerateNDAResponse {
  ndaFile: string;
}

export interface WorkspaceConfiguration {
  enableRegistration: boolean;
  primaryColor: string;
  accentColor1: string;
  accentColor2: string;
  backgroundColor: string;
  navbarLogoImage?: string;
  loginLogoImage?: string;
  loginBackgroundImage?: string;
}

export interface AuditLogEvent {
  id: number;
  timestamp: string;
  user: string;
  buyer?: string;
  event: string;
  description?: string;
}
