import React from 'react';
import { TableBody } from '@mui/material';
import NoData from './NoData';
import TableLoadingOverlay from './TableLoadingOverlay';

interface Props<T> {
  data: Array<T>;
  renderRow: (row: T) => React.ReactNode;
  isLoading?: boolean;
}

export default function CustomTableBody<T>({ data, renderRow, isLoading = false }: Props<T>) {
  return (
    <TableBody style={{ position: 'relative' }}>
      {data.length ? data.map((row) => renderRow(row)) : <NoData />}
      <TableLoadingOverlay isLoading={isLoading} transparent={Boolean(data.length)} />
    </TableBody>
  );
}
