import { CircularProgress } from '@mui/material';

export default function ThreadsLoader() {
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress size={24} />
      <span style={{ marginTop: 10, color: '#5A676E', fontSize: 14 }}>Loading threads</span>
    </div>
  );
}
