import React, { useState } from 'react';
import styles from '../styles.module.scss';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddAdminModal from './AddAdminModal';

export default function AdminHeader() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className={styles.tabContent__header}>
        <Typography variant="h2">Admins</Typography>
        <Button variant="outlined" color="grey" startIcon={<AddIcon />} onClick={() => setOpenModal(true)}>
          Add admin
        </Button>
      </div>
      <AddAdminModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
}
