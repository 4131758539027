import { Button, CircularProgress, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import styles from '../auth.module.scss';
import Plus from '../../shared/components/icons/Plus';
import { useLocation } from 'react-router-dom';
import useApi from '../../shared/hooks/useApi';
import { PhoneNumberField } from '../../shared/components/form_inputs/common';

interface Props {
  setSuccess: (value: boolean) => void;
  setError: (value: boolean) => void;
}

export default function RegisterForm({ setSuccess, setError }: Props) {
  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(requiredMsg),
    email: Yup.string().email('E-mail is not valid.').required(requiredMsg),
    phone: Yup.string().phone().required(),
    mobilePhone: Yup.string().phone().required(),
    companyName: Yup.string().required(requiredMsg),
    jobTitle: Yup.string().required(requiredMsg),
    password: Yup.string().min(6, 'Password has to be longer than 6 characters.').required(requiredMsg),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords are not the same.')
      .required(requiredMsg),
  });

  const { search } = useLocation();
  const refCode = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('ref');
  }, [search]);

  const { client } = useApi();

  return (
    <Formik
      initialValues={{
        fullName: '',
        email: '',
        phone: '',
        mobilePhone: '',
        companyName: '',
        jobTitle: '',
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          setError(false);
          await client.post('/auth/users/', { ...values, refCode });
          setSuccess(true);
        } catch (e: any) {
          setErrors(e.response.data);
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {({ errors, touched, values, isSubmitting, setFieldValue }) => (
        <Form className={styles.form}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Field component={TextField} variant="standard" name="fullName" label="Full Name" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field component={TextField} variant="standard" name="email" type="email" label="Email" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field component={PhoneNumberField} variant="standard" name="phone" label="Phone Number" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={PhoneNumberField}
                variant="standard"
                name="mobilePhone"
                label="Mobile Number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field component={TextField} variant="standard" name="companyName" label="Company" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field component={TextField} variant="standard" name="jobTitle" label="Job Title" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                variant="standard"
                type="password"
                label="Password"
                name="password"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                variant="standard"
                type="password"
                label="Repeat Password"
                name="passwordConfirmation"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                size="large"
                variant="outlined"
                color="primary"
                fullWidth
                endIcon={!isSubmitting && <Plus />}
              >
                {isSubmitting ? <CircularProgress color="primary" size={24} /> : 'Create account'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
