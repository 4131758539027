import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import styles from '../card.module.scss';
import { PortfolioAuction, PortfolioSnapshot } from '../../../../shared/types';
import CardTitle from '../CardTitle';
import AlertMessage from '../../../../shared/components/AlertMessage';
import LabeledValue from '../../../../shared/components/LabeledValue';
import { formatCurrency } from '../../../../shared/utils/helpers';
import NumberFormatInput from './NumberFormatInput';
import useApi from '../../../../shared/hooks/useApi';
import { useTheme } from '@mui/material/styles';

interface Props {
  portfolio: PortfolioSnapshot;
  auction: PortfolioAuction;
  open: boolean;
  onClose: () => void;
}

const requiredMsg = 'This field cannot be empty.';

export default function BidModal({ portfolio, auction, open, onClose }: Props) {
  const [bidSubmitted, setSubmitted] = useState(false);

  const api = useApi();

  const queryClient = useQueryClient();

  const mutationBid = useMutation({
    mutationFn: (bidAmount: number) => api.bid.make(auction.id, bidAmount),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchPortfolioSnapshots']);
      enqueueSnackbar('Bid placed successfully', { variant: 'success' });
      setSubmitted(true);
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to place bid', { variant: 'error' });
    },
  });

  const validationSchema = useMemo(() => {
    if (auction.minimumBid) {
      return Yup.object().shape({
        bidAmount: Yup.number()
          .min(auction.minimumBid || 0, 'Bid value must be more than minimum.')
          .typeError('Value must be a number.')
          .required(requiredMsg),
        confirmOffer: Yup.bool()
          .test('confirmOffer', 'Please confirm the bid.', (value) => value === true)
          .required(requiredMsg),
      });
    } else {
      return Yup.object().shape({});
    }
  }, [auction]);

  const { enqueueSnackbar } = useSnackbar();

  const { highestBid, isNonBindingAuction, isUserBidHighest, minimumBid } = auction;

  const theme = useTheme();

  return (
    <Formik
      initialValues={{
        bidAmount: minimumBid,
        confirmOffer: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        mutationBid.mutate(values.bidAmount);
      }}
      enableReinitialize
    >
      {({ values, errors, touched, dirty, isValid, submitForm }) => (
        <Form>
          <Dialog maxWidth={false} open={open} onClose={onClose}>
            <DialogTitle>
              <Box display="flex" justifyContent="space-between">
                <CardTitle portfolio={portfolio} modal />
                {onClose ? (
                  <IconButton
                    className={styles.modal__btnClose}
                    onClick={onClose}
                    size="large"
                    data-testid="close-modal"
                  >
                    <CloseIcon fontSize="large" />
                  </IconButton>
                ) : null}
              </Box>
            </DialogTitle>
            {bidSubmitted ? (
              <DialogContent>
                <Typography
                  className={styles.modal__confirmationText}
                  variant="h2"
                  data-testid="bid-placed-notification"
                >
                  A confirmation email has been sent. Please confirm your bid by clicking on the link.
                </Typography>
              </DialogContent>
            ) : (
              <>
                <DialogContent>
                  <Grid container spacing={2} className={styles.modal__content}>
                    {isNonBindingAuction && (
                      <Grid className={styles.modal__form__row} item xs={12}>
                        <AlertMessage hideIcon severity="warning" message="This is a non-binding auction." />
                      </Grid>
                    )}
                    {isUserBidHighest && (
                      <Grid className={styles.modal__form__row} item xs={12}>
                        <AlertMessage severity="success" message="You have the highest bid." />
                      </Grid>
                    )}
                    {highestBid && (
                      <Grid className={styles.modal__form__row} item xs={12}>
                        <LabeledValue
                          label="Current portfolio price:"
                          value={formatCurrency(highestBid, portfolio.baseCurrency)}
                          variant="vertical"
                          alignItems="center"
                          labelSize="20px"
                          labelColor="#303334"
                          valueSize="28px"
                          valueColor={theme.palette.primary.main}
                        />
                      </Grid>
                    )}
                    <Grid className={`${styles.modal__form__row} ${styles.modal__priceInput}`} item xs={12}>
                      <Typography className={styles.modal__label}>How much would you like to bid?</Typography>
                      <Field
                        className={styles.modal__bidFormControl}
                        component={TextField}
                        name="bidAmount"
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumberFormatInput as unknown,
                          inputProps: {
                            currency: portfolio.baseCurrency,
                          },
                        }}
                      />

                      <Typography className={styles.modal__minBid}>{`Minimum amount: ${formatCurrency(
                        minimumBid,
                        portfolio.baseCurrency,
                      )}`}</Typography>
                    </Grid>
                    <Grid className={styles.modal__form__row} item xs={12}>
                      <FormControlLabel
                        className="test"
                        control={
                          <Field
                            component={Switch}
                            color="primary"
                            type="checkbox"
                            name="confirmOffer"
                            checked={values.confirmOffer}
                          />
                        }
                        label={
                          isNonBindingAuction
                            ? 'With confirmation of this indicative offer, you express an honest interest in buying the loan portfolio at the price indicated in the offer. This is a non-binding offer. Please check the auction status in the order book.'
                            : 'With confirmation of this order, you submit a binding offer, which may be accepted by the seller. Please check the status of your order in the order book.'
                        }
                        classes={{ label: styles.modal__switchLabel }}
                      />
                      {errors.confirmOffer && touched.confirmOffer ? (
                        <div className={styles.validationError}>{errors.confirmOffer}</div>
                      ) : null}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className={styles.modal__actions} sx={{ backgroundColor: theme.palette.primary.main }}>
                  <Button
                    onClick={submitForm}
                    className={styles.modal__btnSubmit}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!(dirty && isValid) || mutationBid.isLoading}
                  >
                    {mutationBid.isLoading && (
                      <div style={{ position: 'absolute', marginRight: 170, display: 'flex', alignItems: 'center' }}>
                        <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.26)', marginRight: 10 }} size={24} />
                      </div>
                    )}
                    {mutationBid.isLoading ? 'Placing your bid...' : 'Place your bid'}
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
