import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import AuthLayout from '../AuthLayout';
import styles from '../auth.module.scss';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import { useAuth } from '../../shared/hooks/useAuth';
import Confirm2FAForm from './Confirm2FAForm';

export default function Setup2FA() {
  const { tempAccessToken, isLoggedIn } = useAuth();

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  if (isLoggedIn) {
    navigate('/login');
  }

  return tempAccessToken ? (
    <AuthLayout
      title="Setup 2FA"
      topLink={
        <Box display="flex" alignItems="baseline" className={styles.linkContainer}>
          <Typography>Already have 2FA token?</Typography>
          <Button className={styles.linkButton} onClick={() => navigate('/login')} endIcon={<ArrowRight />}>
            Log in to your account
          </Button>
        </Box>
      }
      errorMsg={error ? 'Failed to confirm 2FA activation.' : ''}
    >
      <Confirm2FAForm setError={setError} token={tempAccessToken} />
    </AuthLayout>
  ) : (
    <Navigate to="/login" />
  );
}
