import { Field, Form, Formik } from 'formik';
import { Button, Grid, Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import ArrowRight from '../../shared/components/icons/ArrowRight';
import styles from '../auth.module.scss';
import { useAuth } from '../../shared/hooks/useAuth';
import { CircularProgress } from '@mui/material';

interface Props {
  setError: (value: boolean) => void;
  ephemeralToken: string;
}

export default function LoginCodeForm({ setError, ephemeralToken }: Props) {
  const requiredMsg = 'This field cannot be empty.';
  const validationSchema = Yup.object({
    code: Yup.string()
      .matches(/[0-9]{6}/, 'Must be valid 6-digit code')
      .required(requiredMsg),
  });

  const { loginCode } = useAuth();

  const { search } = useLocation();
  const refCode = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('ref');
  }, [search]);

  return ephemeralToken ? (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setError(false);
        try {
          await loginCode({ ephemeralToken, refCode, ...values });
        } catch (e) {
          setError(true);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <>
          <Typography className={styles.login2FaExplain}>
            Please provide the six-digit numerical code from your authenticator app, which will be different from the
            code you used to register your 2FA method. This code does not require scanning a QR code again, instead your
            MFA application will automatically generate a login code valid for 30 seconds.
          </Typography>
          <Form className={styles.form}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Field component={TextField} variant="standard" name="code" label="2FA Code" fullWidth autoFocus />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  size="large"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} /> : <ArrowRight />}
                >
                  {isSubmitting ? 'Logging in' : 'Log in'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  ) : (
    <Navigate to="/login" />
  );
}
