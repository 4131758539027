import React, { useState } from 'react';
import styles from '../../../styles.module.scss';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddUserModal from './AddUserModal';
import { Buyer } from '../../../../shared/types';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface Props {
  buyer: Buyer;
  clearSelectedBuyer: () => void;
}
export default function UserHeader({ buyer, clearSelectedBuyer }: Props) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className={styles.tabContent__header}>
        <Typography variant="h2">{`${buyer.companyName} users`}</Typography>
        <Box display="flex" gap="10px">
          <Button variant="outlined" color="grey" startIcon={<NavigateBeforeIcon />} onClick={clearSelectedBuyer}>
            Back to buyers
          </Button>
          <Button
            className={styles.tabContent__header__button}
            variant="outlined"
            color="grey"
            startIcon={<AddIcon />}
            onClick={() => setOpenModal(true)}
          >
            Add user
          </Button>
        </Box>
      </div>
      <AddUserModal open={openModal} onClose={() => setOpenModal(false)} buyerId={buyer.id} />
    </>
  );
}
