import React from 'react';

export default function Plus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-302 -2643)">
        <rect
          style={{ fill: 'inherit', opacity: 0 }}
          className="a"
          width="16"
          height="16"
          transform="translate(302 2643)"
        />
        <g transform="translate(305 2646)">
          <g style={{ stroke: 'inherit', fill: 'inherit' }} className="b" transform="translate(0 4)">
            <rect style={{ stroke: 'none' }} className="c" width="10" height="2" />
            <rect style={{ fill: 'none' }} className="d" x="0.5" y="0.5" width="9" height="1" />
          </g>
          <g style={{ stroke: 'inherit', fill: 'inherit' }} className="b" transform="translate(6) rotate(90)">
            <rect style={{ stroke: 'none' }} className="c" width="10" height="2" />
            <rect style={{ fill: 'none' }} className="d" x="0.5" y="0.5" width="9" height="1" />
          </g>
        </g>
      </g>
    </svg>
  );
}
