import { Tooltip } from '@mui/material';
import LabeledValue from '../../../shared/components/LabeledValue';
import { PortfolioAuction } from '../../../shared/types';
import { formatCurrencyCompact } from '../../../shared/utils/helpers';
import styles from './card.module.scss';
import CardAuctionSeparator from './CardAuctionSeparator';

interface Props {
  baseCurrency: string;
  auction: PortfolioAuction;
}

export default function CardAuctionPrice({ auction, baseCurrency }: Props) {
  const { highestBid, isUserBidHighest, userHighestBid } = auction;

  return (
    <>
      {Boolean(highestBid || userHighestBid) && <CardAuctionSeparator />}
      {highestBid && (
        <div className={styles.subheader__item}>
          <LabeledValue
            label="Highest bid"
            value={formatCurrencyCompact(highestBid, baseCurrency)}
            valueSize="12px"
            valueColor="#5A676E"
            marginTop="0px"
          />
        </div>
      )}
      {userHighestBid && (
        <div className={styles.subheader__item} style={{ marginLeft: 10 }}>
          <Tooltip title={isUserBidHighest ? 'You have the highest bid' : "You've been outbid"}>
            <span>
              <LabeledValue
                label="Your bid"
                value={formatCurrencyCompact(userHighestBid, baseCurrency)}
                valueSize="12px"
                valueColor={isUserBidHighest ? '#2AC4AF' : '#F6A26C'}
                marginTop="0px"
              />
            </span>
          </Tooltip>
        </div>
      )}
    </>
  );
}
