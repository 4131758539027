import * as React from 'react';

const MessageCenterIcon = () => (
  <svg width={32} height={32}>
    <g transform="translate(-1736 -28)">
      <circle data-name="Ellipse 134" cx={16} cy={16} r={16} transform="translate(1736 28)" fill="#c4c4c4" />
      <path
        data-name="Path 5592"
        d="M1749.862 46.592v-1.721a4.791 4.791 0 0 1 .149-1.354 1.661 1.661 0 0 1 .47-.769 2.848 2.848 0 0 1 .826-.493l.574-.252a3.055 3.055 0 0 0 .711-.436 1.034 1.034 0 0 0 .31-.551 4.062 4.062 0 0 0 .08-.895 2.254 2.254 0 0 0-.184-1.044.9.9 0 0 0-.619-.433 5.553 5.553 0 0 0-1.216-.1q-.321 0-.711.023l-.792.046q-.4.023-.8.057a5.857 5.857 0 0 0-.723.1v-2.64q.574-.115 1.273-.206t1.445-.138q.746-.046 1.365-.046a8.222 8.222 0 0 1 1.881.2 3.478 3.478 0 0 1 1.388.642 2.723 2.723 0 0 1 .849 1.239 5.888 5.888 0 0 1 .287 1.985 8.524 8.524 0 0 1-.115 1.5 3.166 3.166 0 0 1-.367 1.055 2.874 2.874 0 0 1-.619.734 4.173 4.173 0 0 1-.895.562l-.551.229a4.1 4.1 0 0 0-.608.3.963.963 0 0 0-.321.31 1.032 1.032 0 0 0-.126.447q-.023.275-.023.734v.918Zm-.275 5.185v-3.1h3.492v3.1Z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default MessageCenterIcon;
