import React, { useState, useMemo } from 'react';
import { Button, Typography } from '@mui/material';
import ChangePasswordModal from './ChangePasswordModal';
import { useAuth } from '../shared/hooks/useAuth';
import styles from './profile.module.scss';
import { KycStatusEnum } from '../shared/types';
import { useNavigate } from 'react-router-dom';
import PageLayout from '../shared/components/PageLayout';
import useUser from '../shared/hooks/useUser';
import { useTheme } from '@mui/material/styles';

interface RowProps {
  label: string;
  value: string;
  customStyle?: { color: string } | {};
}

const ProfileRow: React.FC<RowProps> = ({ label, value, customStyle = { color: '#5a676e' } }) => (
  <div className={styles.row}>
    <Typography className={styles.label} variant="h3">
      {label}
    </Typography>
    <Typography className={styles.value} sx={customStyle}>
      {value}
    </Typography>
  </div>
);

export default function UserProfilePage() {
  const { logout } = useAuth();
  const { user } = useUser();
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const userKycStatusText = useMemo(() => {
    switch (user?.kycStatus) {
      case KycStatusEnum.CONFIRMED:
        return 'Confirmed';
      case KycStatusEnum.PENDING:
        return 'Pending';
      case KycStatusEnum.UNCONFIRMED:
        return 'Unconfirmed';
      default:
        return '';
    }
  }, [user?.kycStatus]);

  const theme = useTheme();

  const userKycValueStyle = useMemo(() => {
    switch (user?.kycStatus) {
      case KycStatusEnum.CONFIRMED:
        return { color: theme.palette.accent1.main };
      case KycStatusEnum.PENDING:
        return { color: theme.palette.accent2.main };
      case KycStatusEnum.UNCONFIRMED:
        return { color: theme.palette.accent2.main };
      default:
        return {};
    }
  }, [theme.palette.accent1.main, theme.palette.accent2.main, user?.kycStatus]);

  if (!user) {
    return null;
  }

  return (
    <>
      <PageLayout>
        <Typography className={styles.title} variant="h2">
          User profile
        </Typography>
        <ProfileRow label={'Name:'} value={user.fullName} />
        <ProfileRow label={'Email:'} value={user.email} />
        {!user.isAdmin && <ProfileRow label={'Company:'} value={user.companyName} />}
        <ProfileRow label={'Job title:'} value={user.jobTitle} />
        <ProfileRow label={'Phone:'} value={user.phone} />
        <ProfileRow label={'Mobile phone:'} value={user.mobilePhone} />
        {!user.isAdmin && (
          <ProfileRow label={'KYC status:'} value={userKycStatusText} customStyle={userKycValueStyle} />
        )}
        <div className={styles.buttonContainer}>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            Change password
          </Button>
          <Button variant="contained" color="primary" onClick={logout}>
            Logout
          </Button>
          {!user.isAdmin && (
            <Button variant="contained" color="primary" onClick={() => navigate('/company_kyc')}>
              {user.company ? 'Edit KYC data' : 'Start KYC process'}
            </Button>
          )}
        </div>
      </PageLayout>
      <ChangePasswordModal open={open} onClose={() => setOpen(false)} />
    </>
  );
}
