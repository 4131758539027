import moment from 'moment';
import countries from 'i18n-iso-countries';
import { ConvertedBalance } from '../types';

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');
export const formatDateVerbose = (date: string) => moment(date).format('D MMM YYYY');
export const formatDateTime = (date: string) => moment(date).format('DD.MM.YYYY HH:mm:ss');

export const formatCurrency = (value: number, currency: string) =>
  new Intl.NumberFormat('en', {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

export const formatCurrencyCompact = (value: number, currency: string) =>
  new Intl.NumberFormat('en', { maximumSignificantDigits: 4, currency, notation: 'compact', style: 'currency' })
    .format(value)
    .toLocaleLowerCase();

export const formatConvertedAmount = (value: number, currency: string, convertedValue?: ConvertedBalance) => {
  if (!convertedValue) {
    return formatCurrency(value, currency);
  }
  return currency === convertedValue.currency
    ? formatCurrency(value, currency)
    : `${formatCurrency(value, currency)} (${formatCurrency(convertedValue.balance, convertedValue.currency)})`;
};

export const countryCodeToName = (code: string) => {
  return countries.getName(code, 'en', { select: 'official' });
};

export const getFileNameFromPath = (path: string) => path.replace(/^.*[\\/]/, '');

export const getFileNameFromContentDispositionHeader = (path: string) => {
  const filename = path
    .split(';')
    .find((item: string) => item.includes('filename='))
    ?.replace('filename=', '')
    .trim();
  return filename ? getFileNameFromPath(filename) : '';
};

export const downloadFileURLResponse = (url: string) => {
  let a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = getFileNameFromPath(url);
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
