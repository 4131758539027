import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Alert } from '@mui/lab';

const iconMap = {
  success: <CheckCircleIcon fontSize="inherit" />,
  warning: <ErrorIcon fontSize="inherit" />,
};

interface Props {
  severity: 'success' | 'warning';
  message: string;
  fontSize?: string;
  hideIcon?: boolean;
}

export default function AlertMessage({ severity, message, fontSize = '15px', hideIcon = false }: Props) {
  const getIcon = () => iconMap[severity];

  return (
    <Alert severity={severity} icon={hideIcon ? false : getIcon()} style={{ fontSize }}>
      {message}
    </Alert>
  );
}
