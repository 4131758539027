import React, { ReactNode, useState } from 'react';
import { IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './sider.module.scss';

interface Props {
  sider?: ReactNode;
  content: ReactNode;
}

export default function SiderLayout({ sider, content }: Props) {
  const [open, setOpen] = useState(true);

  return (
    <>
      {sider && open && (
        <div className={styles.drawer}>
          <IconButton
            className={`${styles.drawer__button} ${styles.drawer__button__open}`}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <NavigateBeforeIcon fontSize="large" /> : <NavigateNextIcon fontSize="large" />}
          </IconButton>
          <div className={styles.drawer__container}>{sider}</div>
        </div>
      )}
      <main className={`${styles.content} ${open ? styles.content__shifted : ''}`}>
        {sider && !open && (
          <IconButton
            className={`${styles.drawer__button} ${styles.drawer__button__collapsed}`}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <NavigateBeforeIcon fontSize="large" /> : <NavigateNextIcon fontSize="large" />}
          </IconButton>
        )}
        {content}
      </main>
    </>
  );
}
