import React from 'react';

export default function NavLogo() {
  return (
    <svg
      style={{ height: '30px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="196.813"
      height="35"
      viewBox="0 0 196.813 35"
    >
      <g transform="translate(-278 -48)">
        <path
          className="a"
          style={{ fill: '#2a7eaa' }}
          d="M72.348,10.159V10h-.2L60.73.952V10.64h-3.3V.952H45.817V20.119h7.261V32.5h-2.9v-9.85H42.251V.952H37.895V27.284l-2.9-2.3V.952H30.633V21.529l-2.9-2.3V.952H23.372V20.657l7.261,5.755v.16h.2l11.416,9.047v-9.71h3.566V35.952H57.434V16.666H50.173V4.4h2.9v9.482H60.73V35.952h4.357V9.288l2.9,2.3V35.952h4.357V15.042l2.9,2.3V35.952h4.357V15.914Z"
          transform="translate(254.628 47.048)"
        />
        <g transform="translate(347.003 54.964)">
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M.83,32.668h2.7L6.374,42.13h.054l3.034-9.462h2.567l3.208,9.462h.052l2.7-9.462h2.567l-4.089,12.67H14.033l-3.356-9.462h-.054L7.564,45.338H5Z"
            transform="translate(-0.83 -25.13)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M14.332,30.459a1.778,1.778,0,1,1,.521,1.243A1.7,1.7,0,0,1,14.332,30.459ZM14.894,35H17.3v12.67H14.894Z"
            transform="translate(7.103 -27.465)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M31.531,48.128H29.126V46.31h-.052a4.6,4.6,0,0,1-1.911,1.576,5.927,5.927,0,0,1-2.5.562A6.691,6.691,0,0,1,22,47.939a5.851,5.851,0,0,1-2.03-1.416,6.371,6.371,0,0,1-1.3-2.111,7.343,7.343,0,0,1-.454-2.618,7.463,7.463,0,0,1,.454-2.634,6.214,6.214,0,0,1,1.3-2.111,5.971,5.971,0,0,1,2.03-1.4,6.718,6.718,0,0,1,2.659-.508,5.885,5.885,0,0,1,2.567.575,4.4,4.4,0,0,1,1.845,1.564h.052V27.92h2.405ZM24.957,46.2a4.355,4.355,0,0,0,1.737-.333,3.832,3.832,0,0,0,1.31-.91,3.991,3.991,0,0,0,.829-1.389,5.529,5.529,0,0,0,0-3.556A4.02,4.02,0,0,0,28,38.625a3.85,3.85,0,0,0-1.31-.908,4.674,4.674,0,0,0-3.475,0,3.85,3.85,0,0,0-1.31.908,4.045,4.045,0,0,0-.829,1.389,5.529,5.529,0,0,0,0,3.556,4.015,4.015,0,0,0,.829,1.389,3.832,3.832,0,0,0,1.31.91A4.363,4.363,0,0,0,24.957,46.2Z"
            transform="translate(9.388 -27.92)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M30.988,40a3.166,3.166,0,0,0,.362,1.51,3.748,3.748,0,0,0,.949,1.164,4.369,4.369,0,0,0,1.362.748,4.9,4.9,0,0,0,1.6.267,3.583,3.583,0,0,0,1.951-.521,6.3,6.3,0,0,0,1.524-1.376l1.818,1.391a6.694,6.694,0,0,1-5.613,2.592,6.961,6.961,0,0,1-2.713-.51,5.873,5.873,0,0,1-2.057-1.4,6.2,6.2,0,0,1-1.3-2.11,7.425,7.425,0,0,1-.456-2.632,6.919,6.919,0,0,1,.5-2.634,6.523,6.523,0,0,1,1.362-2.111,6.222,6.222,0,0,1,2.072-1.4,6.672,6.672,0,0,1,2.619-.508,6.277,6.277,0,0,1,2.846.587,5.64,5.64,0,0,1,1.911,1.538,6.084,6.084,0,0,1,1.083,2.137,8.932,8.932,0,0,1,.333,2.419V40Zm7.592-1.924a5.075,5.075,0,0,0-.254-1.47,3.283,3.283,0,0,0-.681-1.164,3.163,3.163,0,0,0-1.137-.775,4.147,4.147,0,0,0-1.589-.281,3.842,3.842,0,0,0-1.618.335,3.908,3.908,0,0,0-1.243.868,4.022,4.022,0,0,0-.789,1.191,3.255,3.255,0,0,0-.281,1.3Z"
            transform="translate(15.382 -25.249)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M39.687,44.328a4.535,4.535,0,0,0,1.8,1.791,5.308,5.308,0,0,0,2.526.587,5.052,5.052,0,0,0,1.657-.267,4.2,4.2,0,0,0,1.349-.748,3.549,3.549,0,0,0,.91-1.164,3.451,3.451,0,0,0,.333-1.537,2.955,2.955,0,0,0-.414-1.63,3.324,3.324,0,0,0-1.11-1.056A8.176,8.176,0,0,0,45.18,39.6q-.869-.293-1.791-.587a19.08,19.08,0,0,1-1.791-.67,5.9,5.9,0,0,1-1.564-.975,4.333,4.333,0,0,1-1.11-1.51,5.4,5.4,0,0,1-.414-2.245,4.867,4.867,0,0,1,.5-2.232,4.771,4.771,0,0,1,1.335-1.63,5.936,5.936,0,0,1,1.938-.989,7.855,7.855,0,0,1,2.3-.333,7.441,7.441,0,0,1,2.873.548,5.457,5.457,0,0,1,2.284,1.83l-1.522,1.1a4.206,4.206,0,0,0-3.689-1.87,5.8,5.8,0,0,0-1.63.227,4.069,4.069,0,0,0-1.349.668,3.283,3.283,0,0,0-.922,1.108,3.382,3.382,0,0,0-.348,1.578A3.046,3.046,0,0,0,41,35.789a5.094,5.094,0,0,0,1.8,1.2,19.1,19.1,0,0,0,2.353.775,11.31,11.31,0,0,1,2.351.9,5.074,5.074,0,0,1,1.805,1.564,4.656,4.656,0,0,1,.721,2.765,5.128,5.128,0,0,1-.481,2.272,5.007,5.007,0,0,1-1.3,1.672,5.592,5.592,0,0,1-1.884,1.027,7.13,7.13,0,0,1-2.218.349,8.915,8.915,0,0,1-3.45-.656A6.316,6.316,0,0,1,38,45.451Z"
            transform="translate(21.01 -27.623)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M54.513,35.629H50.984v8.046a1.728,1.728,0,0,0,.456,1.283,1.692,1.692,0,0,0,1.256.454,3.734,3.734,0,0,0,.922-.119,5.953,5.953,0,0,0,.9-.308l.133,1.443a9.528,9.528,0,0,1-1.043.3,4.781,4.781,0,0,1-1.041.133,3.856,3.856,0,0,1-1.47-.254,2.536,2.536,0,0,1-1.537-1.684,4.277,4.277,0,0,1-.175-1.243V35.629H46.707V34.185H49.38V30.631h1.6v3.554h3.529Z"
            transform="translate(26.124 -26.327)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M53.474,36.464c0-.232-.01-.511-.027-.843s-.032-.662-.04-1-.022-.656-.04-.949-.027-.522-.027-.683h1.6c.017.464.032.91.04,1.337a4.327,4.327,0,0,0,.067.829,5.486,5.486,0,0,1,1.578-1.791,3.908,3.908,0,0,1,2.351-.7,2.676,2.676,0,0,1,.468.041c.151.025.306.057.467.094l-.186,1.576a1.946,1.946,0,0,0-.616-.108,4.449,4.449,0,0,0-1.8.335,3.387,3.387,0,0,0-1.256.921,3.882,3.882,0,0,0-.735,1.376,5.811,5.811,0,0,0-.241,1.7v6.736h-1.6Z"
            transform="translate(30.021 -25.13)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M69.891,43.012a5.415,5.415,0,0,1-2.313,2.03,7.36,7.36,0,0,1-3.034.616,6.55,6.55,0,0,1-2.565-.483A5.793,5.793,0,0,1,60,43.828a5.948,5.948,0,0,1-1.27-2.059,7.524,7.524,0,0,1-.44-2.632,7.065,7.065,0,0,1,.454-2.567,6,6,0,0,1,5.72-3.9,5.974,5.974,0,0,1,2.5.494,5.49,5.49,0,0,1,1.818,1.311,5.564,5.564,0,0,1,1.11,1.857,6.245,6.245,0,0,1,.373,2.111v1.122H59.894a5.469,5.469,0,0,0,.2,1.3,4.572,4.572,0,0,0,.735,1.538,4.663,4.663,0,0,0,1.43,1.283,4.468,4.468,0,0,0,2.311.533,5.165,5.165,0,0,0,2.38-.587,4.3,4.3,0,0,0,1.764-1.6Zm-1.23-4.891a3.654,3.654,0,0,0-.333-1.551,4.2,4.2,0,0,0-.91-1.27,4.309,4.309,0,0,0-1.364-.868,4.4,4.4,0,0,0-1.67-.321,4.1,4.1,0,0,0-2.178.535,4.767,4.767,0,0,0-1.376,1.23,4.855,4.855,0,0,0-.721,1.348,3.594,3.594,0,0,0-.214.9Z"
            transform="translate(32.93 -25.13)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M79.251,43.012a5.4,5.4,0,0,1-2.311,2.03,7.36,7.36,0,0,1-3.034.616,6.551,6.551,0,0,1-2.565-.483,5.778,5.778,0,0,1-1.978-1.348,5.924,5.924,0,0,1-1.27-2.059,7.524,7.524,0,0,1-.441-2.632,7.1,7.1,0,0,1,.454-2.567,6.131,6.131,0,0,1,1.283-2.043,5.979,5.979,0,0,1,4.437-1.859,5.981,5.981,0,0,1,2.5.494,5.435,5.435,0,0,1,2.926,3.169,6.209,6.209,0,0,1,.375,2.111v1.122H69.256a5.388,5.388,0,0,0,.2,1.3,4.571,4.571,0,0,0,.735,1.538,4.627,4.627,0,0,0,1.43,1.283,4.464,4.464,0,0,0,2.311.533,5.159,5.159,0,0,0,2.378-.587,4.285,4.285,0,0,0,1.764-1.6Zm-1.229-4.891a3.656,3.656,0,0,0-.335-1.551,4.163,4.163,0,0,0-.908-1.27,4.326,4.326,0,0,0-1.364-.868,4.411,4.411,0,0,0-1.67-.321,4.093,4.093,0,0,0-2.178.535,4.767,4.767,0,0,0-1.376,1.23,4.864,4.864,0,0,0-.722,1.348,3.566,3.566,0,0,0-.213.9Z"
            transform="translate(38.429 -25.13)"
          />
          <path
            className="a"
            style={{ fill: '#2a7eaa' }}
            d="M84.143,35.629H80.616v8.046a1.733,1.733,0,0,0,.454,1.283,1.7,1.7,0,0,0,1.256.454,3.724,3.724,0,0,0,.922-.119,5.953,5.953,0,0,0,.9-.308l.133,1.443a9.6,9.6,0,0,1-1.041.3,4.783,4.783,0,0,1-1.043.133,3.862,3.862,0,0,1-1.47-.254,2.613,2.613,0,0,1-.989-.683,2.663,2.663,0,0,1-.548-1,4.276,4.276,0,0,1-.173-1.243V35.629H76.339V34.185h2.673V30.631h1.6v3.554h3.527Z"
            transform="translate(43.533 -26.327)"
          />
        </g>
      </g>
    </svg>
  );
}
