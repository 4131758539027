import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { countryCodeToName, formatConvertedAmount, formatDate } from '../../../../shared/utils/helpers';
import ListPagination from '../../../../shared/components/ListPagination';
import styles from '../../details.module.scss';
import { Loan } from '../../../../shared/types';
import LoanDetails from './LoanDetails';
import useApi from '../../../../shared/hooks/useApi';
import CustomTableBody from '../../../../shared/components/table/CustomTableBody';

interface Props {
  portfolioId: number;
}

export default function LoansTab({ portfolioId }: Props) {
  const [activeLoan, setActiveLoan] = useState<Loan | undefined>(undefined);
  const [filterValues, setFilterValues] = useState<string[]>([]);

  const api = useApi();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const { data, isLoading, isFetching, isPreviousData } = useQuery(
    ['fetchPortfolioLoans', portfolioId, page, pageSize, filterValues],
    () => api.portfolio_snapshot.loans(portfolioId, page, pageSize, filterValues),
    { keepPreviousData: true },
  );

  const loans = useMemo(() => {
    if (data?.results) {
      return data.results;
    }
    return [];
  }, [data]);
  const loansCount = data?.count;

  // refresh data on currency change
  useEffect(() => {
    if (activeLoan) {
      const newLoan = _.find(loans, ({ id }) => id === activeLoan.id);
      setActiveLoan(newLoan);
    }
  }, [activeLoan, loans]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const renderPagination = () =>
    _.isNumber(loansCount) && (
      <ListPagination
        count={Math.ceil(loansCount / pageSize)}
        page={page}
        pageSize={pageSize}
        onChange={handleChange}
        onPageSizeChange={(event) => setPageSize(event.target.value as unknown as number)}
      />
    );

  const renderLoansTable = () => (
    <Grid container spacing={3} className={styles.loans__container}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          limitTags={4}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          disableCloseOnSelect
          freeSolo
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth={false}
              sx={{ minWidth: '500px' }}
              variant="outlined"
              label="Search loan IDs"
            />
          )}
          onChange={(event, value, reason, details) => {
            console.log(value);
            setFilterValues(value);
          }}
          value={filterValues}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Details</TableCell>
                <TableCell>Contract ID</TableCell>
                <TableCell align="center">Collateral</TableCell>
                <TableCell>Asset Class</TableCell>
                <TableCell>Loan Purpose</TableCell>
                <TableCell>Product Type</TableCell>
                <TableCell>Loan Currency</TableCell>
                <TableCell align="right">Legal Balance</TableCell>
                <TableCell align="right">Total Past Due Amount</TableCell>
                <TableCell align="right">Days in Past-Due</TableCell>
                <TableCell>Governing Law of Loan Agreement</TableCell>
                <TableCell>Current Maturity Date</TableCell>
              </TableRow>
            </TableHead>
            <CustomTableBody
              data={loans}
              isLoading={isLoading || (isFetching && isPreviousData)}
              renderRow={(loan) => {
                const {
                  id,
                  contractIdentifier,
                  hasCollateral,
                  assetClass,
                  loanPurpose,
                  productType,
                  loanCurrency,
                  legalBalance,
                  legalBalanceConverted,
                  capitalisedPastdueAmount,
                  capitalisedPastdueAmountConverted,
                  daysInPastdue,
                  governingLawOfLoanAgreement,
                  currentMaturityDate,
                } = loan;
                return (
                  <TableRow key={id}>
                    <TableCell align="center">
                      <IconButton
                        data-testid={`loan-details-${loan.contractIdentifier}`}
                        onClick={() => setActiveLoan(loan)}
                        size="large"
                      >
                        <SearchIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{contractIdentifier}</TableCell>
                    <TableCell align="center">{hasCollateral ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{assetClass}</TableCell>
                    <TableCell>{loanPurpose}</TableCell>
                    <TableCell>{productType}</TableCell>
                    <TableCell>{loanCurrency}</TableCell>
                    <TableCell align="right">
                      {formatConvertedAmount(legalBalance, loanCurrency, legalBalanceConverted)}
                    </TableCell>
                    <TableCell align="right">
                      {formatConvertedAmount(capitalisedPastdueAmount, loanCurrency, capitalisedPastdueAmountConverted)}
                    </TableCell>
                    <TableCell align="right">{daysInPastdue}</TableCell>
                    <TableCell>{countryCodeToName(governingLawOfLoanAgreement)}</TableCell>
                    <TableCell>{formatDate(currentMaturityDate)}</TableCell>
                  </TableRow>
                );
              }}
            />
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="start">
          {renderPagination()}
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Paper variant="outlined">
      {activeLoan ? <LoanDetails loan={activeLoan} onClose={() => setActiveLoan(undefined)} /> : renderLoansTable()}
    </Paper>
  );
}
