import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CardHeader } from '@mui/material';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { PortfolioSnapshot } from '../../../shared/types';
import styles from './card.module.scss';
import CardTitle from './CardTitle';
import NDAModal from './NDAModal';
import useUser from '../../../shared/hooks/useUser';
import useApi from '../../../shared/hooks/useApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PlaceBidButton from '../../PlaceBidButton';
import KycRequiredModal from '../../KycRequiredModal';

interface Props {
  portfolio: PortfolioSnapshot;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  setBidModalOpen: (value: boolean) => void;
}

export default function Header({ portfolio, expanded, setExpanded, setBidModalOpen }: Props) {
  const { id, ndaFile, ndaAccepted, ndaFileId } = portfolio;
  const [showNDAModal, setShowNDAModal] = useState(false);
  const [showKYCModal, setShowKYCModal] = useState(false);

  const { isKycDone, user } = useUser();

  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: mutateGenerateNda, data: ndaData } = useMutation(() => api.portfolio_snapshot.generateNda(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['fetchPortfolioSnapshots']);
    },
    onError(e) {
      console.error(e);
      enqueueSnackbar('Failed to generate NDA, please try again in a moment.', { variant: 'error' });
    },
  });

  const handleDataRoomButton = () => {
    if (!isKycDone) {
      return setShowKYCModal(true);
    }

    if (!ndaAccepted) {
      setShowNDAModal(true);
      if (!ndaFile) {
        mutateGenerateNda();
      }
    } else {
      navigate(`/marketplace/portfolio/${id}`);
    }
  };

  const nda = ndaFile ?? ndaData?.ndaFile;

  return (
    <CardHeader
      className={styles.header}
      title={<CardTitle portfolio={portfolio} />}
      action={
        <>
          {!user?.isAdmin && <PlaceBidButton portfolio={portfolio} />}
          <Button
            className={styles.header__button}
            variant={expanded ? 'contained' : 'outlined'}
            color={expanded ? 'primary' : 'grey'}
            startIcon={<SearchOutlinedIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Hide details' : 'Show details'}
          </Button>
          <Button
            className={styles.header__button}
            variant="outlined"
            color="primary"
            startIcon={<BarChartOutlinedIcon />}
            onClick={handleDataRoomButton}
            data-testid={`data-room-${portfolio.identifier}`}
          >
            Data room
          </Button>
          <NDAModal
            open={showNDAModal}
            onClose={() => setShowNDAModal(false)}
            portfolioId={id}
            ndaFile={nda}
            ndaFileId={ndaFileId}
            onAccepted={() => navigate(`/marketplace/portfolio/${id}`)}
          />
          <KycRequiredModal open={showKYCModal} onClose={() => setShowKYCModal(false)} />
        </>
      }
    />
  );
}
