import React, { ReactNode, useState } from 'react';
import { Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import styles from './accordion.module.scss';
import { withStyles } from '@mui/styles';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    padding: '0 20px',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '&:not(:last-of-type)': {},
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

interface AccordionTab {
  key: string;
  label: string;
  component: ReactNode;
}

interface Props {
  title?: string;
  tabs: AccordionTab[];
}

export default function CustomAccordion({ tabs, title }: Props) {
  const [expanded, setExpanded] = useState<string | false>(tabs[0].key);

  const handleChange = (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={styles.container}>
      {title && (
        <Typography className={styles.section__title} variant="h2">
          {title}
        </Typography>
      )}
      {tabs.map(({ key, label, component }) => (
        <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={styles.accordion__title} variant="h2">
              {label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{component}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
