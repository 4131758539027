import React, { useEffect, useMemo, useState } from 'react';
import SingleButtonModal from '../../shared/components/SingleButtonModal';
import { PortfolioImport, PortfolioImportStatus } from '../../shared/types';
import { Box, Button, Checkbox, Grid, List, ListItem, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
import styles from '../styles.module.scss';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getFileNameFromPath } from '../../shared/utils/helpers';
import { useSnackbar } from 'notistack';
import useAdminApi from '../../shared/hooks/useAdminApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

interface RowProps {
  label: string;
  children: JSX.Element;
}

function ContentRow({ label, children }: RowProps) {
  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center">
        <Grid container>
          <Grid item xs={4} display="flex" alignItems="center">
            <Typography className={styles.modal__fileLabel} variant="h3">
              {label}
            </Typography>
          </Grid>
          <Grid item xs={8} display="flex" alignItems="center">
            {children}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

interface ErrorListProps {
  importId: number;
}

function ErrorList({ importId }: ErrorListProps) {
  const [downloadLink, setDownloadLink] = useState('');

  const api = useAdminApi();
  const { data, isLoading } = useQuery(['adminFetchImportErrors', importId], () =>
    api.imports.fetchImportErrors(importId),
  );
  const errors = useMemo(() => data || [], [data]);

  useEffect(() => {
    const generateTextFile = () => {
      const data = new Blob([errors.join('\n')], { type: 'text/plain' });

      // this part avoids memory leaks
      if (downloadLink !== '') window.URL.revokeObjectURL(downloadLink);

      setDownloadLink(window.URL.createObjectURL(data));
    };
    generateTextFile();
    // eslint-disable-next-line
  }, [errors]);

  const { enqueueSnackbar } = useSnackbar();
  const copyToClipboard = () => {
    window.navigator.clipboard
      .writeText(errors.join('\n'))
      .then(() => {
        enqueueSnackbar('Copied errors to clipboard', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Failed to copy errors to clipboard', {
          variant: 'error',
        });
      });
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Skeleton variant="rounded" width={840} height={200} />
      ) : (
        <Paper variant="outlined" className={styles.errorList__container}>
          <List
            className={styles.errorList__list}
            sx={{
              listStyleType: 'disc',
              pl: 3,
            }}
          >
            {errors.slice(0, 99).map((error) => (
              <ListItem className={styles.errorList__listItem}>{error}</ListItem>
            ))}
            {errors.length > 100 && <Typography>Error list truncated. Download file to see all errors.</Typography>}
          </List>
          <div className={styles.errorList__buttonContainer}>
            <Tooltip title="Save to file">
              <Button
                size="small"
                variant="outlined"
                color="grey"
                className={styles.errorList__button}
                download="errors.txt"
                href={downloadLink}
              >
                <DownloadIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Copy to clipboard">
              <Button
                size="small"
                variant="outlined"
                color="grey"
                className={styles.errorList__button}
                onClick={copyToClipboard}
              >
                <ContentCopyIcon />
              </Button>
            </Tooltip>
          </div>
        </Paper>
      )}
    </Grid>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  portfolioImport?: PortfolioImport;
}

export default function ImportDetailsModal({ open, onClose, portfolioImport }: Props) {
  const [overwrite, setOverwrite] = useState(Boolean(portfolioImport?.overwriteData));

  useEffect(() => {
    setOverwrite(Boolean(portfolioImport?.overwriteData));
  }, [portfolioImport]);

  const api = useAdminApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  if (!portfolioImport) {
    return <div />;
  }

  const canRetry = [PortfolioImportStatus.FAILED, PortfolioImportStatus.IN_PROGRESS].includes(portfolioImport.status);

  const renderContent = () => {
    const { id, portfolio, portfolioId, portfolioSnapshot, sourceFile, errorCount } = portfolioImport;

    const hasErrors = Boolean(errorCount);

    const handleSourceFileDownload = () => {
      api.imports.downloadImport(portfolioImport.id, getFileNameFromPath(sourceFile)).catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to download portfolio import', { variant: 'error' });
      });
    };

    return (
      <Grid container spacing={3}>
        <ContentRow label="Portfolio:">
          <Typography>
            {portfolioId && portfolio && (
              <Link to={portfolioId.toString()} className={styles.portfolioLink}>
                {portfolio}
              </Link>
            )}
          </Typography>
        </ContentRow>
        <ContentRow label="Portfolio Snapshot:">
          <Typography>{portfolioSnapshot}</Typography>
        </ContentRow>
        <ContentRow label="Source File:">
          <Button
            className={styles.filesSection__downloadButton}
            onClick={handleSourceFileDownload}
            variant="text"
            startIcon={<DownloadIcon />}
          >
            {getFileNameFromPath(sourceFile)}
          </Button>
        </ContentRow>
        <ContentRow label="Overwrite existing data:">
          <Checkbox
            color="primary"
            checked={overwrite}
            onChange={(_, checked: boolean) => setOverwrite(checked)}
            disabled={!canRetry}
          />
        </ContentRow>
        <ContentRow label="Errors:">
          {hasErrors ? (
            <Typography className={styles.errorList__errorCount}>{`${errorCount} found`}</Typography>
          ) : (
            <Typography>None</Typography>
          )}
        </ContentRow>
        {hasErrors && <ErrorList importId={id} />}
      </Grid>
    );
  };

  const handleRetry = async () => {
    api.imports
      .rerunImport(portfolioImport.id, overwrite)
      .then(async () => {
        await queryClient.invalidateQueries(['adminFetchPortfolioImports']);
        enqueueSnackbar('Import rerun scheduled', { variant: 'success' });
        onClose();
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Failed to schedule import rerun', { variant: 'error' });
      });
  };

  return (
    <SingleButtonModal
      open={open}
      onClose={onClose}
      title="Import Details"
      content={renderContent()}
      buttonLabel="Try again"
      onClick={handleRetry}
      disabled={!canRetry}
      maxWidth="md"
    />
  );
}
