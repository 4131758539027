import { PortfolioDocumentAdmin } from '../../../shared/types';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styles from '../../styles.module.scss';
import { Button, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { getFileNameFromPath } from '../../../shared/utils/helpers';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddPortfolioDocumentModal from '../AddPortfolioDocumentModal';
import useAdminApi from '../../../shared/hooks/useAdminApi';
import { useNavigate } from 'react-router-dom';
import CustomTableBody from '../../../shared/components/table/CustomTableBody';
import { useTheme } from '@mui/material/styles';
import TableButton from '../../../shared/components/table/TableButton';

interface Props {
  snapshotId: number;
  documents: PortfolioDocumentAdmin[];
  disabled?: boolean;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export default function PortfolioDocuments({ snapshotId, documents, disabled = false, loading, setLoading }: Props) {
  const [open, setOpen] = useState(false);

  const api = useAdminApi();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: (documentId: number) => api.snapshots.deleteSnapshotDocument(documentId),
    onMutate: () => {
      setLoading(true);
    },
    onSettled: () => {
      setLoading(false);
    },
    onSuccess: async (newId) => {
      await queryClient.invalidateQueries(['adminFetchPortfolioSnapshotDetails', snapshotId]);
      enqueueSnackbar('Portfolio document deleted', { variant: 'success' });
      if (newId && newId !== snapshotId) {
        enqueueSnackbar('New snapshot draft created', { variant: 'success' });
        navigate(`/portfolio_manager/snapshots/${newId}`);
      }
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar('Failed to delete document', { variant: 'error' });
    },
  });

  const handleDelete = (documentId: number) => {
    deleteMutation.mutate(documentId);
  };

  const theme = useTheme();

  return (
    <div className={styles.filesSection__container}>
      <div className={styles.filesSection__header}>
        <Typography sx={{ color: theme.palette.primary.main }} variant="h2">
          Portfolio documents
        </Typography>
      </div>
      <TableContainer className={styles.filesSection__tableContainer}>
        <Table size="small">
          <TableHead>
            <TableCell sx={{ width: '40%' }}>File Name</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right" />
          </TableHead>
          <CustomTableBody
            data={documents}
            renderRow={({ id, name, url }) => (
              <TableRow key={name}>
                <TableCell sx={{ width: '40%' }}>
                  <Button
                    className={styles.filesSection__downloadButton}
                    fullWidth
                    onClick={() => api.snapshots.downloadDocument(id, getFileNameFromPath(url))}
                    variant="text"
                    endIcon={<DownloadIcon />}
                  >
                    {getFileNameFromPath(url)}
                  </Button>
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell align="right">
                  <TableButton
                    startIcon={<CloseIcon />}
                    onClick={() => handleDelete(id)}
                    disabled={disabled || loading}
                  >
                    Delete
                  </TableButton>
                </TableCell>
              </TableRow>
            )}
          />
        </Table>
      </TableContainer>
      <Button
        variant="outlined"
        color="grey"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
        disabled={disabled || loading}
      >
        Add portfolio document
      </Button>
      <AddPortfolioDocumentModal
        snapshotId={snapshotId}
        open={open}
        onClose={() => setOpen(false)}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
}
