import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../AuthLayout';
import ResetPasswordForm from './ResetPasswordForm';
import useUser from '../../shared/hooks/useUser';

export default function ResetPassword() {
  const { user } = useUser();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  if (user) {
    navigate('/profile');
  }

  return (
    <AuthLayout
      title="Forgot Password"
      errorMsg={error ? 'Failed te request password reset.' : ''}
      successMsg={success ? 'A password reset link has been sent to your email.' : ''}
    >
      <ResetPasswordForm setError={setError} setSuccess={setSuccess} />
    </AuthLayout>
  );
}
